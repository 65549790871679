import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import {
  Alert,
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";

import { STRING_KEYS } from "../constants";
import { getString, MarkdownString } from "../utils";

import "./StayInContact.scss";

class StayInContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showConfirmation: false,
      redirect: false
    };
  }

  handleClickStayInContact = () => {
    if (this.state.email.length > 0) {
      this.props
        .onClickStayInContact(this.state.email, this.props.source)
        .then(() => {
          if (!this.props.stayInContactError) {
            this.toggleConfirmation();
          }
        });
    }
  };

  handleOnChangeEmail = (e) => {
    this.setState({
      email: e.target.value
    });
  };

  handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.handleClickStayInContact();
    }
  };

  toggleConfirmation = () => {
    this.setState({
      showConfirmation: !this.state.showConfirmation
    });
  };

  handleConfirmModal = () => {
    this.setState({
      redirect: true
    });

    this.toggleConfirmation();
  };

  render() {
    const { showConfirmation, redirect } = this.state;

    const { redirectOnSuccess, stayInContactError } = this.props;

    if (redirect) {
      return <Redirect to={redirectOnSuccess} />;
    }

    return (
      <div className="StayInContact">
        <Label for="email-address">E-Mail-Adresse:</Label>
        <Input
          type="email"
          id="email-address"
          onChange={this.handleOnChangeEmail}
          onKeyPress={this.handleKeyPress}
          invalid={!!stayInContactError}
        />
        <Button color="primary" onClick={this.handleClickStayInContact}>
          in Kontakt bleiben
        </Button>
        {!!stayInContactError && (
          <Alert color="danger">
            {typeof stayInContactError === "object" ? (
              Object.values(stayInContactError).map((error, index) => (
                <p key={index} className="error">
                  {error}
                </p>
              ))
            ) : (
              <MarkdownString stringKey={STRING_KEYS.ERROR_GENERAL_CONTACT} />
            )}
          </Alert>
        )}
        <Modal isOpen={showConfirmation} toggle={this.toggleConfirmation}>
          <ModalBody>
            <p style={{ textAlign: "center", marginTop: "10px" }}>
              {getString(STRING_KEYS.STAY_IN_CONTACT_SUCCESS)}
            </p>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}>
            <Button
              color="primary"
              type="submit"
              onClick={this.handleConfirmModal}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

StayInContact.propTypes = {
  redirectOnSuccess: PropTypes.string.isRequired,
  onClickStayInContact: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  stayInContactError: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export { StayInContact };
