import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";
import { Field, formValueSelector, reduxForm } from "redux-form";

class Capacity extends Component {
  render() {
    const { plantInstalledCapacity, errorMessage } = this.props;

    return (
      <FormGroup className="capacity">
        <Label for="plantInstalledCapacity">Leistung (in kW)</Label>
        <Input
          type="number"
          tag={Field}
          name="plantInstalledCapacity"
          id="plantInstalledCapacity"
          component="input"
          min="0"
          invalid={
            !!errorMessage ||
            (plantInstalledCapacity && plantInstalledCapacity < 0)
          }
        />
        {errorMessage && <span className="error">{errorMessage}</span>}
      </FormGroup>
    );
  }
}

let reduxFormCapacity = reduxForm({
  form: "potentialCheck",
  destroyOnUnmount: false
})(Capacity);

const selector = formValueSelector("potentialCheck");
reduxFormCapacity = connect((state) => {
  const plantInstalledCapacity = selector(state, "plantInstalledCapacity");
  return {
    plantInstalledCapacity
  };
})(reduxFormCapacity);

export { reduxFormCapacity as Capacity };
