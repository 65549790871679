import { STRING_KEYS } from "../../constants";

import contactInfo from "./cells-energy/contact-info.md";
import howMuchPowerTooMuchText from "./cells-energy/how-much-power-too-much.md";
import marketingMaterialsText from "./cells-energy/marketing-materials-text.md";
import plantMarginsText from "./cells-energy/plant-margins-text.md";
import potentialCheckPlantTypeMorePlantsTooltip from "./cells-energy/potential-check-plant-type-more-plants-tooltip.md";
import potentialCheckSummaryNextStepsHasRlm from "./cells-energy/potential-check-summary-next-steps-has-rlm.md";
import potentialCheckSummaryNextStepsNoRlm from "./cells-energy/potential-check-summary-next-steps-no-rlm.md";

export const STRINGS = {
  [STRING_KEYS.COMPANY_LEGAL_NAME]: "renergie GmbH",
  [STRING_KEYS.CONFIRM_EMAIL_SUCCESS]: "Deine E-Mail-Adresse wurde bestätigt!",
  [STRING_KEYS.CONFIRM_EMAIL_BUTTON]: "Weiter zu deiner Anlagen",
  [STRING_KEYS.CONSUMER_CONTRACT_ALREADY_CANCELLED]:
    "Hast du bei deinem aktuellen Versorger bereits gekündigt?",
  [STRING_KEYS.CONSUMER_CONTRACT_ALREADY_CANCELLED_TOOLTIP]:
    "Läuft deine Kündigungsfrist in Kürze ab? Kündige in einem solchen Fall bitte selbst bei deinem aktuellen Versorger, um eine ungewollte Verlängerung deines aktuellen Vertrags zu vermeiden! Dazu reicht schon eine kurze E-Mail an den Kundenservice deines aktuellen Versorgers.",
  [STRING_KEYS.CONSUMER_CONTRACT_MOVING_TOOLTIP]: `Um deine Entnahmestelle problemlos Anmeldung zu können, benötigen wir das genaue Datum deines Einzugs. Die Ummeldung zum cells energy Marktplatz ist bis zu 6 Wochen nach Einzugsdatum möglich. Liegt das Datum schon länger zurück, wähle bitte als Grund "Versorgerwechsel"!`,
  [STRING_KEYS.CONSUMER_CONTRACT_YOUR_MIX]: "Dein Mix",
  [STRING_KEYS.CONSUMER_CONTRACT_CONNECTION_POINT]: "Darunter Abnahmestelle",
  [STRING_KEYS.CONSUMER_CONTRACT_AGB]:
    "Die [allgemeinen Geschäftsbedingungen für die Lieferung von Strom]({agbPdf}) habe ich gelesen und erkläre mich vollumfänglich damit einverstanden.",
  [STRING_KEYS.CONSUMER_CONTRACT_PRIVACY]:
    "Die [Bestimmungen zum Datenschutz](/privacy) habe ich gelesen und erkläre mich vollumfänglich damit einverstanden.",
  [STRING_KEYS.CONSUMER_CONTRACT_RIGHT_OF_CANCELLATION]:
    "Ich habe die [Widerrufsbelehrung]({rightOfCancellationPdf}) gelesen und verstanden, dass ich ein 14-tägiges Widerrufsrecht habe.",
  [STRING_KEYS.CONSUMER_CONTRACT_SEPA_TEXT]:
    "Ich/Wir ermächtigen die renergie GmbH bis auf Widerruf, Zahlungen von meinem/unserem angegebenen Konto durch SEPA Lastschrift abzubuchen. Vor dem ersten Einzug einer SEPA-Basislastschrift wird mich die renergie GmbH über den Einzug in dieser Verfahrensart unterrichten und mir die Mandatsreferenz mitteilen.",
  [STRING_KEYS.CONSUMER_CONTRACT_USE_NAME_IN_PRODUCER_NOTIFICATION]:
    "Dürfen wir den Betreibern in deinem Mix deinen Namen mitteilen? Keine Sorge, Adresse und Kontaktdaten bleiben trotzdem geschützt! Wenn du diesen Punkt nicht auswählst, bleibst du anonym und wir übermitteln deinen Lieferanten nur deine PLZ und die Höhe deines Betreiber-Zuschusses.",
  [STRING_KEYS.CONSUMER_CONTRACT_SUCCESS_MESSAGE]:
    "Herzlichen Glückwunsch! Du hast dir deinen persönlichen Mix gesichert. Eine Auftragsbestätigung haben wir dir bereits per E-Mail zugeschickt.\n\nWilkommen zu Hause in der cells energy Community!",
  [STRING_KEYS.CONSUMER_CONTRACT_DELIVERY_START_DATE]:
    "Startdatum Belieferung Marktplatz",
  [STRING_KEYS.CONTACT_MESSAGE_LABEL]: "Deine Nachricht",
  [STRING_KEYS.CONTACT_INFO]: contactInfo,
  [STRING_KEYS.CONTACT_PRIVACY]:
    "Mit dem Absenden einer Anfrage erklärst du, dass du mit der Nutzung deiner personenbezogenen Daten gem. unserer [Datenschutzerklärung](/privacy) einverstanden bist.",
  [STRING_KEYS.CONTACT_SUCCESS]: "Deine Nachricht wurde erfolgreich gesendet.",
  [STRING_KEYS.CREATE_MIX_TITLE]: "Wähle deinen Mix",
  [STRING_KEYS.CREATE_MIX_INTRO]:
    "Unser automatischer Marktplatz-Agent hat dir schon einen Mix von Anlagen in deiner Nähe erstellt. Passe die Auswahl an, indem du die Einstellungen bei „aktueller Mix“ veränderst. Du kannst auch direkt auf der Karte oder der Liste darunter die Erzeuger deines Vertrauens auswählen. Ganz wie du es willst!",
  [STRING_KEYS.CREATE_MIX_CHOOSE_PLANT]:
    "Bitte wähl einen Lieferanten für deinen Mix.",
  [STRING_KEYS.CREATE_MIX_MIX_CHART_TITLE_1]: "Das können deine",
  [STRING_KEYS.CREATE_MIX_MIX_CHART_TITLE_2]: "Betreiber liefern:",
  [STRING_KEYS.EDIT_POWER_PLANT_TITLE]: "Erstelle das Profil für deine Anlage",
  [STRING_KEYS.EDIT_POWER_PLANT_NAME_INFO]:
    "Wähle einen möglichst greifbaren, aussagekräftigen Namen.",
  [STRING_KEYS.EDIT_POWER_PLANT_NAME_INFO_TOOLTIP]:
    "Nutze regionale Ortsbezeichnungen und Besonderheiten. „Windfarm Heiligenfeld“ transportiert viel mehr Emotionen als „Windpark A7 Nord“ und „Bioenergie und Nahwärme Huber“ klingt deutlich runder als „Biogasanlage Huber“.",
  [STRING_KEYS.EDIT_POWER_PLANT_DESCRIPTION_INFO]:
    "Wenn dein Kunde von Namen und Bildern angesprochen wurde, möchte er wissen, was er bei dir bekommt. Hebe dein besonderes Engagement hervor. Zeige dich authentisch und menschlich. Sage, was dir wichtig ist und was dein Kunde unterstützt, wenn er deine Investitionsbonus bezahlt. Pioniergeist, Ökoogie und Idealismus sind wichtige Merkmale für deinen Erfolg!\n\nDu bist nicht gut darin, Texte zu verfassen? Wir helfen dir, das optimale Konzept für deine Außendarstellung zu erarbeiten.",
  [STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_1]: "Mache professionelle Fotos",
  [STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_1_TOOLTIP]:
    "Werbung und Hochglanz gehören untrennbar zusammen. Um Erfolg zu haben, brauchst du eine professionesse Selbstdarstellung. Wir empfehlen dir, einmalig ein paar Euro für einen Fotografen auszugeben, dann wird dein Anlagenprofil die besten Ergebnisse bringen! Und wenn du noch eins drauf setzen willst, mach ein professionelles Dronenvideo und lade es hoch!",
  [STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_2]: "Zeige dich als Betreiber",
  [STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_2_TOOLTIP]:
    "Es ist entscheidend für deinen Erfolg bei der Kundenakquise, dass du dich und deine Anlage optimal darstellst. Wichtig ist dem Kunden der Bezug zu realen Personen. Also scheu dich nicht davor, dich selbst fotografieren zu lassen! Zeig dich voll Stolz, denn du hilfst mit, die Welt für unsere Kinder zu bewahren!",
  [STRING_KEYS.EDIT_POWER_PLANT_PROFILE_VISIBILITY_TITLE]:
    "Sichtbarkeit deines Profils",
  [STRING_KEYS.EDIT_POWER_PLANT_NOT_APPROVED_WARNING]:
    "Wir würden gerne auch noch einen kurzen Blick auf dein Profil werfen, bevor es veröffentlicht wird. Wir lassen es dich wissen, sobald es veröffentlicht werden kann!",
  [STRING_KEYS.EDIT_POWER_PLANT_HELP_MODAL_TITLE]: "Brauchst du Hilfe?",
  [STRING_KEYS.EDIT_POWER_PLANT_HELP_MODAL_TEXT]:
    "Brauchst du eine Idee, wie dein Profil aussehen könnte? Sieh dir [dieses Beispielprofil](https://marktplatz.cells.energy/strom-kaufen/anlagen/85ga8/bioenergie-und-nahwarme-karrer-woringen) an!",
  [STRING_KEYS.ERROR_FIRST_NAME_MISSING]: "Bitte gib einen Vornamen ein.",
  [STRING_KEYS.ERROR_LAST_NAME_MISSING]: "Bitte gib einen Nachnamen ein.",
  [STRING_KEYS.ERROR_TITLE_MISSING]: "Bitte wähle eine Anrede.",
  [STRING_KEYS.ERROR_EMAIL_MISSING]: "Bitte gib eine E-Mail-Adresse ein.",
  [STRING_KEYS.ERROR_EMAIL_INVALID]:
    "Bitte gib eine gültige E-Mail-Adresse ein.",
  [STRING_KEYS.ERROR_PASSWORD_MISSING]: "Bitte gib ein gültiges Passwort ein.",
  [STRING_KEYS.ERROR_PASSWORD_NO_MATCH]: "Beide Passwörter müssen stimmen.",
  [STRING_KEYS.ERROR_STREET_MISSING]: "Bitte gib eine Straße ein.",
  [STRING_KEYS.ERROR_CITY_MISSING]: "Bitte gib einen Ort ein.",
  [STRING_KEYS.ERROR_PHONE_MISSING]: "Bitte gib eine Telefonnummer ein.",
  [STRING_KEYS.ERROR_COMPANY_MISSING]: "Bitte gib den Namen deiner Firma ein.",
  [STRING_KEYS.ERROR_CONTRACT_OWNER_MISSING]:
    "Bitte gib den Namen des Besitzers ein.",
  [STRING_KEYS.ERROR_CONTRACT_STREET_MISSING]:
    "Bitte gib die Straße des Besitzers ein.",
  [STRING_KEYS.ERROR_CONTRACT_CITY_MISSING]:
    "Bitte gib den Ort des Besitzers ein.",
  [STRING_KEYS.ERROR_CONTRACT_PHONE_MISSING]:
    "Bitte gib die Telefonnummer des Besitzers ein.",
  [STRING_KEYS.ERROR_CONTRACT_EMAIL_MISSING]:
    "Bitte gib eine gültige E-Mail-Adresse ein.",
  [STRING_KEYS.ERROR_PLANT_TYPE_MISSING]: "Bitte wähle einen Anlagentyp.",
  [STRING_KEYS.ERROR_INSTALLED_CAPACITY_MISSING]:
    "Bitte gib die Leistung der Anlage ein.",
  [STRING_KEYS.ERROR_YEARLY_ENERGY_MISSING]:
    "Bitte gib die Energiemenge pro Jahr der Anlage ein.",
  [STRING_KEYS.ERROR_PLANT_STREET_MISSING]:
    "Bitte gib die Straße der Anlage ein.",
  [STRING_KEYS.ERROR_PLANT_CITY_MISSING]: "Bitte gib den Ort der Anlage ein.",
  [STRING_KEYS.ERROR_MARKET_LOCATION_ID_INVALID]:
    "Bitte gib eine gültige Marktlokation-ID der Anlage ein. Wenn die Marktlokation-ID gerade nicht vorliegt, kann das Feld auch leer gelassen werden.",
  [STRING_KEYS.ERROR_DMSP_MISSING]:
    "Bitte gib den aktuellen Direktvermarkter der Anlage ein.",
  [STRING_KEYS.ERROR_MARGIN_MISSING]:
    "Bitte gib deine gewünschte Investitionsbonus ein.",
  [STRING_KEYS.ERROR_IBAN_MISSING]: "Bitte gib deine IBAN ein.",
  [STRING_KEYS.ERROR_BIC_MISSING]: "Bitte gib die BIC deiner Bank ein.",
  [STRING_KEYS.ERROR_BANK_STREET_MISSING]:
    "Bitte gib die Straße des Kontoinhabers ein.",
  [STRING_KEYS.ERROR_BANK_CITY_MISSING]:
    "Bitte gib den Ort des Kontoinhabers ein.",
  [STRING_KEYS.ERROR_START_DATE_MISSING]: "Bitte gib ein Startdatum ein.",
  [STRING_KEYS.ERROR_REASON_FOR_CHANGE_MISSING]:
    "Bitte wähle den Grund für den Wechsel.",
  [STRING_KEYS.ERROR_AGREEMENT_MISSING]:
    "Wir benötigen hier bitte dein Einverständnis.",
  [STRING_KEYS.ERROR_NO_OPTION_SELECT]: "Bitte wähle eine Option.",
  [STRING_KEYS.ERROR_MESSAGE_MISSING]: "Bitte schreib eine Nachricht.",
  [STRING_KEYS.ERROR_INVALID_DATA]: "Fehler! Bitte überprüfe deine Daten.",
  [STRING_KEYS.ERROR_REPORT_TEXT]:
    "Bitte schreib [info@cells.energy](mailto:info@cells.energy) mit diesem Fehler: {submitErrors}",
  [STRING_KEYS.ERROR_GENERAL_CONTACT]:
    "Es ist ein Fehler aufgetreten. Bitte kontaktiere [info@cells.energy](mailto:info@cells.energy) für weitere Informationen.",
  [STRING_KEYS.ERROR_GENERAL_RETRY]:
    "Es ist ein Fehler aufgetreten. Bitte versuche es noch mal.",
  [STRING_KEYS.ERROR_UNKNOWN]:
    "Unbekannter Fehler! Bitte schreib [info@cells.energy](mailto:info@cells.energy) für Hilfe.",
  [STRING_KEYS.EXTENDED_MIX_INFO_TAB_OVERVIEW]: "Mixübersicht",
  [STRING_KEYS.EXTENDED_MIX_INFO_TAB_PRODUCERS]: "Stromlieferanten",
  [STRING_KEYS.EXTENDED_MIX_INFO_CONTRACT_DURATION_TITLE]: "Vertragslaufzeit",
  [STRING_KEYS.EXTENDED_MIX_INFO_CONTRACT_DURATION]:
    "Erstlaufzeit 4 Monate, danach monatlich kündbar",
  [STRING_KEYS.EXTENDED_MIX_INFO_NOTICE_PERIOD]: "1 Monat zum Monatsende",
  [STRING_KEYS.EXTENDED_MIX_INFO_TOTAL_PRICE_PER_YEAR]: "Gesamtpreis pro Jahr",
  [STRING_KEYS.EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH]: "Preis pro Monat",
  [STRING_KEYS.FACEBOOK_URL]:
    "https://www.facebook.com/Cells-energy-106900964011798/",
  [STRING_KEYS.FULL_MIX]:
    "Ihr Mix ist bereits voll. Soll die Anlage an Position 5 Ihres Mixes ersetzt werden?",
  [STRING_KEYS.GET_A_QUOTE_PLZ]: "Deine PLZ",
  [STRING_KEYS.GET_A_QUOTE_PLZ_NO_SUPPLY]:
    "Zur Zeit ist im gewählten Gebiet leider keine Belieferung möglich.", // when creating a new whitelabel, use oberland17 or swl as template for this string
  [STRING_KEYS.GET_A_QUOTE_PLZ_INVALID]:
    "Bitte gib eine gültige Postleitzahl ein.",
  [STRING_KEYS.HOW_MUCH_POWER_PRIVATE_TITLE]:
    "Wie viele Personen wohnen in deinem Haushalt?",
  [STRING_KEYS.HOW_MUCH_POWER_BUSINESS_TITLE]: "Wie viel Strom brauchst du?",
  [STRING_KEYS.HOW_MUCH_POWER_TOO_MUCH]: howMuchPowerTooMuchText,
  [STRING_KEYS.HOW_MUCH_POWER_TOO_MUCH_IMAGE]: "{partnerImage}",
  [STRING_KEYS.INSTAGRAM_URL]: "https://www.instagram.com/cellsenergy/",
  [STRING_KEYS.LANDING_CONSUMER_TITLE]:
    "Entscheide selbst bei wem du Strom kaufst!",
  [STRING_KEYS.LANDING_CONSUMER_SUBTITLE]:
    "Unterstütze erneuerbare Stromerzeuger aus deiner Nachbarschaft.",
  [STRING_KEYS.LANDING_CONSUMER_MAP_TITLE]:
    "Nachhaltige Stromproduzenten auf dem Marktplatz",
  [STRING_KEYS.LANDING_CONSUMER_MAP_TEXT]:
    "Du möchtest als erneuerbarer Stromproduzent am Marktplatz teilnehmen? Dann klicke [hier](/strom-verkaufen)!",
  [STRING_KEYS.LANDING_CONSUMER_BENEFITS_TITLE]:
    "Deine Marktplatz-Vorteile auf einen Blick",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_1_TITLE]: "Gut fürs Klima",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_1_TEXT]:
    "Wenn du deinen Strom über den Marktplatz kaufst, förderst du direkt erneuerbare Stromproduzenten deiner Wahl. Diese Stromproduzenten betreiben Solar-, Windkraft-, Wasserkraft- oder Biogasanlagen. Auf deiner Stromrechnung siehst du direkt, welche erneuerbaren Stromproduzenten du dabei unterstützt, ihre Anlagen dauerhaft zu betreiben. So trägst du unmittelbar dazu bei, dass wir zukünftig auf klimaschädliche Kohlekraftwerke verzichten können.",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_2_TITLE]: "Absolut transparent",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_2_TEXT]:
    "Das ist ziemlich einmalig: auf dem Marktplatz bekommst du Strom zum Börsenpreis! Normalerweise kaufen professionelle Händler und Energiekonzerne zu solchen Konditionen ein – auf dem Marktplatz profitierst du selbst als Privatperson von niedrigen Börsenpreisen. Dadurch kann deine monatliche Rechnung zwar schwanken, im Schnitt liegst du aber deutlich günstiger als in normalen Stromtarifen. Die Börsenpreise findest du täglich [hier](https://www.epexspot.com/de/marktdaten/intradayauktion) und monatlich vollständig transparent auf deiner Rechnung.",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_3_TITLE]: "Voll flexibel",
  [STRING_KEYS.LANDING_CONSUMER_BENEFIT_3_TEXT]:
    "Mindestlaufzeiten gibt es bei uns nicht! Du kannst monatlich deinen Vertrag wieder kündigen oder einen neuen Mix von erneuerbaren Stromproduzenten, die du unterstützen möchtest wählen.",
  [STRING_KEYS.LANDING_CONSUMER_FOOTER]:
    "Du bist überzeugt? Willkommen zu Hause! [Werde Teil deiner Strom-Community](#consumer-landing-header).",
  [STRING_KEYS.LANDING_PRODUCER_ANLAGEN_CHECK_CAPTION]:
    "Prüfe mit ein paar Klicks, ob deine Anlage für den Marktplatz geeignet ist!",
  [STRING_KEYS.LANDING_PRODUCER_INFO_1_TITLE]: "Direktvermarktung Plus!",
  [STRING_KEYS.LANDING_PRODUCER_INFO_1_TEXT]:
    "Kennst du die Direktvermarktung nach EEG? Jeder darf in Deutschland seinen Strom direkt vermarkten und gleichzeitig die EEG-Vergütung erhalten. Doch wirklich viel ändert sich dadurch nicht! Wir wollen mehr als das! Darum bietet Dir der cells energy Marktplatz die Direktvermarktung Plus! Du bleibst im herkömmlichen, erprobten EEG Vermarktungssystem. Du kannst sogar deinen bisherigen Vermarkter behalten falls du schon einen hast. Selbst deine Vergütung erhältst du weiterhin aus denselben Händen und in derselben Höhe.",
  [STRING_KEYS.LANDING_PRODUCER_INFO_2_TITLE]:
    "Dazu gewinnst du aber die Chance, endlich wirklich direkt an Endkunden zu vermarkten.",
  [STRING_KEYS.LANDING_PRODUCER_INFO_2_TEXT]:
    "Bau deinen eigenen Kundestamm auf! Bereite dich vor auf die Zeit nach dem Ende deiner EEG-Vergütung! Und das beste: Ganz nebenbei kannst du jetzt schon Mehrerlöse generieren!",
  [STRING_KEYS.LANDING_PRODUCER_INFO_3_TITLE]: "100% sicher",
  [STRING_KEYS.LANDING_PRODUCER_INFO_3_TEXT]:
    "Da du das bewährte „Marktprämiensystem“ des EEG benutzt, bleibt alles genauso sicher wie bisher. Wir ermitteln den Anteil deines Stroms, den du auf dem cells energy Marktplatz verkaufen kannst. Solange du noch im EEG bist, liefern wir diese Menge als Ersatzlieferung. Ab dem ersten Tag nach deiner EEG-Laufzeit erhalten deine Kunden dann automatisch direkt den Grünstrom aus deiner Anlage – ¼-Stunden genau!",
  [STRING_KEYS.LANDING_PRODUCER_INFO_4_TITLE]: "100% einfach",
  [STRING_KEYS.LANDING_PRODUCER_INFO_4_TEXT]:
    "Die gesamte Abwicklung machen wir für dich, du brauchst nichts zu tun außer außer uns dabei zu unterstützen, Kunden für dich zu finden! Dazu lädst du Fotos und Texte über dich und deine Anlage hoch und baust damit deine Werbeplattform. Einfache Möglichkeiten zur Kundenwerbung geben wir Dir an die Hand. Und zudem bietet der Marktplatz ganz automatisch Neukunden deine Anlage an.",
  [STRING_KEYS.LANDING_PRODUCER_INFO_5_TITLE]: "100% günstig",
  [STRING_KEYS.LANDING_PRODUCER_INFO_5_TEXT]:
    "Tausende Anlagen in Deutschland suchen Vermarktungssplattformen! Die große Herausforderung ist es, Kunden zu finden! Wir bieten Dir die Möglichkeit, deinen Kundenstamm aufzubauen für nur 12,50 € netto je Monat an.\n\n**AKTION bis 30.06.2019! Marktplatz-Pioniere bekommen die Gebühren gutgeschrieben!** Der Marktplatz soll sich schnell füllen, um attraktiv für Verbraucher zu werden. Für die ersten Betreiber schreiben wir daher die Marktplatzgebühr für die restlichen Monate in 2019 auf dem Kundenkonto gut und verrechnen sie, sobald genügend Strom für eine Umlage auf den Strompreis gehandelt wird. Also sichere dir jetzt gleich deinen Platz als Marktplatzpionier!",
  [STRING_KEYS.LANDING_PRODUCER_INFO_6_TITLE]: "Mach mit bei deinem Marktplatz",
  [STRING_KEYS.LANDING_PRODUCER_INFO_6_TEXT]:
    "cells energy wird initiiert und finanziert von [renergie Allgäu e. V.](http://www.renergie-allgaeu.de), einem gemeinnützigen Energiewendeverein der ersten Stunde mit 25 Jahren Tradition. Mach auch du mit! Gemeinsam bauen wir die Plattform ständig weiter mit dem Ziel, die bürgerliche Strombörse für Deutschland zu erschaffen. Wenn das gelingt, „enteignen“ wir uns freiwillig und geben die Kontrolle über den Marktplatz an die Community der Nutzer ab. Freier Handel von Strom ohne Konzerne! Die „reine Lehre“ des zellulären Ansatzes der Energiewende: cells energy!",
  [STRING_KEYS.LANDING_PRODUCER_FOOTER]:
    "Überzeugt? Dann melde deine Anlage jetzt an!",
  [STRING_KEYS.LOGIN_TITLE]: "Betreiber login",
  [STRING_KEYS.LOGIN_FORGOT_PASSWORD_TITLE]: "Passwort vergessen",
  [STRING_KEYS.LOGIN_FORGOT_PASSWORD_REQUESTED]:
    "Die E-Mail zum Zurücksetzen des Passwortes wurde verschickt.",
  [STRING_KEYS.LOGIN_SUBMIT_BUTTON]: "Login",
  [STRING_KEYS.MARKETING_MATERIALS_MODAL_TITLE]:
    "Wir helfen dir, deine Anlagen zu vermarkten!",
  [STRING_KEYS.MARKETING_MATERIALS_TEXT_LONG]:
    "Wir helfen dir, deine Anlagen zu vermarkten! " + marketingMaterialsText,
  [STRING_KEYS.MARKETING_MATERIALS_TEXT]: marketingMaterialsText,
  [STRING_KEYS.MENU_ABOUT]: "Über uns",
  [STRING_KEYS.MENU_FAQ]: "Fragen und Antworten",
  [STRING_KEYS.MENU_STROM_KAUFEN]: "Strom kaufen",
  [STRING_KEYS.PLANT_MARGINS_TEXT]: plantMarginsText,
  [STRING_KEYS.POTENTIAL_CHECK_PLANT_TYPE_TITLE]: "Daten zu deiner Anlage",
  [STRING_KEYS.POTENTIAL_CHECK_PLANT_TYPE_MORE_PLANTS_TOOLTIP]:
    potentialCheckPlantTypeMorePlantsTooltip,
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_TITLE]:
    "Wähle deinen aktuellen Direktvermarkter",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_MORE_DMSPS_BUTTON]: "Weitere Vermarkter",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_NO_DMSP_BUTTON]:
    "Ich habe noch keinen Vermarkter",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_MORE_DMSPS_SUBHEADER]:
    "Weitere Vermarkter:",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_MODAL_LINK]:
    "https://www.renergie-allgaeu-pool.de/",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_MORE_DMSPS_MODAL]:
    "Um auf dem cells energy Marktplatz zu vermarkten, kannst du in der Regel bei deinem Vermarkter bleiben. Bei unseren Marktplatzpartnern erhältst du besonders gute Konditionen und geprüfte Verträge mit neutraler Betreuung durch renergie Allgäu e. V. im Pool.",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_NO_DMSP_MODAL]:
    "Bei unseren Marktplatzpartnern erhältst du besonders gute Konditionen und geprüfte Verträge mit neutraler Betreuung durch renergie Allgäu e. V. im Pool.",
  [STRING_KEYS.POTENTIAL_CHECK_DMSP_NO_DMSP_MESSAGE]:
    "In diesem Fall, sag uns bitte:",
  [STRING_KEYS.POTENTIAL_CHECK_MARGIN_TITLE]: "Wähle deinen Investitionsbonus",
  [STRING_KEYS.POTENTIAL_CHECK_MARGIN_HOW_GET_TOOLTIP_TEXT]:
    "Wir nehmen an, dass du bis zu 80% deines Stroms auf dem Marktplatz verkaufen kannst. Es wird aber etwas dauern, das zu erreichen! Viele Betreiber machen mit und der Absatzmarkt ist erst in der Entstehung. Investiere jetzt in deine Zukunft und nutze deinen Vorsprung, sobald es richtig los geht!",
  [STRING_KEYS.POTENTIAL_CHECK_MARGIN_WHAT_IS_BONUS_TOOLTIP_TEXT]:
    "Solange du (noch) eine EEG-Vergütung erhältst, darfst du gesetzlich den Strom nicht an Endkunden verkaufen. Darum liefern wir übergangsweise eine Ersatzlieferung nach dem deutschen Strommix (Graustrom). Obendrauf leistet dein Kunde freiwillig einen Investitionsbonus. Der ist dazu bestimmt, dass du deine Anlage dauerhaft fit hältst. Denn sobald dein EEG-Vergütungszeitraum endet, möchte dein Kunde regionalen Grünstrom vor dir erhalten.",
  [STRING_KEYS.POTENTIAL_CHECK_MARGIN_WHY_LIMIT_TOOLTIP_TEXT]:
    "Wir haben den Investitionsbonus auf 2 ct/kWh begrenzt, denn das ist für Endkunden schon an der Schmerzgrenze. Wir empfehlen dir, zu Beginn auf 0,3 ct/kWh oder weniger zu gehen. Erstes Ziel ist viele Kunden zu finden und das geht besser wenn es günstiger ist.",
  [STRING_KEYS.POTENTIAL_CHECK_MARGIN_WHY_TWENTY_TOOLTIP_TEXT]:
    "Wenn Deine 20 Jahre EEG um sind, hast du keine Zeit mehr, Kunden zu suchen. Denn das geht nicht so schnell und du musst für den Weiterbetrieb Investitionssicherheit haben. Darum nutze die Zeit und lerne die Kunst, dein Konzept und deinen Strom zu vermarkten. Und nebenbei helfen dir die Zusatzerlöse, die du jetzt schon erhältst, deine Anlage fit zu halten!",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_YES_DMSP]:
    "Glückwunsch! Deine Anlage sollte bestens geeignet sein, um am cells energy Marktplatz teilzunehmen.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_HAS_RLM]:
    "Glückwunsch! Deine Anlage erfüllt einige wichtige Voraussetzungen für die Teilnahme am cells energy Marktplatz. Die Vermarktung über den Marktplatz bietet bereits kurzfristig einen finanziellen Mehrwert – trotz noch erforderlicher Investitionen.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_NO_RLM]:
    "Glückwunsch! Deine Anlage hat gute Voraussetzungen für die Teilnahme am cells energy Marktplatz. Die Vermarktung über den Marktplatz bietet bereits kurzfristig einen finanziellen Mehrwert – trotz noch erforderlicher Investitionen. Du musst aber noch einige Formalitäten vorab erledigen. Die jährlichen Kosten dafür sollten unter dem erwarteten Mehrerlös liegen.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_NO_VALUE]:
    "Es geht beim cells energy Marktplatz zwar nicht allein um Gewinne, aber für deine Anlage scheinen die Chancen auf einen kurzfristigen finanziellen Mehrwert noch gering. Ausschlaggebend sind (hauptsächlich) für diese Vermarktungsart erforderliche Investitionen in die Anlageninfrastruktur.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_PARTNER_DMSP]:
    "Melde dich jetzt an und gestalte dein Anlagenprofil! Ein paar Angaben im Formular auf der nächsten Seite sind alles, was wir dafür von dir brauchen.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_OTHER_DMSP]:
    "Melde dich jetzt an und gestalte dein Anlagenprofil! Ein paar Angaben im Formular auf der nächsten Seite sind alles, was wir dafür von dir brauchen.\n\n**Beachte:** Wir haben festgestellt, dass dein aktueller Direktvermarkter noch keine Kooperation mit uns vereinbart hat. Diese Vereinbarung ist für eine reibungslose Abwicklung der energiewirtschaftlichen Prozesse notwendig. Wir setzen uns nach deiner Anmeldung umgehend mit {plantDirectMarketer} in Verbindung und regeln das für dich.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_HAS_RLM]:
    potentialCheckSummaryNextStepsHasRlm,
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_RLM]:
    potentialCheckSummaryNextStepsNoRlm,
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE]:
    "Wir empfehlen dir also, mit der Teilnahme am Marktplatz noch zu warten. Lass uns einfach deine E-Mail-Adresse da! Wir speichern deine Eingabe und informieren dich, sobald wir eine für dich wirtschaftliche Lösung anbieten können.",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE_INFO]:
    "Wenn du uns erlaubst, deine eigegebenen Daten zu speichern, kennen wir deine Anlagenkonstellation. Wir arbeiten dann mit Hochdruck daran, günstigere Lösungen zu erarbeiten, zum Beispiel für Mess- oder Fernsteuerkonzepte. Und wir wirken über unsere Mutter ‚renergie Allgäu e. V.‘ auf die Politik ein, um dir den Weg in deine Teilhabe am Energiemarkt der Zukunft zu ebnen!\n\nAlso lass dich nicht von deinem Ziel abbringen! Registriere dich unverbindlich und mach mit beim Bau unseres gemeinsamen Marktplatzes – von Bürgern für Bürger!",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE_INFO_TITLE]:
    "Warum solltest du mit uns in Kontakt bleiben?",
  [STRING_KEYS.POTENTIAL_CHECK_SUMMARY_CONFIRMATION_MODAL_TEXT]:
    "Erst wenn du deinen Direktvermarkter hinzugefügt hast, kannst du Strom über den Marktplatz verkaufen!",
  [STRING_KEYS.PRODUCER_CONTRACT_INTRO]:
    "Glückwunsch! Du bist kurz davor, deine Vermarktung an Endkunden zu beginnen! Wir brauchen nur noch ein Paar Daten von Dir, um dein Kundenkonto anlegen zu können. Sofort nach dem Klick auf „Vertrag abschließen“ wird deine Vermarktungsplattform frei geschalten.",
  [STRING_KEYS.PRODUCER_CONTRACT_INTRO_NEW_PLANT]:
    "Bitte trage hier deine Daten ein.",
  [STRING_KEYS.PRODUCER_CONTRACT_AGB]:
    "Die [allgemeinen Geschäftsbedingungen für die Teilnahme am cells energy Marktplatz]({agbPdf}) habe ich gelesen und erkläre mich vollumfänglich damit einverstanden.",
  [STRING_KEYS.PRODUCER_CONTRACT_PRIVACY]:
    "Die [Bestimmungen zum Datenschutz](/privacy) habe ich gelesen und erkläre mich vollumfänglich damit einverstanden.",
  [STRING_KEYS.PRODUCER_CONTRACT_RIGHT_OF_CANCELLATION]:
    "Ich habe die [Widerrufsbelehrung]({rightOfCancellationPdf}) gelesen und verstanden, dass ich ein 14-tägiges Widerrufsrecht habe.",
  [STRING_KEYS.PRODUCER_CONTRACT_SEPA_TEXT]:
    "Ich/Wir ermächtigen die renergie GmbH bis auf Widerruf, Zahlungen von meinem/unserem angegebenen Konto durch SEPA Lastschrift abzubuchen. Vor dem ersten Einzug einer SEPA-Basislastschrift wird mich die renergie GmbH über den Einzug in dieser Verfahrensart unterrichten und mir die Mandatsreferenz mitteilen.",
  [STRING_KEYS.PRODUCER_CONTRACT_SUCCESS]:
    "Glückwunsch! Du hast dein Kundenkonto erfolgreich angelegt!",
  [STRING_KEYS.PRODUCER_CONTRACT_SUCCESS_NO_DMSP]:
    "Es sind nur noch einige Kleinigkeiten zu erledigen, bevor du mit der Vermarktung starten kannst.\n\nDu brauchst noch einen Direktvermarkter, um mit dem Verkauf loslegen zu können. Geprüfte Verträge und unabhängige Unterstützung sowie die Auswahl zwischen 5 der wichtigsten Direktvermarkter findest du im renergie Anlagen Pool [hier](http://renergie-allgäu.de/)!\n\nFolge dem Link uns sobald du den Vertrag mit deinem Vermarkter abgeschlossen hast, komm hierher zurück. Wir schicken Dir zur Erinnerung den Link auf diese Seite per Email.",
  [STRING_KEYS.PRODUCER_CONTRACT_SUCCESS_PLANT_BUTTON]: "Deine Anlage",
  [STRING_KEYS.PRODUCER_CONTRACT_ERROR_ACCOUNT_NUMBER]:
    "Wenn du die Nummer einträgst erleichterst du deinem Direktvermarkter die Zuordnung. Das kann die Anmeldung beschleunigen.",
  [STRING_KEYS.RESET_PASSWORD_TEXT]: "Bitte gib ein neues Passwort ein.",
  [STRING_KEYS.STAY_IN_CONTACT_SUCCESS]: "Danke! Bitte prüfe deine E-Mails.",
  [STRING_KEYS.CONSUMER_CONTRACT_SUBMIT]: "Vertrag jetzt abschließen",
  [STRING_KEYS.WHY_POWER_TITLE]: "Wofür brauchst du Strom?",
  [STRING_KEYS.WIDERRUFSBELEHRUNG_TEXT]: "Widerrufsbelehrung",
  [STRING_KEYS.YOUR_MIX]: "Dein Mix"
};
