/* global THEME_VARS */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ReactSpeedometer from "react-d3-speedometer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";

import "./PowerPlant.scss";
import { ENERGY_TYPES, getEnergyTypeFromPlantType } from "../constants.js";
import { BackButton } from "../components/BackButton";
import { AddToMixWidget } from "./consumer/AddToMixWidget";
import { ShowWithMixesWidget } from "./consumer/ShowWithMixesWidget";
import { PowerPlantGoogleMap } from "../components/PowerPlantGoogleMap";
import {
  getContractByIdAction,
  getPowerPlantByIdAction,
  getPowerPlantsAction,
  resetContractGetByIdErrorAction,
  resetPowerPlantGetByIdErrorAction
} from "../actions";
import {
  getPowerPlantMainImageInWideFormat,
  LinkToProfilePageWithBack,
  getProducerDisplayName
} from "../utils.js";
import { EditPowerPlant } from "./producer/EditPowerPlant.js";
import { DMSP_LIST, DMSP_PARTNERS } from "./producer/PotentialCheckDmsp";

class PowerPlant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditModalOpen: false,
      redirectSlugName: null,
      redirectTo: null
    };
  }

  updatePowerPlantData() {
    const id = this.props.match.params.powerPlantId;

    if (this.shouldUsePrivateApi()) {
      this.props.getContractById(id);
    } else {
      this.props.getPowerPlantById(id);
    }
  }

  shouldUsePrivateApi() {
    return this.props.match.path.indexOf("strom-verkaufen") > 0;
  }

  calculateMaxSpeedometerValue(capacity) {
    let max;

    if (capacity > 5000) {
      max = capacity;
    } else if (capacity > 1000) {
      max = 5000;
    } else {
      max = 1000;
    }

    return max;
  }

  componentDidUpdate(prevProps) {
    // check if the contract was renamed (i.e. the slug was changed) and forward to the new one
    if (prevProps.contracts) {
      const prevContract = prevProps.contracts.find(
        (contract) =>
          prevProps.match.params.powerPlantSlug === contract.slugName
      );
      if (prevContract) {
        const changedSlugContract = this.props.contracts.find(
          (contract) =>
            prevContract.id === contract.id &&
            prevContract.slugName !== contract.slugName
        );
        if (changedSlugContract) {
          this.setState({
            redirectSlugName: changedSlugContract.slugName
          });
        }
      }
    }

    const showPrivateProducerMode = this.shouldUsePrivateApi();
    const { powerPlants, contracts, match } = this.props;

    // check if the slug in the URL is wrong and load to the correct one
    if (showPrivateProducerMode) {
      const contractData = contracts
        ? contracts.find(
            (contract) => contract.id === match.params.powerPlantId
          )
        : null;
      if (
        contractData &&
        contractData.slugName !== match.params.powerPlantSlug
      ) {
        this.setState({
          redirectSlugName: contractData.slugName
        });

        return;
      }
    } else {
      const powerPlantData = powerPlants
        ? powerPlants.find((plant) => plant.id === match.params.powerPlantId)
        : null;
      if (
        powerPlantData &&
        powerPlantData.slugName !== match.params.powerPlantSlug
      ) {
        this.setState({
          redirectSlugName: powerPlantData.slugName
        });

        return;
      }
    }

    // check if the URL (current plant) changed, so we can load the new data
    if (
      prevProps.match.params.powerPlantSlug !== match.params.powerPlantSlug ||
      prevProps.match.params.powerPlantId !== match.params.powerPlantId ||
      (showPrivateProducerMode &&
        prevProps.match.path.indexOf("strom-kaufen") > 0) ||
      (!showPrivateProducerMode &&
        prevProps.match.path.indexOf("strom-verkaufen") > 0)
    ) {
      this.updatePowerPlantData();
    }

    // check if there was an error in updating the data
    // if there was, clear the error and redirect to not-found page
    const {
      powerPlantGetError,
      resetPowerPlantGetByIdError,
      contractGetError,
      resetContractGetByIdError
    } = this.props;

    if (showPrivateProducerMode) {
      if (contractGetError) {
        resetContractGetByIdError();
        this.setState({
          redirectTo: "/seite-nicht-gefunden"
        });
      }
    } else {
      if (powerPlantGetError) {
        resetPowerPlantGetByIdError();
        this.setState({
          redirectTo: "/seite-nicht-gefunden"
        });
      }
    }
  }

  componentDidMount() {
    this.updatePowerPlantData();

    // if power plants haven't been loaded yet (i.e. this is the landing page for the user), load them now
    if (!this.props.powerPlants) {
      this.props.getPowerPlants();
    }
  }

  handleToggleEdit = () => {
    this.toggleEditModal();
  };

  toggleEditModal = () => {
    this.setState({
      isEditModalOpen: !this.state.isEditModalOpen
    });
  };

  render() {
    const {
      authenticated,
      contracts,
      powerPlants,
      mixHasBeenCreated,
      match,
      history: { goBack: handleGoBack },
      location: { state: locationState }
    } = this.props;

    const { isEditModalOpen, redirectTo, redirectSlugName } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    if (redirectSlugName && match.params.powerPlantSlug !== redirectSlugName) {
      return <Redirect to={redirectSlugName} />;
    }

    const showPrivateProducerMode = this.shouldUsePrivateApi();
    const contractData = contracts
      ? contracts.find((contract) => contract.id === match.params.powerPlantId)
      : null;
    const powerPlantData = powerPlants
      ? powerPlants.find((plant) => plant.id === match.params.powerPlantId)
      : null;
    const data = showPrivateProducerMode ? contractData : powerPlantData;

    if (!data) {
      return null;
    }

    const images = data.images;
    const headerImg = getPowerPlantMainImageInWideFormat(data);
    const dmspList = DMSP_PARTNERS.concat(
      DMSP_LIST.map((dmsp) => {
        return { name: dmsp, imgUrl: null };
      })
    );
    const dmspLogoData = dmspList.find(
      (dmsp) => dmsp.name === data.plantDirectMarketer
    );
    const showBackButton = locationState && locationState.prevPath;
    const enableLinkButton = data.approved && data.published;
    const previousPageWasCustomizeMix =
      locationState &&
      locationState.prevPath &&
      locationState.prevPath.indexOf("mein-mix") > 0;
    const energyType = ENERGY_TYPES.find(
      (energyType) =>
        energyType.name === getEnergyTypeFromPlantType(data.plantType)
    );
    const energyTypeImage = energyType.image;

    return (
      <div className="PowerPlant page">
        <Container>
          {showPrivateProducerMode && (
            <Row className="breadcrumbs">
              <span>
                <Link to="/strom-verkaufen/meine-anlagen">Meine Anlagen</Link> >{" "}
                {data.name}
              </span>
            </Row>
          )}
          {headerImg && (
            <Row className="flex-center">
              <img src={headerImg} alt={data.name} className="header-image" />
            </Row>
          )}
          <Row>
            <Col xs={12} md={12} lg={showPrivateProducerMode ? 12 : 8}>
              <Container>
                <Row className="flex-center">
                  <Col xs="12">
                    <Container className="header">
                      <Row>
                        <Col xs={{ size: 10, offset: 1 }}>
                          <h2>{getProducerDisplayName(data)}</h2>
                        </Col>
                        {showPrivateProducerMode && authenticated && (
                          <Col xs={{ size: 1 }} className="producer-buttons">
                            {enableLinkButton ? (
                              <LinkToProfilePageWithBack
                                id={data.id}
                                slug={data.slugName}
                              >
                                <FontAwesomeIcon
                                  icon="link"
                                  data-tip
                                  data-for="link-to-public-profile-tooltip"
                                />
                              </LinkToProfilePageWithBack>
                            ) : (
                              <FontAwesomeIcon
                                icon="link"
                                data-tip
                                data-for="link-to-public-profile-tooltip"
                                className="disabled"
                              />
                            )}
                            <ReactTooltip id="link-to-public-profile-tooltip">
                              {enableLinkButton
                                ? "Öffentliches Profil anzeigen"
                                : "Profil noch nicht veröffentlicht"}
                            </ReactTooltip>
                            <FontAwesomeIcon
                              icon="pencil-alt"
                              onClick={this.handleToggleEdit}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col xs={{ size: 10, offset: 1 }}>
                          <p>
                            <em>{data.name}</em>
                          </p>
                        </Col>
                      </Row>
                      <EditPowerPlant
                        isOpen={isEditModalOpen}
                        toggle={this.handleToggleEdit}
                        plantData={data}
                      />
                    </Container>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                      className={classnames("description", {
                        "center-text": showPrivateProducerMode
                      })}
                    >
                      {data.description.length > 0
                        ? data.description
                        : "Noch keine Anlagenbeschreibung."}
                    </p>
                  </Col>
                </Row>
                <Row>{images.length > 0 && <h3>Fotos</h3>}</Row>
                <Row>
                  {images.length > 0 &&
                    images.map((image, index) => (
                      <a href={image.file} target="_blank" key={index}>
                        <img
                          src={image.file}
                          alt={
                            image.caption.length > 0 ? image.caption : data.name
                          }
                          className="image-thumbnail"
                        />
                      </a>
                    ))}
                </Row>
              </Container>
            </Col>
            {!showPrivateProducerMode && (
              <Col xs={12} md={12} lg={4}>
                {previousPageWasCustomizeMix && mixHasBeenCreated ? (
                  <AddToMixWidget
                    powerPlantId={data.id}
                    onGoBack={handleGoBack}
                  />
                ) : (
                  <ShowWithMixesWidget powerPlantIds={[data.id]} showHeader />
                )}
              </Col>
            )}
          </Row>
          <Row className="additional-information">
            <Col>
              <h3>Weitere Informationen</h3>
              <Container>
                <Row>
                  <Col className="image-with-label">
                    <div className="installed-capacity-speedometer">
                      <ReactSpeedometer
                        value={data.plantInstalledCapacity}
                        maxValue={this.calculateMaxSpeedometerValue(
                          data.plantInstalledCapacity
                        )}
                        currentValuePlaceholderStyle="#{value}"
                        currentValueText="#{value} kW"
                        width={200}
                        height={130}
                        needleColor="#495057"
                        startColor={THEME_VARS.default.brandColourLight}
                        endColor={THEME_VARS.default.brandColourDark}
                      />
                    </div>
                    <div className="label">
                      Leistung
                      <br />
                      {data.plantInstalledCapacity.toLocaleString("de-DE", {
                        maximumFractionDigits: 0
                      })}{" "}
                      kW
                    </div>
                  </Col>
                  <Col className="image-with-label">
                    <div className="yearly-energy-image">
                      <FontAwesomeIcon icon="bolt" className="bolt-icon" />
                      <svg width="0" height="0">
                        <linearGradient
                          id="yearly-energy-image-gradient"
                          x1="100%"
                          y1="100%"
                          x2="0%"
                          y2="0%"
                        >
                          <stop
                            offset="0%"
                            style={{
                              stopColor: THEME_VARS.default.brandColourLight,
                              stopOpacity: 1
                            }}
                          />
                          <stop
                            offset="100%"
                            style={{
                              stopColor: THEME_VARS.default.brandColourDark,
                              stopOpacity: 1
                            }}
                          />
                        </linearGradient>
                      </svg>
                    </div>
                    <div className="label">
                      Jährliche Einspeisung
                      <br />
                      {data.plantYearlyEnergy.toLocaleString("de-DE", {
                        maximumFractionDigits: 0
                      })}{" "}
                      kWh
                    </div>
                  </Col>
                  <Col className="image-with-label">
                    <div className="plant-image">
                      <img src={energyTypeImage} alt="Anlagentyp" />
                    </div>
                    <div className="label">
                      Anlagentyp
                      <br />
                      {data.plantType}
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row className="location">
            <Col>
              <h3>Standort</h3>
              <PowerPlantGoogleMap
                plantLatitude={data.plantLatitude}
                plantLongitude={data.plantLongitude}
                plantType={data.plantType}
              />
            </Col>
          </Row>
          {dmspLogoData && dmspLogoData.imgUrl && (
            <Row className="dmsp-logo-outer">
              <Col>
                <h3>Direktvermarktung durch:</h3>
                <img
                  src={dmspLogoData.imgUrl}
                  alt="data.plantDirectMarketer"
                  className="dmsp-logo"
                />
              </Col>
            </Row>
          )}
          {showBackButton && <BackButton onClick={handleGoBack} />}
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    powerPlants: state.powerPlants.powerPlants,
    powerPlantGetError: state.powerPlants.powerPlantGetError,
    contracts: state.contract.contracts,
    contractGetError: state.contract.contractGetError,
    authenticated: state.auth.authenticated,
    mixHasBeenCreated: !!state.mix.selectedMixName
  };
}

const mapDispatchToProps = (dispatch) => ({
  getPowerPlantById: (id) => dispatch(getPowerPlantByIdAction(id)),
  resetPowerPlantGetByIdError: () =>
    dispatch(resetPowerPlantGetByIdErrorAction()),
  getContractById: (id) => dispatch(getContractByIdAction(id)),
  resetContractGetByIdError: () => dispatch(resetContractGetByIdErrorAction()),
  getPowerPlants: () => dispatch(getPowerPlantsAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(PowerPlant);
