import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./InfoIcon.scss";

class InfoIcon extends Component {
  constructor(props) {
    super(props);

    this.nodeElement = document.getElementById("tooltip-portal");
    this.container = document.createElement("div");

    // if tooltip-portal hasn't been rendered yet, create it in the body
    if (!this.nodeElement) {
      const bodyElement = document.querySelector("body");
      this.nodeElement = document.createElement("div");
      this.nodeElement.setAttribute("id", "tooltip-portal");

      if (bodyElement) {
        bodyElement.appendChild(this.nodeElement);
      }
    }
  }

  componentWillUnmount() {
    // clean up when the component unmounts, so divs aren't created forever
    if (this.container) {
      this.container.parentNode.removeChild(this.container);
    }
  }

  render() {
    const { children, tooltipName } = this.props;

    return (
      <span className="InfoIcon">
        <FontAwesomeIcon
          icon="info"
          className="icon"
          data-tip
          data-for={tooltipName}
        />
        {this.nodeElement &&
          ReactDOM.createPortal(
            <ReactTooltip id={tooltipName}>
              <div className="info-icon-tooltip">{children}</div>
            </ReactTooltip>,
            this.nodeElement.appendChild(this.container)
          )}
      </span>
    );
  }
}

InfoIcon.propTypes = {
  children: PropTypes.any.isRequired,
  tooltipName: PropTypes.string.isRequired
};

export { InfoIcon };
