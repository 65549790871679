import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./MyPowerPlants.scss";
import { getMyContractsAction, requestSignInAction } from "../../actions";
import { CURRENT_THEME, STRING_KEYS, THEMES } from "../../constants";
import {
  getPowerPlantMainImage,
  getString,
  MarkdownString
} from "../../utils.js";
import { checkFeature, FEATURES } from "../../features";

class MyPowerPlants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMarketingModalOpen: false
    };
  }

  toggleMarketingModal = () => {
    this.setState({
      isMarketingModalOpen: !this.state.isMarketingModalOpen
    });
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.props.onGetPowerPlants();
    }
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.onGetPowerPlants();

      if (!localStorage.getItem("marketing-modal-shown")) {
        this.toggleMarketingModal();
        localStorage.setItem("marketing-modal-shown", true);
      }
    } else {
      this.props.requestSignIn();
    }
  }

  renderPowerPlant({
    name,
    slugName,
    images,
    plantHeaderImage,
    plantType,
    id
  }) {
    return (
      <Col key={id} className="power-plant" xs="auto">
        <Link to={`/strom-verkaufen/meine-anlagen/${id}/${slugName}`}>
          <img
            src={getPowerPlantMainImage({
              images,
              plantType,
              plantHeaderImage
            })}
            alt={name}
          />
          <span>{name}</span>
        </Link>
      </Col>
    );
  }

  render() {
    const { authenticated, powerPlants } = this.props;

    const { isMarketingModalOpen } = this.state;

    if (!authenticated) {
      return null;
    }

    const showFindDmspText = checkFeature(FEATURES.MY_POWER_PLANTS_FIND_DMSP);

    return (
      <div className="MyPowerPlants page">
        <header>
          <h1>Meine Anlagen</h1>
        </header>
        <Container>
          <Row>
            {powerPlants &&
              powerPlants.map((powerPlant) =>
                this.renderPowerPlant(powerPlant)
              )}
            <Col className="power-plant" xs="auto">
              <Link to="/strom-verkaufen/neue-anlage">
                <div className="plus-border">
                  <FontAwesomeIcon icon="plus" className="plus-icon" />
                </div>
                <span>Neue Anlage</span>
              </Link>
            </Col>
          </Row>
          {showFindDmspText && (
            <Row>
              <Col>
                <MarkdownString
                  stringKey={STRING_KEYS.FIND_DMSP_TEXT}
                  openLinksInNewWindow
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <MarketingText showFullText />
            </Col>
          </Row>
        </Container>
        <MarketingMaterialsModal
          isOpen={isMarketingModalOpen}
          toggle={this.toggleMarketingModal}
        />
      </div>
    );
  }
}

function MarketingMaterialsModal({ isOpen, toggle }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        {getString(STRING_KEYS.MARKETING_MATERIALS_MODAL_TITLE)}
      </ModalHeader>
      <ModalBody>
        <MarketingText />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Schließen
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const leitfadenMarketingPdfUrl =
  "https://marktplatz.cells.energy/files/Leitfaden-Marketing";
const katalogMarketingPdfUrl =
  "https://marktplatz.cells.energy/files/Katalog-Marketing";
const leitfadenKundengespraechePdfUrl =
  "https://marktplatz.cells.energy/files/Leitfaden-Kundengespraeche";
const kurzinfoMarketingPdfUrl =
  "https://marktplatz.cells.energy/files/Kurzinfo-Marketing";
const kurzinfoKundengespraechePdfUrl =
  "https://marktplatz.cells.energy/files/Kurzinfo-Kundengespraeche";

function MarketingText({ showFullText = false }) {
  const suffix = CURRENT_THEME === THEMES.CELLS_ENERGY ? "" : "_wl";

  const replacements = [
    ["leitfadenMarketingPdfUrl", leitfadenMarketingPdfUrl + suffix],
    ["katalogMarketingPdfUrl", katalogMarketingPdfUrl + suffix],
    [
      "leitfadenKundengespraechePdfUrl",
      leitfadenKundengespraechePdfUrl + suffix
    ],
    ["kurzinfoMarketingPdfUrl", kurzinfoMarketingPdfUrl + suffix],
    ["kurzinfoKundengespraechePdfUrl", kurzinfoKundengespraechePdfUrl + suffix]
  ];

  return (
    <MarkdownString
      stringKey={
        showFullText
          ? STRING_KEYS.MARKETING_MATERIALS_TEXT_LONG
          : STRING_KEYS.MARKETING_MATERIALS_TEXT
      }
      replacements={replacements}
      openLinksInNewWindow
    />
  );
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    powerPlants: state.contract.contracts
  };
}

const mapDispatchToProps = (dispatch) => ({
  onGetPowerPlants: () => dispatch(getMyContractsAction()),
  requestSignIn: () => dispatch(requestSignInAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPowerPlants);
