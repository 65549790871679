import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  AUTHENTICATION_ERROR,
  AUTHENTICATION_REQUESTED,
  AUTHENTICATION_REQUEST_CANCELLED,
  AUTHENTICATED_EMAIL,
  AUTHENTICATED_EMAIL_ERROR,
  REGISTERED,
  REGISTRATION_ERROR,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_REQUEST_RESET,
  FORGOT_PASSWORD_ERROR
} from "../actions";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATED:
      return { ...state, authenticated: true, user: action.payload.user };
    case UNAUTHENTICATED:
      return { ...state, authenticated: false, user: undefined };
    case AUTHENTICATION_ERROR:
      return { ...state, error: action.payload };
    case AUTHENTICATION_REQUESTED:
      return { ...state, authenticationRequested: true };
    case AUTHENTICATION_REQUEST_CANCELLED:
      return {
        ...state,
        authenticationRequested: false,
        forgotPasswordRequested: false,
        error: undefined
      };
    case AUTHENTICATED_EMAIL:
      return { ...state, emailConfirmed: true, error: undefined };
    case AUTHENTICATED_EMAIL_ERROR:
      return { ...state, emailConfirmed: false, error: action.payload };
    case REGISTERED:
      return { ...state, registered: true };
    case REGISTRATION_ERROR:
      return { ...state, registrationError: action.payload };
    case FORGOT_PASSWORD_REQUESTED:
      return { ...state, forgotPasswordRequested: true };
    case FORGOT_PASSWORD_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
