import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import classnames from "classnames";

import { STRING_KEYS, VAT_INFO_TEXTS } from "../../constants";
import { FEATURES, checkFeature } from "../../features";
import { InfoIcon } from "../../components/InfoIcon";
import { PlantMargins } from "../../components/PlantMargins";
import {
  Coverage,
  getPowerPlantMainImage,
  getProducerDisplayName,
  LinkToProfilePageWithBack,
  Loader,
  Price,
  getString,
  VatMarker
} from "../../utils.js";

import "./ExtendedMixInfo.scss";

const TABS = {
  OVERVIEW: getString(STRING_KEYS.EXTENDED_MIX_INFO_TAB_OVERVIEW),
  PRODUCERS: getString(STRING_KEYS.EXTENDED_MIX_INFO_TAB_PRODUCERS),
  CUSTOM_MARGINS: "Betreiberzuschuss",
  // INFORMATION: "Weitere Informationen", todo: future sprint?
  CALCULATION: "Berechnung"
};

class ExtendedMixInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: TABS.OVERVIEW
    };
  }

  setTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    const {
      mixPlants,
      mixMonthlyCost,
      mixPostcode,
      postcode,
      consumerType,
      energyPrice,
      mixSurcharge,
      mixPowerPrice,
      mixMaxPower,
      baseTariff,
      mixYearlyEnergy,
      producerPowerPlantDistances,
      mixPlantCoverages,
      showProducers,
      showCustomMargins,
      onUpdateCustomMargins
    } = this.props;

    const { activeTab } = this.state;

    const monthlyBaseTariff = baseTariff / 12;
    const monthlyMixSurcharge = mixSurcharge / 12;

    return (
      <div className="ExtendedMixInfo">
        <Nav tabs>
          {Object.values(TABS).map((tab) => {
            if (!showCustomMargins && tab === TABS.CUSTOM_MARGINS) {
              return null;
            } else if (!showProducers && tab === TABS.PRODUCERS) {
              return null;
            }

            return (
              <NavItem key={tab}>
                <NavLink
                  className={classnames({ active: activeTab === tab })}
                  onClick={() => {
                    this.setTab(tab);
                  }}
                >
                  {tab}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TABS.OVERVIEW}>
            <Container>
              <Row>
                <Col>
                  <Overview
                    postcode={mixPostcode || postcode}
                    consumerType={consumerType}
                    energyPrice={energyPrice}
                    monthlyMixSurcharge={monthlyMixSurcharge}
                    baseTariff={monthlyBaseTariff}
                    powerPrice={mixPowerPrice}
                    maxPower={mixMaxPower}
                    yearlyEnergy={mixYearlyEnergy}
                  />
                </Col>
              </Row>
            </Container>
          </TabPane>
          <TabPane tabId={TABS.INFORMATION}>
            <Container>
              <Row>
                <Col>information</Col>
              </Row>
            </Container>
          </TabPane>
          <TabPane tabId={TABS.CUSTOM_MARGINS}>
            <Container>
              <Row>
                <Col>
                  <CustomMargins
                    mixPlants={mixPlants}
                    mixPlantCoverages={mixPlantCoverages}
                    yearlyEnergy={mixYearlyEnergy}
                    consumerType={consumerType}
                    onUpdateMargins={onUpdateCustomMargins}
                  />
                </Col>
              </Row>
            </Container>
          </TabPane>
          <TabPane tabId={TABS.CALCULATION}>
            <Container>
              <Row>
                <Col>
                  <Calculation
                    mixMonthlyCost={mixMonthlyCost}
                    energyPrice={energyPrice}
                    yearlyMixSurcharge={mixSurcharge}
                    baseTariff={baseTariff}
                    powerPrice={mixPowerPrice}
                    maxPower={mixMaxPower}
                    yearlyEnergy={mixYearlyEnergy}
                    consumerType={consumerType}
                  />
                </Col>
              </Row>
            </Container>
          </TabPane>
          <TabPane tabId={TABS.PRODUCERS}>
            <Container>
              <Row>
                <Col>
                  <Producers
                    mixPlants={mixPlants}
                    mixPowerPlantDistances={producerPowerPlantDistances}
                    mixPlantCoverages={mixPlantCoverages}
                  />
                </Col>
              </Row>
            </Container>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

function Overview({
  postcode,
  consumerType,
  energyPrice,
  monthlyMixSurcharge,
  baseTariff,
  powerPrice,
  maxPower,
  yearlyEnergy
}) {
  const rlmExtraCostsPerMonth = (powerPrice * maxPower) / 12;
  const baseTariffWithRlmExtraCosts = baseTariff + rlmExtraCostsPerMonth;
  const showVariablePricing = checkFeature(FEATURES.VARIABLE_PRICING);
  const showMinContractDuration = checkFeature(FEATURES.MIN_CONTRACT_DURATION);
  const currentYear = new Date().getFullYear();

  return (
    <div className="overview info-body">
      <table>
        <tbody>
          <tr>
            <td className="field-name">Postleitzahl</td>
            <td className="value">{postcode}</td>
          </tr>
          <tr>
            <td className="field-name">Jahresverbrauch</td>
            <td className="value">
              {yearlyEnergy.toLocaleString("de-DE")} kWh
            </td>
          </tr>
          <tr>
            <td className="field-name">Arbeitspreis</td>
            <td className="value">
              <Price value={energyPrice} unit="ct/kWh" />
            </td>
          </tr>
          {showVariablePricing && (
            <tr>
              <td className="field-name"></td>
              <td className="value">
                Aufgrund der direkten Weiterleitung der Börsenpreise ist der
                Arbeitspreis variabel. Der dargestellte Arbeitspreis basiert auf
                einer Preisprognose für die kommenden 12 Monate.
              </td>
            </tr>
          )}
          <tr>
            <td className="field-name">Grundpreis</td>
            <td className="value">
              <Price value={baseTariffWithRlmExtraCosts} unit="€/Monat" />{" "}
              {powerPrice > 0 && <RlmExtraCostsInfoIcon maxPower={maxPower} />}
            </td>
          </tr>
          <tr>
            <td className="field-name">Betreiberzuschuss</td>
            <td className="value">
              <Price value={monthlyMixSurcharge} unit="€/Monat" />
            </td>
          </tr>
          <tr>
            <td className="field-name">
              {getString(STRING_KEYS.EXTENDED_MIX_INFO_CONTRACT_DURATION_TITLE)}
            </td>
            <td className="value">
              {getString(STRING_KEYS.EXTENDED_MIX_INFO_CONTRACT_DURATION, [
                ["currentYear", currentYear]
              ])}
            </td>
          </tr>
          {showMinContractDuration && (
            <tr>
              <td className="field-name">Vertragsverlängerung</td>
              <td className="value">
                {getString(STRING_KEYS.EXTENDED_MIX_INFO_MIN_CONTRACT_DURATION)}
              </td>
            </tr>
          )}
          <tr>
            <td className="field-name">Kündigungsfrist</td>
            <td className="value">
              {getString(STRING_KEYS.EXTENDED_MIX_INFO_NOTICE_PERIOD, [
                ["currentYear", currentYear]
              ])}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="vat-info">
        <VatMarker />
        {VAT_INFO_TEXTS[consumerType]}
      </div>
    </div>
  );
}

function CustomMargins(props) {
  const { mixPlants, mixPlantCoverages, yearlyEnergy, consumerType, onUpdateMargins } = props;

  return (
    <div className="custom-margins info-body">
      <PlantMargins
        plants={mixPlants}
        plantCoverages={mixPlantCoverages}
        yearlyEnergy={yearlyEnergy}
        consumerType={consumerType}
        onUpdateMargin={onUpdateMargins}
      />
    </div>
  );
}

function Calculation(props) {
  const {
    mixMonthlyCost,
    energyPrice,
    yearlyMixSurcharge,
    baseTariff,
    powerPrice,
    maxPower,
    yearlyEnergy,
    consumerType
  } = props;

  const roundedEnergyPrice = Math.round(energyPrice * 100) / 100;
  const energyPricePerYear = (roundedEnergyPrice * yearlyEnergy) / 100;
  const rlmExtraCosts = powerPrice * maxPower;
  const baseTariffWithRlmExtraCosts = baseTariff + rlmExtraCosts;
  const yearlyCost = 12 * mixMonthlyCost;
  const showFinalPricePerMonthHelpText = checkFeature(
    FEATURES.EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH_HELP_TEXT
  );

  return (
    <div className="calculation info-body">
      <table>
        <tbody>
          <tr>
            <td className="operator">&nbsp;</td>
            <td className="label">
              Arbeitspreis
              <span>
                {" "}
                <EnergyPriceInfoIcon />
              </span>
            </td>
            <td className="value">
              <Price value={energyPrice} unit="ct/kWh" hideVatMarker />
            </td>
          </tr>
          <tr className="before-total">
            <td className="operator">x</td>
            <td className="label">Jahresverbrauch</td>
            <td className="value">
              {yearlyEnergy.toLocaleString("de-DE")} kWh
            </td>
          </tr>
          <tr className="total">
            <td className="operator">=</td>
            <td className="label">Preis pro Jahr</td>
            <td className="value">
              <Price value={energyPricePerYear} unit="€/Jahr" hideVatMarker />
            </td>
          </tr>
          <tr>
            <td className="operator">+</td>
            <td className="label">
              Grundpreis{" "}
              {powerPrice > 0 && <RlmExtraCostsInfoIcon maxPower={maxPower} />}
            </td>
            <td className="value">
              <Price
                value={baseTariffWithRlmExtraCosts}
                unit="€/Jahr"
                hideVatMarker
              />
            </td>
          </tr>
          <tr className="before-total">
            <td className="operator">+</td>
            <td className="label">Betreiberzuschuss</td>
            <td className="value">
              <Price value={yearlyMixSurcharge} unit="€/Jahr" hideVatMarker />
            </td>
          </tr>
          <tr className="total">
            <td className="operator">=</td>
            <td className="label">
              {getString(STRING_KEYS.EXTENDED_MIX_INFO_TOTAL_PRICE_PER_YEAR)}
            </td>
            <td className="value">
              <Price value={yearlyCost} unit="€/Jahr" hideVatMarker />
            </td>
          </tr>
          <tr className="final">
            <td className="operator">&nbsp;</td>
            <td className="label">
              {getString(STRING_KEYS.EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH)}
              {showFinalPricePerMonthHelpText && (
                <span>
                  {" "}
                  <FinalPricePerMonthInfoIcon />
                </span>
              )}
            </td>
            <td className="value">
              <Price value={mixMonthlyCost} unit="€/Monat" hideVatMarker />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="vat-info">{VAT_INFO_TEXTS[consumerType]}</div>
    </div>
  );
}

function EnergyPriceInfoIcon() {
  return (
    <InfoIcon tooltipName="energy-price-info-icon">
      <span>
        Beinhaltet die Netzentgelte, die Stromsteuer sowie alle gesetzlichen
        Umlagen und Abgaben.
      </span>
    </InfoIcon>
  );
}

export function RlmExtraCostsInfoIcon({ maxPower }) {
  return (
    <InfoIcon tooltipName="upload-loadprofile-tooltip">
      <span>
        Beinhaltet die jährlichen Kosten für die Netznutzung entsprechend der
        Höchstleistung von {maxPower.toLocaleString("de-DE")} kW.
      </span>
    </InfoIcon>
  );
}

function FinalPricePerMonthInfoIcon() {
  return (
    <InfoIcon tooltipName="final-price-per-month-info-icon">
      <span>
        {getString(
          STRING_KEYS.EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH_HELP_TEXT
        )}
      </span>
    </InfoIcon>
  );
}

function Producer(props) {
  const { id, slug, img, name, slogan, city, distance, coverage } = props;

  return (
    <div className="producer">
      <LinkToProfilePageWithBack id={id} slug={slug}>
        <img src={img} alt={name} />
      </LinkToProfilePageWithBack>
      <div className="content">
        <h5 className="plant-name">
          <LinkToProfilePageWithBack id={id} slug={slug}>
            {name}
          </LinkToProfilePageWithBack>
        </h5>
        <h6 className="name-city">{slogan}</h6>
        <ul className="info">
          <li>
            {city}:{" "}
            {distance.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1
            })}{" "}
            km entfernt
          </li>
          <li>
            <Coverage value={coverage} /> Deckungsrate
          </li>
        </ul>
      </div>
      <div className="view-profile-button">
        <Button
          color="success"
          tag={Link}
          to={"/"}
          className={classnames({ disabled: props.disabled })}
        >
          Profil Anzeigen
        </Button>
      </div>
    </div>
  );
}

function Producers(props) {
  const { mixPlants, mixPowerPlantDistances, mixPlantCoverages } = props;

  if (!mixPowerPlantDistances) {
    return <Loader />;
  }

  return (
    <div className="producers">
      {mixPlants &&
        mixPlants.map((plant) => (
          <Producer
            key={plant.id}
            id={plant.id}
            slug={plant.slugName}
            name={getProducerDisplayName(plant)}
            slogan={plant.name}
            city={plant.plantCity}
            distance={mixPowerPlantDistances[plant.id]}
            coverage={mixPlantCoverages[plant.id]}
            img={getPowerPlantMainImage(plant)}
          />
        ))}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    postcode: state.mix.postcode,
    consumerType: state.mix.consumerType,
    powerPlants: state.powerPlants.powerPlants,
    producerPowerPlantDistances: state.powerPlants.distances
  };
}

const connectedExtendedMixInfo = connect(mapStateToProps)(ExtendedMixInfo);

export { connectedExtendedMixInfo as ExtendedMixInfo };
