import React from "react";

export function Producer({ ownerName, plantName, city, img, onClickProducer }) {
  return (
    <div className="producer" onClick={onClickProducer}>
      <img src={img} alt={plantName} />
      <header className="plant-name">
        <span>{plantName}</span>
      </header>
      <div className="owner-name">
        <span>{ownerName},</span>
        <br />
        <span>{city}</span>
      </div>
    </div>
  );
}
