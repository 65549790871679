import React, { Component } from "react";

export default class NotFound extends Component {
  render() {
    return (
      <div className="NotFound page">
        <header>
          <h1>Ups. Seite nicht vorhanden.</h1>
        </header>
      </div>
    );
  }
}
