import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row
} from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BackButton } from "../../components/BackButton";
import { ContinueButton } from "../../components/ContinueButton";
import { setMixOptionsAction } from "../../actions";
import {
  CURRENT_THEME,
  PEOPLE_ENERGY_AMOUNTS,
  STRING_KEYS,
  THEMES,
  USER_TYPES
} from "../../constants.js";
import { YEARLY_ENERGY_LIMITS } from "../../settings.js";
import { getString, MarkdownString } from "../../utils.js";

import plusIcon from "../../images/icons/icon-plus.png";
import minusIcon from "../../images/icons/icon-minus.png";
import oberland17plusIcon from "../../images/oberland17/icons/icon_plus.jpg";
import oberland17plusIconDisabled from "../../images/oberland17/icons/icon_plus_disabled.jpg";
import oberland17minusIcon from "../../images/oberland17/icons/icon_minus.jpg";
import oberland17minusIconDisabled from "../../images/oberland17/icons/icon_minus_disabled.jpg";
import swlPartnerImage from "../../images/swl/consumer-flow/partner.png";
import oberland17PartnerImage from "../../images/oberland17/consumer-flow/klaus_hochwind.png";
import "./HowMuchPowerDoYouNeed.scss";

import vollmachtLastgangdaten from "../../docs/Vollmacht_Lastgangdaten.pdf";
import oberland17VollmachtLastgangdaten from "../../docs/oberland17/Vollmacht_Lastdaten_01_21.pdf";

const MIN_PEOPLE = 1;
const MAX_PEOPLE = PEOPLE_ENERGY_AMOUNTS.length - 1;

function HowMuchPowerDoYouNeed({
  consumerType,
  yearlyEnergy,
  isWidgetMode,
  setYearlyEnergy,
  onGoBack,
  onGoToNextStep
}) {
  const [showNumberOfPeople, setShowNumberOfPeople] = useState(
    consumerType === USER_TYPES.private
  );
  const [userWantsTooMuchEnergy, setUserWantsTooMuchEnergy] = useState(false);

  function computeNumPeopleFromYearlyEnergy(yearlyEnergy) {
    let numPeople = 1;

    while (
      yearlyEnergy > PEOPLE_ENERGY_AMOUNTS[numPeople] &&
      numPeople < MAX_PEOPLE
    ) {
      numPeople++;
    }

    return numPeople;
  }

  function handleIncreaseNumPeople() {
    const numPeople = computeNumPeopleFromYearlyEnergy(yearlyEnergy);

    if (numPeople < MAX_PEOPLE) {
      setYearlyEnergy(PEOPLE_ENERGY_AMOUNTS[numPeople + 1]);
    }
  }

  function handleDecreaseNumPeople() {
    const numPeople = computeNumPeopleFromYearlyEnergy(yearlyEnergy);
    if (numPeople > MIN_PEOPLE) {
      setYearlyEnergy(PEOPLE_ENERGY_AMOUNTS[numPeople - 1]);
    }
  }

  function handleClickContinue() {
    if (yearlyEnergy >= YEARLY_ENERGY_LIMITS[CURRENT_THEME].MAX) {
      setShowNumberOfPeople(false);
      setUserWantsTooMuchEnergy(true);
    } else {
      onGoToNextStep();
    }
  }

  function handleClickBack() {
    if (userWantsTooMuchEnergy) {
      setUserWantsTooMuchEnergy(false);
      setShowNumberOfPeople(
        consumerType === USER_TYPES.private && !showNumberOfPeople
      );
    } else {
      onGoBack();
    }
  }

  const numPeopleInHousehold = computeNumPeopleFromYearlyEnergy(yearlyEnergy);

  return (
    <Container className="HowMuchPowerDoYouNeed">
      <Row className="header">
        <Col>
          <h3>
            {consumerType === USER_TYPES.business
              ? getString(STRING_KEYS.HOW_MUCH_POWER_BUSINESS_TITLE)
              : getString(STRING_KEYS.HOW_MUCH_POWER_PRIVATE_TITLE)}
          </h3>
        </Col>
      </Row>
      {showNumberOfPeople && (
        <Row>
          <Col>
            <NumberOfPeople
              numPeople={numPeopleInHousehold}
              onIncrease={handleIncreaseNumPeople}
              onDecrease={handleDecreaseNumPeople}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {isWidgetMode ? (
            <YearlyEnergyWithInputGroup
              yearlyEnergy={yearlyEnergy}
              onChangeYearlyEnergy={setYearlyEnergy}
            />
          ) : (
            <YearlyEnergy
              yearlyEnergy={yearlyEnergy}
              userWantsTooMuchEnergy={userWantsTooMuchEnergy}
              onChangeYearlyEnergy={setYearlyEnergy}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <footer className="continue-button-container">
            {!isWidgetMode && !userWantsTooMuchEnergy && (
              <ContinueButton
                onClick={handleClickContinue}
                disabled={!yearlyEnergy}
              />
            )}
          </footer>
        </Col>
      </Row>
      {!isWidgetMode && <BackButton onClick={handleClickBack} />}
    </Container>
  );
}

function YearlyEnergy({
  yearlyEnergy,
  userWantsTooMuchEnergy,
  onChangeYearlyEnergy
}) {
  const vollmachtPdf =
    CURRENT_THEME === THEMES.OBERLAND17
      ? oberland17VollmachtLastgangdaten
      : vollmachtLastgangdaten;
  let partnerImage;

  switch (CURRENT_THEME) {
    case THEMES.SWL:
      partnerImage = swlPartnerImage;
      break;
    case THEMES.OBERLAND17:
      partnerImage = oberland17PartnerImage;
      break;
    default:
      partnerImage = null;
      break;
  }

  if (userWantsTooMuchEnergy) {
    return (
      <Container
        className={classnames("annual-consumption", {
          "with-partner": !!partnerImage
        })}
      >
        <Row>
          <Col
            md={{ size: 8, offset: partnerImage ? 1 : 2 }}
            className="annual-consumption-col"
          >
            <MarkdownString
              stringKey={STRING_KEYS.HOW_MUCH_POWER_TOO_MUCH}
              openLinksInNewWindow
              replacements={[["vollmachtLastgangdaten", vollmachtPdf]]}
            />
          </Col>
          {!!partnerImage && (
            <Col md="3" className="partner-col">
              <img
                src={partnerImage}
                className="partner-image"
                alt="Ansprechpartner"
              />
              <MarkdownString
                stringKey={STRING_KEYS.HOW_MUCH_POWER_TOO_MUCH_IMAGE_CAPTION}
              />
            </Col>
          )}
        </Row>
      </Container>
    );
  }

  return (
    <div className="annual-consumption">
      <FormGroup>
        <YearlyEnergyInput
          yearlyEnergy={yearlyEnergy}
          onChangeYearlyEnergy={onChangeYearlyEnergy}
        />
        <Label for="yearlyEnergy">kWh/Jahr</Label>
      </FormGroup>
    </div>
  );
}

function YearlyEnergyWithInputGroup({ yearlyEnergy, onChangeYearlyEnergy }) {
  return (
    <div className="annual-consumption">
      <InputGroup>
        <YearlyEnergyInput
          yearlyEnergy={yearlyEnergy}
          onChangeYearlyEnergy={onChangeYearlyEnergy}
        />
        <InputGroupAddon addonType="append">kWh/Jahr</InputGroupAddon>
      </InputGroup>
    </div>
  );
}

function YearlyEnergyInput({ yearlyEnergy, onChangeYearlyEnergy }) {
  function checkYearlyEnergyValue(value) {
    // allow empty string - otherwise, make sure the energy is at least the min allowed
    return value === ""
      ? ""
      : Math.max(value, YEARLY_ENERGY_LIMITS[CURRENT_THEME].MIN);
  }

  return (
    <Input
      type="number"
      name="yearlyEnergy"
      id="yearlyEnergy"
      className="nospinners"
      component="input"
      min={YEARLY_ENERGY_LIMITS[CURRENT_THEME].MIN}
      value={yearlyEnergy.toString()}
      onChange={(e) =>
        onChangeYearlyEnergy(checkYearlyEnergyValue(e.target.value))
      }
    />
  );
}

function NumberOfPeople({ numPeople, onIncrease, onDecrease }) {
  let people = [];

  for (let i = 1; i < numPeople + 1; i++) {
    if (i % 2 === 0) {
      people.push(<FontAwesomeIcon className="person" icon="female" key={i} />);
    } else {
      people.push(<FontAwesomeIcon className="person" icon="male" key={i} />);
    }
  }

  return (
    <div className="number-of-people">
      <div className="people">{people.map((person) => person)}</div>
      <div className="text">
        <span className="number">
          {numPeople < MAX_PEOPLE ? numPeople : MAX_PEOPLE + "+"} Person
          {numPeople > 1 ? "en" : ""}
        </span>
      </div>
      <div className="controls">
        <MinusIcon disabled={numPeople < MIN_PEOPLE + 1} onClick={onDecrease} />
        <PlusIcon disabled={numPeople > MAX_PEOPLE - 1} onClick={onIncrease} />
      </div>
    </div>
  );
}

function PlusIcon({ disabled, onClick }) {
  const useDisabledClass = CURRENT_THEME !== THEMES.OBERLAND17;
  let icon, disabledIcon;

  switch (CURRENT_THEME) {
    case THEMES.OBERLAND17:
      icon = oberland17plusIcon;
      disabledIcon = oberland17plusIconDisabled;
      break;
    default:
      icon = plusIcon;
      disabledIcon = plusIcon;
      break;
  }

  return (
    <img
      src={disabled ? disabledIcon : icon}
      alt="Erhöhen"
      className={classnames({ disabled: disabled && useDisabledClass })}
      onClick={onClick}
    />
  );
}

function MinusIcon({ disabled, onClick }) {
  const useDisabledClass = CURRENT_THEME !== THEMES.OBERLAND17;
  let icon, disabledIcon;

  switch (CURRENT_THEME) {
    case THEMES.OBERLAND17:
      icon = oberland17minusIcon;
      disabledIcon = oberland17minusIconDisabled;
      break;
    default:
      icon = minusIcon;
      disabledIcon = minusIcon;
      break;
  }

  return (
    <img
      src={disabled ? disabledIcon : icon}
      alt="Verringern"
      className={classnames({ disabled: disabled && useDisabledClass })}
      onClick={onClick}
    />
  );
}

HowMuchPowerDoYouNeed.propTypes = {
  onGoToNextStep: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  isWidgetMode: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    consumerType: state.mix.consumerType,
    yearlyEnergy: state.mix.yearlyEnergy
  };
}

const mapDispatchToProps = (dispatch) => ({
  setYearlyEnergy: (yearlyEnergy) =>
    dispatch(setMixOptionsAction({ yearlyEnergy }))
});

const connectedHowMuchPowerDoYouNeed = connect(
  mapStateToProps,
  mapDispatchToProps
)(HowMuchPowerDoYouNeed);

export { connectedHowMuchPowerDoYouNeed as HowMuchPowerDoYouNeed };
