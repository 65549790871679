import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";

import { Margin } from "./fields/Margin.js";
import { MAXIMUM_MARGIN, STRING_KEYS } from "../../constants.js";
import { ContinueButton } from "../../components/ContinueButton";
import { InfoIcon } from "../../components/InfoIcon";
import { getString } from "../../utils";
import "./PotentialCheckMargin.scss";

class PotentialCheckMargin extends Component {
  render() {
    const { onGoToNextStep, plantMargin, plantYearlyEnergy } = this.props;

    let yearlyEstimate = this.props.calculateYearlyEstimate(
      plantMargin,
      plantYearlyEnergy
    );
    if (yearlyEstimate !== "???") {
      yearlyEstimate = yearlyEstimate.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }

    return (
      <div className="PotentialCheckMargin">
        <header>
          <h3>{getString(STRING_KEYS.POTENTIAL_CHECK_MARGIN_TITLE)}</h3>
        </header>
        <div className="margin-and-recommendation">
          <Margin />
          <div className="recommendation">
            (wir empfehlen zum Start 0,3 ct/kWh oder weniger)
          </div>
        </div>
        <div className="info">
          <p
            className={"estimation" + (plantMargin ? " estimation-height" : "")}
          >
            <span>Mehrerlös bei 80% Absatzmenge: </span>
            <span className="yearly-estimate">
              {yearlyEstimate}
              {plantMargin ? "€ / Jahr" : ""}
            </span>
          </p>
          <p className="explanation">
            Wann erreiche ich den Mehrerlös?{" "}
            <InfoIcon tooltipName="how-get-tooltip">
              <p>
                {getString(
                  STRING_KEYS.POTENTIAL_CHECK_MARGIN_HOW_GET_TOOLTIP_TEXT
                )}
              </p>
            </InfoIcon>
          </p>
          <p className="explanation">
            Was ist der Investitionsbonus?{" "}
            <InfoIcon tooltipName="what-is-bonus-tooltip">
              <p>
                {getString(
                  STRING_KEYS.POTENTIAL_CHECK_MARGIN_WHAT_IS_BONUS_TOOLTIP_TEXT
                )}
              </p>
            </InfoIcon>
          </p>
          <p className="explanation">
            Wieso ist der Investitionsbonus begrenzt?{" "}
            <InfoIcon tooltipName="why-limit-tooltip">
              <p>
                {getString(
                  STRING_KEYS.POTENTIAL_CHECK_MARGIN_WHY_LIMIT_TOOLTIP_TEXT
                )}
              </p>
            </InfoIcon>
          </p>
          <p className="explanation">
            Wieso sollte ich jetzt anfangen und nicht wenn meine 20 Jahre EEG
            auslaufen?{" "}
            <InfoIcon tooltipName="why-twenty-tooltip">
              <p>
                {getString(
                  STRING_KEYS.POTENTIAL_CHECK_MARGIN_WHY_TWENTY_TOOLTIP_TEXT
                )}
              </p>
            </InfoIcon>
          </p>
        </div>
        {plantMargin && (
          <footer className="continue-button-container">
            <ContinueButton
              onClick={onGoToNextStep}
              disabled={plantMargin < 0 || plantMargin > MAXIMUM_MARGIN}
            />
          </footer>
        )}
      </div>
    );
  }
}

PotentialCheckMargin.propTypes = {
  onGoToNextStep: PropTypes.func.isRequired
};

let reduxFormPotentialCheckMargin = reduxForm({
  form: "potentialCheck",
  destroyOnUnmount: false
})(PotentialCheckMargin);

const selector = formValueSelector("potentialCheck");
reduxFormPotentialCheckMargin = connect((state) => {
  const plantMargin = selector(state, "plantMargin");
  const plantInstalledCapacity = selector(state, "plantInstalledCapacity");
  const plantYearlyEnergy = selector(state, "plantYearlyEnergy");
  return {
    plantMargin,
    plantInstalledCapacity,
    plantYearlyEnergy
  };
})(reduxFormPotentialCheckMargin);

export default reduxFormPotentialCheckMargin;
