import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import { Field } from "redux-form";

import { InfoIcon } from "./InfoIcon";
import { OptionalMarker } from "../utils.js";

class InputField extends Component {
  render() {
    const {
      name,
      label,
      type,
      component = "input",
      maxLength = null,
      required = false,
      error,
      inputClasses = "",
      tooltipText = null,
      inputGroupText
    } = this.props;

    const infoIcon = tooltipText && (
      <InfoIcon tooltipName={name + "-tooltip"}>{tooltipText}</InfoIcon>
    );
    const max = type === "number" ? "9".repeat(maxLength) : null;
    const requiredText = required ? null : <OptionalMarker />;

    return (
      <FormGroup>
        <Label for={name}>
          {label}
          {requiredText} {infoIcon}
        </Label>
        <InputGroupWrapper text={inputGroupText}>
          <Input
            type={type}
            tag={Field}
            name={name}
            id={name}
            maxLength={maxLength}
            max={max}
            component={component}
            invalid={!!error}
            className={inputClasses}
          />
        </InputGroupWrapper>
        {error && <span className="error">{error}</span>}
      </FormGroup>
    );
  }
}

function InputGroupWrapper({ text, children }) {
  if (!text) {
    return <>{children}</>;
  }

  return (
    <InputGroup>
      {children}
      <InputGroupAddon addonType="append">{text}</InputGroupAddon>
    </InputGroup>
  );
}

export { InputField };
