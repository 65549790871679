import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { Blog } from "../../components/Blog";
import { ChooseProducersFromMap } from "./ChooseProducersFromMap";
import { CURRENT_THEME, STRING_KEYS, THEMES } from "../../constants";
import { FEATURES, checkFeature } from "../../features";
import { GetAQuote } from "./GetAQuote";
import { getPowerPlantsAction } from "../../actions";
import { getString, Loader, MarkdownString } from "../../utils.js";
import { TextWithIcon } from "../../components/TextWithIcon.js";

import "./LandingConsumer.scss";

import backgroundImage from "../../images/photos/consumer-landing-background.jpg";
import backgroundImage2 from "../../images/photos/consumer-landing-background-2.jpg";
import backgroundImage3 from "../../images/photos/consumer-landing-background-3.jpg";
import backgroundImage6 from "../../images/photos/consumer-landing-background-6.jpg";
import swlBackgrondImage from "../../images/swl/consumer-landing/background.jpg";
import oberland17BackgroundImage1 from "../../images/oberland17/consumer-landing/Solar_Berge.jpg";
import oberland17BackgroundImage2 from "../../images/oberland17/consumer-landing/Walchensee_Panorama.jpg";
import oberland17BackgroundImage3 from "../../images/oberland17/consumer-landing/Solaranlage.jpg";
import downArrowImage from "../../images/icons/btn-arrow-down.png";
import solarPanelsRoofImage from "../../images/photos/solar-panels-roof.jpg";
import solarWindFlexibilityImage from "../../images/photos/solar-wind-flexibility.jpg";
import variablePricingImage from "../../images/graphics/variable_pricing_with_text.png";
import swlBioImage from "../../images/swl/consumer-landing/icon-bio.png";
import swlSolarImage from "../../images/swl/consumer-landing/icon-solar.png";
import swlWaterImage from "../../images/swl/consumer-landing/icon-wasserkraft.png";
import oberland17BenefitImage1 from "../../images/oberland17/consumer-landing/benefit-1.jpg";
import oberland17BenefitImage2 from "../../images/oberland17/consumer-landing/benefit-2.jpg";
import oberland17BenefitImage3 from "../../images/oberland17/consumer-landing/benefit-3.jpg";
import oberland17BenefitImage4 from "../../images/oberland17/consumer-landing/benefit-4.jpg";

const HEADLINE = getString(STRING_KEYS.LANDING_CONSUMER_TITLE);
const SUBTITLE = getString(STRING_KEYS.LANDING_CONSUMER_SUBTITLE);
const AUTOPLAY_INTERVAL = 8000; // 8s

const CELLS_ENERGY_BACKGROUND_IMAGES = [
  {
    src: backgroundImage,
    altText: HEADLINE
  },
  {
    src: backgroundImage2,
    altText: HEADLINE
  },
  {
    src: backgroundImage6,
    altText: HEADLINE
  },
  {
    src: backgroundImage3,
    altText: HEADLINE
  }
];

const SWL_BACKGROUND_IMAGES = [
  {
    src: swlBackgrondImage,
    altText: HEADLINE
  }
];

const OBERLAND17_BACKGROUND_IMAGES = [
  {
    src: oberland17BackgroundImage1,
    altText: HEADLINE
  },
  {
    src: oberland17BackgroundImage2,
    altText: HEADLINE
  },
  {
    src: oberland17BackgroundImage3,
    altText: HEADLINE
  },
  {
    src: backgroundImage,
    altText: HEADLINE
  },
  {
    src: backgroundImage2,
    altText: HEADLINE
  }
];

const CELLS_ENERGY_BENEFIT_IMAGES = [
  solarPanelsRoofImage,
  backgroundImage,
  solarWindFlexibilityImage
];

const SWL_BENEFIT_IMAGES = [swlBioImage, swlSolarImage, swlWaterImage];

const OBERLAND17_BENEFIT_IMAGES = [
  oberland17BenefitImage1,
  oberland17BenefitImage2,
  oberland17BenefitImage3,
  oberland17BenefitImage4
];

function getCarouselImagesByTheme(theme) {
  switch (theme) {
    case THEMES.SWL:
      return SWL_BACKGROUND_IMAGES;
    case THEMES.OBERLAND17:
      return OBERLAND17_BACKGROUND_IMAGES;
    default:
      return CELLS_ENERGY_BACKGROUND_IMAGES;
  }
}

function getBenefitImageByBenefitNumberAndTheme(benefitNumber, theme) {
  const index = benefitNumber - 1;
  switch (theme) {
    case THEMES.SWL:
      return SWL_BENEFIT_IMAGES[index];
    case THEMES.OBERLAND17:
      return OBERLAND17_BENEFIT_IMAGES[index];
    default:
      return CELLS_ENERGY_BENEFIT_IMAGES[index];
  }
}

class LandingConsumer extends Component {
  componentDidMount() {
    this.props.getPowerPlants();
  }

  render() {
    const { producerPowerPlants } = this.props;

    const carouselImages = getCarouselImagesByTheme(CURRENT_THEME);
    const carouselAutoPlay = carouselImages.length > 1;

    return (
      <div className="page LandingConsumer">
        <header id="consumer-landing-header" className="header">
          <div className="background">
            <Carousel
              autoPlay={carouselAutoPlay}
              interval={AUTOPLAY_INTERVAL}
              infiniteLoop={carouselAutoPlay}
              showStatus={false}
              showIndicators={false}
            >
              {carouselImages.map((image) => {
                return (
                  <div key={image.src} className="image-outer">
                    <img src={image.src} alt={image.altText} />
                  </div>
                );
              })}
            </Carousel>
            <div className="overlay"></div>
          </div>
          <Container className="text">
            <Row>
              <h1 className="title">{HEADLINE}</h1>
              <h3 className="subtitle">{SUBTITLE}</h3>
              <GetAQuote />
            </Row>
          </Container>
        </header>
        <Container className="arrow">
          <Row>
            <Col className="flex-center">
              <a href="#strom-kaufen-main">
                <img src={downArrowImage} alt="Nach unten gehen" />
              </a>
            </Col>
          </Row>
        </Container>
        <div id="strom-kaufen-main" />
        {checkFeature(FEATURES.LANDING_CONSUMER_HOW_IT_WORKS) && (
          <Container className="information">
            <Row>
              <Col xs="12" className="how-it-works">
                <h2>
                  {getString(STRING_KEYS.LANDING_CONSUMER_HOW_IT_WORKS_TITLE)}
                </h2>
                <p>
                  {getString(STRING_KEYS.LANDING_CONSUMER_HOW_IT_WORKS_TEXT)}
                </p>
              </Col>
            </Row>
          </Container>
        )}
        <Container className="information">
          <Row>
            <Col xs="12" className="map">
              <h2>{getString(STRING_KEYS.LANDING_CONSUMER_MAP_TITLE)}</h2>
              {producerPowerPlants ? (
                <ChooseProducersFromMap
                  producers={producerPowerPlants}
                  hideMixControls={true}
                />
              ) : (
                <div className="loader">
                  <Loader />
                </div>
              )}
              <div className="join-community-text">
                <MarkdownString
                  stringKey={STRING_KEYS.LANDING_CONSUMER_MAP_TEXT}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="information">
          <Row className="flex-center">
            <h2>{getString(STRING_KEYS.LANDING_CONSUMER_BENEFITS_TITLE)}</h2>
          </Row>
        </Container>
        <section className="section-odd">
          <Container className="information">
            <Row className="texts-and-icons">
              <Col xs="12">
                <TextWithIcon
                  title={getString(
                    STRING_KEYS.LANDING_CONSUMER_BENEFIT_1_TITLE
                  )}
                  img={getBenefitImageByBenefitNumberAndTheme(1, CURRENT_THEME)}
                >
                  <MarkdownString
                    stringKey={STRING_KEYS.LANDING_CONSUMER_BENEFIT_1_TEXT}
                    openLinksInNewWindow
                  />
                </TextWithIcon>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section-even">
          <Container className="information">
            <Row className="texts-and-icons">
              <Col xs="12">
                <TextWithIcon
                  title={getString(
                    STRING_KEYS.LANDING_CONSUMER_BENEFIT_2_TITLE
                  )}
                  img={getBenefitImageByBenefitNumberAndTheme(2, CURRENT_THEME)}
                  leftToRight={false}
                >
                  <MarkdownString
                    stringKey={STRING_KEYS.LANDING_CONSUMER_BENEFIT_2_TEXT}
                    openLinksInNewWindow
                  />
                </TextWithIcon>
                {checkFeature(FEATURES.VARIABLE_PRICING) && (
                  <img
                    src={variablePricingImage}
                    alt="Das ist echte Preistransparenz!"
                    className="variable-pricing-image"
                  />
                )}
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section-odd">
          <Container className="information">
            <Row className="texts-and-icons">
              <Col xs="12">
                <TextWithIcon
                  title={getString(
                    STRING_KEYS.LANDING_CONSUMER_BENEFIT_3_TITLE
                  )}
                  img={getBenefitImageByBenefitNumberAndTheme(3, CURRENT_THEME)}
                >
                  <MarkdownString
                    stringKey={STRING_KEYS.LANDING_CONSUMER_BENEFIT_3_TEXT}
                    openLinksInNewWindow
                  />
                </TextWithIcon>
              </Col>
            </Row>
          </Container>
        </section>
        {CURRENT_THEME === THEMES.OBERLAND17 && (
          <section className="section-even">
            <Container className="information">
              <Row className="texts-and-icons">
                <Col xs="12">
                  <TextWithIcon
                    title={getString(
                      STRING_KEYS.LANDING_CONSUMER_BENEFIT_4_TITLE
                    )}
                    img={getBenefitImageByBenefitNumberAndTheme(
                      4,
                      CURRENT_THEME
                    )}
                    leftToRight={false}
                  >
                    <MarkdownString
                      stringKey={STRING_KEYS.LANDING_CONSUMER_BENEFIT_4_TEXT}
                      openLinksInNewWindow
                    />
                  </TextWithIcon>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {checkFeature(FEATURES.BLOG) && (
          <Container className="social">
            <Row>
              <Col>
                <Blog />
              </Col>
            </Row>
          </Container>
        )}
        <footer className="information-footer">
          <Container>
            <Row>
              <Col>
                {checkFeature(FEATURES.CONSUMER_LANDING_PAGE_BUTTON_FOOTER) ? (
                  <ButtonFooter />
                ) : (
                  <div className="no-margin">
                    <MarkdownString
                      stringKey={STRING_KEYS.LANDING_CONSUMER_FOOTER}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}

function ButtonFooter() {
  return (
    <div className="button-footer">
      <h2>{getString(STRING_KEYS.LANDING_CONSUMER_FOOTER)}</h2>
      <Button
        color="primary"
        size="lg"
        tag={Link}
        to="#consumer-landing-header"
      >
        {getString(STRING_KEYS.LANDING_CONSUMER_FOOTER_BUTTON)}
      </Button>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    producerPowerPlants: state.powerPlants.powerPlants
  };
}

const mapDispatchToProps = (dispatch) => ({
  getPowerPlants: () => dispatch(getPowerPlantsAction())
});

const connectedLandingConsumer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingConsumer);

export { connectedLandingConsumer as LandingConsumer };
