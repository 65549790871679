import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import consumerAgb from "../docs/AGB_Stromlieferung_SLP.pdf";
import rlmConsumerAgb from "../docs/AGB_Stromlieferung_RLM.pdf";
import producerAgb from "../docs/AGB_Marktplatznutzung.pdf";

export default class Agb extends Component {
  render() {
    return (
      <div className="Agb page" style={{ textAlign: "left" }}>
        <header>
          <Container>
            <Row>
              <h3>Allgemeine Geschäftsbedingungen cells energy Marktplatz</h3>
            </Row>
          </Container>
        </header>
        <main>
          <Container>
            <Row>
              <Col style={{ marginTop: "30px" }}>
                <p>
                  Für die Stromlieferung an Haushalts- und Gewerbekunden bis
                  100.000 kWh je Jahr Stromverbrauch:{" "}
                  <a
                    href={consumerAgb}
                    target="_blank"
                    download="AGB SLP Verbraucher.pdf"
                  >
                    AGB Stromlieferung SLP
                  </a>
                </p>
                <p>
                  Für die Stromlieferung an Haushalts- und Gewerbekunden ab
                  100.000 kWh je Jahr Stromverbrauch:{" "}
                  <a
                    href={rlmConsumerAgb}
                    target="_blank"
                    download="AGB RLM Verbraucher.pdf"
                  >
                    AGB Stromlieferung RLM
                  </a>
                </p>
                <p>
                  Für die Benutzung des Marktplatzes (Anlagenbetreiber):{" "}
                  <a
                    href={producerAgb}
                    target="_blank"
                    download="AGB Marktplatznutzung.pdf"
                  >
                    AGB Marktplatznutzung
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}
