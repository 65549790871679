import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

export default class Impressum extends Component {
  render() {
    return (
      <div className="Impressum page" style={{ textAlign: "left" }}>
        <header>
          <Container>
            <Row>
              <h1>Impressum</h1>
            </Row>
          </Container>
        </header>
        <main>
          <Container>
            <Row>
              <Col>
                <p>renergie GmbH</p>
                <p>
                  Burgstr. 16
                  <br />
                  87435 Kempten
                </p>
                <p>
                  E-Mail: info@cells.energy
                  <br />
                  Telefon: +49 (0)831 – 52 62 680 – 34
                </p>
                <p>
                  Sitz der Gesellschaft: Kempten
                  <br />
                  Amtsgericht Kempten HRB 14286
                  <br />
                  USt-IdNr.: DE319499180
                </p>
                <p>Geschäftsführer: Florian Weh (CEO)</p>
                <p>
                  Inhaltlich Verantwortlicher gem. § 55 Abs. 2 RStV:
                  <br />
                  Florian Weh
                </p>
                <p>
                  Informationen zum Haftungsausschluss finden Sie hier:{" "}
                  <a
                    href="https://cells.energy/?page=formal\imprint"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://cells.energy/?page=formal\imprint
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}
