import {
  POWER_PLANT_GET,
  POWER_PLANT_GET_ERROR,
  RESET_POWER_PLANT_GET_ERROR,
  POWER_PLANTS_GET,
  POWER_PLANTS_GET_ERROR,
  POWER_PLANT_DISTANCES_FROM_POSTCODE_POST,
  POWER_PLANT_CLEAR_ERRORS
} from "../actions";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case POWER_PLANTS_GET:
      return {
        ...state,
        powerPlants: action.payload.powerPlants,
        powerPlantsGetError: false
      };
    case POWER_PLANTS_GET_ERROR:
      return { ...state, powerPlantsGetError: true };
    case POWER_PLANT_GET:
      const plants = state.powerPlants
        ? [...state.powerPlants]
        : [action.payload.powerPlant];

      // update the plant if it is already in the list
      for (let i = 0; i < plants.length; i++) {
        if (plants[i].id === action.payload.powerPlant.id) {
          plants[i] = action.payload.powerPlant;
        }
      }

      // add the plant if it isn't already in the list
      if (!plants.find((plant) => plant.id === action.payload.powerPlant.id)) {
        plants.push(action.payload.powerPlant);
      }

      return { ...state, powerPlants: plants, powerPlantGetError: false };
    case POWER_PLANT_GET_ERROR:
      return { ...state, powerPlantGetError: true };
    case RESET_POWER_PLANT_GET_ERROR:
      return { ...state, powerPlantGetError: false };
    case POWER_PLANT_DISTANCES_FROM_POSTCODE_POST:
      return { ...state, distances: action.payload.distances };
    case POWER_PLANT_CLEAR_ERRORS:
      return {
        ...state,
        powerPlantGetError: false,
        powerPlantsGetError: false
      };
    default:
      return state;
  }
}
