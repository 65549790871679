import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";

import { PlantType, PLANT_TYPE_NOT_SELECT } from "./fields/PlantType.js";
import { Capacity } from "./fields/Capacity.js";
import { EnergyAmountPerYear } from "./fields/EnergyAmountPerYear.js";
import { DEFAULT_MARGIN, STRING_KEYS } from "../../constants";
import { ContinueButton } from "../../components/ContinueButton";
import { InfoIcon } from "../../components/InfoIcon";
import { getString, MarkdownString } from "../../utils";

import "./PotentialCheckPlantType.css";

class PotentialCheckPlantType extends Component {
  componentDidMount() {
    // reset plantMargin to default value
    // this is necessary because the margin value needs to be reset if the user pushes the back button
    // otherwise, it ends up as NaN
    // this wouldn't be necessary if we re-write the potential check to function like the consumer flow
    this.props.change("plantMargin", DEFAULT_MARGIN);
  }

  render() {
    const {
      plantType,
      plantInstalledCapacity,
      plantYearlyEnergy,
      onGoToNextStep
    } = this.props;

    const question1 = <PlantType />;
    const question2 =
      plantType && plantType !== PLANT_TYPE_NOT_SELECT ? <Capacity /> : null;
    const question3 =
      plantType && plantType !== PLANT_TYPE_NOT_SELECT ? (
        <EnergyAmountPerYear />
      ) : null;
    const infoText =
      plantType && plantType !== PLANT_TYPE_NOT_SELECT ? (
        <div>
          <p>
            Mehrere Anlagen?{" "}
            <InfoIcon tooltipName="more-plants-tooltip">
              <MarkdownString
                stringKey={
                  STRING_KEYS.POTENTIAL_CHECK_PLANT_TYPE_MORE_PLANTS_TOOLTIP
                }
              />
            </InfoIcon>
          </p>
        </div>
      ) : null;

    const finalButton =
      plantType &&
      plantType !== PLANT_TYPE_NOT_SELECT &&
      plantInstalledCapacity &&
      plantYearlyEnergy ? (
        <ContinueButton
          onClick={onGoToNextStep}
          disabled={plantInstalledCapacity < 0 || plantYearlyEnergy < 0}
        />
      ) : null;

    return (
      <div className="PotentialCheckPlantType">
        <h3>{getString(STRING_KEYS.POTENTIAL_CHECK_PLANT_TYPE_TITLE)}</h3>
        <section className="question-group">
          {question1}
          {question2}
          {question3}
          {infoText}
        </section>
        <footer className="continue-button-container">{finalButton}</footer>
      </div>
    );
  }
}

PotentialCheckPlantType.propTypes = {
  onGoToNextStep: PropTypes.func.isRequired
};

let reduxFormPotentialCheckPlantType = reduxForm({
  form: "potentialCheck",
  destroyOnUnmount: false
})(PotentialCheckPlantType);

const selector = formValueSelector("potentialCheck");
reduxFormPotentialCheckPlantType = connect((state) => {
  const plantType = selector(state, "plantType");
  const plantInstalledCapacity = selector(state, "plantInstalledCapacity");
  const plantYearlyEnergy = selector(state, "plantYearlyEnergy");
  return {
    plantType,
    plantInstalledCapacity,
    plantYearlyEnergy
  };
})(reduxFormPotentialCheckPlantType);

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      plantMargin: DEFAULT_MARGIN
    }
  };
}

const connectedReduxFormPotentialCheckPlantType = connect(mapStateToProps)(
  reduxFormPotentialCheckPlantType
);

export default connectedReduxFormPotentialCheckPlantType;
