import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

import {
  setMixOptionsAction,
  getPowerPlantDistancesFromPostcodeAction
} from "../../actions";
import api from "../../api";
import { STRING_KEYS } from "../../constants";
import { checkFeature, FEATURES } from "../../features";
import { getString, MarkdownString } from "../../utils";

import "./GetAQuote.scss";

const VALIDATING_POLL_TIMEOUT = 10;

const POSTCODE_STATES = {
  VALID: "valid",
  INVALID: "invalid",
  NO_SUPPLY: "no_supply"
};

class GetAQuote extends Component {
  constructor(props) {
    super(props);

    this.isValidating = false;
    this.validatingPollTimer = null;

    this.state = {
      postcode: "",
      postcodeState: POSTCODE_STATES.INVALID,
      isEmpty: true,
      isPostalCodeErrorModalOpen: false,
      redirectToConsumerFlow: false
    };
  }

  handleInput = (e) => {
    const value = e.target.value;
    const plzRegEx = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;
    const matchesRegEx = plzRegEx.test(value);
    const stateUpdates = {
      isEmpty: value.length === 0,
      postcode: value
    };

    if (matchesRegEx) {
      this.validatePostcode(value);
    } else {
      stateUpdates["postcodeState"] = POSTCODE_STATES.INVALID;
    }

    this.setState(stateUpdates);

    if (value.length > 0 && matchesRegEx) {
      this.props.setMixPostcode(value);
    } else {
      this.props.setMixPostcode(null);
    }
  };

  validatePostcode(postcode) {
    this.isValidating = true;

    api
      .get(`api/postcode-is-valid/?postcode=${postcode}`)
      .then((response) => {
        this.isValidating = false;
        this.setState({
          postcodeState: response.data.state
        });
      })
      .catch((e) => {
        this.isValidating = false;

        // on server error set state valid so user can proceed at least
        this.setState({
          postcodeState: POSTCODE_STATES.VALID
        });
      });
  }

  handleKeyPress = (e) => {
    if (e.charCode === 13 && !this.props.isWidgetMode) {
      this.checkValidationAndHandleClick();
    }
  };

  checkValidationAndHandleClick = () => {
    // clear any existing timer
    if (this.validatingPollTimer) {
      clearTimeout(this.validatingPollTimer);
      this.validatingPollTimer = null;
    }

    if (this.isValidating) {
      // if a validation is in progress, set a timeout and wait
      this.validatingPollTimer = setTimeout(() => {
        this.validatingPollTimer = null;

        // now that the timer has gone off, let's check the validation again
        this.checkValidationAndHandleClick();
      }, VALIDATING_POLL_TIMEOUT);
    } else {
      // no ongoing validation, so we can proceed
      this.handleClick();
    }
  };

  handleClick = () => {
    if (this.state.postcodeState === POSTCODE_STATES.VALID) {
      const postcode = this.state.postcode;
      this.props.getPowerPlantDistancesFromPostcode({ postcode });

      this.setState({
        redirectToConsumerFlow: true
      });
    } else {
      this.togglePostalCodeErrorModal();
    }
  };

  togglePostalCodeErrorModal = () => {
    this.setState({
      isPostalCodeErrorModalOpen: !this.state.isPostalCodeErrorModalOpen
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.onChangeValidity &&
      prevState.postcodeState !== this.state.postcodeState
    ) {
      this.props.onChangeValidity(
        this.state.postcodeState === POSTCODE_STATES.VALID
      );
    }
  }

  componentDidMount() {
    if (this.props.postcode) {
      this.handleInput({ target: { value: this.props.postcode } });
    }
  }

  render() {
    const { isWidgetMode = false } = this.props;

    const {
      postcode,
      postcodeState,
      isEmpty,
      isPostalCodeErrorModalOpen,
      redirectToConsumerFlow
    } = this.state;

    if (redirectToConsumerFlow) {
      return <Redirect to="/strom-kaufen/fragen" push={true} />;
    }

    const isValid = postcodeState === POSTCODE_STATES.VALID;
    const isDisabled = checkFeature(FEATURES.DISABLED_SIGNUPS);

    if (isDisabled) {
      return <ComingSoon />;
    }

    return (
      <div className="GetAQuote">
        <Input
          type="number"
          placeholder={getString(STRING_KEYS.GET_A_QUOTE_PLZ)}
          value={postcode}
          valid={!isEmpty && isValid}
          invalid={!isEmpty && !isValid}
          onChange={this.handleInput}
          onKeyPress={this.handleKeyPress}
        />
        {!isWidgetMode && (
          <Button color="primary" onClick={this.handleClick}>
            Angebot berechnen
          </Button>
        )}
        <Modal
          isOpen={isPostalCodeErrorModalOpen}
          toggle={this.togglePostalCodeErrorModal}
        >
          <ModalHeader>
            {postcodeState === POSTCODE_STATES.NO_SUPPLY
              ? "Keine Belieferung möglich."
              : "Ungültige PLZ."}
          </ModalHeader>
          <ModalBody>
            <PostcodeErrorMessage postcodeState={postcodeState} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.togglePostalCodeErrorModal}>
              Schließen
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function ComingSoon() {
  return (
    <div className="GetAQuote coming-soon">
      <span>Angebot berechnen in Kürze möglich!</span>
    </div>
  );
}

function PostcodeErrorMessage({ postcodeState }) {
  const stringKey =
    postcodeState === POSTCODE_STATES.NO_SUPPLY
      ? STRING_KEYS.GET_A_QUOTE_PLZ_NO_SUPPLY
      : STRING_KEYS.GET_A_QUOTE_PLZ_INVALID;

  return <MarkdownString stringKey={stringKey} openLinksInNewWindow />;
}

function mapStateToProps(state) {
  return {
    postcode: state.mix.postcode
  };
}

const mapDispatchToProps = (dispatch) => ({
  setMixPostcode: (postcode) => dispatch(setMixOptionsAction({ postcode })),
  getPowerPlantDistancesFromPostcode: (postcode) =>
    dispatch(getPowerPlantDistancesFromPostcodeAction(postcode))
});

const connectedGetAQuote = connect(
  mapStateToProps,
  mapDispatchToProps
)(GetAQuote);

export { connectedGetAQuote as GetAQuote };
