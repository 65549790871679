import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap";
import {
  updateContractByIdAction,
  postPowerPlantImageAction,
  deletePowerPlantImageAction
} from "../../actions";

import { CellCheckbox } from "../../components/CellCheckbox";
import { STRING_KEYS } from "../../constants";
import { InfoIcon } from "../../components/InfoIcon";
import { getString, MarkdownString } from "../../utils";

import "./EditPowerPlant.scss";

const PHOTO_UPLOAD_LIMIT = 5000000;

class EditPowerPlant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFiles: [],
      isFirstExampleModalOpen: false,
      isFileUploadErrorModalOpen: false,
      fileUploadErrorText: null
    };
  }

  toggleFirstExampleModal = () => {
    this.setState({
      isFirstExampleModalOpen: !this.state.isFirstExampleModalOpen
    });
  };

  toggleFileUploadErrorModal = () => {
    this.setState({
      isFileUploadErrorModalOpen: !this.state.isFileUploadErrorModalOpen
    });
  };

  openFileUploadErrorModal = (isOpen) => {
    this.setState({
      isFileUploadErrorModalOpen: isOpen
    });
  };

  setFileUploadErrorText = (text) => {
    this.setState(
      {
        fileUploadErrorText: text
      },
      () => {
        this.openFileUploadErrorModal(true);
      }
    );
  };

  submit = (values) => {
    let valuesToSubmit = { ...values };
    if (
      !valuesToSubmit.description ||
      valuesToSubmit.description.length === 0
    ) {
      valuesToSubmit.description = "";
    }

    this.props.updatePowerPlant(this.props.plantData.id, valuesToSubmit);
  };

  handleDrop = (files) => {
    this.setState({
      imageFiles: files.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file)
      }))
    });

    files.forEach((file) => {
      this.props.uploadPowerPlantImage(this.props.plantData.id, file);
    });
  };

  handleDeleteImage = (imageId) => {
    this.props.deletePowerPlantImage({ imageId });
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.isOpen &&
      this.props.isOpen &&
      !localStorage.getItem("example-profile-shown")
    ) {
      this.toggleFirstExampleModal();
      localStorage.setItem("example-profile-shown", true);
    }

    if (prevProps.imageUploadError !== this.props.imageUploadError) {
      this.setFileUploadErrorText(this.props.imageUploadError.text);
    }
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    const { imageFiles } = this.state;
    for (let i = imageFiles.length; i >= 0; i--) {
      const file = imageFiles[0];
      if (file) {
        URL.revokeObjectURL(file.preview);
      }
    }
  }

  render() {
    const {
      handleSubmit,
      toggle,
      isOpen,
      plantData,
      displayName,
      name,
      description
    } = this.props;

    const {
      isFirstExampleModalOpen,
      isFileUploadErrorModalOpen,
      fileUploadErrorText
    } = this.state;

    const disableSubmit =
      !displayName ||
      displayName.length === 0 ||
      !name ||
      name.length === 0 ||
      !description ||
      description.length === 0;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="EditPowerPlant">
        <ModalHeader toggle={toggle}>
          {getString(STRING_KEYS.EDIT_POWER_PLANT_TITLE)}
        </ModalHeader>
        <Form onSubmit={handleSubmit(this.submit)}>
          <ModalBody>
            <Container>
              <Row>
                <Label for="displayName">Anlagenname</Label>
                <Input
                  type="text"
                  tag={Field}
                  name="displayName"
                  id="displayName"
                  component="input"
                  placeholder="Anlagenname"
                  maxLength="100"
                />
                <p className="name-info">
                  {getString(STRING_KEYS.EDIT_POWER_PLANT_NAME_INFO)}{" "}
                  <InfoIcon tooltipName="plant-name-tooltip">
                    {getString(STRING_KEYS.EDIT_POWER_PLANT_NAME_INFO_TOOLTIP)}
                  </InfoIcon>
                </p>
              </Row>
              <Row>
                <Label for="name">Slogan</Label>
                <Input
                  type="text"
                  tag={Field}
                  name="name"
                  id="name"
                  component="input"
                  placeholder="Anlagenslogan"
                  maxLength="100"
                />
              </Row>
              <Row>
                <Label for="description">Anlagenbeschreibung</Label>
                <Input
                  type="textarea"
                  tag={Field}
                  name="description"
                  id="description"
                  component="textarea"
                  placeholder="Anlagenbeschreibung"
                  maxLength="100000"
                />
                <div className="description-info">
                  <MarkdownString
                    stringKey={STRING_KEYS.EDIT_POWER_PLANT_DESCRIPTION_INFO}
                  />
                </div>
              </Row>
              <Row className="images-and-videos">
                <Container>
                  <Row>
                    <header>
                      <h5>Bilder und Videos</h5>
                    </header>
                  </Row>
                  <Row className="images">
                    {plantData.images.map((image) => (
                      <Col className="image-container" key={image.id}>
                        <div className="image-inner">
                          <img src={image.file} alt={plantData.name} />
                          <span
                            className="delete-button"
                            onClick={() => this.handleDeleteImage(image.id)}
                          >
                            &times;
                          </span>
                        </div>
                      </Col>
                    ))}
                    <Col className="image-container">
                      <div className="image">
                        <div className="dropzone-container">
                          <Dropzone
                            accept="image/*"
                            maxSize={PHOTO_UPLOAD_LIMIT}
                            onDrop={this.handleDrop}
                            onDropRejected={() =>
                              this.setFileUploadErrorText(
                                "Bild zu Groß oder falscher Dateityp."
                              )
                            }
                            className="dropzone"
                          />
                        </div>
                        <span>Foto Hochladen (5 MB max.)</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <p>
                      Wir empfehlen, mindestens 4 und optimalerweise bis zu 8
                      professionelle und aussagekräftige Bilder hochzuladen
                    </p>
                    <Alert color="info">
                      Unsere Empfehlungen:
                      <ul>
                        <li>
                          {getString(
                            STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_1
                          )}{" "}
                          <InfoIcon tooltipName="take-professional-photos-tooltip">
                            {getString(
                              STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_1_TOOLTIP
                            )}
                          </InfoIcon>
                        </li>
                        <li>
                          {getString(
                            STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_2
                          )}{" "}
                          <InfoIcon tooltipName="see-self-as-operator-tooltip">
                            {getString(
                              STRING_KEYS.EDIT_POWER_PLANT_RECOMMENDATION_2_TOOLTIP
                            )}
                          </InfoIcon>
                        </li>
                        <li>
                          <a
                            href="/strom-kaufen/anlagen/85ga8/bioenergie-und-nahwarme-karrer-woringen"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Beispielprofil
                          </a>
                        </li>
                      </ul>
                    </Alert>
                  </Row>
                  <Row>
                    <Col>
                      <header>
                        <h5>
                          {getString(
                            STRING_KEYS.EDIT_POWER_PLANT_PROFILE_VISIBILITY_TITLE
                          )}
                        </h5>
                      </header>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CellCheckbox
                        id="published"
                        name="published"
                        formGroupClassName="published"
                        text="Profil veröffentlichen"
                        usesReduxFormField
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {!plantData.approved && (
                        <Alert color="warning">
                          {getString(
                            STRING_KEYS.EDIT_POWER_PLANT_NOT_APPROVED_WARNING
                          )}
                        </Alert>
                      )}
                    </Col>
                  </Row>
                </Container>
              </Row>
            </Container>
            <Modal
              isOpen={isFirstExampleModalOpen}
              toggle={this.toggleFirstExampleModal}
            >
              <ModalHeader>
                {getString(STRING_KEYS.EDIT_POWER_PLANT_HELP_MODAL_TITLE)}
              </ModalHeader>
              <ModalBody>
                <p>
                  <MarkdownString
                    stringKey={STRING_KEYS.EDIT_POWER_PLANT_HELP_MODAL_TEXT}
                    openLinksInNewWindow
                  />
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.toggleFirstExampleModal}>
                  Schließen
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={isFileUploadErrorModalOpen}
              toggle={this.toggleFileUploadErrorModal}
            >
              <ModalHeader>Fehler!</ModalHeader>
              <ModalBody>
                <p
                  dangerouslySetInnerHTML={{ __html: fileUploadErrorText }}
                ></p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={this.toggleFileUploadErrorModal}
                >
                  Schließen
                </Button>
              </ModalFooter>
            </Modal>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              onClick={toggle}
              disabled={disableSubmit}
            >
              Speichern
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

let reduxFormEditPowerPlant = reduxForm({
  form: "editPowerPlant"
})(EditPowerPlant);

const selector = formValueSelector("editPowerPlant");
reduxFormEditPowerPlant = connect((state) => {
  const displayName = selector(state, "displayName");
  const name = selector(state, "name");
  const description = selector(state, "description");
  const published = selector(state, "published");

  return {
    displayName,
    name,
    description,
    published
  };
})(reduxFormEditPowerPlant);

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      displayName: ownProps.plantData.displayName,
      name: ownProps.plantData.name,
      description: ownProps.plantData.description,
      published: ownProps.plantData.published
    },
    imageUploadError: state.contract.imageUploadError
  };
}

const mapDispatchToProps = (dispatch) => ({
  updatePowerPlant: (id, values) =>
    dispatch(updateContractByIdAction(id, values)),
  uploadPowerPlantImage: (plantId, file) =>
    dispatch(postPowerPlantImageAction(plantId, file)),
  deletePowerPlantImage: (imageId) =>
    dispatch(deletePowerPlantImageAction(imageId))
});

const connectedReduxFormEditPowerPlant = connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxFormEditPowerPlant);

export { connectedReduxFormEditPowerPlant as EditPowerPlant };
