import React, { Component } from "react";
import { Button } from "reactstrap";

import {
  CURRENT_THEME,
  MAXIMUM_MARGIN,
  MINIMUM_MARGIN,
  THEMES
} from "../constants.js";

import oberland17MinusIcon from "../images/oberland17/icons/icon_minus.jpg";
import oberland17MinusIconDisabled from "../images/oberland17/icons/icon_minus_disabled.jpg";
import oberland17PlusIcon from "../images/oberland17/icons/icon_plus.jpg";
import oberland17PlusIconDisabled from "../images/oberland17/icons/icon_plus_disabled.jpg";
import "./MarginControl.scss";

const MOUSE_DOWN_DELAY = 300;
const MARGIN_CHANGE_INTERVAL = 50;
const NUM_INTERVALS_BEFORE_SPEED_INCREASE = 5;
const MARGIN_STEP = 0.01;
const MARGIN_FAST_STEP = 0.1;

class MarginControl extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.interval = null;
    this.intervalCount = 0;
  }

  getMinMargin() {
    return this.props.minMargin || MINIMUM_MARGIN;
  }

  getMaxMargin() {
    return this.props.maxMargin || MAXIMUM_MARGIN;
  }

  increaseMargin = () => {
    let newMargin = parseFloat(this.props.margin);

    if (this.intervalCount > NUM_INTERVALS_BEFORE_SPEED_INCREASE) {
      newMargin = Math.min(
        Math.ceil((newMargin + MARGIN_FAST_STEP) * 10) / 10,
        this.getMaxMargin()
      );
    } else {
      newMargin = Math.min(newMargin + MARGIN_STEP, this.getMaxMargin());
    }

    this.props.onIncreaseMargin(newMargin);
  };

  handleMouseDownIncrease = () => {
    this.clearTimer();

    this.timeout = setTimeout(
      function () {
        this.interval = setInterval(
          function () {
            this.increaseMargin();
            this.intervalCount++;
          }.bind(this),
          MOUSE_DOWN_DELAY
        );
      }.bind(this),
      MARGIN_CHANGE_INTERVAL
    );
  };

  decreaseMargin = () => {
    let newMargin = parseFloat(this.props.margin);

    if (this.intervalCount > NUM_INTERVALS_BEFORE_SPEED_INCREASE) {
      newMargin = Math.max(
        Math.floor((newMargin - MARGIN_FAST_STEP) * 10) / 10,
        this.getMinMargin()
      );
    } else {
      newMargin = Math.max(newMargin - MARGIN_STEP, this.getMinMargin());
    }

    this.props.onDecreaseMargin(newMargin);
  };

  handleMouseDownDecrease = () => {
    this.clearTimer();

    this.timeout = setTimeout(
      function () {
        this.interval = setInterval(
          function () {
            this.decreaseMargin();
            this.intervalCount++;
          }.bind(this),
          MOUSE_DOWN_DELAY
        );
      }.bind(this),
      MARGIN_CHANGE_INTERVAL
    );
  };

  clearTimer = () => {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
    this.intervalCount = 0;
  };

  render() {
    const { margin } = this.props;

    return (
      <div className="MarginControl">
        <span className="text">
          {parseFloat(margin).toLocaleString("de-DE")} ct/kWh
        </span>
        <MinusButton
          disabled={margin <= this.getMinMargin()}
          onClick={this.decreaseMargin}
          onMouseDown={this.handleMouseDownDecrease}
          onMouseUp={this.clearTimer}
          onMouseLeave={this.clearTimer}
        />
        <PlusButton
          disabled={margin >= this.getMaxMargin()}
          onClick={this.increaseMargin}
          onMouseDown={this.handleMouseDownIncrease}
          onMouseUp={this.clearTimer}
          onMouseLeave={this.clearTimer}
        />
      </div>
    );
  }
}

function PlusButton({ disabled, ...otherProps }) {
  if (CURRENT_THEME === THEMES.OBERLAND17) {
    return (
      <img
        src={disabled ? oberland17PlusIconDisabled : oberland17PlusIcon}
        className="plus-minus-image"
        {...otherProps}
      />
    );
  } else {
    return (
      <Button
        className="plus-minus"
        color="primary"
        disabled={disabled}
        {...otherProps}
      >
        +
      </Button>
    );
  }
}

function MinusButton({ disabled, ...otherProps }) {
  if (CURRENT_THEME === THEMES.OBERLAND17) {
    return (
      <img
        src={disabled ? oberland17MinusIconDisabled : oberland17MinusIcon}
        className="plus-minus-image"
        {...otherProps}
      />
    );
  } else {
    return (
      <Button
        className="plus-minus"
        color="primary"
        disabled={disabled}
        {...otherProps}
      >
        -
      </Button>
    );
  }
}

export { MarginControl };
