import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Form, FormGroup, Label, Input, Row } from "reactstrap";
import {
  Field,
  formValueSelector,
  getFormSubmitErrors,
  reduxForm,
  SubmissionError
} from "redux-form";

import { Birthdate } from "../../../components/Birthdate";
import { CellCheckbox } from "../../../components/CellCheckbox";
import { ContinueButton } from "../../../components/ContinueButton";
import { InputField } from "../../../components/InputField";
import { getString, MarkdownString } from "../../../utils";

import {
  EMPTY_DROPDOWN,
  USER_TYPES,
  EMAIL_MAX_LENGTH,
  TITLES,
  FIRST_NAME_MAX_LENGTH,
  SURNAME_MAX_LENGTH,
  COMPANY_NAME_MAX_LENGTH,
  STREET_MAX_LENGTH,
  HOUSE_NUMBER_MAX_LENGTH,
  POSTCODE_MAX_LENGTH,
  CITY_MAX_LENGTH,
  TELEPHONE_NUMBER_MAX_LENGTH,
  EMAIL_REG_EX,
  PLZ_REG_EX,
  STRING_KEYS
} from "../../../constants.js";

import swlPrivacyPdf from "../../../docs/swl/2021_04_14_Datenschutzerklärung_Strommarktplatz_node_SWL_clean.pdf";

class AddressForm extends Component {
  handleUpdateBirthdate = (birthdate) => {
    this.props.change("ownerBirthDate", birthdate);
  };

  handleSubmit = (data) => {
    const errors = this.validateAfterSubmit(data);
    if (Object.keys(errors).length > 0) {
      window.scrollTo(0, 0);
      throw new SubmissionError(errors);
    }

    this.props.onSubmitForm();
  };

  validateAfterSubmit(values) {
    const { consumerType } = this.props;

    const errors = {};

    if (!values.title || values.title === EMPTY_DROPDOWN) {
      errors.title = getString(STRING_KEYS.ERROR_TITLE_MISSING);
    }

    if (!values.firstName) {
      errors.firstName = getString(STRING_KEYS.ERROR_FIRST_NAME_MISSING);
    }

    if (!values.lastName) {
      errors.lastName = getString(STRING_KEYS.ERROR_LAST_NAME_MISSING);
    }

    if (!values.email || !EMAIL_REG_EX.test(values.email)) {
      errors.email = getString(STRING_KEYS.ERROR_EMAIL_INVALID);
    }

    if (!values.telephoneNumber) {
      errors.telephoneNumber = getString(STRING_KEYS.ERROR_PHONE_MISSING);
    }

    if (!values.street) {
      errors.street = getString(STRING_KEYS.ERROR_STREET_MISSING);
    }

    if (!values.postcode || !PLZ_REG_EX.test(values.postcode)) {
      errors.postcode = "Fehler.";
    }

    if (!values.city) {
      errors.city = getString(STRING_KEYS.ERROR_CITY_MISSING);
    }

    if (consumerType === USER_TYPES.business && !values.companyOrOwner) {
      errors.companyOrOwner = getString(STRING_KEYS.ERROR_COMPANY_MISSING);
    }

    return errors;
  }

  render() {
    const { submitErrors, consumerType, ownerBirthDate, handleSubmit } =
      this.props;

    return (
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <Container className="contract-fields">
          <Row>
            <Col>
              <h3>Adresse</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={5} lg={4}>
              <InputField
                name="email"
                label="E-Mail-Adresse"
                type="email"
                maxLength={EMAIL_MAX_LENGTH}
                required
                error={submitErrors.email}
              />
            </Col>
            {consumerType === USER_TYPES.business && (
              <Col xs={12} md={5} lg={4}>
                <InputField
                  name="companyOrOwner"
                  label="Firma"
                  type="text"
                  maxLength={COMPANY_NAME_MAX_LENGTH}
                  required
                  error={submitErrors.companyOrOwner}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="title">Anrede</Label>
                <Input
                  type="select"
                  tag={Field}
                  id="title"
                  name="title"
                  component="select"
                  className="shrink"
                  invalid={!!submitErrors.title}
                >
                  <option>{EMPTY_DROPDOWN}</option>
                  {TITLES.map((title) => (
                    <option key={title}>{title}</option>
                  ))}
                </Input>
                {submitErrors.title && (
                  <span className="error">{submitErrors.title}</span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={5} lg={4}>
              <InputField
                name="firstName"
                label="Vorname"
                type="text"
                maxLength={FIRST_NAME_MAX_LENGTH}
                required
                error={submitErrors.firstName}
              />
            </Col>
            <Col xs={12} md={5} lg={4}>
              <InputField
                name="lastName"
                label="Nachname"
                type="text"
                maxLength={SURNAME_MAX_LENGTH}
                required
                error={submitErrors.lastName}
              />
            </Col>
          </Row>
          {consumerType === USER_TYPES.private && (
            <Row>
              <Col>
                <Birthdate
                  value={ownerBirthDate}
                  onUpdateBirthdate={(birthdate) => {
                    this.handleUpdateBirthdate(birthdate);
                  }}
                  error={submitErrors.ownerBirthDate}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} md={12} lg={8} className="big-then-small">
              <InputField
                name="street"
                label="Straße"
                type="text"
                maxLength={STREET_MAX_LENGTH}
                required
                error={submitErrors.street}
                inputClasses="full-length"
              />
              <InputField
                name="houseNumber"
                label="Hausnr."
                type="text"
                maxLength={HOUSE_NUMBER_MAX_LENGTH}
                required
                error={submitErrors.houseNumber}
                inputClasses="short"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={8} className="small-then-big">
              <InputField
                name="postcode"
                label="PLZ"
                type="number"
                maxLength={POSTCODE_MAX_LENGTH}
                required
                error={submitErrors.postcode}
                inputClasses="short nospinners"
              />
              <InputField
                name="city"
                label="Ort"
                type="text"
                maxLength={CITY_MAX_LENGTH}
                required
                error={submitErrors.city}
                inputClasses="full-length"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={4}>
              <InputField
                name="telephoneNumber"
                label="Telefonnummer"
                type="text"
                maxLength={TELEPHONE_NUMBER_MAX_LENGTH}
                required
                error={submitErrors.telephoneNumber}
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12} md={12} lg={8}>
              <CellCheckbox
                id="useNameInProducerNotification"
                name="useNameInProducerNotification"
                formGroupClassName="bank-sepa"
                textComponent={
                  <div style={{ textAlign: "left" }}>
                    <MarkdownString
                      stringKey={
                        STRING_KEYS.CONSUMER_CONTRACT_USE_NAME_IN_PRODUCER_NOTIFICATION
                      }
                      replacements={[["privacyPdf", swlPrivacyPdf]]}
                      openLinksInNewWindow
                    />
                  </div>
                }
                usesReduxFormField
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col className="continue-button-container">
              <ContinueButton
                onClick={handleSubmit((data) => this.handleSubmit(data))}
              />
            </Col>
          </Row>
        </Container>
      </Form>
    );
  }
}

let reduxFormAddressForm = reduxForm({
  form: "consumerFlowAddressForm",
  initialValues: {
    useNameInProducerNotification: false
  },
  destroyOnUnmount: false
})(AddressForm);

const selector = formValueSelector("consumerFlowAddressForm");
reduxFormAddressForm = connect((state) => {
  const title = selector(state, "title");
  const firstName = selector(state, "firstName");
  const lastName = selector(state, "lastName");
  const companyOrOwner = selector(state, "companyOrOwner");
  const email = selector(state, "email");
  const telephoneNumber = selector(state, "telephoneNumber");
  const street = selector(state, "street");
  const houseNumber = selector(state, "houseNumber");
  const postcode = selector(state, "postcode");
  const city = selector(state, "city");
  const ownerBirthDate = selector(state, "ownerBirthDate");
  const useNameInProducerNotification = selector(
    state,
    "useNameInProducerNotification"
  );

  return {
    title,
    firstName,
    lastName,
    companyOrOwner,
    email,
    telephoneNumber,
    street,
    houseNumber,
    postcode,
    city,
    ownerBirthDate,
    useNameInProducerNotification
  };
})(reduxFormAddressForm);

reduxFormAddressForm = connect((state) => ({
  submitErrors: getFormSubmitErrors("consumerFlowAddressForm")(state)
}))(reduxFormAddressForm);

function mapStateToProps(state) {
  return {
    consumerType: state.mix.consumerType
  };
}

const connectedReduxFormAddressForm = connect(
  mapStateToProps,
  null
)(reduxFormAddressForm);

export { connectedReduxFormAddressForm as AddressForm };
