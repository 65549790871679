import React, { Component } from "react";
import PropTypes from "prop-types";
import { CURRENT_THEME, THEMES } from "../constants";

import oberland17Icon1 from "../images/oberland17/icons/Icon_1.jpg";
import oberland17Icon2 from "../images/oberland17/icons/Icon_2.jpg";
import oberland17Icon3 from "../images/oberland17/icons/Icon_3.jpg";
import oberland17Icon4 from "../images/oberland17/icons/Icon_4.jpg";
import oberland17Icon5 from "../images/oberland17/icons/Icon_5.jpg";
import "./ProgressTracker.scss";

const oberland17StepIcons = [
  oberland17Icon1,
  oberland17Icon2,
  oberland17Icon3,
  oberland17Icon4,
  oberland17Icon5
];

export default class ProgressTracker extends Component {
  renderStep(stepName, stepNumber, currentStepNumber, isLastStep, onGoToStep) {
    let extraClass = "";
    if (currentStepNumber === stepNumber) {
      extraClass = " current";
    } else if (currentStepNumber > stepNumber) {
      extraClass = " done";
    }

    return (
      <div className={"step" + extraClass} key={stepNumber}>
        <div
          className="step-name-number"
          onClick={
            currentStepNumber > stepNumber
              ? () => onGoToStep(stepNumber)
              : function () {}
          }
        >
          <StepNumber number={stepNumber} />
          <div className="step-name">
            <span>{stepName}</span>
          </div>
        </div>
        {!isLastStep ? (
          <div className="line-container">
            <div className="line" />
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const { currentStep, steps, onGoToStep } = this.props;

    return (
      <div className="progress-tracker">
        {steps.map((step, index) =>
          this.renderStep(
            step,
            index + 1,
            currentStep,
            index === steps.length - 1,
            onGoToStep
          )
        )}
      </div>
    );
  }
}

function StepNumber({ number }) {
  if (CURRENT_THEME === THEMES.OBERLAND17) {
    return (
      <div className="step-number-image">
        <img src={oberland17StepIcons[number - 1]} />
      </div>
    );
  } else {
    return (
      <div className="step-number">
        <span>{number}</span>
      </div>
    );
  }
}

ProgressTracker.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string)
};

ProgressTracker.defaultProps = {
  currentStep: 1,
  steps: ["Step 1", "Step 2", "Step 3", "Step 4"]
};
