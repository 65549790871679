import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Form, Input, Row } from "reactstrap";

import api from "../api";
import { STRING_KEYS } from "../constants";
import { getString } from "../utils";

import "./PasswordReset.scss";

function PasswordReset({ match }) {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);

  function handleSubmit() {
    const data = {
      uid: match.params.userId,
      token: match.params.token,
      newPassword1: password,
      new_password2: password2
    };

    api
      .post("/rest-auth/password/reset/confirm/", data)
      .then((response) => {
        setSuccessMessage(response.data.detail);
      })
      .catch((error) => {
        if (error.response.data) {
          const dataError =
            error.response.data.newPassword1 ||
            error.response.data.newPassword2;
          const tokenError = !!error.response.data.token;

          if (dataError) {
            setErrorMessage(dataError);
            return;
          } else if (tokenError) {
            setErrorMessage(
              "Passwort kann nicht geändert werden, da dieser Link abgelaufen ist. " +
                'Es kann ein neuer Link über die "Passwort vergessen" Funktion angefordert werden.'
            );
            return;
          }
        }

        setErrorMessage(
          "Es ist ein Fehler beim Ändern des Passwortes aufgetreten. Wir sind bereits darüber informiert."
        );
      });
  }

  function handleFormSubmit(e) {
    e.preventDefault();
  }

  if (successMessage) {
    return (
      <Outer title="Passwort ändern">
        <Row>
          <Col>
            <p>{successMessage}</p>
          </Col>
        </Row>
      </Outer>
    );
  }

  return (
    <Outer title="Passwort ändern">
      <Row>
        <Col>
          <p>{getString(STRING_KEYS.RESET_PASSWORD_TEXT)}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleFormSubmit}>
            <Input
              type="password"
              name="new-password"
              id="new-password"
              component="input"
              placeholder="Neues Passwort"
              autoComplete="new-password"
              invalid={!!errorMessage}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              type="password"
              name="new-password2"
              id="new-password2"
              component="input"
              placeholder="Neues Passwort bestätigen"
              autoComplete="new-password"
              invalid={!!errorMessage}
              onChange={(e) => setPassword2(e.target.value)}
            />
            <Button color="primary" type="submit" onClick={handleSubmit}>
              Speichern
            </Button>
            <p className="error" style={{ marginTop: "20px" }}>
              {errorMessage}
            </p>
          </Form>
        </Col>
      </Row>
    </Outer>
  );
}

function Outer({ title, children }) {
  return (
    <div className="PasswordReset page" style={{ textAlign: "left" }}>
      <Header title={title} />
      <main>
        <Container>{children}</Container>
      </main>
    </div>
  );
}

function Header({ title }) {
  return (
    <header>
      <Container>
        <Row>
          <h2>{title}</h2>
        </Row>
      </Container>
    </header>
  );
}

const PasswordResetWithRouter = withRouter(PasswordReset);

export { PasswordResetWithRouter as PasswordReset };
