/* global THEME */
import solarImage from "./images/icons/icon-solar.png";
import bioImage from "./images/icons/icon-biogas.png";
import windImage from "./images/icons/icon-windkraft.png";
import waterImage from "./images/icons/icon-wasserkraft.png";
import swlSolarImage from "./images/swl/icons/icon-solar.png";
import swlBioImage from "./images/swl/icons/icon-biogas.png";
import swlWindImage from "./images/swl/icons/icon-windkraft.png";
import swlWaterImage from "./images/swl/icons/icon-wasserkraft.png";

// GENERAL & MISC.

export const EMPTY_DROPDOWN = "Bitte wählen";
export const USER_TYPES = { private: "Residential", business: "Business" };
export const METERING_TYPES = { standard: "Standard", rlm: "RLM" };
export const CUSTOM_MARGIN_UPDATE_TIMEOUT = 500;

// THEME CONSTANTS

export const THEMES = {
  CELLS_ENERGY: "cells-energy",
  SWL: "swl",
  OBERLAND17: "oberland17"
};

export const CURRENT_THEME = THEME;

// PLANT CONSTANTS

export const PLANT_TYPES = [
  "PV Dachanlage",
  "PV Park",
  "Biogasanlage",
  "Biomethananlage",
  "Biomassekraftwerk",
  "Windkraftanlage",
  "Windpark",
  "Wasserkraftanlage"
];

export const ENERGY_TYPES = [
  {
    name: "Solar",
    dbName: "Solar",
    image: CURRENT_THEME === THEMES.SWL ? swlSolarImage : solarImage
  },
  {
    name: "Biogas",
    dbName: "Bio",
    image: CURRENT_THEME === THEMES.SWL ? swlBioImage : bioImage
  },
  {
    name: "Windkraft",
    dbName: "Wind",
    image: CURRENT_THEME === THEMES.SWL ? swlWindImage : windImage
  },
  {
    name: "Wasserkraft",
    dbName: "Hydro",
    image: CURRENT_THEME === THEMES.SWL ? swlWaterImage : waterImage
  }
];
export function getEnergyTypeFromPlantType(plantType) {
  switch (plantType) {
    case "PV Dachanlage":
    case "PV Park":
      return ENERGY_TYPES[0].name;
    case "Biogasanlage":
    case "Biomethananlage":
    case "Biomassekraftwerk":
      return ENERGY_TYPES[1].name;
    case "Windkraftanlage":
    case "Windpark":
      return ENERGY_TYPES[2].name;
    case "Wasserkraftanlage":
      return ENERGY_TYPES[3].name;
    default:
      return null;
  }
}

export const PEOPLE_ENERGY_AMOUNTS = [0, 1500, 2500, 3500, 4250, 5000];

export const DEFAULT_NUM_PEOPLE_IN_HOUSEHOLD = 2;

export const DEFAULT_MARGIN = 0.15;
export const MINIMUM_MARGIN = 0.01;
export const MAXIMUM_MARGIN = 2.0;

export const VAT_FACTORS = {
  [USER_TYPES.private]: 1.19,
  [USER_TYPES.business]: 1
};

export const VAT_INFO_TEXTS = {
  [USER_TYPES.private]:
    "Alle Preise enthalten die gesetzliche Umsatzsteuer in Höhe von 19% (brutto)",
  [USER_TYPES.business]:
    "Alle Preise sind ohne die gesetzliche Umsatzsteuer dargestellt (netto)"
};

// MIX CONSTANTS

export const CUSTOM_MIX_NAME = "custom";
export const MAX_PLANTS_PER_MIX = 5;

// USER DATA CONSTANTS

export const TITLES = ["Herr", "Frau", "Divers"];
export const REASONS_FOR_CHANGE = {
  MOVING: "Einzug",
  SWITCHING: "Versorgerwechsel"
};
export const ALREADY_CANCELLED_ANSWERS = { YES: "Yes", NO: "No" };
export const EMAIL_MAX_LENGTH = 254;
export const FIRST_NAME_MAX_LENGTH = 30;
export const SURNAME_MAX_LENGTH = 150;
export const BANK_PAYMENT_METHODS = {
  TRANSFER: "Transfer",
  DEBIT: "Direct Debit"
};
export const BANK_FIRST_NAME_MAX_LENGTH = 100;
export const BANK_SURNAME_MAX_LENGTH = 100;
export const COMPANY_NAME_MAX_LENGTH = 150;
export const STREET_MAX_LENGTH = 100;
export const HOUSE_NUMBER_MAX_LENGTH = 10;
export const POSTCODE_MAX_LENGTH = 5;
export const CITY_MAX_LENGTH = 100;
export const TELEPHONE_NUMBER_MAX_LENGTH = 20;
export const IBAN_MAX_LENGTH = 34;
export const BIC_MAX_LENGTH = 11;
export const METER_NUMBER_MAX_LENGTH = 50;
export const CURRENT_SUPPLIER_MAX_LENGTH = 50;
export const CUSTOMER_NUMBER_MAX_LENGTH = 50;

export const PLZ_REG_EX = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;
export const EMAIL_REG_EX =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// DATES

export const TODAY = new Date();
export const TOMORROW = new Date(new Date().setDate(TODAY.getDate() + 1));
export const EIGHTEN_YEARS_AGO = new Date(
  new Date().setFullYear(TODAY.getFullYear() - 18)
);
export const SIX_WEEKS_AGO = new Date(
  new Date().setDate(TODAY.getDate() - 6 * 7)
);
export const ONE_YEAR_FROM_TODAY = new Date(
  new Date().setFullYear(TODAY.getFullYear() + 1)
);

// STRINGS

export const STRING_KEYS = {
  ABOUT_US: "about-us",
  ABOUT_US_TITLE: "about-us-title",
  COMPANY_LEGAL_NAME: "company-legal-name",
  CONFIRM_EMAIL_SUCCESS: "confirm-email-success",
  CONFIRM_EMAIL_BUTTON: "confirm-email-button",
  CONSUMER_CONTRACT_ALREADY_CANCELLED: "consumer-contract-already-cancelled",
  CONSUMER_CONTRACT_ALREADY_CANCELLED_TOOLTIP:
    "consumer-contract-already-cancelled-tooltip",
  CONSUMER_CONTRACT_MOVING_TOOLTIP: "consumer-contract-moving-tooltip",
  CONSUMER_CONTRACT_YOUR_MIX: "consumer-contract-your-mix",
  CONSUMER_CONTRACT_CONNECTION_POINT: "consumer-contract-connection-point",
  CONSUMER_CONTRACT_AGB: "consumer-contract-agb",
  CONSUMER_CONTRACT_PRIVACY: "consumer-contract-privacy",
  CONSUMER_CONTRACT_RIGHT_OF_CANCELLATION:
    "consumer-contract-right-of-cancellation",
  CONSUMER_CONTRACT_USE_NAME_IN_PRODUCER_NOTIFICATION:
    "consumer-contract-use-name-in-producer-notification",
  CONSUMER_CONTRACT_CONTRACT_CONDITIONS:
    "consumer-contract-contract-conditions",
  CONSUMER_CONTRACT_EMAIL_PHONE_TOP: "consumer-contract-email-phone-top",
  CONSUMER_CONTRACT_EMAIL_PHONE_BOTTOM: "consumer-contract-email-phone-bottom",
  CONSUMER_CONTRACT_DELIVERY_START_DATE:
    "consumer-contract-delivery-start-date",
  CONSUMER_CONTRACT_SEPA_TEXT: "consumer-contract-sepa-text",
  CONSUMER_CONTRACT_SUBMIT: "consumer-contract-submit",
  CONSUMER_CONTRACT_SUCCESS_MESSAGE: "consumer-contract-success-message",
  CONTACT_MESSAGE_LABEL: "contact-message-label",
  CONTACT_INFO: "contact-info",
  CONTACT_PRIVACY: "contact-privacy",
  CONTACT_SUCCESS: "contact-success",
  CREATE_MIX_TITLE: "create-mix-title",
  CREATE_MIX_INTRO: "create-mix-intro",
  CREATE_MIX_CHOOSE_PLANT: "create-mix-choose-plant",
  CREATE_MIX_MIX_CHART_TITLE_1: "create-mix-mix-chart-title-1",
  CREATE_MIX_MIX_CHART_TITLE_2: "create-mix-mix-chart-title-2",
  EDIT_POWER_PLANT_TITLE: "edit-power-plant-title",
  EDIT_POWER_PLANT_NAME_INFO: "edit-power-plant-name-info",
  EDIT_POWER_PLANT_DESCRIPTION_INFO: "edit-powre-plant-description-info",
  EDIT_POWER_PLANT_NAME_INFO_TOOLTIP: "edit-power-plant-name-info-tooltip",
  EDIT_POWER_PLANT_RECOMMENDATION_1: "edit-power-plant-recommendation-1",
  EDIT_POWER_PLANT_RECOMMENDATION_1_TOOLTIP:
    "edit-power-plant-recommendation-1-tooltip",
  EDIT_POWER_PLANT_RECOMMENDATION_2: "edit-power-plant-recommendation-2",
  EDIT_POWER_PLANT_RECOMMENDATION_2_TOOLTIP:
    "edit-power-plant-recommendation-2-tooltip",
  EDIT_POWER_PLANT_PROFILE_VISIBILITY_TITLE:
    "edit-power-plant-profile-visibility-title",
  EDIT_POWER_PLANT_NOT_APPROVED_WARNING:
    "edit-power-plant-not-approved-warning",
  EDIT_POWER_PLANT_HELP_MODAL_TITLE: "edit-power-plant-help-modal-title",
  EDIT_POWER_PLANT_HELP_MODAL_TEXT: "edit-power-plant-help-modal-text",
  ERROR_FIRST_NAME_MISSING: "error-first-name-missing",
  ERROR_LAST_NAME_MISSING: "error-last-name-missing",
  ERROR_TITLE_MISSING: "error-title-missing",
  ERROR_EMAIL_MISSING: "error-email-missing",
  ERROR_EMAIL_INVALID: "error-email-invalid",
  ERROR_PASSWORD_MISSING: "error-password-missing",
  ERROR_PASSWORD_NO_MATCH: "error-password-no-match",
  ERROR_STREET_MISSING: "error-street-missing",
  ERROR_CITY_MISSING: "error-city-missing",
  ERROR_PHONE_MISSING: "error-phone-missing",
  ERROR_COMPANY_MISSING: "error-company-missing",
  ERROR_CONTRACT_OWNER_MISSING: "error-contract-owner-missing",
  ERROR_CONTRACT_STREET_MISSING: "error-contract-street-missing",
  ERROR_CONTRACT_CITY_MISSING: "error-contract-city-missing",
  ERROR_CONTRACT_PHONE_MISSING: "error-contract-phone-missing",
  ERROR_CONTRACT_EMAIL_MISSING: "error-contract-email-missing",
  ERROR_PLANT_TYPE_MISSING: "error-plant-type-missing",
  ERROR_INSTALLED_CAPACITY_MISSING: "error-installed-capacity-missing",
  ERROR_YEARLY_ENERGY_MISSING: "error-yearly-energy-missing",
  ERROR_PLANT_STREET_MISSING: "error-plant-street-missing",
  ERROR_PLANT_CITY_MISSING: "error-plant-city-missing",
  ERROR_MARKET_LOCATION_ID_INVALID: "error-market-location-id-invalid",
  ERROR_DMSP_MISSING: "error-dmsp-missing",
  ERROR_MARGIN_MISSING: "error-margin-missing",
  ERROR_IBAN_MISSING: "error-iban-missing",
  ERROR_BIC_MISSING: "error-bic-missing",
  ERROR_BANK_STREET_MISSING: "error-bank-street-missing",
  ERROR_BANK_CITY_MISSING: "error-bank-city-missing",
  ERROR_METER_NUMBER_MISSING: "error-meter-number-missing",
  ERROR_METER_COUNT_MISSING: "error-meter-count-missing",
  ERROR_START_DATE_MISSING: "error-start-date-missing",
  ERROR_REASON_FOR_CHANGE_MISSING: "error-reason-for-change-missing",
  ERROR_CURRENT_SUPPLIER_MISSING: "error-current-supplier-missing",
  ERROR_CUSTOMER_NUMBER_MISSING: "error-customer-number-missing",
  ERROR_AGREEMENT_MISSING: "error-agreement-missing",
  ERROR_NO_OPTION_SELECT: "error-no-option-selected",
  ERROR_MESSAGE_MISSING: "error-message-missing",
  ERROR_INVALID_DATA: "error-invalid-data",
  ERROR_REPORT_TEXT: "error-report-text",
  ERROR_GENERAL_CONTACT: "error-general-contact",
  ERROR_GENERAL_RETRY: "error-general-retry",
  ERROR_UNKNOWN: "error-unknown",
  EXTENDED_MIX_INFO_TAB_OVERVIEW: "extended-mix-info-tab-overview",
  EXTENDED_MIX_INFO_TAB_PRODUCERS: "extended-mix-info-tab-producers",
  EXTENDED_MIX_INFO_MIN_CONTRACT_DURATION:
    "extended-mix-info-min-contract-duration",
  EXTENDED_MIX_INFO_CONTRACT_DURATION_TITLE:
    "extended-mix-info-contract-duration-title",
  EXTENDED_MIX_INFO_CONTRACT_DURATION: "extended-mix-info-contract-duration",
  EXTENDED_MIX_INFO_NOTICE_PERIOD: "extended-mix-info-notice-period",
  EXTENDED_MIX_INFO_TOTAL_PRICE_PER_YEAR:
    "extended-mix-info-total-price-per-year",
  EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH:
    "extended-mix-info-final-price-per-month",
  EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH_HELP_TEXT:
    "extended-mix-info-final-price-per-month-help-text",
  FACEBOOK_URL: "facebook-url",
  FIND_DMSP_TEXT: "find-dmsp-text",
  FULL_MIX: "full-mix",
  GET_A_QUOTE_PLZ: "get-a-quote-plz",
  GET_A_QUOTE_PLZ_NO_SUPPLY: "get-a-quote-plz-no-supply",
  GET_A_QUOTE_PLZ_INVALID: "get-a-quote-plz-invalid",
  HOW_MUCH_POWER_PRIVATE_TITLE: "how-much-power-private-title",
  HOW_MUCH_POWER_BUSINESS_TITLE: "how-much-power-business-title",
  HOW_MUCH_POWER_TOO_MUCH: "how-much-power-too-much",
  HOW_MUCH_POWER_TOO_MUCH_IMAGE: "how-much-power-too-much-image",
  HOW_MUCH_POWER_TOO_MUCH_IMAGE_CAPTION:
    "how-much-power-too-much-image-caption",
  IMPRESSUM_URL: "impressum-url",
  INSTAGRAM_URL: "instagram-url",
  LANDING_CONSUMER_TITLE: "landing-consumer-title",
  LANDING_CONSUMER_SUBTITLE: "landing-consumer-subtitle",
  LANDING_CONSUMER_HOW_IT_WORKS_TITLE: "landing-consumer-how-it-works-title",
  LANDING_CONSUMER_HOW_IT_WORKS_TEXT: "landing-consumer-how-it-works-text",
  LANDING_CONSUMER_MAP_TITLE: "landing-consumer-map-title",
  LANDING_CONSUMER_MAP_TEXT: "landing-consumer-map-text",
  LANDING_CONSUMER_BENEFITS_TITLE: "landing-consumer-benefits-title",
  LANDING_CONSUMER_BENEFIT_1_TITLE: "landing-consumer-benefit-1-title",
  LANDING_CONSUMER_BENEFIT_1_TEXT: "landing-consumer-benefit-1-text",
  LANDING_CONSUMER_BENEFIT_2_TITLE: "landing-consumer-benefit-2-title",
  LANDING_CONSUMER_BENEFIT_2_TEXT: "landing-consumer-benefit-2-text",
  LANDING_CONSUMER_BENEFIT_3_TITLE: "landing-consumer-benefit-3-title",
  LANDING_CONSUMER_BENEFIT_3_TEXT: "landing-consumer-benefit-3-text",
  LANDING_CONSUMER_BENEFIT_4_TITLE: "landing-consumer-benefit-4-title",
  LANDING_CONSUMER_BENEFIT_4_TEXT: "landing-consumer-benefit-4-text",
  LANDING_CONSUMER_FOOTER: "landing-consumer-footer",
  LANDING_CONSUMER_FOOTER_BUTTON: "landing-consumer-footer-button",
  LANDING_PRODUCER_INTRO: "landing-producer-intro",
  LANDING_PRODUCER_ANLAGEN_CHECK_CAPTION:
    "landing-producer-anlagen-check-caption",
  LANDING_PRODUCER_INFO_1_TITLE: "landing-producer-info-1-title",
  LANDING_PRODUCER_INFO_1_TEXT: "landing-producer-info-1-text",
  LANDING_PRODUCER_INFO_2_TITLE: "landing-producer-info-2-title",
  LANDING_PRODUCER_INFO_2_TEXT: "landing-producer-info-2-text",
  LANDING_PRODUCER_INFO_3_TITLE: "landing-producer-info-3-title",
  LANDING_PRODUCER_INFO_3_TEXT: "landing-producer-info-3-text",
  LANDING_PRODUCER_INFO_4_TITLE: "landing-producer-info-4-title",
  LANDING_PRODUCER_INFO_4_TEXT: "landing-producer-info-4-text",
  LANDING_PRODUCER_INFO_5_TITLE: "landing-producer-info-5-title",
  LANDING_PRODUCER_INFO_5_TEXT: "landing-producer-info-5-text",
  LANDING_PRODUCER_INFO_6_TITLE: "landing-producer-info-6-title",
  LANDING_PRODUCER_INFO_6_TEXT: "landing-producer-info-6-text",
  LANDING_PRODUCER_FOOTER: "landing-producer-footer",
  LOGIN_TITLE: "login-title",
  LOGIN_FORGOT_PASSWORD_TITLE: "login-forgot-password-title",
  LOGIN_FORGOT_PASSWORD_REQUESTED: "login-forgot-password-requested",
  LOGIN_SUBMIT_BUTTON: "login-submit-button",
  MARKETING_MATERIALS_MODAL_TITLE: "marketing-materials-modal-title",
  MARKETING_MATERIALS_TEXT_LONG: "marketing-materials-modal-text",
  MARKETING_MATERIALS_TEXT: "marketing-materials-text",
  MENU_ABOUT: "menu-about",
  MENU_ABOUT_LINK: "menu-about-link",
  MENU_FAQ: "menu-faq",
  MENU_FAQ_LINK: "menu-faq-link",
  MENU_STROM_KAUFEN: "menu-strom-kaufen",
  PLANT_MARGINS_TEXT: "plant-margins-text",
  POTENTIAL_CHECK_PLANT_TYPE_TITLE: "potential-check-plant-type-title",
  POTENTIAL_CHECK_PLANT_TYPE_MORE_PLANTS_TOOLTIP:
    "potential-check-plant-type-more-plants-tooltip",
  POTENTIAL_CHECK_DMSP_TITLE: "potential-check-dmsp-title",
  POTENTIAL_CHECK_DMSP_MORE_DMSPS_BUTTON:
    "potential-check-dmsp-more-dmsps-button",
  POTENTIAL_CHECK_DMSP_NO_DMSP_BUTTON: "potential-check-dmsp-no-dmsp-button",
  POTENTIAL_CHECK_DMSP_MORE_DMSPS_SUBHEADER:
    "potential-check-dmsp-more-dmsps-subheader",
  POTENTIAL_CHECK_DMSP_MODAL_LINK: "potential-check-dmsp-modal-link",
  POTENTIAL_CHECK_DMSP_MORE_DMSPS_MODAL:
    "potential-check-dmsp-more-dmsps-modal",
  POTENTIAL_CHECK_DMSP_NO_DMSP_MODAL: "potential-check-dmsp-no-dmsp-modal",
  POTENTIAL_CHECK_DMSP_NO_DMSP_MESSAGE: "potential-check-dmsp-no-dmsp-message",
  POTENTIAL_CHECK_MARGIN_TITLE: "potential-check-margin-title",
  POTENTIAL_CHECK_MARGIN_HOW_GET_TOOLTIP_TEXT:
    "potential-check-margin-how-get-tooltip-text",
  POTENTIAL_CHECK_MARGIN_WHAT_IS_BONUS_TOOLTIP_TEXT:
    "potential-check-margin-what-is-bonus-tooltip-text",
  POTENTIAL_CHECK_MARGIN_WHY_LIMIT_TOOLTIP_TEXT:
    "potential-check-margin-why-limit-tooltip-text",
  POTENTIAL_CHECK_MARGIN_WHY_TWENTY_TOOLTIP_TEXT:
    "potential-check-margin-why-twenty-tooltip-text",
  POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_YES_DMSP:
    "potential-check-summary-summary-text-yes-dmsp",
  POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_HAS_RLM:
    "potential-check-summary-summary-text-has-rlm",
  POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_NO_RLM:
    "potential-check-summary-summary-text-no-rlm",
  POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_NO_VALUE:
    "potential-check-summary-summary-text-no-value",
  POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_PARTNER_DMSP:
    "potential-check-summary-next-steps-partner-dmsp",
  POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_OTHER_DMSP:
    "potential-check-summary-next-steps-other-dmsp",
  POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_DMSP_NOT_LISTED:
    "potential-check-summary-next-steps-not-listed-dmsp",
  POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_HAS_RLM:
    "potential-check-summary-next-steps-has-rlm",
  POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_RLM:
    "potential-check-summary-next-steps-no-rlm",
  POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE:
    "potential-check-summary-next-steps-no-value",
  POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE_INFO:
    "potential-check-summary-next-steps-no-value-info",
  POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE_INFO_TITLE:
    "potential-check-summary-next-steps-no-value-info-title",
  POTENTIAL_CHECK_SUMMARY_CONFIRMATION_MODAL_TEXT:
    "potential-check-summary-confirmation-modal-text",
  PRIVACY_URL: "privacy-url",
  PRODUCER_CONTRACT_INTRO: "producer-contract-intro",
  PRODUCER_CONTRACT_INTRO_NEW_PLANT: "producer-contract-intro-new-plant",
  PRODUCER_CONTRACT_AGB: "producer-contract-agb-text",
  PRODUCER_CONTRACT_PRIVACY: "producer-contract-privacy-text",
  PRODUCER_CONTRACT_RIGHT_OF_CANCELLATION:
    "producer-contract-right-of-cancellation",
  PRODUCER_CONTRACT_LEGAL_INFORMATION: "producer-contract-legal-information",
  PRODUCER_CONTRACT_LEGAL_INFORMATION_CHECKBOX:
    "producer-contract-legal-information-checkbox",
  PRODUCER_CONTRACT_SEPA_TEXT: "producer-contract-sepa-text",
  PRODUCER_CONTRACT_SUCCESS: "producer-contract-success",
  PRODUCER_CONTRACT_SUCCESS_NO_DMSP: "producer-contract-success-no-dmsp",
  PRODUCER_CONTRACT_SUCCESS_PLANT_BUTTON:
    "producer-contract-success-plant-button",
  PRODUCER_CONTRACT_ERROR_ACCOUNT_NUMBER:
    "producer-contract-error-account-number",
  RESET_PASSWORD_TEXT: "reset-password-text",
  STAY_IN_CONTACT_SUCCESS: "stay-in-contact-success",
  STROMKENNZEICHNUNG_URL: "stromkennzeichnung-url",
  WHY_POWER_TITLE: "why-power-title",
  WIDERRUFSBELEHRUNG_TEXT: "widerrufsbelehrung-text",
  YOUR_MIX: "your-mix"
};
