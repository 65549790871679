import React, { Component } from "react";
import classnames from "classnames";

import "./TextWithIcon.scss";

class TextWithIcon extends Component {
  render() {
    const { children, title, img, leftToRight = true } = this.props;

    // altered from ../images/cells/cell-blue.svg
    const IconBorder = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 135.971 156.516"
        className="icon-border"
      >
        <path
          id="cells"
          className="cls-1"
          d="M486.632,354.28l-65.4,37.26L356.3,353.352l.464-75.293,65.552-37.26,64.934,38.187Z"
          transform="translate(-353.791 -237.912)"
        />
      </svg>
    );

    return (
      <div
        className={classnames("TextWithIcon", {
          "right-to-left": !leftToRight
        })}
      >
        <div className={classnames("icons", { "right-to-left": !leftToRight })}>
          <div className="icon-outer">
            <img src={img} alt={title} className="icon" />
            {IconBorder}
          </div>
          <svg className="check-symbol" />
        </div>
        <main>
          <header>
            <h3>{title}</h3>
          </header>
          {children}
        </main>
      </div>
    );
  }
}

export { TextWithIcon };
