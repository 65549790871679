import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap";

import { CollapsibleInformation } from "../../components/CollapsibleInformation";
import { StayInContact } from "../../components/StayInContact";
import { CURRENT_THEME, STRING_KEYS, THEMES } from "../../constants";
import { DMSP_NOT_LISTED, DMSP_PARTNERS } from "./PotentialCheckDmsp";
import { HAS_RLM_METER_YES, HAS_RLM_METER_NO } from "./fields/HasRLMMeter";
import "./PotentialCheckSummary.scss";
import { subscribePotentialCheckAction } from "../../actions";
import { getString, MarkdownString } from "../../utils";

const RC_ANNUAL_COSTS = 240;
const RC_INSTALLATION = 800;
const RLM_ANNUAL_COSTS = 390;
const RLM_INSTALLATION = 290;

function SummaryText({
  addedValue,
  plantDirectMarketer,
  dmspIsPartner,
  hasRLMMeter
}) {
  let text = null;
  let alertColor = "primary";

  if (addedValue > 0) {
    if (dmspIsPartner || plantDirectMarketer) {
      text = (
        <p>
          {getString(STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_YES_DMSP)}
        </p>
      );
    } else {
      if (hasRLMMeter) {
        text = (
          <p>
            {getString(
              STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_HAS_RLM
            )}
          </p>
        );
      } else {
        text = (
          <p>
            {getString(STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_NO_RLM)}
          </p>
        );
      }
    }
  } else {
    text = (
      <p>
        {getString(STRING_KEYS.POTENTIAL_CHECK_SUMMARY_SUMMARY_TEXT_NO_VALUE)}
      </p>
    );
    alertColor = "danger";
  }

  return <Alert color={alertColor}>{text}</Alert>;
}

function NextSteps({
  addedValue,
  plantDirectMarketer,
  dmspIsPartner,
  hasRLMMeter,
  onClickRegister,
  onClickStayInContact,
  stayInContactError
}) {
  let text = null;

  if (addedValue > 0) {
    if (dmspIsPartner) {
      text = (
        <p>
          {getString(
            STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_PARTNER_DMSP
          )}
        </p>
      );
    } else if (plantDirectMarketer) {
      if (
        CURRENT_THEME === THEMES.SWL &&
        plantDirectMarketer === DMSP_NOT_LISTED
      ) {
        text = (
          <div>
            <MarkdownString
              stringKey={
                STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_DMSP_NOT_LISTED
              }
            />
          </div>
        );
      } else {
        text = (
          <div>
            <MarkdownString
              stringKey={
                STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_OTHER_DMSP
              }
              replacements={[["plantDirectMarketer", plantDirectMarketer]]}
            />
          </div>
        );
      }
    } else {
      if (hasRLMMeter) {
        text = (
          <div>
            <MarkdownString
              stringKey={STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_HAS_RLM}
            />
          </div>
        );
      } else {
        text = (
          <div>
            <MarkdownString
              stringKey={STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_RLM}
            />
          </div>
        );
      }
    }
  } else {
    const body = (
      <div>
        {getString(
          STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE_INFO
        )}
      </div>
    );

    text = (
      <Container>
        <Row>
          <Col>
            <p>
              {getString(
                STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE
              )}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <StayInContact
              redirectOnSuccess="/strom-verkaufen/"
              onClickStayInContact={onClickStayInContact}
              source="potential-check-negative"
              stayInContactError={stayInContactError}
            />
          </Col>
        </Row>
        <Row className="extra-info">
          <Col>
            <CollapsibleInformation
              title={getString(
                STRING_KEYS.POTENTIAL_CHECK_SUMMARY_NEXT_STEPS_NO_VALUE_INFO_TITLE
              )}
            >
              {body}
            </CollapsibleInformation>
          </Col>
        </Row>
      </Container>
    );
  }

  return <Container>{text}</Container>;
}

class PotentialCheckSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmation: false
    };
  }

  toggleConfirmation = () => {
    this.setState({
      showConfirmation: !this.state.showConfirmation
    });
  };

  handleClickNext = (addedValue) => {
    const {
      onGoToNextStep,
      plantDirectMarketer,
      plantMeteredInFifteenMinuteIntervals
    } = this.props;

    if (
      addedValue > 0 &&
      !plantDirectMarketer &&
      plantMeteredInFifteenMinuteIntervals
    ) {
      this.toggleConfirmation();
    } else {
      onGoToNextStep();
    }
  };

  handleClickStayInContact = (email, tag) => {
    return this.props.subscribePotentialCheck(email, tag);
  };

  calculateProfit(
    plantType,
    plantYearlyEnergy,
    plantDirectMarketer,
    plantMargin
  ) {
    let profit = this.props.calculateYearlyEstimate(
      plantMargin,
      plantYearlyEnergy
    );

    if (!plantDirectMarketer) {
      switch (plantType) {
        case "PV Dachanlage":
        case "PV Park":
        case "Windkraftanlage":
        case "Windpark":
          profit += plantYearlyEnergy * (0.26 / 100);
          break;
        case "Biogasanlage":
        case "Biomethananlage":
        case "Biomassekraftwerk":
        case "Wasserkraftanlage":
          profit += plantYearlyEnergy * (0.14 / 100);
          break;
        default:
          break;
      }
    }

    return profit;
  }

  calculateCosts(plantDirectMarketer, hasRLMMeter) {
    let costs = 0;

    if (!plantDirectMarketer) {
      let oneTimeCosts = RC_INSTALLATION;
      costs += RC_ANNUAL_COSTS;

      if (hasRLMMeter === false) {
        oneTimeCosts += RLM_INSTALLATION;
        costs += RLM_ANNUAL_COSTS;
      }

      costs += oneTimeCosts / 8;
    }

    return costs;
  }

  render() {
    const {
      onGoToNextStep,
      plantType,
      plantInstalledCapacity,
      plantYearlyEnergy,
      plantDirectMarketer,
      plantMeteredInFifteenMinuteIntervals,
      plantMargin,
      stayInContactError
    } = this.props;

    const { showConfirmation } = this.state;

    const hasRLMMeterBoolean = plantMeteredInFifteenMinuteIntervals === "true";
    const profit = this.calculateProfit(
      plantType,
      plantYearlyEnergy,
      plantDirectMarketer,
      plantMargin
    );
    const costs = this.calculateCosts(plantDirectMarketer, hasRLMMeterBoolean);
    const addedValue = profit - costs;
    const dmspIsPartner =
      DMSP_PARTNERS.filter((partner) => partner.name === plantDirectMarketer)
        .length > 0;

    return (
      <div className="PotentialCheckSummary">
        <header>
          <h3>Zusammenfassung</h3>
        </header>
        <Container>
          <Row className="summary-data">
            <table>
              <tbody>
                <tr>
                  <td className="user-data-label">Anlagen-Typ</td>
                  <td className="user-data">{plantType}</td>
                </tr>
                <tr>
                  <td className="user-data-label">Leistung</td>
                  <td className="user-data">
                    {parseInt(plantInstalledCapacity, 10).toLocaleString(
                      "de-DE"
                    )}{" "}
                    kW
                  </td>
                </tr>
                <tr>
                  <td className="user-data-label">
                    (eingespeiste) Energiemenge pro Jahr
                  </td>
                  <td className="user-data">
                    {parseInt(plantYearlyEnergy, 10).toLocaleString("de-DE")}{" "}
                    kWh
                  </td>
                </tr>
                <tr>
                  <td className="user-data-label">Direktvermarkter</td>
                  <td className="user-data">
                    {plantDirectMarketer
                      ? plantDirectMarketer
                      : "Ich habe keinen Direktvermarkter"}
                  </td>
                </tr>
                {!plantDirectMarketer && (
                  <tr>
                    <td className="user-data-label">
                      Findet eine Lastgangmessung (RLM) statt?
                    </td>
                    <td className="user-data">
                      {hasRLMMeterBoolean
                        ? HAS_RLM_METER_YES
                        : HAS_RLM_METER_NO}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="user-data-label">Investitionsbonus</td>
                  <td className="user-data">
                    {plantMargin.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}{" "}
                    ct.
                  </td>
                </tr>
                <tr>
                  <td className="user-data-label">Geschätzter Mehrerlös</td>
                  <td className="user-data">
                    <span className="yearly-estimate">
                      {profit.toLocaleString("de-DE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                      {"€ / Jahr"}*
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="smaller">
                    * bei angenommenen 80% Stromverkauf an Endkunden
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
          <Row className="costs">
            <table>
              <tbody>
                <tr>
                  <td className="user-data-label">Mehrerlöse</td>
                  <td className="user-data">
                    {profit.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                    €
                  </td>
                </tr>
                <tr>
                  <td className="user-data-label">Mehrkosten</td>
                  <td className="user-data expenses">
                    {costs.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                    €
                  </td>
                </tr>
                <tr className="total">
                  <td className="user-data-label">Mehrwert</td>
                  <td className="user-data">
                    {addedValue.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                    €
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
          <Row className="summary-text">
            <SummaryText
              addedValue={addedValue}
              plantDirectMarketer={plantDirectMarketer}
              dmspIsPartner={dmspIsPartner}
              hasRLMMeter={hasRLMMeterBoolean}
            />
          </Row>
          <Row className="next-steps">
            <NextSteps
              addedValue={addedValue}
              plantDirectMarketer={plantDirectMarketer}
              dmspIsPartner={dmspIsPartner}
              hasRLMMeter={hasRLMMeterBoolean}
              onClickRegister={onGoToNextStep}
              onClickStayInContact={this.handleClickStayInContact}
              stayInContactError={stayInContactError}
            />
          </Row>
        </Container>
        {addedValue > 0 && (
          <footer>
            <Button
              color="primary"
              className="weiter"
              onClick={() => this.handleClickNext(addedValue)}
            >
              Zur Anmeldung
            </Button>
            <Modal isOpen={showConfirmation} toggle={this.toggleConfirmation}>
              <ModalBody>
                <p>
                  {getString(
                    STRING_KEYS.POTENTIAL_CHECK_SUMMARY_CONFIRMATION_MODAL_TEXT
                  )}
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => {
                    this.toggleConfirmation();
                    onGoToNextStep();
                  }}
                >
                  Einverstanden
                </Button>
              </ModalFooter>
            </Modal>
          </footer>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    plantType: state.form.potentialCheck.values.plantType,
    plantInstalledCapacity:
      state.form.potentialCheck.values.plantInstalledCapacity,
    plantYearlyEnergy: state.form.potentialCheck.values.plantYearlyEnergy,
    plantDirectMarketer: state.form.potentialCheck.values.plantDirectMarketer,
    plantMeteredInFifteenMinuteIntervals:
      state.form.potentialCheck.values.plantMeteredInFifteenMinuteIntervals,
    plantMargin: state.form.potentialCheck.values.plantMargin,
    stayInContactError: state.responses.subscribePotentialCheckError
  };
}

function mapDispatchToProps(dispatch) {
  return {
    subscribePotentialCheck: (email, tag) =>
      dispatch(subscribePotentialCheckAction(email, tag))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PotentialCheckSummary);
