import React, { Component, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import "./Blog.scss";

import backgroundImage from "../images/photos/consumer-landing-background.jpg";
import backgroundImage2 from "../images/photos/consumer-landing-background-2.jpg";
import backgroundImage3 from "../images/photos/consumer-landing-background-3.jpg";
import backgroundImage6 from "../images/photos/consumer-landing-background-6.jpg";

class Blog extends Component {
  constructor(props) {
    super(props);

    this.blogPostsRef = React.createRef();

    this.state = {
      posts: [],
      categories: []
    };
  }

  getBlogEntries = async () => {
    try {
      await fetch("https://blog.cells.energy/wp-json/wp/v2/posts")
        .then((response) => response.json())
        .then((posts) =>
          this.setState({
            posts: posts
          })
        );
    } catch (error) {
      // this is a problem with Wordpress and we will anyway fail silently, so it's ok
    }
  };

  getBlogCategories = async () => {
    try {
      await fetch("https://blog.cells.energy/wp-json/wp/v2/categories")
        .then((response) => response.json())
        .then((categories) =>
          this.setState({
            categories: categories
          })
        );
    } catch (error) {
      // this is a problem with Wordpress and we will anyway fail silently, so it's ok
    }
  };

  componentDidMount() {
    this.getBlogEntries();
    this.getBlogCategories();
  }

  render() {
    const { posts, categories } = this.state;

    return (
      <div className="Blog">
        <h2>
          Neues von Deinem{" "}
          <span style={{ whiteSpace: "nowrap" }}>Bürgerstrom-Marktplatz!</span>
        </h2>
        <div className="blog-posts-with-controls">
          <ScrollControl
            direction="left"
            scrollContainer={this.blogPostsRef.current}
            show={posts.length > 0}
          />
          <div className="blog-posts" ref={this.blogPostsRef}>
            {posts.map((post, index) => (
              <Entry
                key={post.slug + index}
                post={post}
                categories={categories}
                fallbackImage={getPlaceholderImage(index)}
              />
            ))}
          </div>
          <ScrollControl
            direction="right"
            scrollContainer={this.blogPostsRef.current}
            show={posts.length > 0}
          />
        </div>
      </div>
    );
  }
}

function Entry({ post, categories, fallbackImage }) {
  const dateString = moment(post.date).format("DD.MM.YYYY");
  const categoryStrings = post.categories.reduce((list, categoryId) => {
    const category = categories.find((category) => category.id === categoryId);
    if (category) {
      list.push(category.name);
    }
    return list;
  }, []);
  const categoriesString = categoryStrings.join(", ");

  return (
    <a href={post.link} className="blog-post">
      <Image
        imageId={post.featured_media}
        alt={post.title.rendered}
        fallbackImage={fallbackImage}
      />
      <div className="post-text">
        <span className="post-date-categories">
          {dateString} | {categoriesString}
        </span>
        <span className="post-title">{post.title.rendered}</span>
        <Button color="primary" className="read-post-button">
          zum Artikel
        </Button>
      </div>
    </a>
  );
}

function ScrollControl({ direction, show, scrollContainer }) {
  const [timer, setTimer] = useState(null);

  function scroll() {
    const scrollAmount = 660;
    scrollContainer.scrollBy(
      direction === "left" ? -1 * scrollAmount : scrollAmount,
      0
    );
  }

  function onMouseOver() {
    scroll();
    setTimer(
      setInterval(function () {
        scroll();
      }, 1000)
    );
  }

  function onMouseOut() {
    clearInterval(timer);
    setTimer(null);
  }

  if (show) {
    return (
      <div
        className={`scroll-control ${direction}`}
        onClick={scroll}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <FontAwesomeIcon
          icon={direction === "left" ? "chevron-left" : "chevron-right"}
        />
      </div>
    );
  } else {
    return null;
  }
}

function Image({ imageId, alt, fallbackImage }) {
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    async function loadImage() {
      try {
        await fetch(`https://blog.cells.energy/wp-json/wp/v2/media/${imageId}`)
          .then((response) => response.json())
          .then((image) => setImageData(image));
      } catch (error) {
        // this is a problem with Wordpress and we will anyway fail silently, so it's ok
      }
    }

    if (imageId > 0) {
      loadImage();
    }
  }, []);

  if (imageData) {
    return (
      <div className="image-zoom-wrapper">
        <img
          src={imageData.source_url}
          alt={imageData.alt_text || alt}
          className="blog-entry-image"
        />
      </div>
    );
  } else {
    return (
      <div className="image-zoom-wrapper">
        <img src={fallbackImage} alt={alt} className="blog-entry-image" />
      </div>
    );
  }
}

function getPlaceholderImage(index) {
  const imageToUse = index % 4;
  switch (imageToUse) {
    case 0:
      return backgroundImage;
    case 1:
      return backgroundImage2;
    case 2:
      return backgroundImage3;
    case 3:
      return backgroundImage6;
    default:
      return backgroundImage;
  }
}

export { Blog };
