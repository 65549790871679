import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import contract from "./contract";
import mix from "./mix";
import powerPlants from "./powerPlants";
import gridOperators from "./gridOperators";
import {
  CONTACT_FORM_SEND,
  CONTACT_FORM_SEND_ERROR,
  CONTRACT_RESET,
  SUBSCRIBE_POTENTIAL_CHECK,
  SUBSCRIBE_POTENTIAL_CHECK_ERROR
} from "../actions";

export default combineReducers({
  form: formReducer.plugin({
    potentialCheck: (state, action) => {
      switch (action.type) {
        case CONTRACT_RESET:
          return undefined;
        default:
          return state;
      }
    }
  }),
  auth,
  contract,
  mix,
  powerPlants,
  gridOperators,
  responses: function (state = {}, action) {
    switch (action.type) {
      case CONTACT_FORM_SEND:
        return { ...state, contactFormSuccess: true, contactFormError: null };
      case CONTACT_FORM_SEND_ERROR:
        return {
          ...state,
          contactFormSuccess: false,
          contactFormError: action.payload
        };
      case SUBSCRIBE_POTENTIAL_CHECK:
        return {
          ...state,
          subscribePotentialCheck: action.payload.response,
          subscribePotentialCheckError: null
        };
      case SUBSCRIBE_POTENTIAL_CHECK_ERROR:
        return {
          ...state,
          subscribePotentialCheckError: action.payload.response
        };
      default:
        return state;
    }
  }
});
