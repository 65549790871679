import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from "react-router-dom";
import {
  Col,
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavLink,
  NavItem,
  Row
} from "reactstrap";
import * as Klaro from "klaro";
import ReactGA from "react-ga";
import PropTypes from "prop-types";

import "./customBootstrap.scss";
import "./App.scss";
import NotFound from "./pages/NotFound";
import FAQ from "./pages/FAQ";
import { Contact } from "./pages/Contact";
import Impressum from "./pages/Impressum";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { About } from "./pages/About";
import Agb from "./pages/Agb";
import ConfirmEmail from "./pages/ConfirmEmail";
import { PasswordReset } from "./pages/PasswordReset";
import { Stromkennzeichnung } from "./pages/Stromkennzeichnung";

import logo from "./images/logo/cells-energy-marktplatz-logo.png";
import swlLogo from "./images/swl/logo/logo.png";
import oberland17Logo from "./images/oberland17/logo/17er_logo.png";
import cellsEnergyRightOfCancellationPdf from "./docs/Widerrufsbelehrung_Marktplatznutzungsvertrag_und_Stromliefervertrag.pdf";
import swlPrivacyPdf from "./docs/swl/2021_04_14_Datenschutzerklärung_Strommarktplatz_node_SWL_clean.pdf";
import swlPrivacyPdf2 from "./docs/swl/2021_04_15 Datenschutzinformation_ Art. 13_14_ Strommarktplatz_node.pdf";
import oberlandPrivacyPdf from "./docs/oberland17/Widerrufsbelehrung_01_21.pdf";
import oberlandStromKennzeichnung from "./docs/oberland17/Stromkennzeichnung_01_22.pdf";

// producer
import LandingProducer from "./pages/producer/LandingProducer";
import PotentialCheck from "./pages/producer/PotentialCheck";
import ProducerContract from "./pages/producer/ProducerContract";
import MyPowerPlants from "./pages/producer/MyPowerPlants";

// consumer
import { LandingConsumer } from "./pages/consumer/LandingConsumer";
import ConsumerFlow from "./pages/consumer/ConsumerFlow.js";
import { CreateMix } from "./pages/consumer/CreateMix";
import { ConsumerContract } from "./pages/consumer/ConsumerContract";

// power plant
import PowerPlant from "./pages/PowerPlant";

// mixes
import { Mixes } from "./pages/mixes/Mixes";

import { CURRENT_THEME, STRING_KEYS, THEMES } from "./constants";
import { checkFeature, FEATURES } from "./features";
import NavAuth from "./components/NavAuth";
import { getString, ScrollToTop } from "./utils.js";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowDown,
  faArrowUp,
  faBolt,
  faChevronLeft,
  faChevronRight,
  faGripHorizontal,
  faInfo,
  faLink,
  faLongArrowAltLeft,
  faPencilAlt,
  faPlus,
  faMale,
  faFemale,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";
library.add(faArrowDown);
library.add(faArrowUp);
library.add(faBolt);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faFacebookF);
library.add(faFemale);
library.add(faGripHorizontal);
library.add(faInfo);
library.add(faInstagram);
library.add(faLink);
library.add(faLongArrowAltLeft);
library.add(faMale);
library.add(faPencilAlt);
library.add(faPlus);
library.add(faTimes);
library.add(faTwitter);

let gaInitialized = false;

// https://github.com/kiprotect/klaro/blob/HEAD/dist/config.js
const klaroConfig = {
  acceptAll: true,
  lang: "de",
  translations: {
    zz: {
      csrftoken: {
        title: "csrftoken"
      },
      "example-profile-shown": {
        title: "example-profile-shown"
      },
      "marketing-modal-shown": {
        title: "marketing-modal-shown"
      },
      googleAnalytics: {
        title: "Google Analytics"
      },
      purposes: {
        statistics: "Statistiken"
      }
    }
  },
  services: [
    {
      name: "csrftoken",
      purposes: ["functional"],
      description: 'Wird für die "Login"-Funktion benötigt.',
      required: true
    },
    {
      name: "example-profile-shown",
      purposes: ["functional"],
      description:
        "Wird benötigt um Stromverkäufern ein Beispiel-Profil anzuzeigen.",
      required: true
    },
    {
      name: "marketing-modal-shown",
      purposes: ["functional"],
      description:
        "Wird benötigt um Stromverkäufern Marketingmaterialien anzubieten.",
      required: true
    },
    {
      name: "googleAnalytics",
      purposes: ["statistics", "marketing"],
      callback: function (consent, service) {
        if (consent && !gaInitialized) {
          gaInitialized = true;
          ReactGA.initialize("UA-134421874-1");
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
      }
    }
  ],
  styling: {
    theme: ["light"]
  }
};

window.klaro = Klaro;
window.klaroConfig = klaroConfig;
Klaro.setup(klaroConfig);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavbarOpen: false
    };
  }

  toggleNavbar = () => {
    this.setState({
      isNavbarOpen: !this.state.isNavbarOpen
    });
  };

  render() {
    const showAbout = checkFeature(FEATURES.ABOUT_PAGE);
    const faqIsExternal = checkFeature(FEATURES.FAQ_IS_EXTERNAL);
    const showAgb = checkFeature(FEATURES.AGB_PAGE);
    const impressumIsInternal = checkFeature(FEATURES.IMPRESSUM_INTERNAL_PAGE);
    const privacyIsInternal = checkFeature(FEATURES.PRIVACY_INTERNAL_PAGE);
    const privacyPdf = CURRENT_THEME === THEMES.SWL ? swlPrivacyPdf : null;
    const stromkennzeichnungIsInternal = checkFeature(
      FEATURES.STROMKENNZEICHNUNG_INTERNAL_PAGE
    );

    return (
      <div className="App Site">
        <Router>
          <GAListenerWithRouter>
            <ScrollToTop>
              <div className="Site-content">
                <header className="site-header">
                  <Container>
                    <Navbar light expand="md">
                      <NavbarBrand tag={Link} to="/">
                        <Logo />
                      </NavbarBrand>
                      <NavbarToggler
                        onClick={this.toggleNavbar}
                        className="mr-2"
                      />
                      <Collapse isOpen={this.state.isNavbarOpen} navbar>
                        <Nav className="ml-auto" navbar>
                          <NavAuth />
                          {showAbout && (
                            <NavItem className="about-us-nav-item">
                              <AboutLink />
                            </NavItem>
                          )}
                          {faqIsExternal ? (
                            <NavItem className="faq-nav-item">
                              <ExternalFAQLink />
                            </NavItem>
                          ) : (
                            <NavItem className="faq-nav-item">
                              <NavLink tag={Link} to="/faq">
                                {getString(STRING_KEYS.MENU_FAQ)}
                              </NavLink>
                            </NavItem>
                          )}
                          <NavItem className="contact-nav-item">
                            <NavLink tag={Link} to="/kontakt">
                              Kontakt
                            </NavLink>
                          </NavItem>
                          <NavItem className="strom-kaufen-nav-item">
                            <NavLink tag={Link} to="/">
                              {getString(STRING_KEYS.MENU_STROM_KAUFEN)}
                            </NavLink>
                          </NavItem>
                          <NavItem className="strom-verkaufen-nav-item">
                            <NavLink tag={Link} to="/strom-verkaufen">
                              Strom verkaufen
                            </NavLink>
                          </NavItem>
                          <NavItem className="blog-nav-item">
                            <NavLink href="https://blog.cells.energy/">
                              Blog
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Collapse>
                    </Navbar>
                  </Container>
                </header>
                <Switch>
                  <Route exact path="/" component={LandingConsumer} />
                  {!faqIsExternal && (
                    <Route exact path="/faq" component={FAQ} />
                  )}
                  <Route exact path="/kontakt" component={Contact} />
                  {impressumIsInternal && (
                    <Route exact path="/impressum" component={Impressum} />
                  )}
                  {privacyIsInternal && (
                    <Route exact path="/privacy" component={PrivacyPolicy} />
                  )}
                  {showAgb && <Route exact path="/agb" component={Agb} />}
                  {showAbout && (
                    <Route exact path="/ueber-uns" component={About} />
                  )}
                  {stromkennzeichnungIsInternal && (
                    <Route
                      exact
                      path="/stromkennzeichnung"
                      component={Stromkennzeichnung}
                    />
                  )}
                  <Route
                    exact
                    path="/strom-verkaufen"
                    component={LandingProducer}
                  />
                  <Route
                    exact
                    path="/strom-verkaufen/potential-check"
                    component={PotentialCheck}
                  />
                  <Route
                    exact
                    path="/strom-verkaufen/registrieren"
                    component={ProducerContract}
                  />
                  <Route
                    exact
                    path="/strom-verkaufen/neue-anlage"
                    component={ProducerContract}
                  />
                  <Route
                    exact
                    path="/strom-verkaufen/meine-anlagen"
                    component={MyPowerPlants}
                  />
                  <Route
                    exact
                    path="/strom-verkaufen/meine-anlagen/:powerPlantId"
                    component={PowerPlant}
                  />
                  <Route
                    exact
                    path="/strom-verkaufen/meine-anlagen/:powerPlantId/:powerPlantSlug"
                    component={PowerPlant}
                  />
                  <Route exact path="/strom-kaufen" component={BuyPowerPage} />
                  <Route
                    exact
                    path="/strom-kaufen/fragen"
                    component={ConsumerFlow}
                  />
                  {CURRENT_THEME === THEMES.SWL ? (
                    <Route
                      exact
                      path="/strom-kaufen/fragen/wofür-brauchen-sie-strom"
                      component={ConsumerFlow}
                    />
                  ) : (
                    <Route
                      exact
                      path="/strom-kaufen/fragen/wofür-brauchst-du-strom"
                      component={ConsumerFlow}
                    />
                  )}
                  {CURRENT_THEME === THEMES.SWL ? (
                    <Route
                      exact
                      path="/strom-kaufen/fragen/wie-viel-strom-brauchen-sie"
                      component={ConsumerFlow}
                    />
                  ) : (
                    <Route
                      exact
                      path="/strom-kaufen/fragen/wie-viel-strom-brauchst-du"
                      component={ConsumerFlow}
                    />
                  )}
                  <Route
                    exact
                    path="/strom-kaufen/mein-mix"
                    component={CreateMix}
                  />
                  <Route
                    exact
                    path="/strom-kaufen/rlm-kunden/mein-mix"
                    component={CreateMix}
                  />
                  <Route
                    exact
                    path="/strom-kaufen/wechseln"
                    component={ConsumerContract}
                  />
                  <Route
                    exact
                    path="/strom-kaufen/wechseln/adresse"
                    component={ConsumerContract}
                  />
                  <Route
                    exact
                    path="/strom-kaufen/wechseln/anschluss"
                    component={ConsumerContract}
                  />
                  <Route
                    exact
                    path="/strom-kaufen/wechseln/zahlungsdaten"
                    component={ConsumerContract}
                  />
                  <Route
                    exact
                    path="/strom-kaufen/wechseln/bestätigung"
                    component={ConsumerContract}
                  />
                  <Route
                    exact
                    path="/strom-kaufen/anlagen/:powerPlantId"
                    component={PowerPlant}
                  />
                  <Route
                    exact
                    path="/strom-kaufen/anlagen/:powerPlantId/:powerPlantSlug"
                    component={PowerPlant}
                  />
                  <Route exact path="/mixes/:mixId" component={Mixes} />
                  <Route
                    exact
                    path="/email-bestätigung"
                    component={ConfirmEmail}
                  />
                  <Route
                    exact
                    path="/accounts/reset/:userId/:token/"
                    component={PasswordReset}
                  />
                  <Route component={NotFound} />
                </Switch>
              </div>
            </ScrollToTop>
          </GAListenerWithRouter>
        </Router>
        <footer className="footer">
          <Container>
            <Row>
              <Col xs="12">
                <hr />
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="social-media-links">
                <ul>
                  <li>
                    <a
                      href={getString(STRING_KEYS.FACEBOOK_URL)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                    </a>
                  </li>
                  {checkFeature(FEATURES.TWITTER) && (
                    <li>
                      <a
                        href="https://twitter.com/CellsEnergy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={["fab", "twitter"]} />
                      </a>
                    </li>
                  )}
                  {checkFeature(FEATURES.INSTAGRAM) && (
                    <li>
                      <a
                        href={getString(STRING_KEYS.INSTAGRAM_URL)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={["fab", "instagram"]} />
                      </a>
                    </li>
                  )}
                </ul>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="copyright-impressum">
                <div className="copyright">
                  &copy; {new Date().getFullYear()}{" "}
                  {getString(STRING_KEYS.COMPANY_LEGAL_NAME)}
                </div>
                <div className="impressum">
                  <ImpressumLink internal={impressumIsInternal} /> |{" "}
                  <PrivacyLink internal={privacyIsInternal} file={privacyPdf} />{" "}
                  {showAgb && (
                    <React.Fragment>
                      | <a href="/agb">AGB</a>{" "}
                    </React.Fragment>
                  )}
                  | <WiderrufsbelehrungLink /> |{" "}
                  <StromkennzeichnungLink
                    internal={stromkennzeichnungIsInternal}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}

function Logo({}) {
  switch (CURRENT_THEME) {
    case THEMES.CELLS_ENERGY:
      return <img src={logo} className="logo" alt="Cells Energy Marktplatz" />;
    case THEMES.SWL:
      return <img src={swlLogo} className="logo" alt="Stadtwerke Landsberg" />;
    case THEMES.OBERLAND17:
      return (
        <img src={oberland17Logo} className="logo" alt="17er Strommarktplatz" />
      );
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      return <img src={logo} className="logo" alt="Cells Energy Marktplatz" />;
  }
}

function AboutLink() {
  return (
    <>
      {CURRENT_THEME === THEMES.OBERLAND17 ? (
        <a
          href={getString(STRING_KEYS.MENU_ABOUT_LINK)}
          target="_blank"
          rel="noopener noreferrer"
          className="nav-link"
        >
          {getString(STRING_KEYS.MENU_ABOUT)}
        </a>
      ) : (
        <NavLink tag={Link} to="/ueber-uns">
          {getString(STRING_KEYS.MENU_ABOUT)}
        </NavLink>
      )}
    </>
  );
}

function ExternalFAQLink() {
  return (
    <a
      href={getString(STRING_KEYS.MENU_FAQ_LINK)}
      target="_blank"
      rel="noopener noreferrer"
      className="nav-link"
    >
      {getString(STRING_KEYS.MENU_FAQ)}
    </a>
  );
}

function ImpressumLink({ internal, text = "Impressum", className }) {
  if (internal) {
    return (
      <a href="/impressum" className={className}>
        {text}
      </a>
    );
  } else {
    return (
      <a
        href={getString(STRING_KEYS.IMPRESSUM_URL)}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {text}
      </a>
    );
  }
}

function PrivacyLink({ internal, file }) {
  const text = "Datenschutz";

  if (internal) {
    return <a href="/privacy">{text}</a>;
  } else if (file) {
    return (
      <a href={file} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  } else {
    return (
      <a
        href={getString(STRING_KEYS.PRIVACY_URL)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    );
  }
}

function WiderrufsbelehrungLink() {
  const widerrufsbelehrungText = getString(STRING_KEYS.WIDERRUFSBELEHRUNG_TEXT);
  let rightOfCancellationPdf;

  switch (CURRENT_THEME) {
    case THEMES.CELLS_ENERGY:
      rightOfCancellationPdf = cellsEnergyRightOfCancellationPdf;
      break;
    case THEMES.SWL:
      rightOfCancellationPdf = swlPrivacyPdf2;
      break;
    case THEMES.OBERLAND17:
      rightOfCancellationPdf = oberlandPrivacyPdf;
      break;
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      rightOfCancellationPdf = cellsEnergyRightOfCancellationPdf;
      break;
  }

  return (
    <a href={rightOfCancellationPdf} target="_blank">
      {widerrufsbelehrungText}
    </a>
  );
}

function StromkennzeichnungLink({ internal }) {
  const text = "Stromkennzeichnung";

  if (internal) {
    return <a href="/stromkennzeichnung">{text}</a>;
  } else if (CURRENT_THEME === THEMES.OBERLAND17) {
    return (
      <a
        href={oberlandStromKennzeichnung}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    );
  } else {
    return (
      <a
        href={getString(STRING_KEYS.STROMKENNZEICHNUNG_URL)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    );
  }
}

// see https://github.com/react-ga/react-ga/issues/122
// other suggestions are to use createHistory but browserRouter does not work with createHistory
class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    this.props.history.listen(this.sendPageView);
  }

  sendPageView(location) {
    const klaroManager = Klaro.getManager();

    if (klaroManager.getConsent("googleAnalytics")) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  }

  render() {
    return this.props.children;
  }
}

const GAListenerWithRouter = withRouter(GAListener);

class BuyPowerPage extends React.Component {
  render() {
    return <Redirect to="/" />;
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(App);
