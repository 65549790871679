import React from "react";
import { Col, Container, Row } from "reactstrap";

import { STRING_KEYS } from "../constants";
import { getString, MarkdownString } from "../utils";

import swlAboutImage from "../images/swl/images/about.png";

import "./About.scss";

function About({}) {
  return (
    <div className="About page" style={{ textAlign: "left" }}>
      <header>
        <Container>
          <Row>
            <h1>{getString(STRING_KEYS.ABOUT_US_TITLE)}</h1>
          </Row>
        </Container>
      </header>
      <main>
        <Container>
          <Row>
            <Col>
              <MarkdownString
                stringKey={STRING_KEYS.ABOUT_US}
                replacements={[["aboutImage", swlAboutImage]]}
              />
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}

export { About };
