import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Row
} from "reactstrap";
import {
  Field,
  formValueSelector,
  getFormSubmitErrors,
  reduxForm,
  SubmissionError
} from "redux-form";

import { PlantType, PLANT_TYPE_NOT_SELECT } from "./fields/PlantType.js";
import { Capacity } from "./fields/Capacity.js";
import { CellCheckbox } from "../../components/CellCheckbox.js";
import { checkFeature, FEATURES } from "../../features.js";
import { EnergyAmountPerYear } from "./fields/EnergyAmountPerYear.js";
import { HasRLMMeter } from "./fields/HasRLMMeter.js";
import {
  EMPTY_DROPDOWN,
  DMSP_LIST,
  DMSP_PARTNERS
} from "./PotentialCheckDmsp.js";
import { Margin } from "./fields/Margin.js";
import { InfoIcon } from "../../components/InfoIcon";
import {
  EMAIL_MAX_LENGTH,
  BANK_FIRST_NAME_MAX_LENGTH,
  BANK_SURNAME_MAX_LENGTH,
  STREET_MAX_LENGTH,
  HOUSE_NUMBER_MAX_LENGTH,
  POSTCODE_MAX_LENGTH,
  CITY_MAX_LENGTH,
  TELEPHONE_NUMBER_MAX_LENGTH,
  IBAN_MAX_LENGTH,
  BIC_MAX_LENGTH,
  STRING_KEYS
} from "../../constants.js";
import { getString, MarkdownString } from "../../utils";

import "./ProducerContract.css";
import agbPdf from "../../docs/AGB_Marktplatznutzung.pdf";
import rightOfCancellationPdf from "../../docs/Widerrufsbelehrung_Marktplatznutzungsvertrag_und_Stromliefervertrag.pdf";
import {
  registrationAction,
  createContractAction,
  resetContractFormAction,
  getGridOperatorsAction
} from "../../actions";

const PLZ_REG_EX = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;
// eslint-disable-next-line
const EMAIL_REG_EX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const DMSP_LIST_FULL = DMSP_PARTNERS.map((partner) => partner.name)
  .concat(DMSP_LIST)
  .concat("Ich habe noch keinen Direktvermarkter");

class ProducerContract extends Component {
  constructor(props) {
    super(props);

    this.props.getGridOperators();

    if (!this.props.plantMargin) {
      this.props.change("plantMargin", "0.00");
    }

    this.state = {
      redirectToProfilePage: false,
      bankDataDifferentThanContractData: false,
      submitInProgress: false
    };
  }

  handleClickViewProfilePage = () => {
    this.setState({
      redirectToProfilePage: true
    });
  };

  handleSubmit = (data) => {
    const errors = this.validateAfterSubmit(data);
    if (Object.keys(errors).length > 0) {
      window.scrollTo(0, 0);
      throw new SubmissionError(errors);
    }

    this.setState({
      submitInProgress: true
    });

    const {
      email,
      password1,
      password2,
      firstName,
      lastName,
      ...remainingData
    } = data;

    const { ...formData } = remainingData;

    if (formData.plantGridOperator === EMPTY_DROPDOWN) {
      // don't sent plantGridOperator at all, if it wasn't set
      delete formData.plantGridOperator;
    } else {
      // if it was set, replace the name by the id (what the backend wants)
      formData.plantGridOperator = this.props.gridOperators.find(
        (operator) => operator.name === formData.plantGridOperator
      ).id;
    }

    // if the contractEmail wasn't specified, it was because the user didn't want to re-input the same email twice - copy the existing one
    if (!formData.contractEmail || formData.contractEmail === "") {
      formData.contractEmail = email || this.props.user.email;
    }

    // if the user has checked that they don't want to re-input their bank data,
    // we need to copy it out for the backend
    if (!this.state.bankDataDifferentThanContractData) {
      if (this.props.authenticated && this.props.user.firstName) {
        formData.bankFirstName = this.props.user.firstName;
      } else if (this.props.authenticated) {
        formData.bankFirstName = "missing-data"; // fallback for legacy data, created by now fixed bug
      } else {
        formData.bankFirstName = data.firstName;
      }

      if (this.props.authenticated && this.props.user.lastName) {
        formData.bankSurname = this.props.user.lastName;
      } else if (this.props.authenticated) {
        formData.bankSurname = "missing-data"; // fallback for legacy data, created by now fixed bug
      } else {
        formData.bankSurname = data.lastName;
      }

      formData.bankStreet = data.contractStreet;
      if (data.contractHouseNumber) {
        formData.bankHouseNumber = data.contractHouseNumber;
      }
      formData.bankPostcode = data.contractPostcode;
      formData.bankCity = data.contractCity;
    }

    if (!formData.supporter) {
      formData.supporter = false;
    }

    if (!formData.supporterBackwards) {
      formData.supporterBackwards = false;
    }

    if (!formData.legalInformation) {
      const showlegalInformationCheckbox = checkFeature(
        FEATURES.PRODUCER_CONTRACT_LEGAL_INFORMATION
      );

      // always set to true when the user doesn't have to click the checkbox (automatic / value can be ignored)
      formData.legalInformation = !showlegalInformationCheckbox;
    }

    if (this.props.authenticated) {
      return this.createContract(formData);
    } else {
      // create user first
      return this.props
        .onRegister({
          email: email,
          password1: password1,
          password2: password2,
          firstName: firstName,
          lastName: lastName
        })
        .then(() => {
          if (this.props.registrationErrorMessage) {
            window.scrollTo(0, 0);
            this.setState({
              submitInProgress: false
            });
            throw new SubmissionError(this.props.registrationErrorMessage);
          } else if (this.props.registered && this.props.authenticated) {
            return this.createContract(formData);
          } else {
            this.setState({
              submitInProgress: false
            });
            console.log("Unexpected registration error");
            // this shouldn't be possible...
          }
        });
    }
  };

  createContract(data) {
    return this.props.onCreateContract(data).then(() => {
      this.setState({
        submitInProgress: false
      });

      if (!this.props.contractCreated) {
        window.scrollTo(0, 0);
        throw new SubmissionError(this.props.contractCreationError);
      }
    });
  }

  validateAfterSubmit(values) {
    const errors = {};

    if (!this.props.authenticated) {
      if (!values.firstName) {
        errors.firstName = getString(STRING_KEYS.ERROR_FIRST_NAME_MISSING);
      }

      if (!values.lastName) {
        errors.lastName = getString(STRING_KEYS.ERROR_LAST_NAME_MISSING);
      }

      if (!values.email) {
        errors.email = getString(STRING_KEYS.ERROR_EMAIL_MISSING);
      } else if (!EMAIL_REG_EX.test(values.email)) {
        errors.email = getString(STRING_KEYS.ERROR_EMAIL_INVALID);
      }

      if (!values.password1) {
        errors.password1 = getString(STRING_KEYS.ERROR_PASSWORD_MISSING);
      } else if (!values.password2) {
        errors.password2 = getString(STRING_KEYS.ERROR_PASSWORD_MISSING);
      } else if (values.password1 !== values.password2) {
        errors.password2 = getString(STRING_KEYS.ERROR_PASSWORD_NO_MATCH);
      }
    }

    if (!values.contractCompanyOrOwner) {
      errors.contractCompanyOrOwner = getString(
        STRING_KEYS.ERROR_CONTRACT_OWNER_MISSING
      );
    }

    if (!values.contractStreet) {
      errors.contractStreet = getString(
        STRING_KEYS.ERROR_CONTRACT_STREET_MISSING
      );
    }

    if (!values.contractPostcode || !PLZ_REG_EX.test(values.contractPostcode)) {
      errors.contractPostcode = "Fehler.";
    }

    if (!values.contractCity) {
      errors.contractCity = getString(STRING_KEYS.ERROR_CONTRACT_CITY_MISSING);
    }

    if (!values.contractTelephoneNumber) {
      errors.contractTelephoneNumber = getString(
        STRING_KEYS.ERROR_CONTRACT_PHONE_MISSING
      );
    }

    if (values.contractEmail && !EMAIL_REG_EX.test(values.contractEmail)) {
      errors.contractEmail = getString(
        STRING_KEYS.ERROR_CONTRACT_EMAIL_MISSING
      );
    }

    if (!values.plantType || values.plantType === PLANT_TYPE_NOT_SELECT) {
      errors.plantType = getString(STRING_KEYS.ERROR_PLANT_TYPE_MISSING);
    }

    if (!values.plantInstalledCapacity) {
      errors.plantInstalledCapacity = getString(
        STRING_KEYS.ERROR_INSTALLED_CAPACITY_MISSING
      );
    }

    if (!values.plantYearlyEnergy) {
      errors.plantYearlyEnergy = getString(
        STRING_KEYS.ERROR_YEARLY_ENERGY_MISSING
      );
    }

    if (!values.plantStreet) {
      errors.plantStreet = getString(STRING_KEYS.ERROR_PLANT_STREET_MISSING);
    }

    if (!values.plantPostcode || !PLZ_REG_EX.test(values.plantPostcode)) {
      errors.plantPostcode = "Fehler.";
    }

    if (!values.plantCity) {
      errors.plantCity = getString(STRING_KEYS.ERROR_PLANT_CITY_MISSING);
    }

    if (
      !!values.plantMarketLocationId &&
      !this.verifyPlantMarketLocationId(values.plantMarketLocationId)
    ) {
      errors.plantMarketLocationId = getString(
        STRING_KEYS.ERROR_MARKET_LOCATION_ID_INVALID
      );
    }

    if (
      !values.plantDirectMarketer ||
      values.plantDirectMarketer === EMPTY_DROPDOWN
    ) {
      errors.plantDirectMarketer = getString(STRING_KEYS.ERROR_DMSP_MISSING);
    }

    if (values.plantMeteredInFifteenMinuteIntervals === EMPTY_DROPDOWN) {
      errors.plantMeteredInFifteenMinuteIntervals = getString(
        STRING_KEYS.ERROR_NO_OPTION_SELECT
      );
    }

    if (!values.plantMargin) {
      errors.plantMargin = getString(STRING_KEYS.ERROR_MARGIN_MISSING);
    }

    if (!values.bankIban) {
      errors.bankIban = getString(STRING_KEYS.ERROR_IBAN_MISSING);
    }

    if (!values.bankBic) {
      errors.bankBic = getString(STRING_KEYS.ERROR_BIC_MISSING);
    }

    if (this.state.bankDataDifferentThanContractData) {
      if (!values.bankFirstName) {
        errors.bankFirstName = getString(STRING_KEYS.ERROR_FIRST_NAME_MISSING);
      }

      if (!values.bankSurname) {
        errors.bankSurname = getString(STRING_KEYS.ERROR_LAST_NAME_MISSING);
      }

      if (!values.bankStreet) {
        errors.bankStreet = getString(STRING_KEYS.ERROR_BANK_STREET_MISSING);
      }

      if (!values.bankPostcode || !PLZ_REG_EX.test(values.contractPostcode)) {
        errors.bankPostcode = "Fehler.";
      }

      if (!values.bankCity) {
        errors.bankCity = getString(STRING_KEYS.ERROR_BANK_CITY_MISSING);
      }
    }

    if (!values.bankSepa) {
      errors.bankSepa = getString(STRING_KEYS.ERROR_AGREEMENT_MISSING);
    }

    if (!values.agb) {
      errors.agb = getString(STRING_KEYS.ERROR_AGREEMENT_MISSING);
    }

    if (!values.privacy) {
      errors.privacy = getString(STRING_KEYS.ERROR_AGREEMENT_MISSING);
    }

    if (!values.rightOfCancellation) {
      errors.rightOfCancellation = getString(
        STRING_KEYS.ERROR_AGREEMENT_MISSING
      );
    }

    const showlegalInformationCheckbox = checkFeature(
      FEATURES.PRODUCER_CONTRACT_LEGAL_INFORMATION
    );

    if (showlegalInformationCheckbox && !values.legalInformation) {
      errors.legalInformation = getString(STRING_KEYS.ERROR_AGREEMENT_MISSING);
    }

    return errors;
  }

  // valid example number: 41373559241
  verifyPlantMarketLocationId(id) {
    const idString = id.toString();

    if (idString.length !== 11) {
      return false;
    }

    const checkDigit = parseInt(idString[idString.length - 1], 10);

    // sum every number in an odd position, except the check digit
    let num1 = 0;
    for (let i = 0; i < idString.length - 1; i += 2) {
      num1 += parseInt(idString[i], 10);
    }

    // sum every number in an even position, except the check digit
    let num2 = 0;
    for (let i = 1; i < idString.length - 1; i += 2) {
      num2 += parseInt(idString[i], 10);
    }

    // multiply num2 by 2
    num2 = num2 * 2;

    // sum num1 & num 2
    const num3 = num1 + num2;

    // get next multiple of 10 after num3
    const nextMultiple = num3 + (10 - (num3 % 10));

    const difference = nextMultiple - num3;

    // the id is valid if the difference of nextMultiple and num3 equals the check digit or...
    // the check digit 0 is used if the difference is 0
    return difference === checkDigit || (difference === 10 && checkDigit === 0);
  }

  handleToggleSameBankData = (isDifferent) => {
    this.setState({
      bankDataDifferentThanContractData: isDifferent
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.onResetContract();
  }

  renderInputField(
    name,
    label,
    type,
    maxLength = null,
    error,
    extraClasses = "",
    tooltipText = null,
    placeholder = null
  ) {
    const infoIcon = tooltipText && (
      <InfoIcon tooltipName={name + "-tooltip"}>{tooltipText}</InfoIcon>
    );
    const max = type === "number" ? "9".repeat(maxLength) : null;

    return (
      <FormGroup>
        <Label for={name}>
          {label} {infoIcon}
        </Label>
        <Input
          type={type}
          tag={Field}
          name={name}
          id={name}
          maxLength={maxLength}
          max={max}
          component="input"
          invalid={!!error}
          className={extraClasses}
          placeholder={placeholder}
        />
        {error && <span className="error">{error}</span>}
      </FormGroup>
    );
  }

  render() {
    const {
      plantDirectMarketer,
      gridOperators,
      submitErrors,
      authenticated,
      contractCreated,
      match
    } = this.props;

    const {
      redirectToProfilePage,
      bankDataDifferentThanContractData,
      submitInProgress
    } = this.state;

    if (redirectToProfilePage) {
      return <Redirect to="/strom-verkaufen/meine-anlagen" push={true} />;
    }

    if (authenticated && contractCreated) {
      return (
        <div className="ProducerContract page">
          <header>
            <h1>Success!</h1>
          </header>
          <Container>
            <Row>
              <Col>
                <p>{getString(STRING_KEYS.PRODUCER_CONTRACT_SUCCESS)}</p>
              </Col>
            </Row>
            {!plantDirectMarketer ? (
              <Row>
                <Col>
                  <MarkdownString
                    stringKey={STRING_KEYS.PRODUCER_CONTRACT_SUCCESS_NO_DMSP}
                    openLinksInNewWindow
                  />
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col>
                <Button
                  color="primary"
                  className="weiter"
                  onClick={this.handleClickViewProfilePage}
                >
                  {getString(
                    STRING_KEYS.PRODUCER_CONTRACT_SUCCESS_PLANT_BUTTON
                  )}
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    const showRegistrationVersion = match.url.indexOf("registrieren") > 0;
    const showSupporterCheckboxes = checkFeature(FEATURES.PRODUCER_SUPPORTER);
    const showlegalInformationCheckbox = checkFeature(
      FEATURES.PRODUCER_CONTRACT_LEGAL_INFORMATION
    );

    return (
      <div className="ProducerContract page">
        <header>
          <h1>
            {showRegistrationVersion
              ? "Anmeldung im Marktplatz"
              : "Neue Anlage"}
          </h1>
        </header>
        <Container>
          <Row>
            <Col>
              {showRegistrationVersion ? (
                <MarkdownString
                  stringKey={STRING_KEYS.PRODUCER_CONTRACT_INTRO}
                />
              ) : (
                <p>
                  {getString(STRING_KEYS.PRODUCER_CONTRACT_INTRO_NEW_PLANT)}
                </p>
              )}
            </Col>
          </Row>
          {submitErrors && Object.keys(submitErrors).length > 0 && (
            <Row>
              <Col>
                <Alert color="danger">
                  {getString(STRING_KEYS.ERROR_INVALID_DATA)}
                  {submitErrors.detail && (
                    <MarkdownString
                      stringKey={STRING_KEYS.ERROR_REPORT_TEXT}
                      replacements={[["submitErrors", submitErrors.detail]]}
                    />
                  )}
                </Alert>
              </Col>
            </Row>
          )}
        </Container>
        <Form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
          <Container className="contract-fields">
            {!authenticated ? (
              <div>
                <Row>
                  <Col xs={12} lg={{ size: 6, offset: 2 }}>
                    <h3>Kontaktdaten Nutzer</h3>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={{ size: 4, offset: 2 }}>
                    {this.renderInputField(
                      "firstName",
                      "Vorname",
                      "text",
                      null,
                      submitErrors.firstName
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    {this.renderInputField(
                      "lastName",
                      "Nachname",
                      "text",
                      null,
                      submitErrors.lastName
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={{ size: 4, offset: 2 }}>
                    {this.renderInputField(
                      "email",
                      "E-Mail-Adresse",
                      "email",
                      EMAIL_MAX_LENGTH,
                      submitErrors.email
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={{ size: 4, offset: 2 }}>
                    {this.renderInputField(
                      "password1",
                      "Passwort",
                      "password",
                      null,
                      submitErrors.password1,
                      "",
                      "Das Passwort muss aus mindestens 8 Zeichen bestehen, wovon mindestens eines ein Buchstabe sein muss."
                    )}
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    {this.renderInputField(
                      "password2",
                      "Passwort wiederholen",
                      "password",
                      null,
                      submitErrors.password2
                    )}
                  </Col>
                </Row>
              </div>
            ) : null}

            <Row>
              <Col xs={12} lg={{ size: 6, offset: 2 }}>
                <h3>Vertragsdaten</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={{ size: 8, offset: 2 }}>
                {this.renderInputField(
                  "contractCompanyOrOwner",
                  "Name des Besitzers (ggf. Unternehmensname)",
                  "text",
                  50,
                  submitErrors.contractCompanyOrOwner,
                  "full-length"
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={8} md={10} lg={{ size: 5, offset: 2 }}>
                {this.renderInputField(
                  "contractStreet",
                  "Straße",
                  "text",
                  STREET_MAX_LENGTH,
                  submitErrors.contractStreet,
                  "full-length"
                )}
              </Col>
              <Col xs={4} md={2} lg={2}>
                {this.renderInputField(
                  "contractHouseNumber",
                  "Hausnr.",
                  "text",
                  HOUSE_NUMBER_MAX_LENGTH,
                  submitErrors.contractHouseNumber,
                  "short"
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={4} md={2} lg={{ size: 2, offset: 2 }}>
                {this.renderInputField(
                  "contractPostcode",
                  "PLZ",
                  "number",
                  POSTCODE_MAX_LENGTH,
                  submitErrors.contractPostcode,
                  "short nospinners"
                )}
              </Col>
              <Col xs={8} md={10} lg={5}>
                {this.renderInputField(
                  "contractCity",
                  "Ort",
                  "text",
                  CITY_MAX_LENGTH,
                  submitErrors.contractCity,
                  "full-length"
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={{ size: 4, offset: 2 }}>
                {this.renderInputField(
                  "contractTelephoneNumber",
                  "Telefonnummer",
                  "text",
                  TELEPHONE_NUMBER_MAX_LENGTH,
                  submitErrors.contractTelephoneNumber
                )}
              </Col>
              <Col xs={12} md={6} lg={4}>
                {this.renderInputField(
                  "contractEmail",
                  "E-Mail-Adresse (falls abweichend)",
                  "email",
                  EMAIL_MAX_LENGTH,
                  submitErrors.contractEmail
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={{ size: 6, offset: 2 }}>
                <h3>Anlagendaten</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4} lg={{ size: 3, offset: 2 }}>
                <Label>Anlagentyp</Label>
                <PlantType errorMessage={submitErrors.plantType} />
              </Col>
              <Col xs={12} md={4} lg={3}>
                <Capacity
                  classes="full-length"
                  errorMessage={submitErrors.plantInstalledCapacity}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4} lg={{ size: 4, offset: 2 }}>
                <EnergyAmountPerYear
                  classes="full-length"
                  errorMessage={submitErrors.plantYearlyEnergy}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4} lg={{ size: 4, offset: 2 }}>
                {this.renderInputField(
                  "plantCommissioningAccordingToEeg",
                  "Inbetriebnahme laut EEG",
                  "text",
                  null,
                  submitErrors.plantCommissioningAccordingToEeg,
                  null,
                  null,
                  "TT.MM.JJJJ"
                )}
              </Col>
              <Col xs={12} md={4} lg={6}>
                {this.renderInputField(
                  "plantExpectedEndOfEegSubsidy",
                  "Vorraussichtliches Ende der EEG-Vergütung",
                  "text",
                  null,
                  submitErrors.plantExpectedEndOfEegSubsidy,
                  null,
                  null,
                  "TT.MM.JJJJ"
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={8} md={10} lg={{ size: 5, offset: 2 }}>
                {this.renderInputField(
                  "plantStreet",
                  "Straße",
                  "text",
                  STREET_MAX_LENGTH,
                  submitErrors.plantStreet,
                  "full-length"
                )}
              </Col>
              <Col xs={4} md={2} lg={2}>
                {this.renderInputField(
                  "plantHouseNumber",
                  "Hausnr.",
                  "text",
                  HOUSE_NUMBER_MAX_LENGTH,
                  submitErrors.plantHouseNumber,
                  "short"
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={4} md={2} lg={{ size: 2, offset: 2 }}>
                {this.renderInputField(
                  "plantPostcode",
                  "PLZ",
                  "number",
                  POSTCODE_MAX_LENGTH,
                  submitErrors.plantPostcode,
                  "short nospinners"
                )}
              </Col>
              <Col xs={8} md={10} lg={5}>
                {this.renderInputField(
                  "plantCity",
                  "Ort",
                  "text",
                  CITY_MAX_LENGTH,
                  submitErrors.plantCity,
                  "full-length"
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={{ size: 4, offset: 2 }}>
                {this.renderInputField(
                  "plantMarketLocationId",
                  "Marktlokation-ID",
                  "text",
                  50,
                  submitErrors.plantMarketLocationId,
                  null,
                  "Die Marktlokations-ID ist meist auf der Abrechnung des Verteilnetzbetreibers zu finden. Auskunft erteilt der Verteilnetzbetreiber oder der Direktvermarkter. Nur bei Angabe der Korrekten Marktlokation kann die Anlage im Marktplatz angemeldet werden, bleibt das Feld jetzt leer muss diese später nachgetragen werden."
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={{ size: 8, offset: 2 }}>
                <FormGroup>
                  <Label for="plantDirectMarketer">Direktvermarkter</Label>
                  <Input
                    type="select"
                    tag={Field}
                    id="plantDirectMarketer"
                    name="plantDirectMarketer"
                    className="full-length"
                    component="select"
                    invalid={!!submitErrors.plantDirectMarketer}
                  >
                    <option>{EMPTY_DROPDOWN}</option>
                    {DMSP_LIST_FULL.map((dmsp) => (
                      <option key={dmsp}>{dmsp}</option>
                    ))}
                  </Input>
                  {submitErrors.plantDirectMarketer && (
                    <span className="error">
                      {submitErrors.plantDirectMarketer}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={{ size: 4, offset: 2 }}>
                {this.renderInputField(
                  "plantContractAccountNumber",
                  "Vertragskonto-Nr. beim Direktvermarkter",
                  "text",
                  50,
                  submitErrors.plantContractAccountNumber,
                  "full-length",
                  getString(STRING_KEYS.PRODUCER_CONTRACT_ERROR_ACCOUNT_NUMBER)
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={{ size: 8, offset: 2 }}>
                <HasRLMMeter
                  emptyDropdown={EMPTY_DROPDOWN}
                  errorMessage={
                    submitErrors.plantMeteredInFifteenMinuteIntervals
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={{ size: 8, offset: 2 }}>
                <FormGroup>
                  <Label for="plantGridOperator">Anschlussnetzbetreiber</Label>
                  <Input
                    type="select"
                    tag={Field}
                    id="plantGridOperator"
                    name="plantGridOperator"
                    className="full-length"
                    component="select"
                    invalid={!!submitErrors.plantGridOperator}
                  >
                    <option>{EMPTY_DROPDOWN}</option>
                    {Array.isArray(gridOperators) &&
                      gridOperators.map((operator) => (
                        <option key={operator.id}>{operator.name}</option>
                      ))}
                  </Input>
                  {submitErrors.plantGridOperator && (
                    <span className="error">
                      {submitErrors.plantGridOperator}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={{ size: 4, offset: 2 }}>
                <FormGroup>
                  <Label>Investitionsbonus</Label>
                  <Margin />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={{ size: 6, offset: 2 }}>
                <h3>Bankverbindung</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={{ size: 8, offset: 2 }}>
                <CellCheckbox
                  id="sameBankData"
                  name="sameBankData"
                  formGroupClassName="bank-sepa"
                  inputClassName="same-bank-data"
                  checked={bankDataDifferentThanContractData}
                  text="Abweichende Rechnungsadresse"
                  onChange={this.handleToggleSameBankData}
                />
              </Col>
            </Row>
            {bankDataDifferentThanContractData && (
              <Row>
                <Container>
                  <Row>
                    <Col xs={12} md={6} lg={{ size: 4, offset: 2 }}>
                      {this.renderInputField(
                        "bankFirstName",
                        "Vorname",
                        "text",
                        BANK_FIRST_NAME_MAX_LENGTH,
                        submitErrors.bankFirstName
                      )}
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      {this.renderInputField(
                        "bankSurname",
                        "Nachname",
                        "text",
                        BANK_SURNAME_MAX_LENGTH,
                        submitErrors.bankSurname
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8} md={10} lg={{ size: 5, offset: 2 }}>
                      {this.renderInputField(
                        "bankStreet",
                        "Straße",
                        "text",
                        STREET_MAX_LENGTH,
                        submitErrors.bankStreet,
                        "full-length"
                      )}
                    </Col>
                    <Col xs={4} md={2} lg={2}>
                      {this.renderInputField(
                        "bankHouseNumber",
                        "Hausnr.",
                        "text",
                        HOUSE_NUMBER_MAX_LENGTH,
                        submitErrors.bankHouseNumber,
                        "short"
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} md={2} lg={{ size: 2, offset: 2 }}>
                      {this.renderInputField(
                        "bankPostcode",
                        "PLZ",
                        "number",
                        POSTCODE_MAX_LENGTH,
                        submitErrors.bankPostcode,
                        "short nospinners"
                      )}
                    </Col>
                    <Col xs={8} md={10} lg={5}>
                      {this.renderInputField(
                        "bankCity",
                        "Ort",
                        "text",
                        CITY_MAX_LENGTH,
                        submitErrors.bankCity,
                        "full-length"
                      )}
                    </Col>
                  </Row>
                </Container>
              </Row>
            )}
            <Row className="iban-bic">
              <Col xs={12} md={6} lg={{ size: 4, offset: 2 }}>
                {this.renderInputField(
                  "bankIban",
                  "IBAN",
                  "text",
                  IBAN_MAX_LENGTH,
                  submitErrors.bankIban
                )}
              </Col>
              <Col xs={12} md={6} lg={4}>
                {this.renderInputField(
                  "bankBic",
                  "BIC",
                  "text",
                  BIC_MAX_LENGTH,
                  submitErrors.bankBic
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={{ size: 8, offset: 2 }}>
                <CellCheckbox
                  id="bankSepa"
                  name="bankSepa"
                  formGroupClassName="bank-sepa"
                  text={getString(STRING_KEYS.PRODUCER_CONTRACT_SEPA_TEXT)}
                  errors={submitErrors.bankSepa}
                  usesReduxFormField
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={{ size: 8, offset: 2 }}>
                <CellCheckbox
                  id="agb"
                  name="agb"
                  formGroupClassName="bank-sepa"
                  textComponent={
                    <MarkdownString
                      stringKey={STRING_KEYS.PRODUCER_CONTRACT_AGB}
                      replacements={[["agbPdf", agbPdf]]}
                      openLinksInNewWindow
                    />
                  }
                  errors={submitErrors.agb}
                  usesReduxFormField
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={{ size: 8, offset: 2 }}>
                <CellCheckbox
                  id="privacy"
                  name="privacy"
                  formGroupClassName="bank-sepa"
                  textComponent={
                    <MarkdownString
                      stringKey={STRING_KEYS.PRODUCER_CONTRACT_PRIVACY}
                      openLinksInNewWindow
                    />
                  }
                  errors={submitErrors.privacy}
                  usesReduxFormField
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={{ size: 8, offset: 2 }}>
                <CellCheckbox
                  id="rightOfCancellation"
                  name="rightOfCancellation"
                  formGroupClassName="bank-sepa"
                  textComponent={
                    <MarkdownString
                      stringKey={
                        STRING_KEYS.PRODUCER_CONTRACT_RIGHT_OF_CANCELLATION
                      }
                      replacements={[
                        ["rightOfCancellationPdf", rightOfCancellationPdf]
                      ]}
                      openLinksInNewWindow
                    />
                  }
                  errors={submitErrors.rightOfCancellation}
                  usesReduxFormField
                />
              </Col>
            </Row>
            {showSupporterCheckboxes && (
              <React.Fragment>
                <Row>
                  <Col xs={12} md={12} lg={{ size: 8, offset: 2 }}>
                    <CellCheckbox
                      id="supporter"
                      name="supporter"
                      formGroupClassName="bank-sepa"
                      textComponent={
                        <span>
                          Ich möchte den Marktplatz während der aktuellen
                          Aufbauphase unterstützen, indem ich die Monatsbeiträge
                          von 12,50 € netto schon jetzt entrichte, unabhängig
                          davon ob meine Anlage sofort freigeschaltet werden
                          kann (das ist wichtig damit uns keine langen
                          Überbrückungszeiträume entstehen!).
                        </span>
                      }
                      errors={submitErrors.supporter}
                      usesReduxFormField
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={{ size: 8, offset: 2 }}>
                    <CellCheckbox
                      id="supporterBackwards"
                      name="supporterBackwards"
                      formGroupClassName="bank-sepa"
                      textComponent={
                        <span>
                          Ich möchte zusätzlich Marktplatzpionier werden und
                          dazu rückwirkend ab März 2019 die Monatsbeiträge von
                          12,50 € bezahlen. Dadurch unterstütze ich den
                          Marktplatz besonders stark und die Monatsbeiträge bis
                          Ende 2019 werden auf mein Kundenkonto gut geschrieben
                          (Aktion Marktplatzpioniere).
                        </span>
                      }
                      errors={submitErrors.supporterBackwards}
                      usesReduxFormField
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {showlegalInformationCheckbox && (
              <Row>
                <Col xs={12} md={12} lg={{ size: 8, offset: 2 }}>
                  <MarkdownString
                    stringKey={STRING_KEYS.PRODUCER_CONTRACT_LEGAL_INFORMATION}
                  />
                  <CellCheckbox
                    id="legalInformation"
                    name="legalInformation"
                    formGroupClassName="bank-sepa"
                    textComponent={getString(
                      STRING_KEYS.PRODUCER_CONTRACT_LEGAL_INFORMATION_CHECKBOX
                    )}
                    errors={submitErrors.legalInformation}
                    usesReduxFormField
                  />
                </Col>
              </Row>
            )}
          </Container>
          <footer>
            <Button
              color="primary"
              className="weiter"
              type="submit"
              value="Submit"
              disabled={submitInProgress}
            >
              {getString(STRING_KEYS.CONSUMER_CONTRACT_SUBMIT)}
            </Button>
          </footer>
        </Form>
      </div>
    );
  }
}

let reduxFormProducerContract = reduxForm({
  form: "potentialCheck",
  destroyOnUnmount: false,
  initialValues: {
    supporter: checkFeature(FEATURES.PRODUCER_SUPPORTER)
  }
})(ProducerContract);

const selector = formValueSelector("potentialCheck");
reduxFormProducerContract = connect((state) => {
  const plantType = selector(state, "plantType");
  const plantInstalledCapacity = selector(state, "plantInstalledCapacity");
  const plantYearlyEnergy = selector(state, "plantYearlyEnergy");
  const plantCommissioningAccordingToEeg = selector(
    state,
    "plantCommissioningAccordingToEeg"
  );
  const plantExpectedEndOfEegSubsidy = selector(
    state,
    "plantExpectedEndOfEegSubsidy"
  );
  const plantDirectMarketer = selector(state, "plantDirectMarketer");
  const plantMeteredInFifteenMinuteIntervals = selector(
    state,
    "plantMeteredInFifteenMinuteIntervals"
  );
  const plantMargin = selector(state, "plantMargin");
  const firstName = selector(state, "firstName");
  const lastName = selector(state, "lastName");
  const email = selector(state, "email");
  const password1 = selector(state, "password1");
  const password2 = selector(state, "password2");
  const contractCompanyOrOwner = selector(state, "contractCompanyOrOwner");
  const contractStreet = selector(state, "contractStreet");
  const contractHouseNumber = selector(state, "contractHouseNumber");
  const contractPostcode = selector(state, "contractPostcode");
  const contractCity = selector(state, "contractCity");
  const contractTelephoneNumber = selector(state, "contractTelephoneNumber");
  const contractEmail = selector(state, "contractEmail");
  const plantStreet = selector(state, "plantStreet");
  const plantHouseNumber = selector(state, "plantHouseNumber");
  const plantPostcode = selector(state, "plantPostcode");
  const plantCity = selector(state, "plantCity");
  const plantMarketLocationId = selector(state, "plantMarketLocationId");
  const plantContractAccountNumber = selector(
    state,
    "plantContractAccountNumber"
  );
  const plantGridOperator = selector(state, "plantGridOperator");
  const bankIban = selector(state, "bankIban");
  const bankBic = selector(state, "bankBic");
  const bankFirstName = selector(state, "bankFirstName");
  const bankSurname = selector(state, "bankSurname");
  const bankStreet = selector(state, "bankStreet");
  const bankHouseNumber = selector(state, "bankHouseNumber");
  const bankPostcode = selector(state, "bankPostcode");
  const bankCity = selector(state, "bankCity");
  const bankSepa = selector(state, "bankSepa");
  const agb = selector(state, "agb");
  const privacy = selector(state, "privacy");
  const rightOfCancellation = selector(state, "rightOfCancellation");
  const supporter = selector(state, "supporter");
  const supporterBackwards = selector(state, "supporterBackwards");
  const legalInformation = selector(state, "legalInformation");

  return {
    plantType,
    plantInstalledCapacity,
    plantYearlyEnergy,
    plantCommissioningAccordingToEeg,
    plantExpectedEndOfEegSubsidy,
    plantDirectMarketer,
    plantMeteredInFifteenMinuteIntervals,
    plantGridOperator,
    plantMargin,
    firstName,
    lastName,
    email,
    password1,
    password2,
    contractCompanyOrOwner,
    contractStreet,
    contractHouseNumber,
    contractPostcode,
    contractCity,
    contractTelephoneNumber,
    contractEmail,
    plantStreet,
    plantHouseNumber,
    plantPostcode,
    plantCity,
    plantMarketLocationId,
    plantContractAccountNumber,
    bankIban,
    bankBic,
    bankFirstName,
    bankSurname,
    bankStreet,
    bankHouseNumber,
    bankPostcode,
    bankCity,
    bankSepa,
    agb,
    privacy,
    rightOfCancellation,
    supporter,
    supporterBackwards,
    legalInformation
  };
})(reduxFormProducerContract);

reduxFormProducerContract = connect((state) => ({
  submitErrors: getFormSubmitErrors("potentialCheck")(state)
}))(reduxFormProducerContract);

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    authErrorMessage: state.auth.error,
    user: state.auth.user,
    registered: state.auth.registered,
    registrationErrorMessage: state.auth.registrationError,
    contractCreated: state.contract.contractCreated,
    contractResponseData: state.contract.data,
    contractCreationError: state.contract.error,
    gridOperators: state.gridOperators
  };
}

const mapDispatchToProps = (dispatch) => ({
  onRegister: (values) => dispatch(registrationAction(values)),
  onCreateContract: (values) => dispatch(createContractAction(values)),
  onResetContract: () => dispatch(resetContractFormAction()),
  getGridOperators: () => dispatch(getGridOperatorsAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxFormProducerContract);
