import React, { Component, useCallback, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withScriptjs,
  withGoogleMap
} from "react-google-maps";
import { Button, Modal, ModalBody } from "reactstrap";

import { FEATURES, checkFeature } from "../../features";
import {
  getPowerPlantMainImage,
  LinkToProfilePageWithBack,
  powerPlantMapIconsByType,
  powerPlantMapSelectedIconsByType,
  getProducerDisplayName,
  getString
} from "../../utils.js";
import { GOOGLE_MAPS_API_KEY, MAP_OPTIONS } from "../../settings.js";
import { CURRENT_THEME, STRING_KEYS } from "../../constants";

import "./ChooseProducersFromMap.scss";

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openInfoWindowId: null,
      infoWindowContentNode: null
    };

    this.isInitialPanDone = false;
  }

  toggleInfoWindow = (producerId) => {
    this.setState({
      openInfoWindowId:
        this.state.openInfoWindowId !== producerId ? producerId : null
    });
  };

  closeInfoWindow = () => {
    this.setState({
      openInfoWindowId: null
    });
  };

  handleTilesLoaded = () => {
    if (!this.isInitialPanDone) {
      this.isInitialPanDone = true;

      // create a new bounds object, which we will pan to
      const bounds = new window.google.maps.LatLngBounds();

      // extend bounds with the producers and pan to show them
      for (let i = 0; i < this.props.producers.length; i++) {
        const panToPostcodeAndProducerIsInMix =
          this.props.panToPostcode &&
          this.props.producersInMix.includes(this.props.producers[i].id);

        // the producer should either be in the mix or we should be panning to ALL producers
        if (panToPostcodeAndProducerIsInMix || !this.props.panToPostcode) {
          let loc = new window.google.maps.LatLng(
            this.props.producers[i].plantLatitude,
            this.props.producers[i].plantLongitude
          );
          bounds.extend(loc);
        }
      }

      if (this.props.panToPostcode) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          { address: this.props.panToPostcode },
          function (results, status) {
            if (status === "OK") {
              const lat = results[0].geometry.location.lat();
              const lng = results[0].geometry.location.lng();
              const loc = new window.google.maps.LatLng(lat, lng);

              bounds.extend(loc);

              this.refs.map.fitBounds(bounds); // auto-zoom
              this.refs.map.panToBounds(bounds); // auto-center
            } else {
              console.log(
                "Geocode was not successful for the following reason:",
                status
              );
            }
          }.bind(this)
        );
      } else if (checkFeature(FEATURES.PAN_MAP_TO_PRODUCERS)) {
        this.refs.map.fitBounds(bounds); // auto-zoom
        this.refs.map.panToBounds(bounds); // auto-center
      }
    }
  };

  handleInfoWindowContentNodeChange = (node) => {
    this.setState({
      infoWindowContentNode: node
    });
  };

  render() {
    const {
      producers,
      producerDistances,
      producersInMix = [],
      mixIsFull,
      hideMixControls = false,
      onAddToMix
    } = this.props;

    const { openInfoWindowId, infoWindowContentNode } = this.state;

    const googleMapOptions = {
      streetViewControl: false
    };

    return (
      <React.Fragment>
        <GoogleMap
          ref="map"
          defaultZoom={MAP_OPTIONS[CURRENT_THEME].DEFAULT_ZOOM}
          defaultCenter={{
            lat: MAP_OPTIONS[CURRENT_THEME].DEFAULT_LATITUDE,
            lng: MAP_OPTIONS[CURRENT_THEME].DEFAULT_LONGITUDE
          }}
          options={googleMapOptions}
          onTilesLoaded={this.handleTilesLoaded}
          onClick={() => this.closeInfoWindow()}
        >
          {producers.map((producer) => {
            const inMix = producersInMix.includes(producer.id);
            return (
              <Marker
                key={producer.id}
                position={{
                  lat: producer.plantLatitude,
                  lng: producer.plantLongitude
                }}
                icon={
                  inMix
                    ? powerPlantMapSelectedIconsByType[producer.plantType]
                    : powerPlantMapIconsByType[producer.plantType]
                }
                onClick={() => this.toggleInfoWindow(producer.id)}
              >
                {openInfoWindowId === producer.id && (
                  <ProducerInfoWindow
                    producer={producer}
                    producerDistances={producerDistances}
                    hideMixControls={hideMixControls}
                    inMix={inMix}
                    mixIsFull={mixIsFull}
                    onAddToMix={onAddToMix}
                    toggleInfoWindow={this.toggleInfoWindow}
                    onInfoWindowContentNodeChange={
                      this.handleInfoWindowContentNodeChange
                    }
                  />
                )}
              </Marker>
            );
          })}
        </GoogleMap>
        {infoWindowContentNode &&
          openInfoWindowId &&
          ReactDOM.createPortal(
            <InfoWindowContent
              producer={producers.find(
                (producer) => producer.id === openInfoWindowId
              )}
              producerDistances={producerDistances}
            />,
            infoWindowContentNode
          )}
      </React.Fragment>
    );
  }
}

function ProducerInfoWindow({
  producer,
  hideMixControls,
  inMix,
  mixIsFull,
  onAddToMix,
  toggleInfoWindow,
  onInfoWindowContentNodeChange
}) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const infoWindowContentRef = useCallback((node) => {
    if (node != null) {
      onInfoWindowContentNodeChange(node);
    }
  }, []);

  const toggleConfirmModal = function () {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const handleClickConfirm = function () {
    onAddToMix(producer.id);
    toggleConfirmModal();
  };

  return (
    <InfoWindow onCloseClick={() => toggleInfoWindow(producer.id)}>
      <div className="info-window">
        <div ref={infoWindowContentRef} />
        {!hideMixControls && (
          <div className="controls">
            <Button
              color="success"
              disabled={inMix}
              onClick={
                mixIsFull ? toggleConfirmModal : () => onAddToMix(producer.id)
              }
            >
              {inMix ? "Ausgewählt" : "Zum Mix hinzufügen"}
            </Button>
          </div>
        )}
        <Modal
          isOpen={isConfirmModalOpen}
          toggle={toggleConfirmModal}
          centered={true}
        >
          <ModalBody style={{ textAlign: "center" }}>
            <p>{getString(STRING_KEYS.FULL_MIX)}</p>
            <Button onClick={handleClickConfirm} color="primary">
              Ja
            </Button>
            &nbsp;
            <Button onClick={toggleConfirmModal} color="secondary">
              Nein
            </Button>
          </ModalBody>
        </Modal>
      </div>
    </InfoWindow>
  );
}

function InfoWindowContent({ producer, producerDistances }) {
  return (
    <main>
      <div className="image">
        <LinkToProfilePageWithBack id={producer.id} slug={producer.slugName}>
          <img src={getPowerPlantMainImage(producer)} alt={producer.name} />
        </LinkToProfilePageWithBack>
      </div>
      <div className="info">
        <h6>
          <LinkToProfilePageWithBack id={producer.id} slug={producer.slugName}>
            {getProducerDisplayName(producer)}
          </LinkToProfilePageWithBack>
        </h6>
        <ul className="info">
          <li>
            <em>{producer.name}</em>
          </li>
          {producerDistances && (
            <li>
              {producerDistances[producer.id].toLocaleString("de-DE", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1
              })}{" "}
              km entfernt
            </li>
          )}
        </ul>
      </div>
    </main>
  );
}

const WithScriptjsWithGoogleMapMapComponent = withScriptjs(
  withGoogleMap(MapComponent)
);
const ChooseProducersFromMap = (props) => (
  <WithScriptjsWithGoogleMapMapComponent
    producers={props.producers}
    producerDistances={props.producerDistances}
    producersInMix={props.producersInMix}
    panToPostcode={props.panToPostcode}
    mixIsFull={props.mixIsFull}
    hideMixControls={props.hideMixControls}
    onAddToMix={props.onAddToMix}
    googleMapURL={
      "https://maps.googleapis.com/maps/api/js?key=" +
      GOOGLE_MAPS_API_KEY +
      "&v=3.exp&libraries=geometry,drawing"
    }
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div className="map-container" />}
    mapElement={
      <div style={{ height: `100%` }} className="ChooseProducersFromMap" />
    }
  />
);

ChooseProducersFromMap.propTypes = {
  producers: PropTypes.array
};

export { ChooseProducersFromMap };
