import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container } from "reactstrap";

import { CURRENT_THEME, THEMES } from "../../constants";
import { HowMuchPowerDoYouNeed } from "./HowMuchPowerDoYouNeed";
import { WhyDoYouNeedPower } from "./WhyDoYouNeedPower";

import "./ConsumerFlow.css";

const WHY_PAGE =
  CURRENT_THEME === THEMES.SWL
    ? "wofür-brauchen-sie-strom"
    : "wofür-brauchst-du-strom";
const HOW_MUCH_PAGE =
  CURRENT_THEME === THEMES.SWL
    ? "wie-viel-strom-brauchen-sie"
    : "wie-viel-strom-brauchst-du";

const PAGES = [WHY_PAGE, HOW_MUCH_PAGE];

export default class ConsumerFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo: null
    };
  }

  handleGoToNextStep = (currentPageIndex) => {
    const userIsOnLastPageOfFlow = currentPageIndex + 1 === PAGES.length;
    if (userIsOnLastPageOfFlow) {
      this.setState({
        redirectTo: "/strom-kaufen/mein-mix"
      });
    } else {
      this.handleGoToPage(currentPageIndex + 1);
    }
  };

  handleGoToPage = (pageIndex) => {
    const redirect =
      "/strom-kaufen/fragen/" +
      (pageIndex < PAGES.length ? PAGES[pageIndex] : null);
    this.setState({
      redirectTo: redirect
    });
  };

  handleGoBack = (currentPageIndex) => {
    const userIsNotOnFirstPage = currentPageIndex > 0;
    if (userIsNotOnFirstPage) {
      this.handleGoToPage(currentPageIndex - 1);
    } else {
      this.setState({
        redirectTo: "/strom-kaufen"
      });
    }
  };

  renderStep(page, currentPageIndex) {
    switch (page) {
      case WHY_PAGE:
        return (
          <WhyDoYouNeedPower
            onGoToNextStep={() => this.handleGoToNextStep(currentPageIndex)}
            onGoBack={() => this.handleGoBack(currentPageIndex)}
          />
        );
      case HOW_MUCH_PAGE:
        return (
          <HowMuchPowerDoYouNeed
            onGoToNextStep={() => this.handleGoToNextStep(currentPageIndex)}
            onGoBack={() => this.handleGoBack(currentPageIndex)}
          />
        );
      default:
        return <Redirect to="/strom-kaufen" push={true} />;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.redirectTo &&
      prevState.redirectTo !== this.state.redirectTo
    ) {
      this.setState({
        redirectTo: null
      });
    }
  }

  render() {
    const { match } = this.props;

    if (this.state.redirectTo && this.state.redirectTo !== match.path) {
      return <Redirect to={this.state.redirectTo} push={true} />;
    }

    let currentPageIndex = 0;
    while (
      currentPageIndex < PAGES.length &&
      match.path.indexOf(PAGES[currentPageIndex]) < 0
    ) {
      currentPageIndex++;
    }

    // no valid page found, so redirect to page 1
    if (currentPageIndex === PAGES.length) {
      return <Redirect to={"/strom-kaufen/fragen/" + PAGES[0]} />;
    }

    return (
      <Container className="ConsumerFlow page">
        {this.renderStep(PAGES[currentPageIndex], currentPageIndex)}
      </Container>
    );
  }
}
