import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import moment from "moment";

import { AddressForm } from "./forms/AddressForm";
import { ConnectionForm } from "./forms/ConnectionForm";
import { PaymentDetailsForm } from "./forms/PaymentDetailsForm";

import { BackButton } from "../../components/BackButton";
import { CellCheckbox } from "../../components/CellCheckbox";
import { Producer } from "./Producer";
import { ExtendedMixInfoModal } from "./ExtendedMixInfoModal";
import ProgressTracker from "../../components/ProgressTracker";

import { RlmExtraCostsInfoIcon } from "./ExtendedMixInfo";
import { createConsumerContractAction } from "../../actions";
import {
  CURRENT_THEME,
  BANK_PAYMENT_METHODS,
  ALREADY_CANCELLED_ANSWERS,
  CUSTOM_MIX_NAME,
  METERING_TYPES,
  THEMES,
  USER_TYPES,
  VAT_FACTORS,
  VAT_INFO_TEXTS,
  STRING_KEYS
} from "../../constants.js";
import { checkFeature, FEATURES } from "../../features";
import {
  Coverage,
  getMixPowerPlantsFromMixIdsAndProducerPowerPlants,
  getPowerPlantMainImage,
  getMixDisplayName,
  getString,
  Price,
  VatMarker,
  MarkdownString
} from "../../utils.js";

import "./ConsumerContract.scss";
import agbPdf from "../../docs/AGB_Stromlieferung_SLP.pdf";
import rlmAgbPdf from "../../docs/AGB_Stromlieferung_RLM.pdf";
import swlAgbPdf from "../../docs/swl/10_Stadtwerke-Landsberg-08-21-Strommarktplatz-AGB.pdf";
import oberland17AgbPdf from "../../docs/oberland17/AGB_Strom_01_22_BBH.pdf";
import swlPrivacyPdf from "../../docs/swl/2021_04_14_Datenschutzerklärung_Strommarktplatz_node_SWL_clean.pdf";
import oberland17PrivacyPdf from "../../docs/oberland17/DSGVO_01_22.pdf";
import rightOfCancellationPdf from "../../docs/Widerrufsbelehrung_Marktplatznutzungsvertrag_und_Stromliefervertrag.pdf";
import swlRightOfCancellationPdf from "../../docs/swl/Widerrufsbelehrung.pdf";
import oberland17RightOfCancellationPdf from "../../docs/oberland17/Widerrufsbelehrung_01_21.pdf";
import swlContractConditionsPdf from "../../docs/swl/Vertragsbedingungen.pdf";

const ADDRESS_PAGE = "adresse";
const CURRENT_PROVIDER_PAGE = "anschluss";
const PAYMENT_DETAILS_PAGE = "zahlungsdaten";
const CONFIRMATION_PAGE = "bestätigung";

const PAGES = [
  ADDRESS_PAGE,
  CURRENT_PROVIDER_PAGE,
  PAYMENT_DETAILS_PAGE,
  CONFIRMATION_PAGE
];

function getThemedAgbPdf(isRLMConsumer) {
  switch (CURRENT_THEME) {
    case THEMES.SWL:
      return swlAgbPdf;
    case THEMES.OBERLAND17:
      return oberland17AgbPdf;
    case THEMES.CELLS_ENERGY:
      return isRLMConsumer ? rlmAgbPdf : agbPdf;
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      return agbPdf;
  }
}

function getThemedRightOfCancellationPdf() {
  switch (CURRENT_THEME) {
    case THEMES.CELLS_ENERGY:
      return rightOfCancellationPdf;
    case THEMES.SWL:
      return swlRightOfCancellationPdf;
    case THEMES.OBERLAND17:
      return oberland17RightOfCancellationPdf;
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      return rightOfCancellationPdf;
  }
}

class ConsumerContract extends Component {
  constructor(props) {
    super(props);

    const requireContractConditions = checkFeature(
      FEATURES.CONSUMER_CONTRACT_VERTRAGSBEDINGUNGEN
    );

    this.state = {
      redirectTo: null,
      submitInProgress: false,
      contractConditionsAccepted: !requireContractConditions,
      agbAccepted: false,
      agbErrorText: null,
      privacyAccepted: false,
      rightOfCancellationAccepted: false,
      rightOfCancellationErrorText: null,
      sendEmailAccepted: false,
      callPhoneAccepted: false
    };
  }

  handleSubmit = () => {
    const {
      contractConditionsAccepted,
      agbAccepted,
      privacyAccepted,
      rightOfCancellationAccepted,
      sendEmailAccepted,
      callPhoneAccepted
    } = this.state;

    if (!contractConditionsAccepted) {
      this.setState({
        contractConditionsErrorText: getString(
          STRING_KEYS.ERROR_AGREEMENT_MISSING
        )
      });

      return;
    }

    if (!agbAccepted) {
      this.setState({
        agbErrorText: getString(STRING_KEYS.ERROR_AGREEMENT_MISSING)
      });

      return;
    }

    if (!rightOfCancellationAccepted) {
      this.setState({
        rightOfCancellationErrorText: getString(
          STRING_KEYS.ERROR_AGREEMENT_MISSING
        )
      });

      return;
    }

    if (!privacyAccepted) {
      this.setState({
        privacyErrorText: getString(STRING_KEYS.ERROR_AGREEMENT_MISSING)
      });

      return;
    }

    const {
      mixData,
      consumerType,
      consumerFlowAddressFormData,
      consumerFlowConnectionFormData,
      consumerFlowPaymentDetailsFormData,
      powerPlants
    } = this.props;

    this.setState({
      submitInProgress: true
    });

    // make copies of the data and fill in the blanks where needed

    const producerIds = [...mixData.mixes[mixData.selectedMixName].powerPlants];

    const { ...addressData } = consumerFlowAddressFormData;

    if (addressData.ownerBirthDate) {
      addressData.ownerBirthDate = moment(addressData.ownerBirthDate).format(
        "YYYY-MM-DD"
      );
    }

    const { ...connectionData } = consumerFlowConnectionFormData;

    if (
      connectionData.connectionAlreadyCancelled ===
      ALREADY_CANCELLED_ANSWERS.YES
    ) {
      connectionData.connectionAlreadyCancelled = true;
    } else {
      connectionData.connectionAlreadyCancelled = false;
    }

    if (connectionData.startDate) {
      connectionData.startDate = moment(connectionData.startDate).format(
        "YYYY-MM-DD"
      );
    }

    if (!connectionData.connectionStreet) {
      connectionData.connectionStreet = addressData.street;
      connectionData.connectionPostcode = addressData.postcode;
      connectionData.connectionCity = addressData.city;
      connectionData.connectionHouseNumber = addressData.houseNumber;
    }

    const { ...paymentDetailsData } = consumerFlowPaymentDetailsFormData;

    if (!paymentDetailsData.connectionStreet) {
      paymentDetailsData.bankFirstName = addressData.firstName;
      paymentDetailsData.bankSurname = addressData.lastName;
      paymentDetailsData.bankStreet = addressData.street;
      paymentDetailsData.bankPostcode = addressData.postcode;
      paymentDetailsData.bankCity = addressData.city;
      paymentDetailsData.bankHouseNumber = addressData.houseNumber;
    }

    if (
      paymentDetailsData.bankPaymentMethod === BANK_PAYMENT_METHODS.TRANSFER
    ) {
      // the user chose Überweisung, so don't send the IBAN/BIC info, in case they were filled out
      delete paymentDetailsData.bankIban;
      delete paymentDetailsData.bankBic;
      delete paymentDetailsData.bankSepa;
      delete paymentDetailsData.bankFirstName;
      delete paymentDetailsData.bankSurname;
      delete paymentDetailsData.bankStreet;
      delete paymentDetailsData.bankPostcode;
      delete paymentDetailsData.bankCity;
      delete paymentDetailsData.bankHouseNumber;
    }

    const data = {
      producerMix: this.createProducerMixArray(
        producerIds,
        mixData.customMargins,
        powerPlants
      ),
      yearlyEnergy: mixData.mixes[mixData.selectedMixName].yearlyEnergy,
      consumerType: consumerType,
      meteringType: mixData.meteringType,
      agb: agbAccepted,
      contractConditions: contractConditionsAccepted,
      privacy: privacyAccepted,
      rightOfCancellation: rightOfCancellationAccepted,
      sendEmail: sendEmailAccepted,
      callPhone: callPhoneAccepted,
      ...addressData,
      ...connectionData,
      ...paymentDetailsData,
      multiplier: mixData.multiplierId
    };

    if (mixData.meteringType === METERING_TYPES.rlm) {
      data.historicalLoadProfile = mixData.rlmUuid;
    }

    this.props.onCreateContract(data).then(() => {
      this.setState({
        submitInProgress: false
      });
    });
  };

  createProducerMixArray(producers, customMargins, powerPlants) {
    const producerMixArray = producers.map((producer) => {
      const producerPlant = powerPlants.find((plant) => plant.id === producer);

      if (producerPlant) {
        const originalMargin = producerPlant.plantMargin;
        return {
          producer: producer,
          additionalMargin:
            customMargins && customMargins[producer]
              ? customMargins[producer] - originalMargin
              : 0
        };
      }

      return null;
    });

    return producerMixArray.filter((producer) => !!producer);
  }

  handleGoToPage = (pageIndex) => {
    const redirect =
      "/strom-kaufen/wechseln/" +
      (pageIndex < PAGES.length ? PAGES[pageIndex] : null);
    this.setState({
      redirectTo: redirect
    });
  };

  handleClickBack = (currentPageIndex) => {
    if (currentPageIndex > 0) {
      this.handleGoToPage(currentPageIndex - 1);
    } else {
      this.setState({
        redirectTo:
          this.props.mixData.selectedMixName === CUSTOM_MIX_NAME
            ? "/strom-kaufen/mein-mix"
            : "/strom-kaufen/mein-mix"
      });
    }
  };

  handleContractConditionsChange = (checked) => {
    this.setState({
      contractConditionsAccepted: checked,
      contractConditionsErrorText: null
    });
  };

  handleAgbChange = (checked) => {
    this.setState({
      agbAccepted: checked,
      agbErrorText: null
    });
  };

  handlePrivacyChange = (checked) => {
    this.setState({
      privacyAccepted: checked,
      privacyErrorText: null
    });
  };

  handleRightOfCancellationChange = (checked) => {
    this.setState({
      rightOfCancellationAccepted: checked,
      rightOfCancellationErrorText: null
    });
  };

  handleSendEmailChange = (checked) => {
    this.setState({
      sendEmailAccepted: checked
    });
  };

  handleCallPhoneChange = (checked) => {
    this.setState({
      callPhoneAccepted: checked
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.redirectTo &&
      prevState.redirectTo !== this.state.redirectTo
    ) {
      this.setState({
        redirectTo: null
      });
    }
  }

  render() {
    const {
      mixData,
      powerPlants,
      producerPowerPlantDistances,
      consumerType,
      consumerFlowAddressFormData,
      consumerFlowConnectionFormData,
      consumerFlowPaymentDetailsFormData,
      contractCreationError,
      match
    } = this.props;

    if (this.state.redirectTo && this.state.redirectTo !== match.path) {
      return <Redirect to={this.state.redirectTo} push={true} />;
    }

    let currentPageIndex = 0;
    while (
      currentPageIndex < PAGES.length &&
      match.path.indexOf(PAGES[currentPageIndex]) < 0
    ) {
      currentPageIndex++;
    }

    // no valid page found, so redirect to page 1
    if (currentPageIndex === PAGES.length) {
      return <Redirect to={"/strom-kaufen/wechseln/" + PAGES[0]} />;
    }

    // check for missing data (i.e. maybe the user tried to skip here without viewing the other pages)
    if (
      !mixData ||
      !powerPlants ||
      !producerPowerPlantDistances ||
      !consumerType ||
      (PAGES[currentPageIndex] === CURRENT_PROVIDER_PAGE &&
        !consumerFlowAddressFormData) ||
      (PAGES[currentPageIndex] === PAYMENT_DETAILS_PAGE &&
        (!consumerFlowAddressFormData || !consumerFlowConnectionFormData)) ||
      (PAGES[currentPageIndex] === CONFIRMATION_PAGE &&
        (!consumerFlowAddressFormData ||
          !consumerFlowConnectionFormData ||
          !consumerFlowPaymentDetailsFormData))
    ) {
      return <Redirect to={"/strom-kaufen"} />;
    }

    const {
      submitInProgress,
      contractConditionsAccepted,
      contractConditionsErrorText,
      agbAccepted,
      agbErrorText,
      privacyAccepted,
      privacyErrorText,
      rightOfCancellationAccepted,
      rightOfCancellationErrorText,
      sendEmailAccepted,
      callPhoneAccepted
    } = this.state;

    const selectedMix = mixData.mixes[mixData.selectedMixName];
    const selectedMixPlants =
      selectedMix &&
      getMixPowerPlantsFromMixIdsAndProducerPowerPlants(
        selectedMix.powerPlants,
        powerPlants
      );
    const vatFactor = VAT_FACTORS[consumerType];
    const monthlyCosts = (vatFactor * selectedMix.yearlyCosts) / 12;
    const yearlyCost = 12 * monthlyCosts;
    const energyPrice = vatFactor * selectedMix.tariffStructure.energyPrice;
    const mixSurcharge = vatFactor * selectedMix.tariffStructure.fixedSurcharge;
    const monthlyMixSurcharge = mixSurcharge / 12;
    const baseTariff = vatFactor * selectedMix.tariffStructure.baseTariff;
    const mixPowerPrice = vatFactor * selectedMix.tariffStructure.powerPrice;
    const mixMaxPower = vatFactor * selectedMix.maxPower;
    const monthlyBaseTariff = baseTariff / 12;
    const monthlyRlmExtraCosts = (mixPowerPrice * mixMaxPower) / 12;
    const monthlyBaseTariffWithRlmExtraCosts =
      monthlyBaseTariff + monthlyRlmExtraCosts;
    const isRLMConsumer = mixData.meteringType === METERING_TYPES.rlm;
    const themedRightOfCancellationPdf = getThemedRightOfCancellationPdf();
    const showPrintButton = checkFeature(
      FEATURES.CONSUMER_CONTRACT_PRINT_BUTTON
    );

    if (mixData && mixData.contractCreated) {
      return (
        <div className="ConsumerContract page">
          <header>
            <h1>Herzlichen Glückwunsch!</h1>
          </header>
          <Container>
            <Row>
              <Col>
                <MarkdownString
                  stringKey={STRING_KEYS.CONSUMER_CONTRACT_SUCCESS_MESSAGE}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    const themedAgbPdf = getThemedAgbPdf(isRLMConsumer);
    const showMinContractDuration = checkFeature(
      FEATURES.MIN_CONTRACT_DURATION
    );
    const showVertragsbedingungen = checkFeature(
      FEATURES.CONSUMER_CONTRACT_VERTRAGSBEDINGUNGEN
    );
    const showEmailAndPhoneCheckboxes = checkFeature(
      FEATURES.CONSUMER_CONTRACT_SHARE_EMAIL_OR_PHONE
    );
    const currentYear = new Date().getFullYear();

    return (
      <div className="ConsumerContract page">
        <Container>
          <ProgressTracker
            currentStep={currentPageIndex + 1}
            steps={["Adresse", "Anschluss", "Zahlungsdaten", "Bestätigung"]}
            onGoToStep={(step) => this.handleGoToPage(step - 1)}
          />
          <div className="progress-tracker-spacing"></div>
        </Container>
        {PAGES[currentPageIndex] === ADDRESS_PAGE && (
          <AddressForm
            onSubmitForm={() => this.handleGoToPage(currentPageIndex + 1)}
          />
        )}
        {PAGES[currentPageIndex] === CURRENT_PROVIDER_PAGE && (
          <ConnectionForm
            onSubmitForm={() => this.handleGoToPage(currentPageIndex + 1)}
          />
        )}
        {PAGES[currentPageIndex] === PAYMENT_DETAILS_PAGE && (
          <PaymentDetailsForm
            onSubmitForm={() => this.handleGoToPage(currentPageIndex + 1)}
          />
        )}
        {PAGES[currentPageIndex] === CONFIRMATION_PAGE && (
          <Container className="contract-fields confirmation-page">
            <Row>
              <Col xs={12} lg={6}>
                <h3>Bestätigung</h3>
              </Col>
            </Row>
            <Row className="summary">
              <Col xs={12} lg={6}>
                <Container>
                  <Row>
                    <Col>
                      <h4>Adresse</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {consumerFlowAddressFormData.title}{" "}
                      {consumerFlowAddressFormData.firstName}{" "}
                      {consumerFlowAddressFormData.lastName}
                    </Col>
                  </Row>
                  {consumerType === USER_TYPES.private &&
                    consumerFlowAddressFormData.ownerBirthDate && (
                      <Row>
                        <Col>
                          Geburtsdatum:{" "}
                          {new Date(
                            consumerFlowAddressFormData.ownerBirthDate
                          ).toLocaleDateString("de-DE")}
                        </Col>
                      </Row>
                    )}
                  {consumerType === USER_TYPES.business && (
                    <Row>
                      <Col>{consumerFlowAddressFormData.companyOrOwner}</Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs="12" md="6">
                      {consumerFlowAddressFormData.email}
                    </Col>
                    <Col xs="12" md="6">
                      {consumerFlowAddressFormData.telephoneNumber}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      {consumerFlowAddressFormData.street}{" "}
                      {consumerFlowAddressFormData.houseNumber}
                    </Col>
                    <Col xs="12" md="6">
                      {consumerFlowAddressFormData.postcode}{" "}
                      {consumerFlowAddressFormData.city}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h4>Anschluss</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      Zählernummer:{" "}
                      {consumerFlowConnectionFormData.connectionMeterNumber}
                    </Col>
                    {consumerFlowConnectionFormData.connectionMeterCount && (
                      <Col xs="12" md="6">
                        Zählerstand:{" "}
                        {consumerFlowConnectionFormData.connectionMeterCount}
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      aktueller Stromversorger:{" "}
                      {consumerFlowConnectionFormData.connectionCurrentSupplier}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Kundennummer:{" "}
                      {consumerFlowConnectionFormData.connectionCustomerNumber}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Grund für Wechsel:{" "}
                      {consumerFlowConnectionFormData.reasonForChange}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {consumerFlowConnectionFormData.startDate ? (
                        <span>
                          {getString(
                            STRING_KEYS.CONSUMER_CONTRACT_DELIVERY_START_DATE
                          )}
                          :{" "}
                          {new Date(
                            consumerFlowConnectionFormData.startDate
                          ).toLocaleDateString("de-DE")}
                        </span>
                      ) : (
                        <span>
                          {getString(
                            STRING_KEYS.CONSUMER_CONTRACT_DELIVERY_START_DATE
                          )}
                          : Nächstmöglicher Zeitpunkt
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {getString(
                        STRING_KEYS.CONSUMER_CONTRACT_CONNECTION_POINT
                      )}
                      :
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      {consumerFlowConnectionFormData.connectionStreet
                        ? consumerFlowConnectionFormData.connectionStreet
                        : consumerFlowAddressFormData.street}{" "}
                      {consumerFlowConnectionFormData.connectionHouseNumber
                        ? consumerFlowConnectionFormData.connectionHouseNumber
                        : consumerFlowAddressFormData.houseNumber}
                    </Col>
                    <Col xs="12" md="6">
                      {consumerFlowConnectionFormData.connectionPostcode
                        ? consumerFlowConnectionFormData.connectionPostcode
                        : consumerFlowAddressFormData.postcode}{" "}
                      {consumerFlowConnectionFormData.connectionCity
                        ? consumerFlowConnectionFormData.connectionCity
                        : consumerFlowAddressFormData.city}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h4>Zahlungsdaten und Rechnungsanschrift</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      Zahlungsmethode:{" "}
                      {consumerFlowPaymentDetailsFormData.bankPaymentMethod ===
                      BANK_PAYMENT_METHODS.DEBIT
                        ? "Lastschrift"
                        : "Überweisung"}
                    </Col>
                  </Row>
                  {consumerFlowPaymentDetailsFormData.bankPaymentMethod ===
                    BANK_PAYMENT_METHODS.DEBIT && (
                    <Row>
                      <Col xs="12" md="6">
                        {consumerFlowPaymentDetailsFormData.bankIban}
                      </Col>
                      <Col xs="12" md="6">
                        {consumerFlowPaymentDetailsFormData.bankBic}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      {consumerFlowPaymentDetailsFormData.bankFirstName
                        ? consumerFlowPaymentDetailsFormData.bankFirstName
                        : consumerFlowAddressFormData.firstName}{" "}
                      {consumerFlowPaymentDetailsFormData.bankSurname
                        ? consumerFlowPaymentDetailsFormData.bankSurname
                        : consumerFlowAddressFormData.lastName}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      {consumerFlowPaymentDetailsFormData.bankStreet
                        ? consumerFlowPaymentDetailsFormData.bankStreet
                        : consumerFlowAddressFormData.street}{" "}
                      {consumerFlowPaymentDetailsFormData.bankHouseNumber
                        ? consumerFlowPaymentDetailsFormData.bankHouseNumber
                        : consumerFlowAddressFormData.houseNumber}
                    </Col>
                    <Col xs="12" md="6">
                      {consumerFlowPaymentDetailsFormData.bankPostcode
                        ? consumerFlowPaymentDetailsFormData.bankPostcode
                        : consumerFlowAddressFormData.postcode}{" "}
                      {consumerFlowPaymentDetailsFormData.bankCity
                        ? consumerFlowPaymentDetailsFormData.bankCity
                        : consumerFlowAddressFormData.city}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h4>Vertragsbedingungen</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6" className="field-name">
                      {getString(
                        STRING_KEYS.EXTENDED_MIX_INFO_CONTRACT_DURATION_TITLE
                      )}
                    </Col>
                    <Col xs="12" md="6" className="value">
                      {getString(
                        STRING_KEYS.EXTENDED_MIX_INFO_CONTRACT_DURATION,
                        [["currentYear", currentYear]]
                      )}
                    </Col>
                  </Row>
                  {showMinContractDuration && (
                    <Row>
                      <Col xs="12" md="6" className="field-name">
                        Vertragsverlängerung
                      </Col>
                      <Col xs="12" md="6" className="value">
                        {getString(
                          STRING_KEYS.EXTENDED_MIX_INFO_MIN_CONTRACT_DURATION
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs="12" md="6" className="field-name">
                      Kündigungsfrist
                    </Col>
                    <Col xs="12" md="6" className="value">
                      {getString(STRING_KEYS.EXTENDED_MIX_INFO_NOTICE_PERIOD, [
                        ["currentYear", currentYear]
                      ])}
                    </Col>
                  </Row>
                  <Row className="my-mix-header">
                    <h3>
                      {getString(STRING_KEYS.CONSUMER_CONTRACT_YOUR_MIX)}
                      {mixData.selectedMixName !== CUSTOM_MIX_NAME &&
                        ": " + getMixDisplayName(mixData.selectedMixName)}
                    </h3>
                  </Row>
                  <Row className="yearly-costs">
                    <Col>
                      <Price value={monthlyCosts} unit="€/Monat" />
                    </Col>
                  </Row>
                  <Row className="info">
                    <Col>
                      <ul>
                        <li>
                          {selectedMix.yearlyEnergy.toLocaleString("de-DE")}{" "}
                          kWh/Jahr
                        </li>
                        <li>
                          <Price value={energyPrice} unit="ct/kWh" />{" "}
                          Arbeitspreis
                        </li>
                        <li>
                          <Price
                            value={monthlyBaseTariffWithRlmExtraCosts}
                            unit="€/Monat"
                          />{" "}
                          Grundpreis{" "}
                          {mixPowerPrice > 0 && (
                            <RlmExtraCostsInfoIcon maxPower={mixMaxPower} />
                          )}
                        </li>
                        <li>
                          <Price value={monthlyMixSurcharge} unit="€/Monat" />{" "}
                          Betreiberzuschuss
                        </li>
                        <li>
                          <Coverage value={selectedMix.coverage} /> Deckungsrate
                        </li>
                        <li>
                          {selectedMix.averageDistance.toLocaleString("de-DE", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1
                          })}{" "}
                          km mittlere Entfernung
                        </li>
                        <li>
                          <Price value={yearlyCost} unit="€/Jahr" />{" "}
                          {getString(
                            STRING_KEYS.EXTENDED_MIX_INFO_TOTAL_PRICE_PER_YEAR
                          )}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="producers">
                      {selectedMixPlants &&
                        selectedMixPlants.map((plant) => (
                          <Producer
                            key={plant.id}
                            slug={plant.slugName}
                            plantName={plant.name}
                            ownerName={
                              plant.contractCompanyOrOwner ||
                              plant.owner.firstName + " " + plant.owner.lastName
                            }
                            description={plant.description}
                            city={plant.plantCity}
                            distance={producerPowerPlantDistances[plant.id]}
                            type={plant.plantType}
                            installedCapacity={plant.plantInstalledCapacity}
                            img={getPowerPlantMainImage(plant)}
                          />
                        ))}
                    </Col>
                  </Row>
                  <Row className="vat-info">
                    <Col>
                      <VatMarker />
                      {VAT_INFO_TEXTS[consumerType]}
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            {showVertragsbedingungen && (
              <Row>
                <Col xs={12} md={12} lg={8}>
                  <CellCheckbox
                    id="contractConditions"
                    formGroupClassName="bank-sepa"
                    textComponent={
                      <MarkdownString
                        stringKey={
                          STRING_KEYS.CONSUMER_CONTRACT_CONTRACT_CONDITIONS
                        }
                        replacements={[
                          ["contractConditionsPdf", swlContractConditionsPdf]
                        ]}
                        openLinksInNewWindow
                      />
                    }
                    checked={contractConditionsAccepted}
                    errors={contractConditionsErrorText}
                    onChange={this.handleContractConditionsChange}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12} md={12} lg={8}>
                <CellCheckbox
                  id="agb"
                  formGroupClassName="bank-sepa"
                  textComponent={
                    <MarkdownString
                      stringKey={STRING_KEYS.CONSUMER_CONTRACT_AGB}
                      replacements={[["agbPdf", themedAgbPdf]]}
                      openLinksInNewWindow
                    />
                  }
                  checked={agbAccepted}
                  errors={agbErrorText}
                  onChange={this.handleAgbChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={8}>
                <CellCheckbox
                  id="rightOfCancellation"
                  formGroupClassName="bank-sepa"
                  textComponent={
                    <MarkdownString
                      stringKey={
                        STRING_KEYS.CONSUMER_CONTRACT_RIGHT_OF_CANCELLATION
                      }
                      replacements={[
                        ["rightOfCancellationPdf", themedRightOfCancellationPdf]
                      ]}
                      openLinksInNewWindow
                    />
                  }
                  checked={rightOfCancellationAccepted}
                  errors={rightOfCancellationErrorText}
                  onChange={this.handleRightOfCancellationChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={8}>
                <CellCheckbox
                  id="privacy"
                  formGroupClassName="bank-sepa"
                  textComponent={
                    <MarkdownString
                      stringKey={STRING_KEYS.CONSUMER_CONTRACT_PRIVACY}
                      replacements={[
                        ["swlPrivacyPdf", swlPrivacyPdf],
                        ["oberland17PrivacyPdf", oberland17PrivacyPdf]
                      ]}
                      openLinksInNewWindow
                    />
                  }
                  checked={privacyAccepted}
                  errors={privacyErrorText}
                  onChange={this.handlePrivacyChange}
                />
              </Col>
            </Row>
            {showEmailAndPhoneCheckboxes && (
              <Row>
                <Col xs={12} md={12} lg={8}>
                  <MarkdownString
                    stringKey={STRING_KEYS.CONSUMER_CONTRACT_EMAIL_PHONE_TOP}
                  />
                  <div className="email-phone">
                    <CellCheckbox
                      id="sendEmail"
                      formGroupClassName="bank-sepa"
                      textComponent="per Email"
                      checked={sendEmailAccepted}
                      onChange={this.handleSendEmailChange}
                    />
                    <CellCheckbox
                      id="callPhone"
                      formGroupClassName="bank-sepa"
                      textComponent="per Telefon"
                      checked={callPhoneAccepted}
                      onChange={this.handleCallPhoneChange}
                    />
                  </div>
                  <MarkdownString
                    stringKey={STRING_KEYS.CONSUMER_CONTRACT_EMAIL_PHONE_BOTTOM}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col className="sign-contract-button-container">
                <Button
                  color="primary"
                  className="weiter"
                  disabled={submitInProgress}
                  onClick={this.handleSubmit}
                >
                  {getString(STRING_KEYS.CONSUMER_CONTRACT_SUBMIT)}
                </Button>
                {showPrintButton && (
                  <Button
                    color="secondary"
                    className="weiter"
                    onClick={() => window.print()}
                  >
                    Ausdrucken
                  </Button>
                )}
              </Col>
            </Row>
            {contractCreationError && (
              <Row>
                <Col>
                  <Alert color="danger" className="errors">
                    <ul>
                      {typeof contractCreationError === "object" &&
                        Object.values(contractCreationError).length > 0 &&
                        Object.values(contractCreationError).map((error) =>
                          typeof error === "object" &&
                          Object.values(error).length > 0 ? (
                            Object.values(error).map((subError) => (
                              <li key={subError}>{subError}</li>
                            ))
                          ) : (
                            <li key={error}>{error}</li>
                          )
                        )}
                      {typeof contractCreationError !== "object" && (
                        <li>
                          <MarkdownString
                            stringKey={STRING_KEYS.ERROR_UNKNOWN}
                          />
                        </li>
                      )}
                    </ul>
                  </Alert>
                </Col>
              </Row>
            )}
          </Container>
        )}
        <Container>
          <BackButton onClick={() => this.handleClickBack(currentPageIndex)} />
        </Container>
        {PAGES[currentPageIndex] !== CONFIRMATION_PAGE && (
          <div className="overview">
            <div className="inner">
              <header>
                <h3>
                  {mixData.selectedMixName === CUSTOM_MIX_NAME
                    ? getString(STRING_KEYS.YOUR_MIX)
                    : getMixDisplayName(mixData.selectedMixName)}
                </h3>
              </header>
              <Container>
                <Row className="yearly-costs">
                  <Col>
                    <Price value={monthlyCosts} unit="€/Monat" />
                  </Col>
                </Row>
                <Row className="info">
                  <Col>
                    <ul>
                      <li>
                        <Price value={energyPrice} unit="ct/kWh" /> Arbeitspreis
                      </li>
                      <li>
                        <Price value={monthlyBaseTariff} unit="€/Monat" />{" "}
                        Grundpreis
                      </li>
                      <li>
                        <Price value={monthlyMixSurcharge} unit="€/Monat" />{" "}
                        Betreiberzuschuss
                      </li>
                      <li>
                        <Coverage value={selectedMix.coverage} /> Deckungsrate
                      </li>
                      <li>
                        {selectedMix.averageDistance.toLocaleString("de-DE", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1
                        })}{" "}
                        km mittlere Entfernung
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ExtendedMixInfoModal
                      monthlyCosts={monthlyCosts}
                      mixYearlyEnergy={selectedMix.yearlyEnergy}
                      mixPlants={selectedMixPlants}
                      mixPlantCoverages={
                        selectedMix.powerPlantCoveragePercentages
                      }
                      mixPostcode={selectedMix.postcode}
                      energyPrice={energyPrice}
                      mixSurcharge={mixSurcharge}
                      baseTariff={baseTariff}
                      mixPowerPrice={mixPowerPrice}
                      mixMaxPower={mixMaxPower}
                      showProducers
                    />
                  </Col>
                </Row>
                {showVertragsbedingungen && (
                  <Row>
                    <Col>
                      <Button
                        color="secondary"
                        size="sm"
                        style={{ marginTop: "3px" }}
                        tag="a"
                        href={swlContractConditionsPdf}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vertragsbedingungen
                      </Button>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <div className="vat-info">
                      <VatMarker />
                      {VAT_INFO_TEXTS[consumerType]}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mixData: state.mix,
    powerPlants: state.powerPlants.powerPlants,
    producerPowerPlantDistances: state.powerPlants.distances,
    consumerType: state.mix.consumerType,

    consumerFlowAddressFormData: state.form.consumerFlowAddressForm
      ? state.form.consumerFlowAddressForm.values
      : null,
    consumerFlowConnectionFormData: state.form.consumerFlowConnectionForm
      ? state.form.consumerFlowConnectionForm.values
      : null,
    consumerFlowPaymentDetailsFormData: state.form
      .consumerFlowPaymentDetailsForm
      ? state.form.consumerFlowPaymentDetailsForm.values
      : null,
    contractCreationError: state.mix.contractCreationError
  };
}

const mapDispatchToProps = (dispatch) => ({
  onCreateContract: (values) => dispatch(createConsumerContractAction(values))
});

const connectedConsumerContract = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumerContract);

export { connectedConsumerContract as ConsumerContract };
