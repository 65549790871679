import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./AddToMixWidget.scss";
import { setMixOptionsAction, storeMixAction } from "../../actions";
import { MAX_PLANTS_PER_MIX, STRING_KEYS } from "../../constants.js";
import {
  getMixPowerPlantsFromMixIdsAndProducerPowerPlants,
  getPowerPlantMainImage,
  getProducerDisplayName,
  getString
} from "../../utils.js";

function Producer(props) {
  return (
    <div className="producer">
      <img src={props.img} alt={props.name} className="plant-image" />
      <span className="plant-name">{props.name}</span>
      <span className="remove-button" onClick={props.onRemoveFromMix}>
        <FontAwesomeIcon icon="times" />
      </span>
    </div>
  );
}

class AddToMixWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null
    };
  }

  handleClick = () => {
    const { powerPlantId, mixPowerPlantIds, mixName, setMixOptions, storeMix } =
      this.props;

    const powerPlantIds = [...mixPowerPlantIds, powerPlantId];

    storeMix({
      powerPlants: powerPlantIds,
      mixName: mixName
    })
      .then(() => {
        return setMixOptions({
          firstPowerPlantIds: powerPlantIds
        });
      })
      .then(() => {
        this.setState({
          redirectTo: "/strom-kaufen/mein-mix"
        });
      });
  };

  handleRemoveFromMix = (plantId) => {
    const powerPlantIds = this.props.mixPowerPlantIds.filter(
      (mixPlantId) => mixPlantId !== plantId
    );
    this.props.storeMix({
      powerPlants: powerPlantIds,
      mixName: this.props.mixName
    });
  };

  componentDidMount() {
    this.setState({
      redirectTo: null
    });
  }

  render() {
    const { powerPlantId, mixPowerPlantIds, producerPowerPlants, onGoBack } =
      this.props;

    const mixPowerPlants = getMixPowerPlantsFromMixIdsAndProducerPowerPlants(
      mixPowerPlantIds,
      producerPowerPlants
    );
    const plantIsAlreadyInMix =
      mixPowerPlantIds && mixPowerPlantIds.includes(powerPlantId);

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} push={true} />;
    }

    return (
      <Container className="AddToMixWidget">
        <Row>
          <Col>
            <h2>{getString(STRING_KEYS.YOUR_MIX)}</h2>
          </Col>
        </Row>
        <Row>
          <Col className="producers">
            {mixPowerPlants &&
              mixPowerPlants.map((plant) => (
                <Producer
                  key={plant.id}
                  name={getProducerDisplayName(plant)}
                  img={getPowerPlantMainImage(plant)}
                  onRemoveFromMix={() => this.handleRemoveFromMix(plant.id)}
                />
              ))}
            {(!mixPowerPlantIds || mixPowerPlantIds.length === 0) && (
              <p>Noch keine Anlagen im Mix</p>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="buttons">
            <Button
              color="primary"
              disabled={
                mixPowerPlants.length >= MAX_PLANTS_PER_MIX ||
                plantIsAlreadyInMix
              }
              onClick={this.handleClick}
            >
              {plantIsAlreadyInMix ? "Ausgewählt" : "Zum Mix hinzufügen"}
            </Button>
            <Button color="secondary" size="sm" onClick={onGoBack}>
              Zurück zum Mix
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    yearlyEnergy: state.mix.yearlyEnergy,
    mixPowerPlantIds: state.mix.powerPlants,
    mixName: state.mix.selectedMixName,
    producerPowerPlants: state.powerPlants.powerPlants
  };
}

const mapDispatchToProps = (dispatch) => ({
  setMixOptions: (options) => dispatch(setMixOptionsAction(options)),
  storeMix: (powerPlants, mixName) =>
    dispatch(storeMixAction(powerPlants, mixName))
});

const connectedAddToMixWidget = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddToMixWidget);

export { connectedAddToMixWidget as AddToMixWidget };
