import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap";
import { Field, formValueSelector, reduxForm } from "redux-form";

import { STRING_KEYS } from "../../constants";
import { ContinueButton } from "../../components/ContinueButton";
import { checkFeature, FEATURES } from "../../features";
import { HasRLMMeter } from "./fields/HasRLMMeter.js";
import { getString } from "../../utils";

import "./PotentialCheckDmsp.scss";

import bayWaReDmspImage from "./dmsp-logos/bay-wa-re.png";
import alpiqDmspImage from "./dmsp-logos/alpiq.png";
import enbwDmspImage from "./dmsp-logos/enbw.png";
import wemagDmspImage from "./dmsp-logos/wemag.png";
import wvvDmspImage from "./dmsp-logos/wvv.png";

const DMSP_NOT_SELECT = "Auswahl Direktvermarkter";
export const EMPTY_DROPDOWN = "-----";
export const DMSP_NOT_LISTED = "Nicht aufgelistet";
export const DMSP_PARTNERS = [
  {
    name: "Baywa re",
    imgUrl: bayWaReDmspImage
  },
  {
    name: "Alpiq",
    imgUrl: alpiqDmspImage
  },
  {
    name: "EnBW",
    imgUrl: enbwDmspImage
  },
  {
    name: "Würzburger Versorgungs- und Verkehrs GmbH",
    imgUrl: wvvDmspImage
  },
  {
    name: "WEMAG",
    imgUrl: wemagDmspImage
  }
];

/*
 *  Note! If you edit this list make sure it is in sync with:
 *
 *   - The ``DirectMarketerName`` enum in the backend
 *
 *   - The ``DirectMarketer`` instances in the production database to match
 *
 *  Ticket to improve this situation at https://github.com/node-energy/cells_energy/issues/621
 */
export const DMSP_LIST = [
  "Allgäuer Überlandwerke",
  "Badenova",
  "Clean Energy Sourcing",
  "Danske Commodities Deutschland",
  "Dong Energy",
  "EnDaNet",
  "Energiedienst",
  "Energieservice Westfalen Weser",
  "energy2market",
  "Engie Deutschland",
  "enviaM",
  "Eon Energie Deutschland",
  "EWE Trading",
  "Gasag",
  "GDGE",
  "Greenpeace Energy",
  "In.power / BKW Energie",
  "Innogy",
  "Lechwerke",
  "Lichtblick",
  "Mark-E",
  "Max-Power",
  "MVV Energie",
  "Natgas",
  "Naturstrom",
  "Neas Energy",
  "Next Kraftwerke",
  "N-Ergie",
  "Nordgröön Energie",
  "Quadra Energy",
  "Rhein Ruhr Partner Gesellschaft für Energiehandel",
  "Stadtwerke Bielefeld",
  "Stadtwerke Düsseldorf",
  "Stadtwerke Flensburg",
  "Stadtwerke München",
  "Statkraft Markets",
  "Steag",
  "Südwestdeutsche Stromhandelsgesellschaft",
  "Sunnic Lighthouse",
  "Syneco Trading",
  "Trianel Gruppe",
  "Uniper Energy Sales",
  "Unterfränkische Überlandzentrale",
  "Verbund Deutschland",
  "Wind Energy Trading",
  DMSP_NOT_LISTED
];

function Partner(props) {
  return (
    <div
      className={
        "partner-outer" + (props.selectedDmsp === props.name ? " selected" : "")
      }
      onClick={() => {
        props.onClickPartner(props.name);
      }}
    >
      <img src={props.imgUrl} alt={props.name} className="partner" />
    </div>
  );
}

class PotentialCheckDmsp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreDmsps:
        !!props.plantDirectMarketer &&
        !DMSP_PARTNERS.find((dmsp) => dmsp.name === props.plantDirectMarketer),
      userHasNoDmsp: props.plantDirectMarketer === null,
      showInfoModal: false,
      infoModalText: null
    };
  }

  handleClickPartner = (name) => {
    this.props.change("plantDirectMarketer", name);
    this.setState({
      showMoreDmsps: false,
      userHasNoDmsp: false
    });
  };

  handleClickMoreDmsps = () => {
    this.props.change("plantDirectMarketer", null);
    this.setState({
      showMoreDmsps: true,
      userHasNoDmsp: false,
      showInfoModal: checkFeature(FEATURES.DMSP_PARTNERS),
      infoModalText: getString(
        STRING_KEYS.POTENTIAL_CHECK_DMSP_MORE_DMSPS_MODAL
      )
    });
  };

  handleChangeDmspDropdown = () => {
    this.setState({
      userHasNoDmsp: false
    });
  };

  handleClickNoDmsp = () => {
    this.props.change("plantDirectMarketer", null);
    this.setState({
      userHasNoDmsp: true,
      showMoreDmsps: false,
      showInfoModal: true,
      infoModalText: getString(STRING_KEYS.POTENTIAL_CHECK_DMSP_NO_DMSP_MODAL)
    });
  };

  toggleInfoModal = () => {
    this.setState({
      showInfoModal: !this.state.showInfoModal
    });
  };

  handleConfirmInfoModal = () => {
    this.toggleInfoModal();
  };

  handleSecondaryModal() {
    window.open(getString(STRING_KEYS.POTENTIAL_CHECK_DMSP_MODAL_LINK));
  }

  render() {
    const {
      plantDirectMarketer,
      plantMeteredInFifteenMinuteIntervals,
      onGoToNextStep
    } = this.props;

    const { showMoreDmsps, userHasNoDmsp, showInfoModal, infoModalText } =
      this.state;

    const showDmspPartners = checkFeature(FEATURES.DMSP_PARTNERS);
    const showDmspInfoButton = checkFeature(FEATURES.DMSP_INFO_BUTTON);
    let dmspList = DMSP_LIST;

    if (!showDmspPartners) {
      dmspList = [
        ...DMSP_PARTNERS.map((partner) => partner.name),
        ...dmspList.filter((dmsp) => dmsp !== DMSP_NOT_LISTED)
      ];
      dmspList.sort();
      dmspList.push(DMSP_NOT_LISTED);
    }

    const dmspDropdown = showMoreDmsps && (
      <Input
        type="select"
        tag={Field}
        name="plantDirectMarketer"
        component="select"
        onChange={this.handleChangeDmspDropdown}
      >
        <option>{DMSP_NOT_SELECT}</option>
        {dmspList.map((dmsp) => (
          <option key={dmsp}>{dmsp}</option>
        ))}
      </Input>
    );

    const finalButton =
      (plantDirectMarketer && plantDirectMarketer !== DMSP_NOT_SELECT) ||
      (userHasNoDmsp &&
        plantMeteredInFifteenMinuteIntervals &&
        plantMeteredInFifteenMinuteIntervals !== { EMPTY_DROPDOWN }) ? (
        <ContinueButton onClick={onGoToNextStep} />
      ) : null;

    return (
      <div className="PotentialCheckDmsp">
        <header>
          <h3>{getString(STRING_KEYS.POTENTIAL_CHECK_DMSP_TITLE)}</h3>
        </header>
        <Container>
          {showDmspPartners && (
            <DmspPartners
              selectedDmsp={plantDirectMarketer}
              onClickPartner={this.handleClickPartner}
            />
          )}
          <Row className="buttons">
            <Button
              color="secondary"
              size="sm"
              onClick={this.handleClickMoreDmsps}
              className={showMoreDmsps ? "focused" : ""}
            >
              {getString(STRING_KEYS.POTENTIAL_CHECK_DMSP_MORE_DMSPS_BUTTON)}
            </Button>
            <Button
              color="secondary"
              size="sm"
              onClick={this.handleClickNoDmsp}
              className={userHasNoDmsp ? "focused" : ""}
            >
              {getString(STRING_KEYS.POTENTIAL_CHECK_DMSP_NO_DMSP_BUTTON)}
            </Button>
          </Row>
          {showMoreDmsps && (
            <Row>
              <p className="dmsp-subheader">
                {getString(
                  STRING_KEYS.POTENTIAL_CHECK_DMSP_MORE_DMSPS_SUBHEADER
                )}
              </p>
            </Row>
          )}
          <Row>
            <div className="question-group">
              {dmspDropdown}
              {userHasNoDmsp && (
                <div>
                  <p>
                    {getString(
                      STRING_KEYS.POTENTIAL_CHECK_DMSP_NO_DMSP_MESSAGE
                    )}
                  </p>
                </div>
              )}
              {userHasNoDmsp && <HasRLMMeter emptyDropdown={EMPTY_DROPDOWN} />}
            </div>
          </Row>
        </Container>
        <footer className="continue-button-container">{finalButton}</footer>
        <Modal isOpen={showInfoModal} toggle={this.toggleInfoModal}>
          <ModalBody>
            <p className="info-modal-text">{infoModalText}</p>
          </ModalBody>
          <ModalFooter>
            {showDmspInfoButton && (
              <Button
                color="secondary"
                type="submit"
                onClick={this.handleSecondaryModal}
              >
                jetzt informieren
              </Button>
            )}
            <Button
              color="primary"
              type="submit"
              onClick={this.handleConfirmInfoModal}
            >
              weiter
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function DmspPartners({ selectedDmsp, onClickPartner }) {
  return (
    <React.Fragment>
      <Row>
        <p className="dmsp-subheader">Unsere Partner:</p>
      </Row>
      <Row>
        <div className="partners">
          {DMSP_PARTNERS.map((dmspPartner) => (
            <Partner
              key={dmspPartner.name}
              name={dmspPartner.name}
              imgUrl={dmspPartner.imgUrl}
              selectedDmsp={selectedDmsp}
              onClickPartner={onClickPartner}
            />
          ))}
        </div>
      </Row>
    </React.Fragment>
  );
}

PotentialCheckDmsp.propTypes = {
  onGoToNextStep: PropTypes.func.isRequired
};

let reduxFormPotentialCheckDmsp = reduxForm({
  form: "potentialCheck",
  destroyOnUnmount: false
})(PotentialCheckDmsp);

const selector = formValueSelector("potentialCheck");
reduxFormPotentialCheckDmsp = connect((state) => {
  const plantDirectMarketer = selector(state, "plantDirectMarketer");
  const plantMeteredInFifteenMinuteIntervals = selector(
    state,
    "plantMeteredInFifteenMinuteIntervals"
  );
  return {
    plantDirectMarketer,
    plantMeteredInFifteenMinuteIntervals
  };
})(reduxFormPotentialCheckDmsp);

export { reduxFormPotentialCheckDmsp as PotentialCheckDmsp };
