import React from "react";

import { CURRENT_THEME, THEMES } from "../constants";

import oberland17ArrowIcon from "../images/oberland17/icons/icon_arrow_right.jpg";
import oberland17ArrowIconDisabled from "../images/oberland17/icons/icon_arrow_right_disabled.jpg";
import "./ContinueButton.scss";

function ContinueButton({ disabled, onClick }) {
  const className =
    "continue-button" + (disabled ? " continue-button-disabled" : "");

  switch (CURRENT_THEME) {
    case THEMES.OBERLAND17:
      return (
        <img
          src={disabled ? oberland17ArrowIconDisabled : oberland17ArrowIcon}
          className={className}
          onClick={disabled ? null : onClick}
        />
      );
    default:
      return <svg className={className} onClick={disabled ? null : onClick} />;
  }
}

export { ContinueButton };
