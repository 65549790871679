import React, { Component } from "react";
import { CustomInput, FormGroup, Label } from "reactstrap";
import { Field } from "redux-form";

import classnames from "classnames";

import "./CellCheckbox.scss";

class CellCheckbox extends Component {
  render() {
    const {
      id,
      name,
      formGroupClassName,
      inputClassName,
      checked,
      text,
      textComponent,
      errors,
      usesReduxFormField,
      onChange
    } = this.props;

    if (usesReduxFormField) {
      return (
        <FormGroup className={classnames("CellCheckbox", formGroupClassName)}>
          <Field
            name={name}
            component={(props) => (
              <LabelAndInput
                id={id}
                inputClassName={inputClassName}
                checked={props.input.value}
                errors={errors}
                text={text}
                textComponent={textComponent}
                onChange={props.input.onChange}
              />
            )}
          />
          {errors && <span className="error">{errors}</span>}
        </FormGroup>
      );
    }
    return (
      <FormGroup className={classnames("CellCheckbox", formGroupClassName)}>
        <LabelAndInput
          id={id}
          name={name}
          inputClassName={inputClassName}
          checked={checked}
          errors={errors}
          text={text}
          textComponent={textComponent}
          onChange={onChange}
        />
        {errors && <span className="error">{errors}</span>}
      </FormGroup>
    );
  }
}

function LabelAndInput({
  id,
  name,
  inputClassName,
  checked,
  errors,
  text,
  textComponent,
  onChange
}) {
  return (
    <Label for={id} className="checkbox-label">
      <CustomInput
        type="checkbox"
        name={name}
        id={id}
        component="input"
        className={inputClassName}
        checked={checked}
        invalid={!!errors}
        onChange={(e) => onChange(e.target.checked)}
      />{" "}
      {text || textComponent}
    </Label>
  );
}

export { CellCheckbox };
