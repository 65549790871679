import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { ExtendedMixInfo } from "./ExtendedMixInfo";

import "./ExtendedMixInfoModal.scss";

class ExtendedMixInfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false
    };
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  };

  render() {
    const { isModalOpen } = this.state;

    const {
      monthlyCosts,
      mixYearlyEnergy,
      mixPostcode,
      mixPlants,
      mixPlantCoverages,
      energyPrice,
      mixSurcharge,
      baseTariff,
      mixPowerPrice,
      mixMaxPower,
      showProducers,
      showCustomMargins,
      onUpdateCustomMargins
    } = this.props;

    return (
      <div className="ExtendedMixInfoModal">
        <Button color="secondary" size="sm" onClick={this.toggleModal}>
          Mix &amp; Preisdetails
        </Button>
        <Modal
          isOpen={isModalOpen}
          toggle={this.toggleModal}
          size="lg"
          className="extended-mix-info-modal"
        >
          <ModalHeader toggle={this.toggleModal}>
            Mix &amp; Preisdetails
          </ModalHeader>
          <ModalBody>
            <ExtendedMixInfo
              mixMonthlyCost={monthlyCosts}
              mixYearlyEnergy={mixYearlyEnergy}
              mixPostcode={mixPostcode}
              mixPlants={mixPlants}
              mixPlantCoverages={mixPlantCoverages}
              energyPrice={energyPrice}
              mixSurcharge={mixSurcharge}
              baseTariff={baseTariff}
              mixPowerPrice={mixPowerPrice}
              mixMaxPower={mixMaxPower}
              showProducers={showProducers}
              showCustomMargins={showCustomMargins}
              onUpdateCustomMargins={onUpdateCustomMargins}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="close-button"
              onClick={this.toggleModal}
            >
              Schließen
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export { ExtendedMixInfoModal };
