import { CURRENT_THEME, THEMES } from "./constants";

export const FEATURES = {
  ABOUT_PAGE: "about-page",
  AGB_PAGE: "agb-page",
  BLOG: "blog",
  CONSUMER_CONTRACT_VERTRAGSBEDINGUNGEN:
    "consumer-contract-vertragsbedingungen",
  CONSUMER_CONTRACT_BEGIN_DELIVERY_BEFORE_RECOVCATION_PERIOD_ENDS:
    "consumer-contract-begin-delivery-period-before-revocation-period-ends",
  CONSUMER_CONTRACT_PRINT_BUTTON: "consumer-contract-print-button",
  CONSUMER_CONTRACT_SHARE_EMAIL_OR_PHONE:
    "consumer-contract-share-email-or-phone",
  CONSUMER_CONTRACT_METER_COUNT: "consumer-contract-meter-count",
  CONSUMER_CONTRACT_METER_NUMBER_REQUIRED:
    "consumer-contract-meter-number-required",
  CONSUMER_CONTRACT_CURRENT_SUPPLIER_REQUIRED:
    "consumer-contract-current-supplier-required",
  CONSUMER_CONTRACT_CUSTOMER_NUMBER_REQUIRED:
    "consumer-contract-customer-number-required",
  CONSUMER_LANDING_PAGE_BUTTON_FOOTER: "consumer-landing-page-button-footer",
  CREATE_MIX_COLLAPSIBLE_INFO: "create-mix-collapsible-info",
  DISABLED_SIGNUPS: "disabled-signups",
  DMSP_INFO_BUTTON: "dmsp-info-button",
  DMSP_PARTNERS: "dmsp-partners",
  EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH_HELP_TEXT:
    "extended-mix-info-final-price-per-month-help-text",
  FAQ_IS_EXTERNAL: "external-faq-link",
  FORGOT_PASSWORD: "forgot-password",
  IMPRESSUM_INTERNAL_PAGE: "impressum-internal-page",
  INSTAGRAM: "instagram",
  LANDING_CONSUMER_HOW_IT_WORKS: "landing-consumer-how-it-works",
  LANDING_PRODUCER_INTRO: "landing-producer-intro",
  MIN_CONTRACT_DURATION: "min-contract-duration",
  MY_POWER_PLANTS_FIND_DMSP: "my-power-plants-find-dmsp",
  PAN_MAP_TO_PRODUCERS: "pan-map-to-producers",
  PRIVACY_INTERNAL_PAGE: "privacy-internal-page",
  PRODUCER_CONTRACT_LEGAL_INFORMATION: "producer-contract-legal-information",
  PRODUCER_SUPPORTER: "producer-supporter",
  STROMKENNZEICHNUNG_INTERNAL_PAGE: "stromkennzeichnung-internal-page",
  TWITTER: "twitter",
  VARIABLE_PRICING: "variable-pricing"
};

const DEFAULT_FEATURES = Object.values(FEATURES).reduce((features, feature) => {
  features[feature] = true;
  features[FEATURES.ABOUT_PAGE] = false;
  features[FEATURES.CONSUMER_CONTRACT_VERTRAGSBEDINGUNGEN] = false;
  features[
    FEATURES.CONSUMER_CONTRACT_BEGIN_DELIVERY_BEFORE_RECOVCATION_PERIOD_ENDS
  ] = false;
  features[FEATURES.CONSUMER_CONTRACT_PRINT_BUTTON] = false;
  features[FEATURES.CONSUMER_CONTRACT_SHARE_EMAIL_OR_PHONE] = false;
  features[FEATURES.CONSUMER_CONTRACT_METER_COUNT] = false;
  features[FEATURES.CONSUMER_CONTRACT_METER_NUMBER_REQUIRED] = false;
  features[FEATURES.CONSUMER_CONTRACT_CURRENT_SUPPLIER_REQUIRED] = false;
  features[FEATURES.CONSUMER_CONTRACT_CUSTOMER_NUMBER_REQUIRED] = false;
  features[FEATURES.CONSUMER_LANDING_PAGE_BUTTON_FOOTER] = false;
  features[FEATURES.DISABLED_SIGNUPS] = false;
  features[FEATURES.EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH_HELP_TEXT] = false;
  features[FEATURES.FAQ_IS_EXTERNAL] = false;
  features[FEATURES.LANDING_CONSUMER_HOW_IT_WORKS] = false;
  features[FEATURES.LANDING_PRODUCER_INTRO] = false;
  features[FEATURES.MIN_CONTRACT_DURATION] = false;
  features[FEATURES.MY_POWER_PLANTS_FIND_DMSP] = false;
  features[FEATURES.PRODUCER_CONTRACT_LEGAL_INFORMATION] = false;
  return features;
}, {});

const SWL_FEATURES = {
  ...DEFAULT_FEATURES,
  [FEATURES.ABOUT_PAGE]: true,
  [FEATURES.AGB_PAGE]: false,
  [FEATURES.BLOG]: false,
  [FEATURES.CONSUMER_CONTRACT_VERTRAGSBEDINGUNGEN]: true,
  [FEATURES.CONSUMER_CONTRACT_BEGIN_DELIVERY_BEFORE_RECOVCATION_PERIOD_ENDS]: true,
  [FEATURES.CONSUMER_CONTRACT_PRINT_BUTTON]: true,
  [FEATURES.CONSUMER_CONTRACT_SHARE_EMAIL_OR_PHONE]: true,
  [FEATURES.CREATE_MIX_COLLAPSIBLE_INFO]: false,
  [FEATURES.DISABLED_SIGNUPS]: true,
  [FEATURES.DMSP_INFO_BUTTON]: false,
  [FEATURES.DMSP_PARTNERS]: false,
  [FEATURES.EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH_HELP_TEXT]: true,
  [FEATURES.FORGOT_PASSWORD]: false, // https://trello.com/c/FZrWMyAs/64-swl-forgot-password-bugs
  [FEATURES.IMPRESSUM_INTERNAL_PAGE]: false,
  [FEATURES.INSTAGRAM]: false,
  [FEATURES.LANDING_CONSUMER_HOW_IT_WORKS]: true,
  [FEATURES.LANDING_PRODUCER_INTRO]: true,
  [FEATURES.MIN_CONTRACT_DURATION]: true,
  [FEATURES.MY_POWER_PLANTS_FIND_DMSP]: true,
  [FEATURES.PAN_MAP_TO_PRODUCERS]: false,
  [FEATURES.PRIVACY_INTERNAL_PAGE]: false,
  [FEATURES.PRODUCER_CONTRACT_LEGAL_INFORMATION]: true,
  [FEATURES.PRODUCER_SUPPORTER]: false,
  [FEATURES.STROMKENNZEICHNUNG_INTERNAL_PAGE]: false,
  [FEATURES.TWITTER]: false,
  [FEATURES.VARIABLE_PRICING]: false
};

const OBERLAND17_FEATURES = {
  ...DEFAULT_FEATURES,
  [FEATURES.ABOUT_PAGE]: true,
  [FEATURES.AGB_PAGE]: false,
  [FEATURES.BLOG]: false,
  [FEATURES.CONSUMER_CONTRACT_METER_COUNT]: true,
  [FEATURES.CONSUMER_CONTRACT_METER_NUMBER_REQUIRED]: true,
  [FEATURES.CONSUMER_CONTRACT_CURRENT_SUPPLIER_REQUIRED]: true,
  [FEATURES.CONSUMER_CONTRACT_CUSTOMER_NUMBER_REQUIRED]: true,
  [FEATURES.CONSUMER_LANDING_PAGE_BUTTON_FOOTER]: true,
  [FEATURES.CREATE_MIX_COLLAPSIBLE_INFO]: false,
  [FEATURES.EXTENDED_MIX_INFO_FINAL_PRICE_PER_MONTH_HELP_TEXT]: true,
  [FEATURES.FAQ_IS_EXTERNAL]: true,
  [FEATURES.IMPRESSUM_INTERNAL_PAGE]: false,
  [FEATURES.LANDING_CONSUMER_HOW_IT_WORKS]: true,
  [FEATURES.MIN_CONTRACT_DURATION]: true,
  [FEATURES.PAN_MAP_TO_PRODUCERS]: false,
  [FEATURES.PRIVACY_INTERNAL_PAGE]: false,
  [FEATURES.STROMKENNZEICHNUNG_INTERNAL_PAGE]: false,
  [FEATURES.TWITTER]: false,
  [FEATURES.VARIABLE_PRICING]: false
};

export function checkFeature(feature) {
  switch (CURRENT_THEME) {
    case THEMES.CELLS_ENERGY:
      return DEFAULT_FEATURES[feature];
    case THEMES.SWL:
      return SWL_FEATURES[feature];
    case THEMES.OBERLAND17:
      return OBERLAND17_FEATURES[feature];
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      return DEFAULT_FEATURES[feature];
  }
}
