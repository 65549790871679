import {
  MIX_GENERATE_PRESET,
  MIX_GENERATE_PRESET_ERROR,
  MIX_CUSTOM_UPDATE,
  MIX_STORE,
  MIX_SET_OPTIONS,
  MIX_SET_CUSTOM_MARGIN,
  CONSUMER_CONTRACT_CREATED,
  CONSUMER_CONTRACT_CREATION_ERROR,
  MIX_CLEAR_ERRORS,
  MULTIPLIER_MIX_GET,
  MULTIPLIER_MIX_GET_ERROR,
  RESET_MULTIPLIER_MIX_GET_ERROR
} from "../actions";

import {
  DEFAULT_NUM_PEOPLE_IN_HOUSEHOLD,
  ENERGY_TYPES,
  METERING_TYPES,
  PEOPLE_ENERGY_AMOUNTS,
  USER_TYPES
} from "../constants.js";

const initialState = {
  consumerType: USER_TYPES.private,
  yearlyEnergy: PEOPLE_ENERGY_AMOUNTS[DEFAULT_NUM_PEOPLE_IN_HOUSEHOLD],
  allowedEnergyTypes: ENERGY_TYPES.map((energyType) => energyType.dbName),
  rlmUuid: null,
  meteringType: METERING_TYPES.standard,
  mixes: {},
  multiplierMixes: {}
};

function composeMixOptionsState(payload) {
  const optionsState = {};

  if (payload.hasOwnProperty("postcode")) {
    optionsState.postcode = payload.postcode;
  }

  if (payload.hasOwnProperty("consumerType")) {
    optionsState.consumerType = payload.consumerType;
  }

  if (payload.hasOwnProperty("yearlyEnergy")) {
    optionsState.yearlyEnergy = payload.yearlyEnergy;
  }

  if (payload.hasOwnProperty("allowedEnergyTypes")) {
    optionsState.allowedEnergyTypes = payload.allowedEnergyTypes;
  }

  if (payload.hasOwnProperty("rlmUuid")) {
    optionsState.rlmUuid = payload.rlmUuid;
  }

  if (payload.hasOwnProperty("meteringType")) {
    optionsState.meteringType = payload.meteringType;
  }

  if (payload.hasOwnProperty("firstPowerPlantIds")) {
    optionsState.firstPowerPlantIds = payload.firstPowerPlantIds;
  } else {
    // if firstPowerPlantIds hasn't been explicitly set, we should reset it
    // we only want to use it if it was explicitly set (e.g. in the profile)
    optionsState.firstPowerPlantId = undefined;
  }

  if (payload.hasOwnProperty("multiplierId")) {
    optionsState.multiplierId = payload.multiplierId;
  }

  return optionsState;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case MIX_GENERATE_PRESET:
      // if there isn't already a selected mix, try to use closest mix as default or take the first one as fall-back
      const mixNames = Object.keys(action.payload.mixes);
      let selectedMixName = state.selectedMixName;

      if (!selectedMixName) {
        if (mixNames.includes("closest")) {
          selectedMixName = "closest";
        } else {
          selectedMixName = mixNames[0];
        }
      }

      return {
        ...state,
        mixes: { ...state.mixes, ...action.payload.mixes },
        selectedMixName: selectedMixName,
        mixCreateError: false
      };
    case MIX_GENERATE_PRESET_ERROR:
      return { ...state, mixCreateError: true };
    case MIX_CUSTOM_UPDATE:
      return {
        ...state,
        mixes: { ...state.mixes, custom: action.payload.customMixData }
      };
    case MIX_STORE:
      return {
        ...state,
        powerPlants: action.payload.powerPlants,
        selectedMixName: action.payload.mixName
      };
    case MIX_SET_OPTIONS:
      return { ...state, ...composeMixOptionsState(action.payload) };
    case MIX_SET_CUSTOM_MARGIN:
      return {
        ...state,
        customMargins: {
          ...state.customMargins,
          [action.payload.plantId]: action.payload.margin
        }
      };
    case CONSUMER_CONTRACT_CREATED:
      return { ...state, contractCreated: true, contractCreationError: null };
    case CONSUMER_CONTRACT_CREATION_ERROR:
      return { ...state, contractCreationError: action.payload };
    case MIX_CLEAR_ERRORS:
      return { ...state, mixCreateError: false, contractCreationError: false };
    case MULTIPLIER_MIX_GET:
      return {
        ...state,
        multiplierMixes: {
          ...state.multiplierMixes,
          [action.payload.multiplierMixId]: action.payload.multiplierMix
        }
      };
    case MULTIPLIER_MIX_GET_ERROR:
      return { ...state, multiplierMixGetError: true };
    case RESET_MULTIPLIER_MIX_GET_ERROR:
      return { ...state, multiplierMixGetError: false };
    default:
      return state;
  }
}
