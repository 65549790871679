import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import classnames from "classnames";

import { CURRENT_THEME, STRING_KEYS, THEMES } from "../../constants";
import { checkFeature, FEATURES } from "../../features";
import { getString, MarkdownString } from "../../utils";

import "./LandingProducer.scss";

import swlAnlageCheckImage from "../../images/swl/producer-landing/anlagen-check.png";
import swlAnlageCheckPlusImage from "../../images/swl/producer-landing/anlagen-check-plus.png";
import oberland17AnlageCheckImage from "../../images/oberland17/producer-landing/anlagen-check-button.jpg";
import oberland17AnlageJetztAnmeldenImage from "../../images/oberland17/producer-landing/anlage-jetzt-anmelden-button.jpg";
import downArrowImage from "../../images/icons/btn-arrow-down.png";

import cellsEnergyImageGroup1 from "../../images/photos/Gruppe_1.png";
import swlSection1Image from "../../images/swl/consumer-landing/icon-bio.png";
import oberland17Section1Image from "../../images/oberland17/producer-landing/Solar_Berge.jpg";
import solarCellImage from "../../images/photos/solar-cell.png";
import customerCellImage from "../../images/photos/customer-cell.png";

import cellsEnergyImageGroup2 from "../../images/photos/Gruppe_2.png";
import swlSection2Image from "../../images/swl/consumer-landing/icon-solar.png";
import oberland17Section2Image1 from "../../images/oberland17/producer-landing/Solar_Hände.jpg";
import oberland17Section2Image2 from "../../images/oberland17/producer-landing/Solar_Geldsack.jpg";
import oberland17Section2OneHundredImage from "../../images/oberland17/producer-landing/one_hundred.jpg";
import secureCellImage from "../../images/photos/secure-cell.png";
import easyCellImage from "../../images/photos/easy-cell.png";
import moneyCellImage from "../../images/photos/money-cell.png";

import cellsEnergySection3Image from "../../images/photos/build-energy-cell.png";
import swlSection3Image from "../../images/swl/producer-landing/Handshake-Icon.png";
import oberland17Section3Image from "../../images/oberland17/producer-landing/Walchenseebild_Homepage.jpg";

const LandingProducer = (props) => {
  if (props.authenticated) {
    return <Redirect to="/strom-verkaufen/meine-anlagen" push={true} />;
  }

  return (
    <div className="LandingProducer page">
      <header className="page-header">
        <h2>Strom Verkaufen</h2>
      </header>
      {checkFeature(FEATURES.LANDING_PRODUCER_INTRO) && (
        <Container>
          <Row>
            <p>{getString(STRING_KEYS.LANDING_PRODUCER_INTRO)}</p>
          </Row>
        </Container>
      )}
      <Container className="cta">
        <Row>
          <AnlagenCheckButton />
          <JetztAnmeldenButton />
        </Row>
      </Container>
      <Container className="arrow">
        <Row>
          <Col className="flex-center">
            <a href="#strom-verkaufen-main">
              <img src={downArrowImage} alt="Untengehen" />
            </a>
          </Col>
        </Row>
      </Container>
      <main id="strom-verkaufen-main">
        <Section1 />
        <Section2 />
        <Section3 />
      </main>
      <footer>
        <h2>{getString(STRING_KEYS.LANDING_PRODUCER_FOOTER)}</h2>
        <Button
          className="potential-check-bottom"
          color="primary"
          size="lg"
          tag={Link}
          to="/strom-verkaufen/potential-check"
        >
          Anlage jetzt anmelden
        </Button>
      </footer>
    </div>
  );
};

function AnlagenCheckButton() {
  return (
    <div className="anlagen-check-outer">
      <Link
        to="/strom-verkaufen/potential-check"
        className="anlagen-check-link link"
      >
        <div className="anlagen-check-button button">
          <AnlagenCheckButtonImage />
        </div>
        <h3>Anlagen-Check</h3>
      </Link>
      <div className="subtext">
        <span>
          {getString(STRING_KEYS.LANDING_PRODUCER_ANLAGEN_CHECK_CAPTION)}
        </span>
      </div>
    </div>
  );
}

function JetztAnmeldenButton() {
  return (
    <Link
      to="/strom-verkaufen/registrieren"
      className="jetzt-anmelden-link link"
    >
      <div className="jetzt-anmelden-button button">
        <JetztAnmeldenButtonImage />
      </div>
      <h3>Anlage jetzt anmelden</h3>
    </Link>
  );
}

function Section1() {
  const showSecondSubsection = CURRENT_THEME === THEMES.CELLS_ENERGY;
  let image, imageClassName;

  switch (CURRENT_THEME) {
    case THEMES.CELLS_ENERGY:
      image = cellsEnergyImageGroup1;
      imageClassName = "double-cell";
      break;
    case THEMES.SWL:
      image = swlSection1Image;
      imageClassName = "single-cell";
      break;
    case THEMES.OBERLAND17:
      image = oberland17Section1Image;
      imageClassName = "single-cell";
      break;
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      image = cellsEnergyImageGroup1;
      imageClassName = "double-cell";
      break;
  }

  return (
    <section className="odd">
      <Container>
        <Row>
          <Col className="photos-column" xs="12" lg="6">
            <img
              src={image}
              className={imageClassName}
              alt={getString(STRING_KEYS.LANDING_PRODUCER_INFO_1_TITLE)}
            />
            {CURRENT_THEME === THEMES.CELLS_ENERGY && (
              <React.Fragment>
                <img
                  src={solarCellImage}
                  className="double-cell-horizontal"
                  alt={getString(STRING_KEYS.LANDING_PRODUCER_INFO_1_TITLE)}
                />
                <img
                  src={customerCellImage}
                  className="double-cell-horizontal"
                  alt={getString(STRING_KEYS.LANDING_PRODUCER_INFO_2_TITLE)}
                />
              </React.Fragment>
            )}
          </Col>
          <Col className="text-column dmsp-column" xs="12" lg="6">
            <div className="header-and-text dmsp-text">
              <h2>{getString(STRING_KEYS.LANDING_PRODUCER_INFO_1_TITLE)}</h2>
              <MarkdownString
                stringKey={STRING_KEYS.LANDING_PRODUCER_INFO_1_TEXT}
              />
            </div>
            {showSecondSubsection && (
              <div className="header-and-text">
                <h2>{getString(STRING_KEYS.LANDING_PRODUCER_INFO_2_TITLE)}</h2>
                <MarkdownString
                  stringKey={STRING_KEYS.LANDING_PRODUCER_INFO_2_TEXT}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

function Section2() {
  return (
    <section className="even">
      <Container>
        <Row>
          <Col
            className={classnames("photos-column", {
              "triple-cell": CURRENT_THEME === THEMES.CELLS_ENERGY
            })}
            xs="12"
            lg="6"
          >
            <Section2Image />
          </Col>
          {CURRENT_THEME === THEMES.CELLS_ENERGY && (
            <Col className="photos-column triple-cell-horizontal">
              <img
                src={secureCellImage}
                alt={getString(STRING_KEYS.LANDING_PRODUCER_INFO_3_TITLE)}
              />
              <img
                src={easyCellImage}
                alt={getString(STRING_KEYS.LANDING_PRODUCER_INFO_4_TITLE)}
              />
              <img
                src={moneyCellImage}
                alt={getString(STRING_KEYS.LANDING_PRODUCER_INFO_5_TITLE)}
              />
            </Col>
          )}
          <Col className="text-column" xs="12" lg="6">
            <Section2Text />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

function Section3() {
  const altText = getString(STRING_KEYS.LANDING_PRODUCER_INFO_3_TITLE);
  let image;

  switch (CURRENT_THEME) {
    case THEMES.CELLS_ENERGY:
      image = cellsEnergySection3Image;
      break;
    case THEMES.SWL:
      image = swlSection3Image;
      break;
    case THEMES.OBERLAND17:
      image = oberland17Section3Image;
      break;
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      image = cellsEnergySection3Image;
      break;
  }

  return (
    <section className="odd">
      <Container>
        <Row>
          <Col className="photos-column" xs="12" lg="6">
            <img src={image} className="single-cell" alt={altText} />
          </Col>
          <Col className="text-column" xs="12" lg="6">
            <div className="header-and-text">
              {CURRENT_THEME === THEMES.SWL ? (
                <MarkdownString
                  stringKey={STRING_KEYS.LANDING_PRODUCER_INFO_3_TEXT}
                  openLinksInNewWindow
                />
              ) : (
                <React.Fragment>
                  {CURRENT_THEME === THEMES.OBERLAND17 && (
                    <h2 className="extra-title">
                      {getString(STRING_KEYS.LANDING_PRODUCER_INFO_3_TITLE)}
                    </h2>
                  )}
                  <h2>
                    {getString(STRING_KEYS.LANDING_PRODUCER_INFO_6_TITLE)}
                  </h2>
                  <MarkdownString
                    stringKey={STRING_KEYS.LANDING_PRODUCER_INFO_6_TEXT}
                    openLinksInNewWindow
                  />
                </React.Fragment>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

function AnlagenCheckButtonImage() {
  switch (CURRENT_THEME) {
    case THEMES.SWL:
      return <img src={swlAnlageCheckImage} width="196" alt="Anlagen-Check" />;
    case THEMES.OBERLAND17:
      return (
        <img src={oberland17AnlageCheckImage} width="196" alt="Anlagen-Check" />
      );
    default:
      return (
        <React.Fragment>
          <svg className="anlagen-check-image image" />
          <svg className="anlagen-check-check symbol" />
        </React.Fragment>
      );
  }
}

function JetztAnmeldenButtonImage() {
  switch (CURRENT_THEME) {
    case THEMES.SWL:
      return (
        <img src={swlAnlageCheckPlusImage} width="196" alt="Anlagen-Check" />
      );
    case THEMES.OBERLAND17:
      return (
        <img
          src={oberland17AnlageJetztAnmeldenImage}
          width="196"
          alt="Anlagen-Check"
        />
      );
    default:
      return (
        <React.Fragment>
          <svg className="jetzt-anmelden-image image" />
          <svg className="jetzt-anmelden-plus symbol" />
        </React.Fragment>
      );
  }
}

function Section2Image() {
  switch (CURRENT_THEME) {
    case THEMES.CELLS_ENERGY:
      return (
        <img
          src={cellsEnergyImageGroup2}
          alt={getString(STRING_KEYS.LANDING_PRODUCER_INFO_3_TITLE)}
        />
      );
    case THEMES.SWL:
      return (
        <img
          src={swlSection2Image}
          className="single-cell"
          alt={getString(STRING_KEYS.LANDING_PRODUCER_INFO_2_TITLE)}
        />
      );
    case THEMES.OBERLAND17:
      return (
        <div className="section2-image-group">
          <div className="row1">
            <img
              src={oberland17Section2Image1}
              className="section2-image"
              alt={getString(STRING_KEYS.LANDING_PRODUCER_INFO_2_TITLE)}
            />
            <img
              src={oberland17Section2OneHundredImage}
              className="icon-one-hundred"
              alt="100%"
            />
          </div>
          <div className="row2">
            <img
              src={oberland17Section2Image2}
              className="section2-image"
              alt={getString(STRING_KEYS.LANDING_PRODUCER_INFO_3_TITLE)}
            />
          </div>
        </div>
      );
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      return (
        <img
          src={cellsEnergyImageGroup2}
          alt={getString(STRING_KEYS.LANDING_PRODUCER_INFO_3_TITLE)}
        />
      );
  }
}

function Section2Text() {
  switch (CURRENT_THEME) {
    case THEMES.SWL:
      return (
        <div className="header-and-text">
          <MarkdownString
            stringKey={STRING_KEYS.LANDING_PRODUCER_INFO_2_TEXT}
          />
        </div>
      );
    case THEMES.OBERLAND17:
      return (
        <div className="header-and-text">
          <MarkdownString
            stringKey={STRING_KEYS.LANDING_PRODUCER_INFO_2_TEXT}
          />
        </div>
      );
    default:
      return (
        <React.Fragment>
          <div className="header-and-text">
            <h2>{getString(STRING_KEYS.LANDING_PRODUCER_INFO_3_TITLE)}</h2>
            <MarkdownString
              stringKey={STRING_KEYS.LANDING_PRODUCER_INFO_3_TEXT}
            />
          </div>
          <div className="header-and-text">
            <h2>{getString(STRING_KEYS.LANDING_PRODUCER_INFO_4_TITLE)}</h2>
            <MarkdownString
              stringKey={STRING_KEYS.LANDING_PRODUCER_INFO_4_TEXT}
            />
          </div>
          <div className="header-and-text">
            <h2>{getString(STRING_KEYS.LANDING_PRODUCER_INFO_5_TITLE)}</h2>
            <MarkdownString
              stringKey={STRING_KEYS.LANDING_PRODUCER_INFO_5_TEXT}
            />
          </div>
        </React.Fragment>
      );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated
  };
}

export default connect(mapStateToProps)(LandingProducer);
