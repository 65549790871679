import React, { Component } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { Field, formValueSelector, reduxForm } from "redux-form";

import { PLANT_TYPES } from "../../../constants.js";

export const PLANT_TYPE_NOT_SELECT = "Anlagentyp wählen";

class PlantType extends Component {
  render() {
    const { errorMessage } = this.props;

    return (
      <div>
        <Input
          type="select"
          tag={Field}
          name="plantType"
          component="select"
          invalid={!!errorMessage}
        >
          <option>{PLANT_TYPE_NOT_SELECT}</option>
          {PLANT_TYPES.map((type) => (
            <option key={type}>{type}</option>
          ))}
        </Input>
        {errorMessage && <span className="error">{errorMessage}</span>}
      </div>
    );
  }
}

let reduxFormPlantType = reduxForm({
  form: "potentialCheck",
  destroyOnUnmount: false
})(PlantType);

const selector = formValueSelector("potentialCheck");
reduxFormPlantType = connect((state) => {
  const plantType = selector(state, "plantType");
  return {
    plantType
  };
})(reduxFormPlantType);

export { reduxFormPlantType as PlantType };
