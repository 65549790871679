import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Row
} from "reactstrap";
import {
  Field,
  formValueSelector,
  getFormSubmitErrors,
  reduxForm,
  reset,
  SubmissionError
} from "redux-form";

import { contactFormSendAction } from "../actions";
import { CellCheckbox } from "../components/CellCheckbox.js";
import { InputField } from "../components/InputField";

import {
  CURRENT_THEME,
  EMPTY_DROPDOWN,
  EMAIL_MAX_LENGTH,
  TITLES,
  FIRST_NAME_MAX_LENGTH,
  SURNAME_MAX_LENGTH,
  COMPANY_NAME_MAX_LENGTH,
  TELEPHONE_NUMBER_MAX_LENGTH,
  THEMES,
  EMAIL_REG_EX,
  STRING_KEYS
} from "../constants.js";
import { getString, MarkdownString } from "../utils";

import "./Contact.scss";

const MESSAGE_MAX_LENGTH = 100000;

function Contact({
  submitErrors,
  responseError,
  handleSubmit,
  onSubmitContactForm
}) {
  useEffect(() => {
    const iframe = document.getElementById(
      "EPilot-Iframe-5f74ddfa-aa9b-4c35-90bb-263a8852b115"
    );

    function receiveMessage(e) {
      const token = "5f74ddfa-aa9b-4c35-90bb-263a8852b115";

      if (e.data.token === token) {
        iframe.style.height = e.data.height + "px";
      }
    }

    window.addEventListener("message", receiveMessage, false);

    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, []);

  return (
    <div className="Contact page">
      <header>
        <Container>
          <Row>
            <h1>Kontakt</h1>
          </Row>
        </Container>
      </header>
      <main>
        <Container>
          {CURRENT_THEME === THEMES.SWL ? (
            <Row>
              <SwlContactForm />
            </Row>
          ) : (
            <Row>
              <StandardContactForm
                submitErrors={submitErrors}
                responseError={responseError}
                onSubmit={handleSubmit}
                onSubmitContactForm={onSubmitContactForm}
              />
            </Row>
          )}
        </Container>
      </main>
    </div>
  );
}

function StandardContactForm({
  submitErrors,
  responseError,
  onSubmit,
  onSubmitContactForm
}) {
  const [privacyCheckboxAccepted, setPrivacyCheckboxAccepted] = useState(false);
  const [privacyCheckboxErrorText, setPrivacyCheckboxErrorText] =
    useState(null);
  const [showFormResult, setShowFormResult] = useState(false);

  function handlePrivacyCheckboxChange(checked) {
    setPrivacyCheckboxAccepted(checked);
    setPrivacyCheckboxErrorText(null);
  }

  function handleSubmit(data) {
    // in case the user is submitted the form again for some reason, clear the success state
    setShowFormResult(false);

    if (!privacyCheckboxAccepted) {
      setPrivacyCheckboxErrorText(
        getString(STRING_KEYS.ERROR_AGREEMENT_MISSING)
      );
      throw new SubmissionError({});
    }

    const errors = validateAfterSubmit(data);
    if (Object.keys(errors).length > 0) {
      window.scrollTo(0, 0);
      throw new SubmissionError(errors);
    }

    return onSubmitContactForm(data).then(() => {
      setPrivacyCheckboxAccepted(false);
      setShowFormResult(true);

      if (responseError) {
        throw new SubmissionError(responseError);
      }
    });
  }

  function validateAfterSubmit(values) {
    const errors = {};

    if (!values.title || values.title === EMPTY_DROPDOWN) {
      errors.title = getString(STRING_KEYS.ERROR_TITLE_MISSING);
    }

    if (!values.firstName) {
      errors.firstName = getString(STRING_KEYS.ERROR_FIRST_NAME_MISSING);
    }

    if (!values.lastName) {
      errors.lastName = getString(STRING_KEYS.ERROR_LAST_NAME_MISSING);
    }

    if (!values.email || !EMAIL_REG_EX.test(values.email)) {
      errors.email = getString(STRING_KEYS.ERROR_EMAIL_INVALID);
    }

    if (!values.message) {
      errors.message = getString(STRING_KEYS.ERROR_MESSAGE_MISSING);
    }

    return errors;
  }

  function getErrorByField(field) {
    if (submitErrors[field]) {
      return submitErrors[field];
    } else if (responseError && responseError[field]) {
      return responseError[field];
    }

    return null;
  }

  function getErrorTextFromResponseError(error) {
    if (typeof error === "string") {
      return error;
    } else {
      return getString(STRING_KEYS.ERROR_INVALID_DATA);
    }
  }

  return (
    <React.Fragment>
      <Col lg={8}>
        <Form onSubmit={onSubmit(handleSubmit)}>
          <Container className="contract-fields">
            <Row>
              <Col xs={12} md={5}>
                <FormGroup>
                  <Label for="title">Anrede</Label>
                  <Input
                    type="select"
                    tag={Field}
                    id="title"
                    name="title"
                    component="select"
                    className="shrink"
                    invalid={!!getErrorByField("title")}
                  >
                    <option>{EMPTY_DROPDOWN}</option>
                    {TITLES.map((title) => (
                      <option key={title}>{title}</option>
                    ))}
                  </Input>
                  {getErrorByField("title") && (
                    <span className="error">{getErrorByField("title")}</span>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} md={5}>
                <InputField
                  name="companyOrOwner"
                  label="Firma"
                  type="text"
                  maxLength={COMPANY_NAME_MAX_LENGTH}
                  error={getErrorByField("companyOrOwner")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={5}>
                <InputField
                  name="firstName"
                  label="Vorname"
                  type="text"
                  maxLength={FIRST_NAME_MAX_LENGTH}
                  required
                  error={getErrorByField("firstName")}
                />
              </Col>
              <Col xs={12} md={5}>
                <InputField
                  name="lastName"
                  label="Nachname"
                  type="text"
                  maxLength={SURNAME_MAX_LENGTH}
                  required
                  error={getErrorByField("lastName")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={5}>
                <InputField
                  name="telephoneNumber"
                  label="Telefonnummer"
                  type="text"
                  maxLength={TELEPHONE_NUMBER_MAX_LENGTH}
                  error={getErrorByField("telephoneNumber")}
                />
              </Col>
              <Col xs={12} md={5}>
                <InputField
                  name="email"
                  label="E-Mail-Adresse"
                  type="email"
                  maxLength={EMAIL_MAX_LENGTH}
                  required
                  error={getErrorByField("email")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={10}>
                <Label for="message">{getString(STRING_KEYS.CONTACT_MESSAGE_LABEL)}</Label>
                <InputField
                  type="textarea"
                  tag={Field}
                  name="message"
                  id="message"
                  component="textarea"
                  placeholder=""
                  maxLength={MESSAGE_MAX_LENGTH}
                  required
                  error={getErrorByField("message")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={10}>
                <CellCheckbox
                  id="privacy-checkbox"
                  formGroupClassName="privacy-checkbox-formgroup"
                  checked={privacyCheckboxAccepted}
                  textComponent={
                    <MarkdownString
                      stringKey={STRING_KEYS.CONTACT_PRIVACY}
                      openLinksInNewWindow
                    />
                  }
                  errors={privacyCheckboxErrorText}
                  onChange={handlePrivacyCheckboxChange}
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs={12} md={10} className="submit-button-container">
                <Button
                  color={"primary"}
                  onClick={onSubmit((data) => handleSubmit(data))}
                >
                  Abschicken
                </Button>
              </Col>
            </Row>
            {showFormResult && (
              <Row>
                <Col xs={12} md={10}>
                  <Alert
                    color={!!responseError ? "danger" : "success"}
                    className="form-success"
                  >
                    <p>
                      {!!responseError
                        ? getErrorTextFromResponseError(responseError)
                        : getString(STRING_KEYS.CONTACT_SUCCESS)}
                    </p>
                  </Alert>
                </Col>
              </Row>
            )}
          </Container>
        </Form>
      </Col>
      <Col lg="4">
        <MarkdownString stringKey={STRING_KEYS.CONTACT_INFO} />
      </Col>
    </React.Fragment>
  );
}

function SwlContactForm({}) {
  return (
    <iframe
      id="EPilot-Iframe-5f74ddfa-aa9b-4c35-90bb-263a8852b115"
      src="https://frontend.epilot.cloud/frontends?token=5f74ddfa-aa9b-4c35-90bb-263a8852b115"
      width="100%"
      height="700"
      scrolling="no"
      frameborder="0"
      name="epilot_iframe"
    ></iframe>
  );
}

let reduxFormContact = reduxForm({
  form: "contactForm",
  onSubmitSuccess: function (result, dispatch) {
    dispatch(reset("contactForm"));
  }
})(Contact);

const selector = formValueSelector("contactForm");
reduxFormContact = connect((state) => {
  const title = selector(state, "title");
  const firstName = selector(state, "firstName");
  const lastName = selector(state, "lastName");
  const companyOrOwner = selector(state, "companyOrOwner");
  const email = selector(state, "email");
  const telephoneNumber = selector(state, "telephoneNumber");
  const message = selector(state, "message");

  return {
    title,
    firstName,
    lastName,
    companyOrOwner,
    email,
    telephoneNumber,
    message
  };
})(reduxFormContact);

reduxFormContact = connect((state) => ({
  submitErrors: getFormSubmitErrors("contactForm")(state)
}))(reduxFormContact);

function mapStateToProps(state) {
  return {
    responseSuccess: state.responses.contactFormSuccess,
    responseError: state.responses.contactFormError
  };
}

const mapDispatchToProps = (dispatch) => ({
  onSubmitContactForm: (id, values) =>
    dispatch(contactFormSendAction(id, values)),
  resetContactForm: () => dispatch(reset("contactForm"))
});

const connectedReduxFormContact = connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxFormContact);

export { connectedReduxFormContact as Contact };
