import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";

import Login from "./Login";
import { signInAction, forgotPasswordAction, signOutAction } from "../actions";
import { STRING_KEYS } from "../constants";
import { getString } from "../utils";

class NavAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavbarOpen: false,
      isLoginOpen: false
    };
  }

  toggleLogin = () => {
    this.setState({
      isLoginOpen: !this.state.isLoginOpen
    });
  };

  closeLogin = () => {
    this.setState({
      isLoginOpen: false
    });
  };

  handleClickLogin = () => {
    this.toggleLogin();
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.authenticationRequested &&
      !prevProps.authenticationRequested &&
      !this.state.isLoginOpen
    ) {
      this.toggleLogin();
    }
  }

  render() {
    const {
      location,
      authenticated,
      onLogin,
      onForgotPassword,
      onLogout,
      errorMessage
    } = this.props;

    const loginText = getString(STRING_KEYS.LOGIN_TITLE);

    // for now, only show the login/logout link on the producer (strom-verkaufen) page
    const showLoginLink = location.pathname.indexOf("strom-verkaufen") > 0;
    const loginLogout = authenticated ? (
      <NavLink onClick={onLogout} className="navlink-onclick">
        Abmelden
      </NavLink>
    ) : (
      <NavLink onClick={this.handleClickLogin} className="navlink-onclick">
        {loginText}
      </NavLink>
    );
    const navItem = showLoginLink ? loginLogout : null;

    return (
      <NavItem>
        {navItem}
        {this.state.isLoginOpen && (
          <Login
            isOpen={this.state.isLoginOpen}
            onLogin={onLogin}
            onForgotPassword={onForgotPassword}
            onClose={this.closeLogin}
            errorMessage={errorMessage}
            authenticated={authenticated}
          />
        )}
      </NavItem>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    errorMessage: state.auth.error,
    user: state.auth.user,
    authenticationRequested: state.auth.authenticationRequested
  };
}

const mapDispatchToProps = (dispatch) => ({
  onLogin: (values) => dispatch(signInAction(values)),
  onForgotPassword: (values) => dispatch(forgotPasswordAction(values)),
  onLogout: () => dispatch(signOutAction())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavAuth)
);
