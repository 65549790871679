import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";

import { BackButton } from "../../components/BackButton";
import { setMixOptionsAction } from "../../actions";
import {
  CURRENT_THEME,
  STRING_KEYS,
  THEMES,
  USER_TYPES
} from "../../constants.js";
import { getString } from "../../utils.js";
import "./WhyDoYouNeedPower.scss";

import cellsHouseImage from "../../images/icons/icon-big-privathaus.png";
import cellsBusinessImage from "../../images/icons/icon-big-gewerbe.png";
import swlHouseImage from "../../images/swl/consumer-flow/icon-big-privathaus.png";
import swlBusinessImage from "../../images/swl/consumer-flow/icon-big-gewerbe.png";
import oberland17HouseImage from "../../images/oberland17/consumer-flow/Icon_Haus_privat.jpg";
import oberland17BusinessImage from "../../images/oberland17/consumer-flow/Icon_Haus_gewerbe.jpg";

function ConsumerType({ name, imgUrl, onClickConsumerType }) {
  return (
    <div className="user-type" onClick={onClickConsumerType}>
      <img src={imgUrl} alt={name} />
    </div>
  );
}

class WhyDoYouNeedPower extends Component {
  handleChangeConsumerType = (type) => {
    this.props.setConsumerType(type);
    this.props.onGoToNextStep();
  };

  render() {
    const { onGoBack } = this.props;
    let houseImage, businessImage;

    switch (CURRENT_THEME) {
      case THEMES.CELLS_ENERGY:
        houseImage = cellsHouseImage;
        businessImage = cellsBusinessImage;
        break;
      case THEMES.SWL:
        houseImage = swlHouseImage;
        businessImage = swlBusinessImage;
        break;
      case THEMES.OBERLAND17:
        houseImage = oberland17HouseImage;
        businessImage = oberland17BusinessImage;
        break;
      default:
        console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
        houseImage = cellsHouseImage;
        businessImage = cellsBusinessImage;
        break;
    }

    return (
      <Container className="WhyDoYouNeedPower">
        <Row>
          <Col>
            <h3>{getString(STRING_KEYS.WHY_POWER_TITLE)}</h3>
          </Col>
        </Row>
        <Row>
          <Col className="user-types" xs={12}>
            <ConsumerType
              name={USER_TYPES.private}
              imgUrl={houseImage}
              onClickConsumerType={() =>
                this.handleChangeConsumerType(USER_TYPES.private)
              }
            />
            <ConsumerType
              name={USER_TYPES.business}
              imgUrl={businessImage}
              onClickConsumerType={() =>
                this.handleChangeConsumerType(USER_TYPES.business)
              }
            />
          </Col>
        </Row>
        <BackButton onClick={onGoBack} />
      </Container>
    );
  }
}

WhyDoYouNeedPower.propTypes = {
  onGoToNextStep: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    consumerType: state.mix.consumerType
  };
}

const mapDispatchToProps = (dispatch) => ({
  setConsumerType: (consumerType) =>
    dispatch(setMixOptionsAction({ consumerType }))
});

const connectedWhyDoYouNeedPower = connect(
  mapStateToProps,
  mapDispatchToProps
)(WhyDoYouNeedPower);

export { connectedWhyDoYouNeedPower as WhyDoYouNeedPower };
