import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container } from "reactstrap";

import PotentialCheckPlantType from "./PotentialCheckPlantType";
import { PotentialCheckDmsp } from "./PotentialCheckDmsp";
import PotentialCheckMargin from "./PotentialCheckMargin";
import PotentialCheckSummary from "./PotentialCheckSummary";
import ProgressTracker from "../../components/ProgressTracker";
import { BackButton } from "../../components/BackButton";

import "./PotentialCheck.css";

export default class PotentialCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1
    };
  }

  handleGoToNextStep = () => {
    this.setState({
      currentStep: this.state.currentStep + 1
    });
  };

  handleGoBack = () => {
    this.setState({
      currentStep: this.state.currentStep - 1
    });
  };

  handleGoToStep = (step) => {
    this.setState({
      currentStep: step
    });
  };

  calculateYearlyEstimate(plantMargin, plantYearlyEnergy) {
    let estimate = "???";

    if (plantMargin && plantMargin >= 0 && plantMargin <= 100) {
      estimate = ((plantMargin * plantYearlyEnergy) / 100) * 0.8;
    }

    return estimate;
  }

  renderStep(step) {
    switch (step) {
      case 1:
        return (
          <PotentialCheckPlantType onGoToNextStep={this.handleGoToNextStep} />
        );
      case 2:
        return <PotentialCheckDmsp onGoToNextStep={this.handleGoToNextStep} />;
      case 3:
        return (
          <PotentialCheckMargin
            onGoToNextStep={this.handleGoToNextStep}
            calculateYearlyEstimate={this.calculateYearlyEstimate}
          />
        );
      case 4:
        return (
          <PotentialCheckSummary
            onGoToNextStep={this.handleGoToNextStep}
            calculateYearlyEstimate={this.calculateYearlyEstimate}
          />
        );
      default:
        return <Redirect to="registrieren" push={true} />;
    }
  }

  render() {
    const { currentStep } = this.state;

    return (
      <Container className="PotentialCheck page">
        <ProgressTracker
          currentStep={currentStep}
          steps={[
            "Anlage",
            "Direktvermarkter",
            "Investitionsbonus",
            "Zusammenfassung"
          ]}
          onGoToStep={this.handleGoToStep}
        />
        <div className="progress-tracker-spacing"></div>
        {this.renderStep(currentStep)}
        {currentStep > 1 && <BackButton onClick={this.handleGoBack} />}
      </Container>
    );
  }
}
