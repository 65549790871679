import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import { CollapsibleInformation } from "./CollapsibleInformation";
import { MarginControl } from "../components/MarginControl";
import {
  STRING_KEYS,
  USER_TYPES,
  VAT_FACTORS,
  VAT_INFO_TEXTS
} from "../constants";
import { setCustomMarginAction } from "../actions";
import {
  getProducerDisplayName,
  MarkdownString,
  Price,
  VatMarker
} from "../utils";

import "./PlantMargins.scss";

const MAX_CUSTOM_MARGIN = 4.0;

class PlantMargins extends Component {
  handleSetMargin = (plantId, margin) => {
    this.props.setCustomMargin(plantId, margin);
    this.props.onUpdateMargin();
  };

  render() {
    const {
      plants,
      plantCoverages,
      customMargins,
      consumerType,
      yearlyEnergy
    } = this.props;

    return (
      <CollapsibleInformation title="Betreiberzuschuss">
        <MarkdownString stringKey={STRING_KEYS.PLANT_MARGINS_TEXT} />
        <Container className="plant-margins">
          {plants &&
            plants.map((plant, index) => (
              <Row key={plant.id}>
                <Col
                  className="plant-name"
                  style={{ textAlign: "left" }}
                  sm="6"
                >{`${index + 1}. ${getProducerDisplayName(plant)}:`}</Col>
                <Col
                  className="plant-margin-per-year"
                  style={{ textAlign: "right" }}
                >
                  <PlantMarginPerYear
                    margin={
                      (customMargins && customMargins[plant.id]) ||
                      plant.plantMargin
                    }
                    yearlyEnergy={yearlyEnergy}
                    coverage={plantCoverages[plant.id]}
                    consumerType={consumerType}
                  />
                </Col>
                <Col className="plant-margin">
                  <MarginControl
                    margin={
                      (customMargins && customMargins[plant.id]) ||
                      plant.plantMargin
                    }
                    minMargin={plant.plantMargin}
                    maxMargin={MAX_CUSTOM_MARGIN}
                    onIncreaseMargin={(margin) =>
                      this.handleSetMargin(plant.id, margin)
                    }
                    onDecreaseMargin={(margin) =>
                      this.handleSetMargin(plant.id, margin)
                    }
                  />
                </Col>
              </Row>
            ))}
          <Row>
            <Col>
              <div className="vat-info">
                <VatMarker />
                {VAT_INFO_TEXTS[consumerType]}
              </div>
            </Col>
          </Row>
        </Container>
      </CollapsibleInformation>
    );
  }
}

function PlantMarginPerYear({ margin, yearlyEnergy, coverage, consumerType }) {
  const eurPerYear = margin / 100;
  const energyPortion = yearlyEnergy * coverage;
  const marginEurPerYear = eurPerYear * energyPortion;
  const marginEurPerYearWithVat = Math.max(
    marginEurPerYear * VAT_FACTORS[consumerType],
    0
  );

  return (
    <Price
      value={marginEurPerYearWithVat}
      unit="€/Jahr"
      hideVatMarker={consumerType === USER_TYPES.business}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    customMargins: state.mix.customMargins
  };
}

const mapDispatchToProps = (dispatch) => ({
  setCustomMargin: (plantId, margin) =>
    dispatch(setCustomMarginAction(plantId, margin))
});

const connectedPlantMargins = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlantMargins);

export { connectedPlantMargins as PlantMargins };
