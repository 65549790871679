import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";
import { Field, formValueSelector, reduxForm } from "redux-form";

class EnergyAmountPerYear extends Component {
  render() {
    const { plantYearlyEnergy, errorMessage } = this.props;

    return (
      <FormGroup className="plantYearlyEnergy">
        <Label for="plantYearlyEnergy">
          (eingespeiste) Energiemenge pro Jahr (in kWh)
        </Label>
        <Input
          type="number"
          tag={Field}
          name="plantYearlyEnergy"
          id="plantYearlyEnergy"
          component="input"
          min="0"
          invalid={
            !!errorMessage || (plantYearlyEnergy && plantYearlyEnergy < 0)
          }
        />
        {errorMessage && <span className="error">{errorMessage}</span>}
      </FormGroup>
    );
  }
}

let reduxFormEnergyAmountPerYear = reduxForm({
  form: "potentialCheck",
  destroyOnUnmount: false
})(EnergyAmountPerYear);

const selector = formValueSelector("potentialCheck");
reduxFormEnergyAmountPerYear = connect((state) => {
  const plantYearlyEnergy = selector(state, "plantYearlyEnergy");
  return {
    plantYearlyEnergy
  };
})(reduxFormEnergyAmountPerYear);

export { reduxFormEnergyAmountPerYear as EnergyAmountPerYear };
