import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";
import { Field, formValueSelector, reduxForm } from "redux-form";

import { InfoIcon } from "../../../components/InfoIcon";
import "./HasRLMMeter.css";

export const HAS_RLM_METER_YES = "Ja";
export const HAS_RLM_METER_NO = "Nein";

class HasRLMMeter extends Component {
  render() {
    const { errorMessage } = this.props;

    return (
      <FormGroup className="has-rlm-meter">
        <Label>
          Findet eine Lastgangmessung (RLM) statt?{" "}
          <InfoIcon tooltipName="rlm-info-tooltip">
            <p>
              Eine registrierende Leistungsmessung (RLM) erfasst einen
              ¼-stundengenauen Lastgang. Bei Anlagen ab 100 kW und für
              Direktvermarktung ist diese Messung vorgeschrieben (alternativ
              kann auch ein Smart Meter verwendet werden). Im Zweifel gibt der
              Verteilnetzbetreiber Auskunft.
            </p>
          </InfoIcon>
        </Label>
        <Input
          type="select"
          tag={Field}
          name="plantMeteredInFifteenMinuteIntervals"
          component="select"
          invalid={!!errorMessage}
        >
          <option>{this.props.emptyDropdown}</option>
          <option value={true}>{HAS_RLM_METER_YES}</option>
          <option value={false}>{HAS_RLM_METER_NO}</option>
        </Input>
        {errorMessage && <span className="error">{errorMessage}</span>}
      </FormGroup>
    );
  }
}

let reduxFormHasRLMMeter = reduxForm({
  form: "potentialCheck",
  destroyOnUnmount: false
})(HasRLMMeter);

const selector = formValueSelector("potentialCheck");
reduxFormHasRLMMeter = connect((state) => {
  const plantMeteredInFifteenMinuteIntervals = selector(
    state,
    "plantMeteredInFifteenMinuteIntervals"
  );
  return {
    plantMeteredInFifteenMinuteIntervals
  };
})(reduxFormHasRLMMeter);

export { reduxFormHasRLMMeter as HasRLMMeter };
