import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";

import { STRING_KEYS } from "../constants";
import { getString } from "../utils";

export default class ConfirmEmail extends Component {
  render() {
    return (
      <div className="ConfirmEmail page" style={{ textAlign: "center" }}>
        <header>
          <Container>
            <Row style={{ justifyContent: "center" }}>
              <h1>Vielen Dank!</h1>
            </Row>
          </Container>
        </header>
        <main>
          <Container>
            <Row>
              <Col>
                <p>{getString(STRING_KEYS.CONFIRM_EMAIL_SUCCESS)}</p>
                <Button
                  color="primary"
                  tag={Link}
                  to="/strom-verkaufen/meine-anlagen"
                >
                  {getString(STRING_KEYS.CONFIRM_EMAIL_BUTTON)}
                </Button>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}
