import { GRID_OPERATORS_GET } from "../actions";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case GRID_OPERATORS_GET:
      return action.payload.gridOperators;
    default:
      return state;
  }
}
