import React, { Component } from "react";
import { Collapse } from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./CollapsibleInformation.scss";

class CollapsibleInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { title, children } = this.props;

    const { collapse } = this.state;

    return (
      <div className="CollapsibleInformation">
        <header
          onClick={this.toggle}
          className={classnames({ show: collapse })}
        >
          <span className="title">{title}</span>
          <span className="icon">
            <FontAwesomeIcon icon={collapse ? "times" : "plus"} />
          </span>
        </header>
        <Collapse isOpen={collapse}>
          <div className="body">{children}</div>
        </Collapse>
      </div>
    );
  }
}

export { CollapsibleInformation };
