/* global THEME_VARS */
import React, { Component, useState, PureComponent } from "react";
import {
  Alert,
  Button,
  Card,
  CardFooter,
  CardImg,
  CardText,
  CardTitle,
  CardSubtitle,
  Col,
  Container,
  CustomInput,
  Label,
  Input,
  Modal,
  ModalBody,
  Row
} from "reactstrap";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { DragDropContext, Droppable, Draggable } from "@react-forked/dnd";
import classnames from "classnames";
import Dotdotdot from "react-dotdotdot";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";

import "./CreateMix.scss";
import { ChooseProducersFromMap } from "./ChooseProducersFromMap";
import {
  CUSTOM_MARGIN_UPDATE_TIMEOUT,
  CUSTOM_MIX_NAME,
  ENERGY_TYPES,
  getEnergyTypeFromPlantType
} from "../../constants.js";
import {
  generatePresetMixesAction,
  getPowerPlantDistancesFromPostcodeAction,
  getPowerPlantsAction,
  setMixOptionsAction,
  storeMixAction,
  updateCustomMixAction
} from "../../actions";
import {
  getMixDisplayName,
  getPowerPlantMainImage,
  getProducerDisplayName
} from "../../utils.js";
import { BackButton } from "../../components/BackButton";
import { CollapsibleInformation } from "../../components/CollapsibleInformation";
import { ExtendedMixInfoModal } from "./ExtendedMixInfoModal";
import { checkFeature, FEATURES } from "../../features";
import {
  calculateAdditionalMargins,
  getMixPowerPlantsFromMixIdsAndProducerPowerPlants,
  getString,
  LinkToProfilePageWithBack,
  Loader,
  powerPlantCircleIconsByType,
  powerPlantSelectedIconsByType,
  powerPlantMapSelectedIconsByType,
  Price,
  VatMarker
} from "../../utils";
import {
  MAX_PLANTS_PER_MIX,
  METERING_TYPES,
  STRING_KEYS,
  USER_TYPES,
  VAT_FACTORS,
  VAT_INFO_TEXTS
} from "../../constants";

const HOVER_GREY = ["#676767"];

class CreateMix extends Component {
  constructor(props) {
    super(props);

    this.customMarginUpdateTimer = null;

    this.state = {
      searchString: "",
      hoveredMixPlantIndex: null,
      mixLoadError: false
    };
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // reorder the dragged item
    const powerPlantIds = this.reorderPlantInMix(
      result.source.index,
      result.destination.index
    );

    // update data
    this.setAndUpdateMix(CUSTOM_MIX_NAME, powerPlantIds);
  };

  handleReorderPlantInMix = (fromIndex, toIndex) => {
    // reorder the dragged item
    const powerPlantIds = this.reorderPlantInMix(fromIndex, toIndex);

    // update data
    this.setAndUpdateMix(CUSTOM_MIX_NAME, powerPlantIds);
  };

  reorderPlantInMix(fromIndex, toIndex) {
    const powerPlantIds = Array.from(this.props.mixPowerPlantIds);
    const [removed] = powerPlantIds.splice(fromIndex, 1);
    powerPlantIds.splice(toIndex, 0, removed);

    return powerPlantIds;
  }

  handleAddToMix = (plantId) => {
    const powerPlantIds = Array.from(this.props.mixPowerPlantIds);

    // if the mix is already full, get rid of the last one
    if (powerPlantIds.length === MAX_PLANTS_PER_MIX) {
      powerPlantIds.pop();
    }

    powerPlantIds.push(plantId);
    this.setAndUpdateMix(CUSTOM_MIX_NAME, powerPlantIds);
  };

  handleRemoveFromMix = (plantId) => {
    // reorder the dragged item
    const powerPlantIds = this.props.mixPowerPlantIds.filter(
      (mixPlantId) => mixPlantId !== plantId
    );
    this.setAndUpdateMix(CUSTOM_MIX_NAME, powerPlantIds);
  };

  setAndUpdateMix = (mixName, powerPlantIds) => {
    this.props.storeMix({
      powerPlants: powerPlantIds,
      mixName: mixName
    });

    if (mixName === CUSTOM_MIX_NAME) {
      this.doUpdateCustomMixData(powerPlantIds);
    }
  };

  isConsumerFlowDataAvailable() {
    const queryParams = queryString.parse(this.props.location.search);
    return (this.props.postcode || queryParams.uuid) && this.props.consumerType;
  }

  isMixDataAvailable() {
    return (
      this.props.mixData.selectedMixName &&
      this.props.mixPowerPlantIds &&
      this.props.producerPowerPlantDistances
    );
  }

  matchStringAgainstPowerPlantData(string, plant) {
    const lowerCaseSearchString = string.toLowerCase();
    return (
      plant.displayName.toLowerCase().indexOf(lowerCaseSearchString) >= 0 ||
      plant.name.toLowerCase().indexOf(lowerCaseSearchString) >= 0 ||
      plant.plantType.toLowerCase().indexOf(lowerCaseSearchString) >= 0
    );
  }

  handleUpdateCustomMargins = () => {
    if (this.customMarginUpdateTimer) {
      clearTimeout(this.customMarginUpdateTimer);
    }

    this.customMarginUpdateTimer = setTimeout(() => {
      this.customMarginUpdateTimer = null;
      this.doUpdateCustomMixData(this.props.mixPowerPlantIds);
    }, CUSTOM_MARGIN_UPDATE_TIMEOUT);
  };

  handleHoverProducer = (selectedPlantIndex, hovered) => {
    this.setState({
      hoveredMixPlantIndex: hovered ? selectedPlantIndex : null
    });
  };

  handleMixTypeChange = (mixName) => {
    const { mixData } = this.props;

    let mix;
    if (mixName === CUSTOM_MIX_NAME) {
      // use the current power plants for the basis of the custom mix
      mix = mixData.mixes[mixData.selectedMixName];
    } else {
      mix = mixData.mixes[mixName];
    }

    const powerPlantIds = Array.from(mix.powerPlants);
    this.setAndUpdateMix(mixName, powerPlantIds);
  };

  handleChangeAllowedEnergyTypes = (allowedTypes) => {
    this.props
      .setMixOptions({
        allowedEnergyTypes: allowedTypes
      })
      .then(() => {
        if (this.props.mixData.selectedMixName === CUSTOM_MIX_NAME) {
          // simply remove the disallowed power plants from the mix
          const powerPlantIds =
            this.props.mixData.mixes[CUSTOM_MIX_NAME].powerPlants;
          const powerPlants = this.props.producerPowerPlants.filter((plant) =>
            powerPlantIds.includes(plant.id)
          );
          const filteredPowerPlants =
            this.filterPowerPlantsByAllowedEnergyTypes(
              powerPlants,
              allowedTypes
            );
          const filteredPowerPlantIds = filteredPowerPlants.map(
            (filtered) => filtered.id
          );
          this.setAndUpdateMix(CUSTOM_MIX_NAME, filteredPowerPlantIds);
        }

        // update the preset mixes
        this.gatherOptionsAndGeneratePresetMixes();
      });
  };

  filterPowerPlantsByAllowedEnergyTypes(powerPlants, allowedEnergyTypes) {
    return powerPlants.filter((plant) => {
      const plantEnergyType = ENERGY_TYPES.find(
        (type) => type.name === getEnergyTypeFromPlantType(plant.plantType)
      );
      const plantEnergyTypeDbName = plantEnergyType.dbName;
      return allowedEnergyTypes.includes(plantEnergyTypeDbName);
    });
  }

  handleChangeSearchString = (string) => {
    this.setState({
      searchString: string
    });
  };

  doUpdateCustomMixData(powerPlantIds) {
    const mixOptions = {
      consumerType: this.props.consumerType,
      powerPlants: powerPlantIds,
      additionalMargins: calculateAdditionalMargins(
        this.props.customMargins,
        this.props.producerPowerPlants
      )
    };

    if (this.props.rlmUuid) {
      mixOptions.historicalLoadProfile = this.props.rlmUuid;
    } else {
      mixOptions.postcode = this.props.postcode;
      mixOptions.yearlyEnergy = this.props.consumerYearlyEnergy;
    }

    return this.props.updateCustomMixData(mixOptions);
  }

  areCreateMixOptionsAvailable() {
    return (
      (this.props.mixPostcode || this.props.rlmUuid) &&
      this.props.consumerType &&
      this.props.allowedEnergyTypes
    );
  }

  gatherOptionsAndGeneratePresetMixes() {
    const mixOptions = {
      consumerType: this.props.consumerType,
      allowedEnergyTypes: this.props.allowedEnergyTypes,
      additionalMargins: calculateAdditionalMargins(
        this.props.customMargins,
        this.props.producerPowerPlants
      )
    };

    if (this.props.rlmUuid) {
      mixOptions.historicalLoadProfile = this.props.rlmUuid;
    } else {
      mixOptions.postcode = this.props.mixPostcode;
      mixOptions.yearlyEnergy = this.props.consumerYearlyEnergy;
    }

    return this.props.generatePresetMixes(mixOptions).then(() => {
      if (this.props.firstPowerPlantIds) {
        this.setAndUpdateMix(CUSTOM_MIX_NAME, this.props.firstPowerPlantIds);
      } else {
        const selectedMix =
          this.props.mixData.mixes[this.props.mixData.selectedMixName];
        if (selectedMix) {
          const powerPlants = selectedMix.powerPlants;
          this.setAndUpdateMix(this.props.mixData.selectedMixName, powerPlants);

          if (this.props.rlmUuid) {
            this.props.getPowerPlantDistancesFromPostcode({
              postcode: selectedMix.postcode
            });
          }
        } else {
          // this happens if the server did not return any mixes for some reason
          this.setState({
            mixLoadError: true
          });
        }
      }
    });
  }

  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams.uuid) {
      // a uuid is specific for a RLM customer
      this.props
        .setMixOptions({
          consumerType: USER_TYPES.business,
          rlmUuid: queryParams.uuid,
          meteringType: METERING_TYPES.rlm
        })
        .then(() => this.doInitialPresetMixGeneration());
    } else {
      this.doInitialPresetMixGeneration();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.rlmUuid !== this.props.rlmUuid) {
      this.doInitialPresetMixGeneration();
    }
  }

  doInitialPresetMixGeneration() {
    if (this.areCreateMixOptionsAvailable()) {
      this.gatherOptionsAndGeneratePresetMixes().then(function () {
        window.scrollTo(0, 140);
      });
      this.props.getPowerPlants();
    }
  }

  render() {
    const {
      consumerType,
      mixData,
      mixInfo = mixData.mixes[mixData.selectedMixName],
      mixPowerPlantIds,
      producerPowerPlants,
      producerPowerPlantDistances,
      allowedEnergyTypes
    } = this.props;

    const { searchString, hoveredMixPlantIndex, mixLoadError } = this.state;

    // redirect back to consumer landing page, if required data is missing
    if (!this.isConsumerFlowDataAvailable()) {
      return <Redirect to="/strom-kaufen" />;
    } else if (mixLoadError) {
      return (
        <Alert color="danger">
          Es ist ein Fehler aufgetreten. Bitte versuch die Seite neuzuladen.
        </Alert>
      );
    } else if (!this.isMixDataAvailable()) {
      return (
        <Container className="CreateMix page">
          <Row>
            <Col>
              <Loader />
            </Col>
          </Row>
        </Container>
      );
    }

    const mixPowerPlants = getMixPowerPlantsFromMixIdsAndProducerPowerPlants(
      mixPowerPlantIds,
      producerPowerPlants
    );

    let filteredPowerPlants = producerPowerPlants
      ? [...producerPowerPlants]
      : [];

    // filter out anything not matching allowedEnergyTypes
    filteredPowerPlants = this.filterPowerPlantsByAllowedEnergyTypes(
      filteredPowerPlants,
      allowedEnergyTypes
    );
    // sort by distance
    filteredPowerPlants.sort(
      (a, b) =>
        producerPowerPlantDistances[a.id] - producerPowerPlantDistances[b.id]
    );

    // filter out anything not matching the search string, if there is one
    if (searchString.length > 0) {
      filteredPowerPlants = filteredPowerPlants.filter((plant) =>
        this.matchStringAgainstPowerPlantData(searchString, plant)
      );
    }

    const vatFactor = VAT_FACTORS[consumerType];
    const monthlyCosts = mixInfo ? (vatFactor * mixInfo.yearlyCosts) / 12 : 0;
    // todo: move all the below stuff to Mix Übersicht
    const isCTAButtonDisabled = !mixPowerPlants || mixPowerPlants.length === 0;
    const mixIsFull = mixPowerPlantIds.length === MAX_PLANTS_PER_MIX;
    const mixList = Object.keys(mixData.mixes);

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Container className="CreateMix page">
          <Row>
            <Col>
              <h2>{getString(STRING_KEYS.CREATE_MIX_TITLE)}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{getString(STRING_KEYS.CREATE_MIX_INTRO)}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <MixOptions
                allowedEnergyTypes={allowedEnergyTypes}
                mixes={mixList}
                selectedMixName={mixData.selectedMixName}
                onMixTypeChange={this.handleMixTypeChange}
                onChangeAllowedEnergyTypes={this.handleChangeAllowedEnergyTypes}
              />
            </Col>
          </Row>
          <Row className="producers-row">
            <Col md={6} className="producers-col">
              {mixPowerPlants && mixPowerPlants.length === 0 && (
                <div className="no-plants-in-mix">
                  {getString(STRING_KEYS.CREATE_MIX_CHOOSE_PLANT)}
                </div>
              )}
              <MixProducers
                mixPowerPlants={mixPowerPlants}
                mixInfo={mixInfo}
                producerPowerPlantDistances={producerPowerPlantDistances}
                hoveredMixPlantIndex={hoveredMixPlantIndex}
                onRemoveFromMix={this.handleRemoveFromMix}
                onReorderPlantInMix={this.handleReorderPlantInMix}
                onHoverProducer={this.handleHoverProducer}
              />
            </Col>
            <Col md={6}>
              {!mixInfo && (
                <div className="info-and-map-loader">
                  <Loader />
                </div>
              )}
              {mixInfo && mixPowerPlants && (
                <MixSummary
                  mixData={mixData}
                  selectedMix={mixInfo}
                  monthlyCosts={monthlyCosts}
                  vatFactor={vatFactor}
                  consumerType={consumerType}
                  selectedMixPlants={mixPowerPlants}
                  disableCTAButton={isCTAButtonDisabled}
                  hoveredMixPlantIndex={hoveredMixPlantIndex}
                  onHoverMixChart={this.handleHoverProducer}
                  onUpdateCustomMargins={this.handleUpdateCustomMargins}
                />
              )}
              {mixInfo && (
                <div className="map-container-outer">
                  <ChooseProducersFromMap
                    producers={filteredPowerPlants}
                    producerDistances={producerPowerPlantDistances}
                    producersInMix={mixPowerPlantIds}
                    panToPostcode={mixInfo.postcode}
                    mixIsFull={mixIsFull}
                    onAddToMix={this.handleAddToMix}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row className="other-producers-header">
            <Col>
              <h3>Andere Lieferanten Auswählen</h3>
            </Col>
          </Row>
          <Row>
            <Col className="other-producers">
              <OtherProducers
                filteredPowerPlants={filteredPowerPlants}
                producerPowerPlantDistances={producerPowerPlantDistances}
                mixPowerPlantIds={mixPowerPlantIds}
                mixIsFull={mixIsFull}
                allowedEnergyTypes={allowedEnergyTypes}
                searchString={searchString}
                onChangeAllowedEnergyTypes={this.handleChangeAllowedEnergyTypes}
                onChangeSearchString={this.handleChangeSearchString}
                onAddToMix={this.handleAddToMix}
              />
            </Col>
          </Row>
          {checkFeature(FEATURES.CREATE_MIX_COLLAPSIBLE_INFO) && (
            <CollapsibleInformations />
          )}
          <BackButton to="/strom-kaufen/fragen/wie-viel-strom-brauchst-du" />
        </Container>
      </DragDropContext>
    );
  }
}

function MixOptions({
  allowedEnergyTypes,
  mixes,
  selectedMixName,
  onMixTypeChange,
  onChangeAllowedEnergyTypes
}) {
  return (
    <div className="mix-options">
      <MixType
        mixes={mixes}
        selectedMixName={selectedMixName}
        onChange={onMixTypeChange}
      />
      <EnergyTypes
        allowedTypes={allowedEnergyTypes}
        onChangeAllowedTypes={onChangeAllowedEnergyTypes}
      />
    </div>
  );
}

function MixProducers(props) {
  const {
    mixPowerPlants,
    mixInfo,
    producerPowerPlantDistances,
    hoveredMixPlantIndex,
    onRemoveFromMix,
    onReorderPlantInMix,
    onHoverProducer
  } = props;

  return (
    <React.Fragment>
      <div className="producers-drag-and-drop">
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <div
              className="producers"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {mixPowerPlants &&
                mixPowerPlants.map((plant, index) => (
                  <Draggable
                    key={plant.id}
                    draggableId={plant.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="producer-outer"
                      >
                        <div className="controls">
                          <FontAwesomeIcon
                            icon="arrow-up"
                            className={classnames("move-up", {
                              disabled: index === 0
                            })}
                            onClick={
                              index > 0
                                ? () => onReorderPlantInMix(index, index - 1)
                                : null
                            }
                          />
                          <FontAwesomeIcon
                            icon="times"
                            className="remove"
                            onClick={() => onRemoveFromMix(plant.id)}
                          />
                          <FontAwesomeIcon
                            icon="arrow-down"
                            className={classnames("move-down", {
                              disabled: index >= mixPowerPlants.length - 1
                            })}
                            onClick={
                              index < mixPowerPlants.length - 1
                                ? () => onReorderPlantInMix(index, index + 1)
                                : null
                            }
                          />
                        </div>
                        <MixProducer
                          id={plant.id}
                          name={getProducerDisplayName(plant)}
                          slogan={plant.name}
                          city={plant.plantCity}
                          type={plant.plantType}
                          coverage={
                            mixInfo &&
                            mixInfo.powerPlantCoveragePercentages[plant.id]
                              ? mixInfo.powerPlantCoveragePercentages[plant.id]
                              : ""
                          }
                          distance={producerPowerPlantDistances[plant.id]}
                          img={getPowerPlantMainImage(plant)}
                          order={index + 1}
                          showHoverColour={index === hoveredMixPlantIndex}
                          onRemoveFromMix={() => onRemoveFromMix(plant.id)}
                          onHover={(hovering) =>
                            onHoverProducer(index, hovering)
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      <div className="producers-mobile">
        <div className="producers">
          {mixPowerPlants &&
            mixPowerPlants.map((plant, index) => (
              <div className="producer-outer" key={index}>
                <MixProducer
                  id={plant.id}
                  name={getProducerDisplayName(plant)}
                  slogan={plant.name}
                  city={plant.plantCity}
                  type={plant.plantType}
                  coverage={
                    mixInfo && mixInfo.powerPlantCoveragePercentages[plant.id]
                      ? mixInfo.powerPlantCoveragePercentages[plant.id]
                      : ""
                  }
                  distance={producerPowerPlantDistances[plant.id]}
                  img={getPowerPlantMainImage(plant)}
                  order={index + 1}
                  onMoveUp={
                    index > 0
                      ? () => onReorderPlantInMix(index, index - 1)
                      : null
                  }
                  onMoveDown={
                    index < mixPowerPlants.length - 1
                      ? () => onReorderPlantInMix(index, index + 1)
                      : null
                  }
                  onRemoveFromMix={() => onRemoveFromMix(plant.id)}
                />
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
}

function MixType({ mixes, selectedMixName, onChange }) {
  const mixesWithoutCustomMix = mixes.filter((mix) => mix !== CUSTOM_MIX_NAME);

  return (
    <div className="mix-type">
      <Label for="select-mix-type">Aktueller Mix:</Label>
      <Input
        type="select"
        id="select-mix-type"
        name="select-mix-type"
        value={selectedMixName}
        onChange={(e) => onChange(e.target.value)}
      >
        {mixesWithoutCustomMix.map((mix) => (
          <option value={mix} key={mix}>
            {getMixDisplayName(mix)}
          </option>
        ))}
        <option value={CUSTOM_MIX_NAME}>Meine Wahl</option>
      </Input>
    </div>
  );
}

function EnergyTypes({ allowedTypes, onChangeAllowedTypes }) {
  return (
    <div className="energy-types">
      {ENERGY_TYPES.map((energyType) => (
        <CustomInput
          className="energy-type"
          key={energyType.name}
          type="switch"
          id={`energyType${energyType.name}`}
          label={energyType.name}
          checked={allowedTypes.includes(energyType.dbName)}
          onChange={(e) => {
            if (e.target.checked) {
              const newAllowedTypes = [...allowedTypes];
              newAllowedTypes.push(energyType.dbName);
              onChangeAllowedTypes(newAllowedTypes);
            } else {
              onChangeAllowedTypes(
                allowedTypes.filter((type) => type !== energyType.dbName)
              );
            }
          }}
        />
      ))}
    </div>
  );
}

function MixProducer(props) {
  return (
    <div
      className={classnames("producer", {
        [`producer-hover-${props.order}`]: props.showHoverColour
      })}
      onMouseEnter={props.onHover ? () => props.onHover(true) : null}
      onMouseLeave={props.onHover ? () => props.onHover(false) : null}
    >
      <header>
        <img src={props.img} alt={props.name} />
      </header>
      <main>
        <span className="name">{`${props.order}. ${props.name}`}</span>
        <Dotdotdot clamp={2} className="slogan">
          {`${props.slogan}`}
        </Dotdotdot>
        <span className="plant-type-distance">
          {props.city}:{" "}
          {props.distance.toLocaleString("de-DE", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1
          })}{" "}
          km entfernt
        </span>
      </main>
      <div className="plant-type-icon">
        <img
          src={powerPlantMapSelectedIconsByType[props.type]}
          alt={props.type}
        />
      </div>
      <div className="plant-icons">
        <div className="close-button" onClick={props.onRemoveFromMix}>
          <FontAwesomeIcon icon="times" />
        </div>
      </div>
      <footer
        className={classnames({ hide: !props.onMoveUp && !props.onMoveDown })}
      >
        <FontAwesomeIcon
          icon="arrow-up"
          className="move-up"
          onClick={!!props.onMoveUp ? props.onMoveUp : null}
        />
        <FontAwesomeIcon
          icon="arrow-down"
          className="move-down"
          onClick={!!props.onMoveDown ? props.onMoveDown : null}
        />
        <FontAwesomeIcon
          icon="times"
          className="remove"
          onClick={props.onRemoveFromMix}
        />
      </footer>
    </div>
  );
}

function CollapsibleInformations() {
  return (
    <React.Fragment>
      <Row className="marketplace-explanation">
        <Col xs="12" md="6" lg="6">
          <CollapsibleInformation title="Erklär mir den Marktplatz!">
            <p>
              Mit den Standard-Mixen kannst du ganz einfach bestimmen, was dir
              besonders wichtig ist. Der elektronische Marktplatz-Agent wählt
              dann automatisch die besten Anlagen für Dich aus.
            </p>
            <p>
              Da die Sonne nicht immer scheint und der Wind nicht immer weht,
              ergibt sich eine Deckungsrate. So viel Strom ist voraussichtlich
              direkt aus deinem Mix lieferbar – den Rest kaufen wir vorerst an
              der Strombörse, später aus einem Gesamt-Markplatz-Mix.
            </p>
            <p>
              Super! Jetzt kannst Du gleich deinen Marktplatzstrom bestellen,
              oder vorher den Mix anpassen und selbst die 5 Lieferanten deines
              Vertrauens wählen.
            </p>
          </CollapsibleInformation>
        </Col>
      </Row>
      <Row className="no-power-explanation">
        <Col xs="12" md="6" lg="6">
          <CollapsibleInformation title="Was passiert, wenn mein Betreiber keine staatliche Förderung mehr bekommt?">
            <p>
              Ab dem Tag, ab dem einer deiner Betreiber keine gesetzliche
              Vergütung mehr bekommt, liefert er automatisch Grünstrom direkt
              aus der Anlage an dich. Das geht ohne große Umstellung, denn
              virtuell läuft die Anlage bei uns die ganze Zeit als dein
              Lieferant mit. So stellen wir sicher, dass alles reibungslos
              klappt, sobald die Direktlieferung beginnt.
            </p>
            <p>
              Und du siehst bis dahin bereits in deiner Abrechnung, wie die
              Belieferung in Kürze wirklich laufen wird (Hinweis: das Ergebnis
              der virtuellen Belieferung beeinflusst nicht die Höhe des
              Betreiberzuschuss und dient nur der Information).
            </p>
          </CollapsibleInformation>
        </Col>
      </Row>
      <Row className="egalstrom-text">
        <Col xs="12" md="6" lg="6">
          <CollapsibleInformation title='Ersatzlieferung für Anlagen mit staatlicher Förderung ("Egalstrom")'>
            <p>
              Wir dürfen dir Strom aus Anlagen, die noch eine staatliche
              Förderung erhalten, nicht direkt weiterleiten. Die Belieferung
              erfolgt daher virtuell und du erhältst von renergie eine
              Ersatzlieferung nach dem deutschen Strommix (Graustrom). Auf dem
              cells energy Marktplatz gibt es kein „greenwashing“, du förderst
              immer den Ausbau von Bürgerenergieanlagen. Und zwar nicht von
              irgendwelchen, sondern genau von den 5 Betreibern deines
              Vertrauens. Sobald eine Anlage in deinem Mix keine Förderung mehr
              erhält, erfolgt die Lieferung direkt mit Grünstromzertifikat aus
              den von dir gewählten Anlagen. Vorerst erhalten alle Anlagen noch
              eine Förderung.
            </p>
          </CollapsibleInformation>
        </Col>
      </Row>
      <Row className="custom-mix-explanation">
        <Col xs="12" md="6" lg="6">
          <CollapsibleInformation title="Erklär mir den Mix-Konfigurator!">
            <div>
              <p>
                Willkommen im Mix-Konfigurator! Hier wählst du die 5 Lieferanten
                deines Vertrauens!
              </p>
              <p>
                Deine Anlagen haben eine Reihenfolge, die sogenannte
                „Merit-Order“. In jeder ¼-Stunde wird zuerst Strom aus der
                Anlage auf Position 1 an Dich geliefert, deinem Haupt-Lieferant.
                Erst wenn dort kein Strom mehr verfügbar ist, springen die
                übrigen Anlagen ein. Sollte auch Nummer 5 ausverkauft sein,
                liefern wir dir vorübergehend Börsenstrom, bald aber den
                Gesamt-Markplatz-Mix.
              </p>
              <p>
                Du kannst die Anordnung durch ziehen der Profile frei verändern
                oder Anlagen löschen. Hast du leere Plätze, kannst du in der
                Liste unten oder auf der Landkarte gezielt Anlagen hinzu wählen.
              </p>
              <p>Jetzt viel Spaß beim Konfigurieren!</p>
            </div>
          </CollapsibleInformation>
        </Col>
      </Row>
    </React.Fragment>
  );
}

function OtherProducers(props) {
  const {
    filteredPowerPlants,
    producerPowerPlantDistances,
    mixPowerPlantIds,
    mixIsFull,
    allowedEnergyTypes,
    searchString,
    onChangeAllowedEnergyTypes,
    onChangeSearchString,
    onAddToMix
  } = props;

  return (
    <React.Fragment>
      <div className="sorting-controls">
        <div className="search-string-control">
          <div className="sort-by-search-label">
            <span>Suchen nach:</span>
          </div>
          <div className="sort-by-search">
            <Input
              type="search"
              placeholder="Suche"
              value={searchString}
              onChange={(e) => onChangeSearchString(e.target.value)}
            />
          </div>
        </div>
        <EnergyTypes
          allowedTypes={allowedEnergyTypes}
          onChangeAllowedTypes={onChangeAllowedEnergyTypes}
        />
      </div>
      <div className="detailed-producers">
        {filteredPowerPlants &&
          filteredPowerPlants.map((plant, index) => (
            <ProducerDetailed
              key={plant.id}
              id={plant.id}
              slug={plant.slugName}
              name={getProducerDisplayName(plant)}
              slogan={plant.name}
              city={plant.plantCity}
              type={plant.plantType}
              distance={producerPowerPlantDistances[plant.id]}
              img={getPowerPlantMainImage(plant)}
              inMix={mixPowerPlantIds.includes(plant.id)}
              mixIsFull={mixIsFull}
              onAddToMix={() => onAddToMix(plant.id)}
            />
          ))}
      </div>
      {(!filteredPowerPlants || filteredPowerPlants.length === 0) && (
        <div className="no-plants">Keine passende Lieferanten gefunden.</div>
      )}
    </React.Fragment>
  );
}

function ProducerDetailed(props) {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const toggleConfirmModal = function () {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const handleClickConfirm = function () {
    props.onAddToMix(props.id);
    toggleConfirmModal();
  };

  return (
    <Card
      className={classnames("producer-detailed", { "in-mix": props.inMix })}
    >
      <LinkToProfilePageWithBack id={props.id} slug={props.slug}>
        <CardImg top width="100%" src={props.img} alt={props.name} />
      </LinkToProfilePageWithBack>
      <CardTitle className="plant-name">
        <LinkToProfilePageWithBack id={props.id} slug={props.slug}>
          {props.name}
        </LinkToProfilePageWithBack>
      </CardTitle>
      <CardSubtitle className="slogan">{props.slogan}</CardSubtitle>
      <CardText>
        {props.city}
        <br />
        {props.distance.toLocaleString("de-DE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1
        })}{" "}
        km entfernt
      </CardText>
      <CardFooter>
        <Button
          color="success"
          disabled={props.inMix}
          onClick={props.mixIsFull ? toggleConfirmModal : props.onAddToMix}
        >
          {props.inMix ? "Ausgewählt" : "Zum Mix hinzufügen"}
        </Button>
      </CardFooter>
      <div className="plant-type">
        <img
          src={
            props.inMix
              ? powerPlantSelectedIconsByType[props.type]
              : powerPlantCircleIconsByType[props.type]
          }
          alt={props.type}
        />
      </div>
      <Modal
        isOpen={isConfirmModalOpen}
        toggle={toggleConfirmModal}
        centered={true}
      >
        <ModalBody style={{ textAlign: "center" }}>
          <p>{getString(STRING_KEYS.FULL_MIX)}</p>
          <Button onClick={handleClickConfirm} color="primary">
            Ja
          </Button>
          &nbsp;
          <Button onClick={toggleConfirmModal} color="secondary">
            Nein
          </Button>
        </ModalBody>
      </Modal>
    </Card>
  );
}

class MixSummary extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chartContext: null
    };
  }

  componentDidUpdate(prevProps) {
    // disable for now, so we can release the current version
    // if (prevProps.hoveredMixPlantIndex !== this.props.hoveredMixPlantIndex && this.state.chartContext) {
    //   // select new point, if there is one to select
    //   if (this.props.hoveredMixPlantIndex !== null) {
    //     this.state.chartContext.toggleDataPointSelection(this.props.hoveredMixPlantIndex);
    //   }
    //   // de-select old point, if there was one selected
    //   if (prevProps.hoveredMixPlantIndex !== null) {
    //     this.state.chartContext.toggleDataPointSelection(prevProps.hoveredMixPlantIndex);
    //   }
    // }
  }

  render() {
    const {
      mixData,
      selectedMix,
      monthlyCosts,
      vatFactor,
      consumerType,
      selectedMixPlants,
      disableCTAButton,
      onHoverMixChart,
      onUpdateCustomMargins
    } = this.props;

    if (!mixData || !selectedMix) {
      return null;
    }

    const mixInfo = mixData.mixes[mixData.selectedMixName];
    const hoverColours = [
      THEME_VARS.default.producerColour1,
      THEME_VARS.default.producerColour2,
      THEME_VARS.default.producerColour3,
      THEME_VARS.default.producerColour4,
      THEME_VARS.default.producerColour5
    ];

    const coverageChartOptions = {
      chart: {
        events: {
          mounted: function (chartContext) {
            this.setState({
              chartContext: chartContext
            });
          }.bind(this),
          dataPointMouseEnter: function (event, chartContext, config) {
            onHoverMixChart(config.dataPointIndex, true);
          },
          dataPointMouseLeave: function (event, chartContext, config) {
            onHoverMixChart(config.dataPointIndex, false);
          }
        }
      },
      labels: selectedMixPlants
        .map((plant) => getProducerDisplayName(plant))
        .concat(["Rest"]),
      legend: {
        show: false
      },
      colors: hoverColours
        .slice(0, selectedMixPlants.length)
        .concat(HOVER_GREY),
      dataLabels: {
        style: {
          fontSize: "13px"
        },
        formatter: function (value) {
          return Math.round(value) + "%";
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%"
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (value) {
            if (value > 1) {
              return value + "%";
            } else {
              return "< 1%";
            }
          }
        }
      }
    };

    const coverageChartSeries = selectedMix.powerPlants.map((plantId) =>
      Math.round(selectedMix.powerPlantCoveragePercentages[plantId] * 100)
    );
    const amountNotCovered = Object.values(
      selectedMix.powerPlantCoveragePercentages
    ).reduce((a, b) => a - b, 1);
    if (amountNotCovered > 0) {
      coverageChartSeries.push(Math.round(amountNotCovered * 100));
    }

    return (
      <div className="create-mix-summary">
        <div className="inner">
          <main>
            <div className="mix-chart">
              <h4>
                {getString(STRING_KEYS.CREATE_MIX_MIX_CHART_TITLE_1)}
                <br />
                {getString(STRING_KEYS.CREATE_MIX_MIX_CHART_TITLE_2)}
              </h4>
              <ReactApexChart
                options={coverageChartOptions}
                series={coverageChartSeries}
                type="donut"
                width="200"
              />
            </div>
            <div className="info-and-buttons">
              <div className="info">
                <div className="yearly-costs">
                  <h4>
                    <Price value={monthlyCosts} unit="€/Monat" />
                  </h4>
                </div>
                <span>
                  {selectedMix.averageDistance.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1
                  })}{" "}
                  km mittlere Entfernung
                </span>
              </div>
              <div className="cta-button-container">
                <CTAButton disabled={disableCTAButton} />
              </div>
              {mixInfo && (
                <ExtendedMixInfoButton
                  mixInfo={mixInfo}
                  mixPowerPlants={selectedMixPlants}
                  monthlyCosts={monthlyCosts}
                  vatFactor={vatFactor}
                  onUpdateCustomMargins={onUpdateCustomMargins}
                />
              )}
            </div>
          </main>
          <footer className="vat-info">
            <VatMarker />
            {VAT_INFO_TEXTS[consumerType]}
          </footer>
        </div>
      </div>
    );
  }
}

function ExtendedMixInfoButton({
  mixInfo,
  mixPowerPlants,
  monthlyCosts,
  vatFactor,
  onUpdateCustomMargins
}) {
  const energyPrice = mixInfo
    ? vatFactor * mixInfo.tariffStructure.energyPrice
    : 0;
  const mixSurcharge = mixInfo
    ? vatFactor * mixInfo.tariffStructure.fixedSurcharge
    : 0;
  const baseTariff = mixInfo
    ? vatFactor * mixInfo.tariffStructure.baseTariff
    : 0;
  const mixPowerPrice = mixInfo
    ? vatFactor * mixInfo.tariffStructure.powerPrice
    : 0;
  const mixMaxPower = mixInfo ? vatFactor * mixInfo.maxPower : 0;

  return (
    <div className="extended-info-button-container">
      <ExtendedMixInfoModal
        monthlyCosts={monthlyCosts}
        mixYearlyEnergy={mixInfo.yearlyEnergy}
        mixPlants={mixPowerPlants}
        mixPlantCoverages={mixInfo.powerPlantCoveragePercentages}
        mixPostcode={mixInfo.postcode}
        energyPrice={energyPrice}
        mixSurcharge={mixSurcharge}
        baseTariff={baseTariff}
        mixPowerPrice={mixPowerPrice}
        mixMaxPower={mixMaxPower}
        onUpdateCustomMargins={onUpdateCustomMargins}
        showCustomMargins
      />
    </div>
  );
}

function CTAButton(props) {
  return (
    <Button
      color="primary"
      tag={Link}
      to="/strom-kaufen/wechseln"
      disabled={props.disabled}
    >
      Mix bestellen
    </Button>
  );
}

function mapStateToProps(state) {
  return {
    mixData: state.mix,
    mixPostcode: state.mix.postcode,
    mixPowerPlantIds: state.mix.powerPlants,
    mixInfo: state.mix.mixes.custom,
    customMargins: state.mix.customMargins,
    producerPowerPlants: state.powerPlants.powerPlants,
    producerPowerPlantDistances: state.powerPlants.distances,
    postcode: state.mix.postcode,
    consumerType: state.mix.consumerType,
    consumerYearlyEnergy: state.mix.yearlyEnergy,
    allowedEnergyTypes: state.mix.allowedEnergyTypes,
    firstPowerPlantIds: state.mix.firstPowerPlantIds,
    rlmUuid: state.mix.rlmUuid
  };
}

const mapDispatchToProps = (dispatch) => ({
  getPowerPlants: () => dispatch(getPowerPlantsAction()),
  generatePresetMixes: (data) => dispatch(generatePresetMixesAction(data)),
  storeMix: (powerPlants, mixName) =>
    dispatch(storeMixAction(powerPlants, mixName)),
  setMixOptions: (options) => dispatch(setMixOptionsAction(options)),
  getPowerPlantDistancesFromPostcode: (postcode) =>
    dispatch(getPowerPlantDistancesFromPostcodeAction(postcode)),
  updateCustomMixData: (data) => dispatch(updateCustomMixAction(data))
});

const connectedCreateMix = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMix);

export { connectedCreateMix as CreateMix };
