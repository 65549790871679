import {
  CONTRACT_CREATED,
  CONTRACT_CREATION_ERROR,
  CONTRACT_RESET,
  MY_CONTRACTS_GET,
  MY_CONTRACTS_GET_BY_ID,
  MY_CONTRACTS_GET_BY_ID_ERROR,
  RESET_MY_CONTRACTS_GET_BY_ID_ERROR,
  MY_CONTRACTS_UPDATE,
  POWER_PLANT_IMAGES_POST,
  POWER_PLANT_IMAGES_POST_ERROR,
  POWER_PLANT_IMAGE_DELETE
} from "../actions";

const initialState = {};

export default function (state = initialState, action) {
  let contracts;

  switch (action.type) {
    case CONTRACT_CREATED:
      return { ...state, contractCreated: true, data: action.payload }; // todo
    case CONTRACT_CREATION_ERROR:
      return { ...state, contractCreated: false, error: action.payload };
    case CONTRACT_RESET:
      return { ...state, contractCreated: false };
    case MY_CONTRACTS_GET:
      return { ...state, contracts: action.payload.contracts };
    case MY_CONTRACTS_GET_BY_ID:
    case MY_CONTRACTS_UPDATE:
      contracts = state.contracts
        ? [...state.contracts]
        : [action.payload.contract];
      for (let i = 0; i < contracts.length; i++) {
        if (contracts[i].id === action.payload.contract.id) {
          contracts[i] = action.payload.contract;
        }
      }

      return { ...state, contracts: contracts, contractGetError: false };
    case MY_CONTRACTS_GET_BY_ID_ERROR:
      return { ...state, contractGetError: true };
    case RESET_MY_CONTRACTS_GET_BY_ID_ERROR:
      return { ...state, contractGetError: false };
    case POWER_PLANT_IMAGES_POST:
      contracts = [...state.contracts];
      for (let i = 0; i < contracts.length; i++) {
        if (contracts[i].id === action.payload.plantId) {
          contracts[i] = { ...contracts[i] }; // create a new object, so it looks updated
          contracts[i].images = [action.payload.image].concat(
            contracts[i].images
          );
          break;
        }
      }

      return { ...state, contracts: contracts };
    case POWER_PLANT_IMAGES_POST_ERROR:
      return { ...state, imageUploadError: action.payload.response };
    case POWER_PLANT_IMAGE_DELETE:
      // look for the contract with the deleted image and remove it
      for (let i = 0; i < state.contracts.length; i++) {
        let deletedImage = state.contracts[i].images.find(
          (image) => image.id === action.payload.imageId
        );
        if (deletedImage) {
          contracts = [...state.contracts]; // create new object to force re-render
          contracts[i] = { ...contracts[i] };
          contracts[i].images = contracts[i].images.filter(
            (image) => image.id !== deletedImage.id
          );
          return { ...state, contracts: contracts };
        }
      }

      return state;
    default:
      return state;
  }
}
