import React, { Component } from "react";
import { FormGroup, Label, Input } from "reactstrap";

import { EIGHTEN_YEARS_AGO } from "../constants";
import { OptionalMarker } from "../utils";

import "./Birthdate.scss";

class Birthdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      day: "",
      month: "",
      year: ""
    };
  }

  handleChangeDay = (e) => {
    this.setState(
      {
        day: parseInt(e.target.value, 10)
      },
      this.updateBirthdate
    );
  };

  handleChangeMonth = (e) => {
    this.setState(
      {
        month: parseInt(e.target.value, 10)
      },
      this.updateBirthdate
    );
  };

  handleChangeYear = (e) => {
    this.setState(
      {
        year: parseInt(e.target.value, 10)
      },
      this.updateBirthdate
    );
  };

  updateBirthdate = () => {
    // only sent out an updated Date for Birthdate if all fields filled in correctly
    if (
      this.state.day !== null &&
      this.state.day !== "" &&
      this.validateDay(this.state.day) &&
      this.state.month !== null &&
      this.state.month !== "" &&
      this.validateMonth(this.state.month) &&
      this.state.year !== null &&
      this.state.year !== "" &&
      this.validateYear(this.state.year) &&
      this.state.year > 999
    ) {
      this.props.onUpdateBirthdate(
        new Date(this.state.year, this.state.month - 1, this.state.day)
      );
    } else {
      this.props.onUpdateBirthdate(null);
    }
  };

  validateDay(day) {
    return day === "" || (day >= 1 && day <= 31);
  }

  validateMonth(month) {
    return month === "" || (month >= 1 && month <= 12);
  }

  validateYear(year) {
    return (
      year === "" ||
      year <= 999 ||
      (year >= 1900 && year <= EIGHTEN_YEARS_AGO.getFullYear())
    );
  }

  setStateFromProps() {
    const value = this.props.value;
    this.setState({
      day: value.getDate(),
      month: value.getMonth() + 1,
      year: value.getFullYear()
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.value instanceof Date &&
      (!(prevProps.value instanceof Date) ||
        prevProps.value.getTime() !== this.props.value.getTime())
    ) {
      this.setStateFromProps();
    }
  }

  componentDidMount() {
    if (this.props.value instanceof Date) {
      this.setStateFromProps();
    }
  }

  render() {
    const { required, error } = this.props;

    const { day, month, year } = this.state;

    return (
      <div className="Birthdate">
        <span>Geburtsdatum{!required && <OptionalMarker />}</span>
        <div className="fields">
          <FormGroup className="day">
            <Label for="day">Tag</Label>
            <Input
              type="number"
              name="day"
              value={day}
              onChange={this.handleChangeDay}
              maxLength="2"
              min="1"
              max="31"
              component="input"
              invalid={!!error || !this.validateDay(day)}
              className="nospinners"
            />
          </FormGroup>
          <FormGroup className="month">
            <Label for="month">Monat</Label>
            <Input
              type="number"
              name="month"
              value={month}
              onChange={this.handleChangeMonth}
              maxLength="2"
              min="1"
              max="12"
              component="input"
              invalid={!!error || !this.validateMonth(month)}
              className="nospinners"
            />
          </FormGroup>
          <FormGroup className="year">
            <Label for="year">Jahr</Label>
            <Input
              type="number"
              name="year"
              value={year}
              onChange={this.handleChangeYear}
              maxLength="4"
              min="1900"
              max={EIGHTEN_YEARS_AGO.toISOString().split("T")[0]}
              component="input"
              invalid={!!error || !this.validateYear(year)}
              className="nospinners"
            />
          </FormGroup>
        </div>
        {error && <span className="error">{error}</span>}
      </div>
    );
  }
}

export { Birthdate };
