import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import ReactMarkdown from "react-markdown";

import { CURRENT_THEME, THEMES } from "../constants";
import { CollapsibleInformation } from "../components/CollapsibleInformation";

import "./FAQ.scss";

const CELLS_ENERGY_FAQ_QUESTIONS = [
  {
    header: null,
    questions: [
      {
        title: "Keine Abschläge - Wie funktioniert die Abrechnung?",
        answer: [
          "Da du bei uns die stündlich schwankenden Börsenstrompreise weiterberechnet bekommst, gibt es auch keine festgelegten monatlichen Abschläge. Wir schicken dir monatlich eine Abschlagsrechnung für die im Vormonat (laut Schätzung deines Netzbetreibers) durch dich verbrauchte Menge. Abgerechnet wird diese Menge dann schon zu den tatsächlichen Börsenpreisen aus dem Vormonat. Ganz einfach und vollkommen transparent!",
          "Auf der Abrechnung siehst du auch, welcher Betreiber wie viel Prozent deines Stroms geliefert hat – virtuell für Anlagen, die noch staatlich gefördert sind, und real für alle Anlagen in deinem Mix, die den Schritt auf den freien Markt schon vollzogen haben!",
          "Übrigens: Egal ob Direkt- oder Ersatzlieferung, du tust immer das Richtige für die Bürgerenergiewende! Denn du entscheidest durch deine Nachfrage, welche Anlagen auch zukünftig weiter Strom erzeugen sollen!"
        ]
      },
      {
        title: "Wie funktionert der variable Strompreis?",
        answer: [
          "Eine der Besonderheiten des Marktplatzes ist, dass der Energiepreis bei den Stromlieferungen an Kunden variabel ist. Das haben wir uns nicht ausgedacht, das ist einfach die Realität beim Handel mit Strom.",
          "Anders als bei Netzentgelten und den diversen Abgaben und Umlagen, ist der Preis für die elektrische Energie zu jeder Stunde ein anderer. Die stündlichen Preise ergeben sich in einer vortäglichen Auktion, der sogenannten Day-Ahead-Auktion an der European Power Exchange (EPEX). Die Preise werden nach Handelsschluss veröffentlicht und sind einsehbar auf [https://www.epexspot.com/de/marktdaten/dayaheadauktion](https://www.epexspot.com/de/marktdaten/dayaheadauktion).",
          "Um auf die Energiekosten zu kommen, die in deiner Abrechnung ausgewiesen werden, multiplizieren wir einen Stundenpreis mit der Menge, die du (laut geschätztem Verbrauchsprofil deines Netzbetreibers) in dieser Stunde verbraucht haben sollst – und das für alle Stunden im Abrechnungszeitraum.",
          "Durch die direkte Weiterleitung der Börsenpreise siehst du auf einen Blick, was dich der Strom im abgerechneten Zeitraum gekostet hat. Außerdem hast du die Möglichkeit von fallenden oder sogar negativen Preisen zu profitieren.",
          "Sofern dein Verbrauch mit einem intelligenten Messgerät gemessen und entsprechend gemäß deines realen Verbrauchprofils bilanziert wird, kannst du durch die direkte Weiterleitung des Börsenpreises sogar aktiv auf Preisschwankungen reagieren und so Kosten einsparen! Bei Interesse schreib‘ uns eine E-Mail an [service@cells.energy](mailto:service@cells.energy)."
        ]
      },
      {
        title: "Ist der Börsenpreis günstig?",
        answer: [
          "Generell ist der Börsenpreis der Indikator für das Verhältnis von Angebot und Nachfrage im Großhandel, wo auch große Kraftwerksbetreiber und Energiekonzerne ihren Strom kaufen. Du bezahlst für den Strom im Mittel also nur das, was er auch  für große Stromkonzerne im Einkauf kostet. Wir stellen dich also auf die gleiche Stufe.",
          "Natürlich müssen wir dir für den Betrieb und die Verwaltung des Marktplatzes eine Gebühr berechnen – sonst hätte der Marktplatz nicht lange Bestand. Durch effiziente Prozesse versuchen wir diese Gebühr aber so gering wie möglich zu halten - und vor allem weisen wir diese Gebühr vollständig transparent auf der Stromrechnung aus. Dieses Geld dient letztlich der Community, von der du nun ein Teil bist."
        ]
      },
      {
        title: "Wie sieht es mit der Versorgungssicherheit aus?",
        answer: [
          "Keine Sorge! Es ist zu jeder Zeit sichergestellt, dass du mit Strom versorgt bist. Unterbrechungen wird es durch uns nicht geben. Offen gesagt: Die gibt es bei keinem Versorger. Dafür ist in Deutschland gesorgt."
        ]
      },
      {
        title: "Wie ist die Qualität meines Stroms?",
        answer: [
          "Sehr hoch! Denn du unterstützt mit deinem Stromkauf die erneuerbaren Stromproduzenten deines Vertrauens direkt. Dein Geld geht nach Abzug der Marktplatzgebühr in voller Höhe an die Betreiber. Diese machen damit, was sie am besten können – neue Anlagen bauen und die bestehenden Anlagen dauerhaft fit halten."
        ]
      },
      {
        title: "Wie wird mein Strom gekennzeichnet?",
        answer: [
          "Sobald eine Anlage in deinem Mix keine staatliche Förderung mehr benötigt, liefern wir dir Grünstrom direkt aus dieser Anlage. Bis dahin liefert dir die renergie GmbH eine Ersatzlieferung an Stelle des Betreibers. Diese wird gemäß gesetzlichen Vorgaben  mit dem Bundesdeutschen Strom-Mix („Graustrom“) kennzeichnen."
        ]
      },
      {
        title: "Warum bekomme ich heute noch keinen „Grünstrom“?",
        answer: [
          "Das mit dem „Grünstrom“ ist so eine Sache… Wir sind uns sicher: Mit jeder Kilowattstunde Strom, die du über den cells energy Marktplatz beziehst, tust du jetzt schon mehr für die Energiewende als „normale“ Grünstromkunden.",
          "Viele Energieversorger kaufen sogenannte „Grünstromzertifikate“ ein, um den „Graustrom“, den sie liefern, „Grünstrom“ nennen zu dürfen. Das gibt dem Kunden ein gutes Gewissen und bringt mehr Geld ein. Letztlich wird durch diese Praxis in Deutschland aber wohl keine Kilowattstunde mehr grüner Strom erzeugt. (Vergleiche auch: ([https://www.klimareporter.de/strom/mehr-trittbrettfahrer-beim-oekostrom](https://www.klimareporter.de/strom/mehr-trittbrettfahrer-beim-oekostrom))",
          "Wir finden, dass es effektivere Wege gibt, die Energiewende voran zu bringen. Zum Beispiel indem das Geld für die Stromrechnung direkt bei den Betreibern ankommt, die auch Erneuerbare-Energien-Kraftwerke betreiben.",
          "Wollten wir nichts verändern, würden wir den Strom genauso mit zugekauften Grünstromzertifikaten etikettieren. Wir wollen aber etwas verändern und unsere Kunden außerdem nicht „für dumm verkaufen“: Wir liefern dir zwar offiziell keinen „grünen“ Strom. Dafür kannst du beim cells energy Marktplatz sicher sein, dass der Mehrbetrag, den du für deinen Strom bezahlst, bei einem Stromerzeuger aus der Community ankommt.",
          "Du hast da so deine Zweifel? Schau einfach bei ihr/ihm vorbei und überzeuge dich!"
        ]
      },
      {
        title: "Wann wird mein „Graustrom“ grün?",
        answer: [
          "Der Grünstrom-Anteil deiner Stromlieferung steigt automatisch, bis die letzte Anlage in deinem Mix keine staatliche Förderung mehr benötigt - dann liefern wir dir 100% Grünstrom direkt aus den Anlagen deiner Wahl. In der Zwischenzeit sichert dein Betreiberzuschuss, dass der Standort erhalten bleibt. Das ist heute die wichtigste Aufgabe der Bürgerenergiewende, denn ab 2021 fallen jährlich zehntausende Anlagen aus der Förderung. Diese müssen wir erhalten!"
        ]
      }
    ]
  }
];

const SWL_FAQ_QUESTIONS = [
  {
    header: "Strom kaufen",
    questions: [
      {
        title: "Wie stelle ich mir einen Anlagen-Mix zusammen?",
        answer: [
          "Mit den Standard-Mixen können Sie einfach bestimmen, was Ihnen besonders wichtig ist. Der elektronische Marktplatz-Agent wählt dann automatisch die besten Anlagen für Sie aus. Sie können die Anordnung durch Ziehen der Profile frei verändern oder Anlagen löschen. Haben Sie leere Plätze, können Sie in der Liste unten oder auf der Landkarte gezielt Anlagen hinzu wählen. Die Anlagen haben eine Reihenfolge. Die Anlage auf der Position 1 ist Ihre Hauptanlage, welche Sie mit dem Betreiberzuschuss unterstützen. Erst wenn Ihr Verbrauch virtuell nicht mehr von dieser Anlage gedeckt werden kann, springen die übrigen Anlagen ein."
        ]
      },
      {
        title: "Was ist eine Deckungsrate?",
        answer: [
          "Die Deckungsrate gibt an, wie hoch die voraussichtliche Stromproduktion des gewählten Anlagenmix, im Vergleich zu Ihrem Jahresverbrauch, ist. Eine Deckungsrate von z.B. 99 % bedeutet demnach, dass die Anlagen aus Ihrem Mix, auf das gesamte Jahr gerechnet, fast die gleiche Menge erneuerbare Energien erzeugen, wie Sie jährlich verbrauchen."
        ]
      },
      {
        title: "Wie funktioniert meine Abrechnung?",
        answer: [
          "Im ersten Schritt werden Ihre voraussichtlichen Gesamtkosten pro Jahr ermittelt. Diese Kosten werden auf 11 monatliche Teilzahlungen, auch Abschlag genannt, aufgeteilt. Am Ende des Abrechnungszeitraums überprüfen wir, wie viel Strom Sie über die Abschläge bereits bezahlt und wie viel Strom Sie tatsächlich verbraucht haben. Ob Sie eine Gutschrift erhalten, oder es zu einer Nachzahlung kommt, teilen wir Ihnen in der jährlichen Jahresendabrechnung mit. "
        ]
      },
      {
        title:
          "Warum weicht die Höhe meines Abschlags von der Berechnung der Gesamtkosten ab?",
        answer: [
          "Der Markplatz-Agent ermittelt die Gesamtkosten und teilt diese gleichmäßig auf 12 Monate auf. Bei uns bezahlen Sie jedoch nur 11 Abschläge (von Februar bis Dezember)."
        ]
      },
      {
        title: "Muss ich den alten Stromvertrag selbst kündigen?",
        answer: [
          "Nein, wir kündigen für Sie kostenlos zum gewünschten Wechseltermin oder zum nächstmöglichen Zeitpunkt. Sollten Sie jedoch bereits vor Antragsstellung selbst bei Ihrem Versorger gekündigt haben, bitten wir Sie, uns dies mitzuteilen."
        ]
      },
      {
        title: "Wie lange dauert der Versorgerwechsel?",
        answer: [
          "Der Versorgerwechsel dauert in der Regel 14 Tage ab Auftragseingang. Sobald der Wechsel vom Netzbetreiber bestätigt wurde, senden wir Ihnen die Vertragsbestätigung mit dem Datum des Belieferungsbeginns zu."
        ]
      },
      {
        title: "Findet eine physikalische Stromlieferung statt?",
        answer: [
          "Es findet keine physikalische Grünstrom-Lieferung, aus den von Ihnen gewählten Anlagen, statt. Die Lieferung erfolgt virtuell und die Grünstromqualität wird über Herkunftsnachweise (HKN) sichergestellt."
        ]
      },
      {
        title: "Wofür ist der Betreiberzuschuss?",
        answer: [
          "Mit dem Betreiberzuschuss leisten Sie als Kunde einen wichtigen Betrag zur Energiewende. Denn mit der Zahlung des Zuschusses, der nach Abzug der Marktplatzgebühr in voller Höhe an die Betreiber geht, können die bestehenden Anlagen erhalten bleiben. Das ist eine wichtige Aufgabe der Bürgerenergiewende, denn ab 2021 fallen jährlich zehntausende Anlagen aus der Förderung. Diese müssen wir erhalten!"
        ]
      }
    ]
  },
  {
    header: "Strom verkaufen",
    questions: [
      {
        title:
          "Welche Fristen muss ich im Anmeldeprozess für die Strom Direktvermarktung einhalten?",
        answer: [
          "Haben Sie eine EEG-Anlage (Neubau- oder Bestandsanlagen, die zukünftig nach dem EEG in Betrieb genommen werden oder dies bereits sind) und wollen deren Strom bei uns in die Direktvermarktung geben, müssen Sie ihre Anlage bis spätestens 1 Monat und 5 Werktage vor dem gewünschten Direktvermarktungsstart in unserem Portal anmelden. Haben Sie eine Anlage, die sich in der Ausfallvergütung befindet oder wechseln Sie von einem anderen Direktvermarkter zu uns, müssen Sie ihre Anlage bis spätestens 10 Tage vor dem gewünschten Direktvermarktungsstart in unserem Portal anmelden.",
          "Die Fernsteuerbarkeit muss in allen diesen Fällen vor dem Start der Direktvermarktung nachgewiesen werden, außer wenn sich der Direktvermarktungsstart und das EEG-Inbetriebnahmedatum im selben Monat befinden. Dann kann der Fernsteuerbarkeitsnachweis bis zum Ende des Folgemonats nachgereicht werden."
        ]
      },
      {
        title: "Wie wird der Erlös auf meiner Abrechnung berechnet?",
        answer: [
          "Wir berechnen den Erlös anhand der ¼-Stunden Einspeisewerte, auch Lastgänge genannt, die uns vom Netzbetreiber via EDIFACT (Electronic Data Interchange for Administration, Commerce and Transport; ein branchenübergreifender Standard für elektronische Daten) übermittelt werden."
        ]
      },
      {
        title: "Für welchen Zeitraum wird jeweils abgerechnet?",
        answer: [
          "Wir rechnen immer für den vorangegangenen Monat ab. Beispiel: Sie haben eine Abrechnung im Februar erhalten, der Abrechnungszeitraum dafür war der Januar."
        ]
      },
      {
        title: "Von wem erhalte ich meine Mehrerlöse?",
        answer: [
          "Wenn Sie die Direktvermarktung nach dem Marktprämienmodell nutzen, bekommen Sie zwei verschiedene Zahlungen. Der Marktwert wird Ihnen von Ihrem Direktvermarkter gezahlt. Auf diese Abrechnung, abzüglich des Dienstleistungsentgeltes, kommt die Umsatzsteuer hinzu. Der Netzbetreiber hingegen bezahlt Ihnen die Marktprämie inklusive der Managementprämie jedoch ohne die Umsatzsteuer. Diese Konstellation nennt sich Zweistrommodell. Der Betreiberzuschuss wird Ihnen von cells energy ausbezahlt."
        ]
      },
      {
        title: "Wann endet für meine Anlage die EEG-Vergütung?",
        answer: [
          "Für alle Anlagen die vor dem 01.01.2000 in Betrieb genommen wurden, läuft die EEG-Vergütung zum 31.12.2020 aus. Für Anlagen die später In Betrieb genommen wurden, endet Sie nach 20 Jahren. Beispiel: Ihre Anlage ging am 15.05.2004 in Betrieb, so endet ihr Anspruch auf Förderung am 31.12.2024."
        ]
      },
      {
        title: "Was bedeutet Post-EEG?",
        answer: [
          "Als Post-EEG bezeichnet man die Zeit ab dem 01.01.2021, da ab diesem Zeitpunkt für die ersten Erneuerbare Energien Anlagen die EEG-Vergütung nach 20 Jahren ausläuft. Zu beachten ist jedoch, dass für Anlagen, welche keinen Anspruch mehr auf eine Vergütung gem. §19 EEG 2021 haben, dennoch die allgemeinen, nicht zahlungsbezogenen Regelungen des EEG gelten. Ausführliche Infos finden Sie unter “Post EEG”."
        ]
      },
      {
        title: "Was ist die sonstige Direktvermarktung?",
        answer: [
          "In der sonstigen Direktvermarktung erhält der Anlagenbetreiber keine Förderung durch das EEG. Er kann seinen Strom dennoch weiterhin über einen Direktvermarkter zum Marktpreis an der Börse verkaufen. Vorteil der sonstigen Direktvermarktung ist, dass die grüne Eigenschaft des Stroms in Form von Herkunftsnachweisen (HKN) vermarktet werden kann."
        ]
      },
      {
        title: "Was muss ich beachten, wenn meine EEG-Vergütung ausläuft?",
        answer: [
          "Zunächst einmal müssen Sie sich entscheiden, welche Art des Weiterbetriebes für Ihre Anlage sinnvoll ist. Im Allgemeinen können Anlagen entweder die Einspeisevergütung für ausgeförderte Anlagen (Abwicklung über den Netzbetreiber) in Anspruch nehmen oder Sie haben die Möglichkeit, Ihren Strom selbst zu verbrauchen. Sollten Sie sich für eine Variante entscheiden, bei welcher Ihre Anlage Strom ins öffentliche Netz einspeist, müssen Sie einen Wechsel in die sonstige Direktvermarktung vornehmen."
        ]
      },
      {
        title: "Wie viel kostet der Service der Fernsteuerbarkeit?",
        answer: [
          "Die Kosten ergeben sich durch den jeweiligen Dienstleister und der bereits vorhandenen Hardware."
        ]
      },
      {
        title: "Was ist ein RLM-Zähler?",
        answer: [
          "Ein RLM-Zähler erfasst den Leistungsmittelwert eines Stromverbrauchers je Messperiode. Die Messperiode beträgt in der Regel bei Strom 15 Minuten und bei Gas 60 Minuten. Dabei können in einem Lastgangzähler mehrere Leistungsmittelwerte gleichzeitig gemessen und gespeichert werden. Die Summe all dieser registrierten Leistungsmittelwerte ergibt das Lastprofil (auch Lastgang genannt). Diesen Messvorgang bezeichnet man auch als registrierende Leistungsmessung oder registrierende Lastgangmessung (RLM). Der RLM-Zähler kann aber nicht nur messen, er kann über standardisierte Schnittstellen seine Messdaten live an beispielsweise Kommunikationseinrichtungen zur Verfügung stellen. Dank dieser Besonderheit kann der RLM-Zähler aus der Ferne über das Internet, den Mobilfunk oder das Stromnetz ausgelesen werden."
        ]
      },
      {
        title: "Wer kann einen RLM-Zähler verwenden?",
        answer: [
          "Ab einem Stromverbrauch von 100.000 kWh im Jahr wird laut der deutschen Stromnetzzugangsverordnung (StromNZV) ein RLM-Zähler Pflicht. In der Regel haben vor allem Industrie- und Gewerbeunternehmen einen so hohen Stromverbrauch und sind demnach von dieser Regelung betroffen. Auch Betreiber von PV-Anlagen mit einer installierten Leistung von mindestens 100 kWp gehören dazu. Diese Grenzen sind aber flexibel. Wenn die Stromverbraucher oder Anlagenbetreiber den Wunsch auf einen RLM-Zähler haben und beispielsweise bei 70.000 kWh Jahresverbrauch liegen, ist der Einbau des Zählers möglich."
        ]
      },
      {
        title: "RLM-Zähler bei PV-Anlagen",
        answer: [
          "Das Erneuerbare-Energien-Gesetz (EEG) schreibt vor, dass PV-Anlagen über 100 kWp seit 2009 fernsteuerbar sein müssen. Zusätzlich muss der Netzbetreiber Zugriff zur Ist-Leistung haben und diese jederzeit einsehen können. Damit das Steuern und Kontrollieren der PV-Anlage einwandfrei funktionieren kann, braucht es neben dem Steuerempfänger eine Messtechnik. Dafür ist der RLM-Zähler zuständig. PV-Anlagen in der Direktvermarktung sind verpflichtet, einen RLM-Zähler einzubauen – unabhängig der installierten Leistung. Darüber hinaus müssen seit 2016 alle EE-Anlagen über 100 kWp in die Direktvermarktung; die Kommunikation mit dem Direktvermarkter erfolgt hierbei mit der Steuerbox, welche mit dem RLM-Zähler vor Ort per Schnittstelle verbunden ist. Die Messdaten werden somit live zum Direktvermarkter übertragen. PV-Anlagen ohne Direktvermarktung und mit einer Größte von 100-500 kW haben die Pflicht, einen RLM-Zähler zu besitzen."
        ]
      }
    ]
  }
];

export default class FAQ extends Component {
  render() {
    const faqQuestions =
      CURRENT_THEME === THEMES.SWL
        ? SWL_FAQ_QUESTIONS
        : CELLS_ENERGY_FAQ_QUESTIONS;

    return (
      <div className="FAQ page">
        <header>
          <Container>
            <Row>
              <h1>Fragen und Antworten</h1>
            </Row>
          </Container>
        </header>
        <main>
          <Container>
            {faqQuestions.map((section, index) => (
              <React.Fragment key={index}>
                {!!section.header && <h2>{section.header}</h2>}
                {section.questions.map((question) => {
                  return (
                    <Row key={question.title} className="row-question">
                      <Col lg="8">
                        <CollapsibleInformation title={question.title}>
                          {question.answer.map((paragraph, index) => {
                            return (
                              <ReactMarkdown linkTarget="_blank" key={index}>
                                {paragraph}
                              </ReactMarkdown>
                            );
                          })}
                        </CollapsibleInformation>
                      </Col>
                    </Row>
                  );
                })}
              </React.Fragment>
            ))}
          </Container>
        </main>
      </div>
    );
  }
}
