import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";

import "./MultiplierMix.scss";
import {
  getPowerPlantMainImage,
  LinkToProfilePageWithBack,
  getProducerDisplayName
} from "../../utils.js";
import { Producer } from "../consumer/Producer";
import { ShowWithMixesWidget } from "../consumer/ShowWithMixesWidget";

import regioCellsLogo from "../../images/icons/regio-cells.png";

class MultiplierMix extends Component {
  render() {
    const { mix: multiplierMix } = this.props;
    const mixPlantIds = multiplierMix.mix.map((mixPlant) => mixPlant.id);
    const showMixButton = multiplierMix.allowCustomMix;

    return (
      <div className="MultiplierMix page">
        <Container>
          <MixBody
            headerText={multiplierMix.header}
            subHeaderText={multiplierMix.subHeader}
            headerImage={multiplierMix.picture}
            logo={multiplierMix.logo}
            description={multiplierMix.description}
            mixName={multiplierMix.mixName}
            mixPlants={multiplierMix.mix}
          />
          <Row>
            <Col>
              {showMixButton ? (
                <ShowWithMixesWidget
                  powerPlantIds={mixPlantIds}
                  multiplierId={multiplierMix.id}
                />
              ) : (
                <Button
                  className="own-mix-button"
                  color="primary"
                  to=""
                  tag={Link}
                >
                  Einen eigenen Mix zusammenstellen
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function MixBody({
  headerText,
  subHeaderText,
  headerImage,
  logo,
  description,
  mixName,
  mixPlants
}) {
  return (
    <React.Fragment>
      <Row className="flex-center">
        <Col xs="12">
          <Container className="header">
            <Row>
              <Col xs={{ size: 10, offset: 1 }}>
                <h1>{headerText}</h1>
                <h2>{subHeaderText}</h2>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      {headerImage && (
        <Row className="flex-center">
          <div className="header-image-outer">
            <img src={headerImage} alt={mixName} className="header-image" />
            {logo && (
              <div className="logo-outer">
                <div className="watermark-background"></div>
                <img src={logo} alt={headerText} className="logo" />
              </div>
            )}
            <div className="logo-outer regio-cells-logo-outer">
              <div className="watermark-background"></div>
              <img
                src={regioCellsLogo}
                alt="Wir fördern Regio Cells"
                className="logo"
              />
            </div>
          </div>
        </Row>
      )}
      <Row>
        <Col className="description-col">
          <ReactMarkdown linkTarget="_blank">{description}</ReactMarkdown>
        </Col>
      </Row>
      <Row className="flex-center">
        <Col xs="12">
          <Container className="header">
            <Row>
              <Col xs={{ size: 10, offset: 1 }}>
                <h2>{mixName}</h2>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="producers">
            {mixPlants &&
              mixPlants.map((plant) => (
                <LinkToProfilePageWithBack
                  id={plant.id}
                  slug={plant.slugName}
                  key={plant.id}
                >
                  <Producer
                    key={plant.id}
                    plantName={plant.name}
                    ownerName={getProducerDisplayName(plant)}
                    city={plant.plantCity}
                    img={getPowerPlantMainImage(plant)}
                  />
                </LinkToProfilePageWithBack>
              ))}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

MultiplierMix.propTypes = {
  mix: PropTypes.object.isRequired
};

MixBody.propTypes = {
  headerText: PropTypes.string.isRequired,
  subHeaderText: PropTypes.string.isRequired,
  headerImage: PropTypes.string,
  logo: PropTypes.string,
  description: PropTypes.string.isRequired,
  mixName: PropTypes.string.isRequired,
  mixPlants: PropTypes.arrayOf(PropTypes.object).isRequired
};

export { MultiplierMix, MixBody };
