import React, { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";

import { MarginControl } from "../../../components/MarginControl";

class Margin extends Component {
  handleIncreaseMargin = (newMargin) => {
    this.props.change("plantMargin", newMargin.toFixed(2));
  };

  handleDecreaseMargin = (newMargin) => {
    this.props.change("plantMargin", newMargin.toFixed(2));
  };

  render() {
    const { plantMargin } = this.props;

    return (
      <div className="plantMargin">
        <MarginControl
          margin={plantMargin}
          onIncreaseMargin={this.handleIncreaseMargin}
          onDecreaseMargin={this.handleDecreaseMargin}
        />
      </div>
    );
  }
}

let reduxFormMargin = reduxForm({
  form: "potentialCheck",
  destroyOnUnmount: false
})(Margin);

const selector = formValueSelector("potentialCheck");
reduxFormMargin = connect((state) => {
  const plantMargin = selector(state, "plantMargin");
  return {
    plantMargin
  };
})(reduxFormMargin);

export { reduxFormMargin as Margin };
