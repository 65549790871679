import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  getMultiplierMixByIdAction,
  resetGetMultiplierMixByIdErrorAction
} from "../../actions";
import { MultiplierMix } from "./MultiplierMix";
import { Loader } from "../../utils";

class Mixes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null
    };
  }

  updateMultiplierMixData() {
    const mixId = this.props.match.params.mixId;
    this.props.getMultiplierMixById(mixId);
  }

  componentDidUpdate() {
    // check if there was an error in updating the data
    // if there was, clear the error and redirect to not-found page
    const { multiplierMixGetError, resetGetMultiplierMixByIdError } =
      this.props;

    if (multiplierMixGetError) {
      resetGetMultiplierMixByIdError();
      this.setState({
        redirectTo: "/seite-nicht-gefunden"
      });
    }
  }

  componentDidMount() {
    this.updateMultiplierMixData();
  }

  render() {
    const { multiplierMixes, match } = this.props;

    const { redirectTo } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    const multiplierMix = multiplierMixes[match.params.mixId];

    if (multiplierMix) {
      return <MultiplierMix mix={multiplierMix} />;
    } else {
      return <Loader />;
    }
  }
}

function mapStateToProps(state) {
  return {
    multiplierMixes: state.mix.multiplierMixes,
    multiplierMixGetError: state.mix.multiplierMixGetError,
    multiplierMix: state.mix.multiplierMix,
    authenticated: state.auth.authenticated
  };
}

const mapDispatchToProps = (dispatch) => ({
  getMultiplierMixById: (id) => dispatch(getMultiplierMixByIdAction(id)),
  resetGetMultiplierMixByIdError: () =>
    dispatch(resetGetMultiplierMixByIdErrorAction())
});

const connectedMixes = connect(mapStateToProps, mapDispatchToProps)(Mixes);
export { connectedMixes as Mixes };
