import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Col, Container, Modal, ModalBody, Row } from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";

import { GetAQuote } from "./GetAQuote";
import { HowMuchPowerDoYouNeed } from "./HowMuchPowerDoYouNeed";
import {
  setMixOptionsAction,
  getPowerPlantDistancesFromPostcodeAction
} from "../../actions";
import { CURRENT_THEME, STRING_KEYS, THEMES } from "../../constants";
import { YEARLY_ENERGY_LIMITS } from "../../settings";
import { getString, MarkdownString } from "../../utils";

import swlPartnerImage from "../../images/swl/consumer-flow/partner.png";
import vollmachtLastgangdaten from "../../docs/Vollmacht_Lastgangdaten.pdf";

import "./ShowWithMixesWidget.scss";
import { checkFeature, FEATURES } from "../../features";

class ShowWithMixesWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,
      postcodeIsValid: false,
      showTooMuchEnergyModal: false
    };
  }

  handleClick = () => {
    const {
      postcode,
      powerPlantIds,
      yearlyEnergy,
      getPowerPlantDistancesFromPostcode,
      setMixOptions,
      multiplierId
    } = this.props;

    if (parseInt(yearlyEnergy, 10) >= YEARLY_ENERGY_LIMITS[CURRENT_THEME].MAX) {
      this.setState({
        showTooMuchEnergyModal: true
      });
    } else {
      setMixOptions({
        firstPowerPlantIds: powerPlantIds,
        multiplierId: multiplierId
      });
      getPowerPlantDistancesFromPostcode({ postcode: postcode }).then(() => {
        this.setState({
          redirectTo: "/strom-kaufen/mein-mix"
        });
      });
    }
  };

  handleChangePostcodeIsValid = (validity) => {
    this.setState({
      postcodeIsValid: validity
    });
  };

  toggleTooMuchEnergyModal = () => {
    this.setState({
      showTooMuchEnergyModal: !this.state.showTooMuchEnergyModal
    });
  };

  componentDidMount() {
    this.setState({
      redirectTo: null
    });
  }

  render() {
    const { showHeader, yearlyEnergy } = this.props;

    const { postcodeIsValid, showTooMuchEnergyModal } = this.state;

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} push={true} />;
    }

    const partnerImage = getString(STRING_KEYS.HOW_MUCH_POWER_TOO_MUCH_IMAGE, [
      ["partnerImage", CURRENT_THEME === THEMES.SWL ? swlPartnerImage : ""]
    ]);
    const isSignupDisabled = checkFeature(FEATURES.DISABLED_SIGNUPS);

    return (
      <Container className="ShowWithMixesWidget">
        {showHeader && (
          <Row>
            <Col>
              <h2>Stromangebot erstellen</h2>
            </Col>
          </Row>
        )}
        {isSignupDisabled ? (
          <span>Angebot berechnen in Kürze möglich!</span>
        ) : (
          <>
            <Row>
              <Col>
                <h3>Postleitzahl</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <GetAQuote
                  isWidgetMode
                  onChangeValidity={(validity) =>
                    this.handleChangePostcodeIsValid(validity)
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Stromverbrauch</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <Container className="how-much-power-outer">
                  <HowMuchPowerDoYouNeed
                    onGoToNextStep={() => {}}
                    onGoBack={() => {}}
                    isWidgetMode
                  />
                </Container>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  color="primary"
                  disabled={
                    !postcodeIsValid ||
                    isNaN(yearlyEnergy) ||
                    parseInt(yearlyEnergy, 10) === 0
                  }
                  onClick={this.handleClick}
                >
                  Stromangebot erstellen
                </Button>
              </Col>
            </Row>
            <Modal
              isOpen={showTooMuchEnergyModal}
              toggle={this.toggleTooMuchEnergyModal}
              centered={true}
              className={classnames("ShowWithMixesWidget-TooMuchEnergyModal", {
                "left-align": CURRENT_THEME === THEMES.SWL
              })}
            >
              <ModalBody style={{ textAlign: "center" }}>
                <MarkdownString
                  stringKey={STRING_KEYS.HOW_MUCH_POWER_TOO_MUCH}
                  openLinksInNewWindow
                  replacements={[
                    ["vollmachtLastgangdaten", vollmachtLastgangdaten]
                  ]}
                />
                {partnerImage && (
                  <div className="partner-image-section">
                    <img
                      src={partnerImage}
                      className="partner-image"
                      alt="Ansprechpartner"
                    />
                    <MarkdownString
                      stringKey={
                        STRING_KEYS.HOW_MUCH_POWER_TOO_MUCH_IMAGE_CAPTION
                      }
                    />
                  </div>
                )}
                <Button onClick={this.toggleTooMuchEnergyModal} color="primary">
                  Schließen
                </Button>
              </ModalBody>
            </Modal>
          </>
        )}
      </Container>
    );
  }
}

ShowWithMixesWidget.propTypes = {
  powerPlantIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  showHeader: PropTypes.bool,
  postcode: PropTypes.string,
  yearlyEnergy: PropTypes.number.isRequired,
  setMixOptions: PropTypes.func.isRequired,
  getPowerPlantDistancesFromPostcode: PropTypes.func.isRequired,
  multiplierId: PropTypes.string
};

function mapStateToProps(state) {
  return {
    postcode: state.mix.postcode,
    yearlyEnergy: state.mix.yearlyEnergy
  };
}

const mapDispatchToProps = (dispatch) => ({
  setMixOptions: (options) => dispatch(setMixOptionsAction(options)),
  getPowerPlantDistancesFromPostcode: (postcode) =>
    dispatch(getPowerPlantDistancesFromPostcodeAction(postcode))
});

const connectedShowWithMixesWidget = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowWithMixesWidget);

export { connectedShowWithMixesWidget as ShowWithMixesWidget };
