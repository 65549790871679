import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./BackButton.scss";

class BackButton extends Component {
  render() {
    const { to, onClick } = this.props;

    const backText = " zurück";
    const icon = <FontAwesomeIcon icon="long-arrow-alt-left" />;

    return (
      <Row className="back-button">
        <Col>
          {to && (
            <Link to={to}>
              {icon}
              {backText}
            </Link>
          )}
          {onClick && (
            <span onClick={onClick}>
              {icon}
              {backText}
            </span>
          )}
        </Col>
      </Row>
    );
  }
}

BackButton.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func
};

export { BackButton };
