import api from "../api";
import { STRING_KEYS } from "../constants";
import { getString } from "../utils";
import {clearAuthTokens, setAuthTokens} from 'axios-jwt'

export const AUTHENTICATED = "authenticated_user";
export const UNAUTHENTICATED = "unauthenticated_user";
export const AUTHENTICATION_ERROR = "authentication_error";
export const AUTHENTICATION_REQUESTED = "authentication_requested";
export const AUTHENTICATION_REQUEST_CANCELLED =
  "authentication_request_cancelled";
export const AUTHENTICATED_EMAIL = "authenticated_email";
export const AUTHENTICATED_EMAIL_ERROR = "authenticated_email_error";
export const FORGOT_PASSWORD_REQUESTED = "forgot_password_requested";
export const FORGOT_PASSWORD_ERROR = "forgot_password_error";
export const REGISTERED = "registered_user";
export const REGISTRATION_ERROR = "registration_error";
export const CONTRACT_CREATED = "contract_created";
export const CONTRACT_CREATION_ERROR = "contract_creation_error";
export const CONTRACT_RESET = "contract_reset";
export const MY_CONTRACTS_GET = "my_contracts_get";
export const MY_CONTRACTS_GET_BY_ID = "my_contracts_get_by_id";
export const MY_CONTRACTS_GET_BY_ID_ERROR = "my_contracts_get_by_id_error";
export const RESET_MY_CONTRACTS_GET_BY_ID_ERROR =
  "reset_my_contracts_get_by_id_error";
export const MY_CONTRACTS_UPDATE = "power_plant_update";
export const POWER_PLANT_CLEAR_ERRORS = "power_plant_clear_errors";
export const POWER_PLANT_IMAGES_POST = "power_plant_images_post";
export const POWER_PLANT_IMAGES_POST_ERROR = "power_plant_images_post_error";
export const POWER_PLANT_IMAGE_DELETE = "power_plant_image_delete";
export const POWER_PLANT_GET = "power_plant_get";
export const POWER_PLANT_GET_ERROR = "power_plant_get_error";
export const RESET_POWER_PLANT_GET_ERROR = "reset_power_plant_get_error";
export const POWER_PLANTS_GET = "power_plants_get";
export const POWER_PLANTS_GET_ERROR = "power_plants_get_error";
export const POWER_PLANT_DISTANCES_FROM_POSTCODE_POST =
  "power_plant_distances_from_postcode_post";
export const POWER_PLANT_DISTANCES_FROM_POSTCODE_POST_ERROR =
  "power_plant_distances_from_postcode_post_error";
export const MIX_CLEAR_ERRORS = "mix_clear_errors";
export const MIX_GENERATE_PRESET = "mix_generate_preset";
export const MIX_GENERATE_PRESET_ERROR = "mix_generate_preset_error";
export const MIX_CUSTOM_UPDATE = "mix_custom_update";
export const MIX_CUSTOM_UPDATE_ERROR = "mix_custom_update_error";
export const MIX_STORE = "mix_store";
export const MIX_SET_OPTIONS = "mix_set_options";
export const MIX_SET_CUSTOM_MARGIN = "mix_set_custom_margin";
export const MULTIPLIER_MIX_GET = "multiplier_mix_get";
export const MULTIPLIER_MIX_GET_ERROR = "multiplier_mix_get_error";
export const RESET_MULTIPLIER_MIX_GET_ERROR = "reset_multiplier_mix_get_error";
export const SUBSCRIBE_POTENTIAL_CHECK = "subscribe_potential_check";
export const SUBSCRIBE_POTENTIAL_CHECK_ERROR =
  "subscribe_potential_check_error";
export const GRID_OPERATORS_GET = "grid_operators_get";
export const CONSUMER_CONTRACT_CREATED = "consumer_contract_created";
export const CONSUMER_CONTRACT_CREATION_ERROR =
  "consumer_contract_creation_error";
export const CONTACT_FORM_SEND = "contact_form_send";
export const CONTACT_FORM_SEND_ERROR = "contact_form_send_error";

export function signInAction({ email, password }) {
  return async (dispatch) => {
    try {
      const response = await api.post(`rest-auth/login/`, { email, password });
      setAuthTokens({
        accessToken: response.data.access,
        refreshToken: response.data.refresh
      })

      dispatch({
        type: AUTHENTICATED,
        payload: {
          user: response.data.user
        }
      });
    } catch (error) {
      let errorMessage;
      switch (error.response.status) {
        case 400:
          errorMessage = "Ungültiger Benutzername oder Passwort.";
          break;
        case 405:
          errorMessage =
            "Fehler beim Login. Bitte aktualisieren Sie die Seite und versuchen es erneut.";
          break;
        default:
          errorMessage =
            "Fehler beim Einloggen. Wir sind bereits darüber informiert.";
      }
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: errorMessage
      });
    }
  };
}

export function signOutAction() {
  return async (dispatch) => {
    clearAuthTokens();
    dispatch({
        type: UNAUTHENTICATED
      });
  };
}

export function requestSignInAction() {
  return async (dispatch) => {
    dispatch({
      type: AUTHENTICATION_REQUESTED
    });
  };
}

export function cancelSignInRequestAction() {
  return async (dispatch) => {
    dispatch({
      type: AUTHENTICATION_REQUEST_CANCELLED
    });
  };
}

export function forgotPasswordAction({ email }) {
  return async (dispatch) => {
    try {
      await api.post(`rest-auth/password/reset/`, { email });

      dispatch({
        type: FORGOT_PASSWORD_REQUESTED,
        payload: {}
      });
    } catch (error) {
      let errorMessage;

      switch (error.response.status) {
        case 400:
          errorMessage = "Ungültiger Benutzername.";
          break;
        default:
          errorMessage = "Fehler. Wir sind bereits darüber informiert.";
      }
      dispatch({
        type: FORGOT_PASSWORD_ERROR,
        payload: errorMessage
      });
    }
  };
}

export function registrationAction({
  email,
  password1,
  password2,
  firstName,
  lastName
}) {
  return async (dispatch) => {
    try {
      const response = await api.post(`rest-auth/registration/`, {
        email,
        password1,
        password2,
        firstName,
        lastName
      });

      dispatch({
        type: REGISTERED,
        payload: {
          user: response.data.user
        }
      });

      dispatch({
        type: AUTHENTICATED,
        payload: {
          user: response.data.user
        }
      });
      setAuthTokens({
        accessToken: response.data.access,
        refreshToken: response.data.refresh,
      });

    } catch (error) {
      dispatch({
        type: REGISTRATION_ERROR,
        payload: error.response.data
      });
    }
  };
}

export function confirmEmailAction(key) {
  return async (dispatch) => {
    try {
      const response = await api.post(
        `rest-auth/registration/account-confirm-email/${key}/`
      );

      dispatch({
        type: AUTHENTICATED_EMAIL,
        payload: {
          response: response.data
        }
      });
    } catch (error) {
      dispatch({
        type: AUTHENTICATED_EMAIL_ERROR,
        payload: error.response.data
      });
    }
  };
}

export function createContractAction(contractData) {
  return async (dispatch) => {
    try {
      const response = await api.post(`api/my-contracts/`, contractData);

      dispatch({
        type: CONTRACT_CREATED,
        payload: {
          contractData: response.data // todo
        }
      });
    } catch (error) {
      dispatch({
        type: CONTRACT_CREATION_ERROR,
        payload: error.response.data
      });
    }
  };
}

export function resetContractFormAction() {
  return async (dispatch) => {
    dispatch({
      type: CONTRACT_RESET
    });
  };
}

export function getMyContractsAction() {
  return async (dispatch) => {
    try {
      const response = await api.get(`api/my-contracts/`);

      dispatch({
        type: MY_CONTRACTS_GET,
        payload: {
          contracts: response.data
        }
      });
    } catch (error) {
      console.log("Error getting contracts.");
      // todo: what can we show the user if this is broken?
    }
  };
}

export function getContractByIdAction(id) {
  return async (dispatch) => {
    try {
      const response = await api.get(`api/my-contracts/${id}/`);

      dispatch({
        type: MY_CONTRACTS_GET_BY_ID,
        payload: {
          contract: response.data
        }
      });
    } catch (error) {
      dispatch({
        type: MY_CONTRACTS_GET_BY_ID_ERROR,
        payload: error.response.data
      });
    }
  };
}

export function resetContractGetByIdErrorAction() {
  return async (dispatch) => {
    dispatch({
      type: RESET_MY_CONTRACTS_GET_BY_ID_ERROR
    });
  };
}

export function updateContractByIdAction(id, data) {
  return async (dispatch) => {
    try {
      const response = await api.patch(`api/my-contracts/${id}/`, data);

      dispatch({
        type: MY_CONTRACTS_UPDATE,
        payload: {
          contract: response.data
        }
      });
    } catch (error) {
      console.log("Error updating contract.");
      // todo: what can we show the user if this is broken?
    }
  };
}

export function postPowerPlantImageAction(
  plantId,
  file,
  caption = "",
  description = ""
) {
  return async (dispatch) => {
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("plant", plantId);
      formData.append("caption", caption);
      formData.append("description", description);

      const response = await api.post(`api/power-plant-images/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      dispatch({
        type: POWER_PLANT_IMAGES_POST,
        payload: {
          image: response.data,
          plantId: plantId
        }
      });
    } catch (error) {
      let imagePostErrorText =
        'Es ist ein Fehler aufgetreten. Bitte kontaktiere <a href="mailto:info@cells.energy">info@cells.energy</a> für weitere Informationen.';
      if (error.response) {
        switch (error.response.status) {
          case 400:
            imagePostErrorText = error.response.data.file[0];
            break;
          case 408: // timeout
            imagePostErrorText =
              "Fehler! Es hat zu lange gedauert. Bitte versuche es noch mal.";
            break;
          case 413: // too large
            imagePostErrorText = error.response.data;
            break;
          case 500: // internal server error (notified via Sentry)
            imagePostErrorText = "Es ist ein Fehler aufgetreten.";
            break;
          default:
            break;
        }
      }

      dispatch({
        type: POWER_PLANT_IMAGES_POST_ERROR,
        payload: {
          response: {
            response: error.response,
            text: imagePostErrorText
          }
        }
      });
    }
  };
}

export function deletePowerPlantImageAction({ imageId }) {
  return async (dispatch) => {
    try {
      const response = await api.remove(`api/power-plant-images/${imageId}/`);

      if (response.status === 204) {
        dispatch({
          type: POWER_PLANT_IMAGE_DELETE,
          payload: {
            imageId: imageId
          }
        });
      } else {
        console.log(
          "Error " + response.status + " deleting power plant image " + imageId
        );
      }
    } catch (error) {
      console.log("Unexpected error deleting power plant image " + imageId);
    }
  };
}

export function getPowerPlantsAction() {
  return async (dispatch) => {
    dispatch({
      type: POWER_PLANT_CLEAR_ERRORS
    });

    try {
      const response = await api.get(`api/power-plants/`);

      dispatch({
        type: POWER_PLANTS_GET,
        payload: {
          powerPlants: response.data
        }
      });
    } catch (error) {
      dispatch({
        type: POWER_PLANTS_GET_ERROR,
        payload: {
          error: error
        }
      });
    }
  };
}

export function getPowerPlantByIdAction(id) {
  return async (dispatch) => {
    try {
      const response = await api.get(`api/power-plants/${id}/`);

      dispatch({
        type: POWER_PLANT_GET,
        payload: {
          powerPlant: response.data
        }
      });
    } catch (error) {
      dispatch({
        type: POWER_PLANT_GET_ERROR,
        payload: error.response.data
      });
    }
  };
}

export function resetPowerPlantGetByIdErrorAction() {
  return async (dispatch) => {
    dispatch({
      type: RESET_POWER_PLANT_GET_ERROR
    });
  };
}

export function getPowerPlantDistancesFromPostcodeAction({ postcode }) {
  return async (dispatch) => {
    try {
      const response = await api.post(`api/power-plant-distances/`, {
        postcode
      });

      dispatch({
        type: POWER_PLANT_DISTANCES_FROM_POSTCODE_POST,
        payload: {
          distances: response.data.distances
        }
      });
    } catch (error) {
      dispatch({
        type: POWER_PLANT_DISTANCES_FROM_POSTCODE_POST_ERROR,
        payload: error.response.data
      });

      console.log("Error getting power plant distances.");
    }
  };
}

export function generatePresetMixesAction(data) {
  return async (dispatch) => {
    dispatch({
      type: MIX_CLEAR_ERRORS
    });

    try {
      const response = await api.post(`api/consumer-mixes/preset/`, data);

      dispatch({
        type: MIX_GENERATE_PRESET,
        payload: {
          mixes: response.data
        }
      });
    } catch (error) {
      dispatch({
        type: MIX_GENERATE_PRESET_ERROR,
        payload: error
      });
    }
  };
}

export function updateCustomMixAction(customMixData) {
  return async (dispatch) => {
    try {
      const response = await api.post(
        `api/consumer-mixes/custom/`,
        customMixData
      );

      dispatch({
        type: MIX_CUSTOM_UPDATE,
        payload: {
          customMixData: response.data
        }
      });
    } catch (error) {
      dispatch({
        type: MIX_CUSTOM_UPDATE_ERROR,
        payload: error.response.data
      });

      console.log("Error updating custom mix data.");
    }
  };
}

export function storeMixAction({ powerPlants, mixName }) {
  return async (dispatch) => {
    dispatch({
      type: MIX_STORE,
      payload: {
        powerPlants: powerPlants,
        mixName: mixName
      }
    });
  };
}

export function setMixOptionsAction(options) {
  return async (dispatch) => {
    dispatch({
      type: MIX_SET_OPTIONS,
      payload: {
        ...options
      }
    });
  };
}

export function setCustomMarginAction(plantId, margin) {
  return async (dispatch) => {
    dispatch({
      type: MIX_SET_CUSTOM_MARGIN,
      payload: {
        plantId: plantId,
        margin: margin
      }
    });
  };
}

export function getMultiplierMixByIdAction(mixId) {
  return async (dispatch) => {
    try {
      const response = await api.get(`api/multiplier-mixes/${mixId}/`);

      dispatch({
        type: MULTIPLIER_MIX_GET,
        payload: {
          multiplierMix: response.data,
          multiplierMixId: mixId
        }
      });
    } catch (error) {
      dispatch({
        type: MULTIPLIER_MIX_GET_ERROR,
        payload: error.response.data
      });
    }
  };
}

export function resetGetMultiplierMixByIdErrorAction() {
  return async (dispatch) => {
    dispatch({
      type: RESET_POWER_PLANT_GET_ERROR
    });
  };
}

export function subscribePotentialCheckAction(email, tag) {
  return async (dispatch) => {
    try {
      const response = await api.post(`api/subscribe/`, {
        emailAddress: email,
        tag: tag
      });

      dispatch({
        type: SUBSCRIBE_POTENTIAL_CHECK,
        payload: {
          response: response.data
        }
      });
    } catch (error) {
      console.log("Error subscribing user.");

      dispatch({
        type: SUBSCRIBE_POTENTIAL_CHECK_ERROR,
        payload: {
          response:
            Object.values(error.response.data).length > 0
              ? error.response.data
              : "Unknown error"
        }
      });
    }
  };
}

export function getGridOperatorsAction() {
  return async (dispatch) => {
    try {
      const response = await api.get(`api/grid-operators/`);

      dispatch({
        type: GRID_OPERATORS_GET,
        payload: {
          gridOperators: response.data
        }
      });
    } catch (error) {
      console.log("Error getting grid operators.");
    }
  };
}

export function createConsumerContractAction(contractData) {
  return async (dispatch) => {
    try {
      const response = await api.post(`api/consumers/`, contractData);

      dispatch({
        type: CONSUMER_CONTRACT_CREATED,
        payload: {
          contractData: response.data
        }
      });
    } catch (error) {
      dispatch({
        type: CONSUMER_CONTRACT_CREATION_ERROR,
        payload: error.response.data
      });
    }
  };
}

export function contactFormSendAction(formData) {
  return async (dispatch) => {
    try {
      await api.post(`api/contact/`, formData);
      dispatch({ type: CONTACT_FORM_SEND });
    } catch (error) {
      dispatch({
        type: CONTACT_FORM_SEND_ERROR,
        payload:
          error.status === 400 &&
          error.response &&
          error.response.data &&
          typeof error.response.data === "object" &&
          error.response.data !== null
            ? error.response.data
            : getString(STRING_KEYS.ERROR_GENERAL_RETRY)
      });
    }
  };
}
