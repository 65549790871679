// https://gist.github.com/mariocesar/e96f6cf6cb2db213173a9c08b9a9867a
import axios from "axios";
import humps from "humps";
import { applyAuthTokenInterceptor } from 'axios-jwt';

const singleton = Symbol();
const singletonEnforcer = Symbol();

function readCookie(name) {
  const match = document.cookie.match(
    new RegExp("(^|;\\s*)(" + name + ")=([^;]*)")
  );
  return match ? decodeURIComponent(match[3]) : null;
}

const _isNumerical = function (obj) {
  obj = obj - 0;
  // eslint-disable-next-line
  return obj === obj;
};

const camelizeWithoutHyphens = function (string) {
  if (_isNumerical(string)) {
    return string;
  }
  string = string.replace(/[_\s]+(.)?/g, function (match, chr) {
    // modified the regex to not match hyphens
    return chr ? chr.toUpperCase() : "";
  });
  // Ensure 1st char is always lowercase
  return string.substr(0, 1).toLowerCase() + string.substr(1);
};
// end copied code from humps

const decamelizeThatDontBreaksFile = (object) => {
  if (object && !(object instanceof File)) {
    if (object instanceof Array) {
      return object.map((item) => decamelizeThatDontBreaksFile(item));
    }
    if (object instanceof FormData) {
      let formData = new FormData();
      for (const [key, value] of object.entries()) {
        formData.append(humps.decamelize(key), value);
      }
      return formData;
    }
    if (typeof object === "object") {
      return Object.keys(object).reduce(
        (acc, next) => ({
          ...acc,
          [humps.decamelize(next)]: decamelizeThatDontBreaksFile(object[next])
        }),
        {}
      );
    }
  }
  return object;
};

class ApiService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error("Cannot construct singleton");
    }

    console.log(
      `API Service for ${window.location.protocol}//${window.location.host}/`
    );

    const session = axios.create({
      baseURL: `${window.location.protocol}//${window.location.host}/`,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRFToken": readCookie("csrftoken")
      },
      transformResponse: [
        ...axios.defaults.transformResponse,
        (data) => humps.camelizeKeys(data, { process: camelizeWithoutHyphens })
      ],
      transformRequest: [
        (data) => decamelizeThatDontBreaksFile(data),
        ...axios.defaults.transformRequest
      ]
    });
    const requestRefresh = (refresh) => {
        // Notice that this is the global axios instance, not the axiosInstance!  <-- important
        return new Promise((resolve, reject) => {
          axios.post(`${window.location.protocol}//${window.location.host}/api/token/refresh/`, { refresh })
            .then(response => {
              return resolve(response.data.access);
            }, reject)
        })
    };
    applyAuthTokenInterceptor(session, { requestRefresh });
    this.session = session;

  }

  static get instance() {
    // Try to get an efficient singleton
    if (!this[singleton]) {
      this[singleton] = new ApiService(singletonEnforcer);
    }

    return this[singleton];
  }

  get = (...params) => this.session.get(...params);
  post = (...params) => this.session.post(...params);
  put = (...params) => this.session.put(...params);
  patch = (...params) => this.session.patch(...params);
  remove = (...params) => this.session.delete(...params);
}

export default ApiService.instance;
