import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import {
  Field,
  formValueSelector,
  getFormSubmitErrors,
  reduxForm,
  SubmissionError
} from "redux-form";

import { CellCheckbox } from "../../../components/CellCheckbox";
import { STRING_KEYS } from "../../../constants";
import { ContinueButton } from "../../../components/ContinueButton";
import { InputField } from "../../../components/InputField";
import { getString } from "../../../utils";

import {
  BANK_PAYMENT_METHODS,
  BANK_FIRST_NAME_MAX_LENGTH,
  BANK_SURNAME_MAX_LENGTH,
  STREET_MAX_LENGTH,
  HOUSE_NUMBER_MAX_LENGTH,
  POSTCODE_MAX_LENGTH,
  CITY_MAX_LENGTH,
  IBAN_MAX_LENGTH,
  BIC_MAX_LENGTH,
  PLZ_REG_EX
} from "../../../constants.js";

import "./PaymentDetailsForm.scss";

class PaymentDetailsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bankDataDifferentThanContractData: false
    };
  }

  handleSubmit = (data) => {
    const errors = this.validateAfterSubmit(data);
    if (Object.keys(errors).length > 0) {
      window.scrollTo(0, 0);
      throw new SubmissionError(errors);
    }

    this.props.onSubmitForm();
  };

  validateAfterSubmit(values) {
    const { bankPaymentMethod } = this.props;

    const { bankDataDifferentThanContractData } = this.state;

    const errors = {};

    if (bankPaymentMethod === BANK_PAYMENT_METHODS.DEBIT) {
      if (!values.bankIban) {
        errors.bankIban = getString(STRING_KEYS.ERROR_IBAN_MISSING);
      }

      if (!values.bankBic) {
        errors.bankBic = getString(STRING_KEYS.ERROR_BIC_MISSING);
      }

      if (!values.bankSepa) {
        errors.bankSepa = getString(STRING_KEYS.ERROR_AGREEMENT_MISSING);
      }
    }

    if (bankDataDifferentThanContractData) {
      if (!values.bankFirstName) {
        errors.bankFirstName = getString(STRING_KEYS.ERROR_FIRST_NAME_MISSING);
      }

      if (!values.bankSurname) {
        errors.bankSurname = getString(STRING_KEYS.ERROR_LAST_NAME_MISSING);
      }

      if (!values.bankStreet) {
        errors.bankStreet = getString(STRING_KEYS.ERROR_BANK_STREET_MISSING);
      }

      if (!values.bankPostcode || !PLZ_REG_EX.test(values.bankPostcode)) {
        errors.bankPostcode = "Fehler.";
      }

      if (!values.bankCity) {
        errors.bankCity = getString(STRING_KEYS.ERROR_BANK_CITY_MISSING);
      }
    }

    return errors;
  }

  handleToggleSameBankData = (isDifferent) => {
    this.setState({
      bankDataDifferentThanContractData: isDifferent
    });
  };

  render() {
    const { bankPaymentMethod, submitErrors, handleSubmit } = this.props;

    const { bankDataDifferentThanContractData } = this.state;

    return (
      <Form
        onSubmit={this.props.handleSubmit(this.handleSubmit)}
        className="PaymentDetailsForm"
      >
        <Container className="contract-fields">
          <Row>
            <Col>
              <h3>Zahlungsdaten</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Zahlungsmethode Lastschrift</h4>
            </Col>
          </Row>
          <Row className="payment-method">
            <Col>
              <FormGroup tag="fieldset">
                <Field
                  name="bankPaymentMethod"
                  component={(props) => (
                    <div>
                      {/* <span className="intro-text">Ich bezahle per</span> */}
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="hidden"
                            name="payment-method"
                            className="shrink"
                            value={BANK_PAYMENT_METHODS.DEBIT}
                            checked={true}
                            onChange={(e) => {
                              props.input.onChange(e.target.value);
                            }}
                          />{" "}
                          {/* Lastschrift */}
                        </Label>
                      </FormGroup>
                      {/* <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            name="payment-method"
                            disabled={true}
                            className="shrink"
                            value={BANK_PAYMENT_METHODS.TRANSFER}
                            checked={
                              props.input.value ===
                              BANK_PAYMENT_METHODS.TRANSFER
                            }
                            onChange={(e) => {
                              props.input.onChange(e.target.value);
                            }}
                          />{" "}
                          Überweisung
                        </Label>
                      </FormGroup> */}
                    </div>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
          {bankPaymentMethod === BANK_PAYMENT_METHODS.DEBIT && (
            <Row className="iban-bic">
              <Col xs={12} md={6} lg={4}>
                <InputField
                  name="bankIban"
                  label="IBAN"
                  type="text"
                  maxLength={IBAN_MAX_LENGTH}
                  required
                  error={submitErrors.bankIban}
                />
              </Col>
              <Col xs={12} md={6} lg={4}>
                <InputField
                  name="bankBic"
                  label="BIC"
                  type="text"
                  maxLength={BIC_MAX_LENGTH}
                  required
                  error={submitErrors.bankBic}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} md={6} lg={8}>
              <CellCheckbox
                id="sameBankData"
                name="sameBankData"
                formGroupClassName="bank-sepa"
                inputClassName="same-bank-data"
                text="Abweichende Rechnungsadresse"
                checked={bankDataDifferentThanContractData}
                onChange={this.handleToggleSameBankData}
              />
            </Col>
          </Row>
          {bankDataDifferentThanContractData && (
            <Row>
              <Container>
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    <InputField
                      name="bankFirstName"
                      label="Vorname"
                      type="text"
                      maxLength={BANK_FIRST_NAME_MAX_LENGTH}
                      required
                      error={submitErrors.bankFirstName}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <InputField
                      name="bankSurname"
                      label="Nachname"
                      type="text"
                      maxLength={BANK_SURNAME_MAX_LENGTH}
                      required
                      error={submitErrors.bankSurname}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={8} className="big-then-small">
                    <InputField
                      name="bankStreet"
                      label="Straße"
                      type="text"
                      maxLength={STREET_MAX_LENGTH}
                      required
                      error={submitErrors.bankStreet}
                      inputClasses="full-length"
                    />
                    <InputField
                      name="bankHouseNumber"
                      label="Hausnr."
                      type="text"
                      maxLength={HOUSE_NUMBER_MAX_LENGTH}
                      required
                      error={submitErrors.bankHouseNumber}
                      inputClasses="short"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} lg={8} className="small-then-big">
                    <InputField
                      name="bankPostcode"
                      label="PLZ"
                      type="number"
                      maxLength={POSTCODE_MAX_LENGTH}
                      required
                      error={submitErrors.bankPostcode}
                      inputClasses="short nospinners"
                    />
                    <InputField
                      name="bankCity"
                      label="Ort"
                      type="text"
                      maxLength={CITY_MAX_LENGTH}
                      required
                      error={submitErrors.bankCity}
                      inputClasses="full-length"
                    />
                  </Col>
                </Row>
              </Container>
            </Row>
          )}
          {bankPaymentMethod === BANK_PAYMENT_METHODS.DEBIT && (
            <Row>
              <Col xs={12} md={12} lg={8}>
                <CellCheckbox
                  id="bankSepa"
                  name="bankSepa"
                  formGroupClassName="bank-sepa"
                  text={getString(STRING_KEYS.CONSUMER_CONTRACT_SEPA_TEXT)}
                  errors={submitErrors.bankSepa}
                  usesReduxFormField
                />
              </Col>
            </Row>
          )}
        </Container>
        <Container>
          <Row>
            <Col className="continue-button-container">
              <ContinueButton
                onClick={handleSubmit((data) => this.handleSubmit(data))}
              />
            </Col>
          </Row>
        </Container>
      </Form>
    );
  }
}

let reduxFormPaymentDetailsForm = reduxForm({
  form: "consumerFlowPaymentDetailsForm",
  destroyOnUnmount: false,
  initialValues: {
    bankPaymentMethod: BANK_PAYMENT_METHODS.DEBIT
  }
})(PaymentDetailsForm);

const selector = formValueSelector("consumerFlowPaymentDetailsForm");
reduxFormPaymentDetailsForm = connect((state) => {
  const bankPaymentMethod = selector(state, "bankPaymentMethod");
  const bankIban = selector(state, "bankIban");
  const bankBic = selector(state, "bankBic");
  const bankFirstName = selector(state, "bankFirstName");
  const bankSurname = selector(state, "bankSurname");
  const bankStreet = selector(state, "bankStreet");
  const bankHouseNumber = selector(state, "bankHouseNumber");
  const bankPostcode = selector(state, "bankPostcode");
  const bankCity = selector(state, "bankCity");
  const bankSepa = selector(state, "bankSepa");

  return {
    bankPaymentMethod,
    bankIban,
    bankBic,
    bankFirstName,
    bankSurname,
    bankStreet,
    bankHouseNumber,
    bankPostcode,
    bankCity,
    bankSepa
  };
})(reduxFormPaymentDetailsForm);

reduxFormPaymentDetailsForm = connect((state) => ({
  submitErrors: getFormSubmitErrors("consumerFlowPaymentDetailsForm")(state)
}))(reduxFormPaymentDetailsForm);

export { reduxFormPaymentDetailsForm as PaymentDetailsForm };
