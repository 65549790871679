import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap
} from "react-google-maps";

import { GOOGLE_MAPS_API_KEY } from "../settings.js";
import { powerPlantMapIconsByType } from "../utils.js";

const DEFAULT_ZOOM = 8;

class MapComponent extends Component {
  render() {
    const { plantLongitude, plantLatitude, plantType } = this.props;

    const options = {
      disableDefaultUI: true,
      mapTypeControl: true,
      zoomControl: true
    };

    return (
      <GoogleMap
        ref="map"
        defaultZoom={DEFAULT_ZOOM}
        defaultCenter={{ lat: plantLatitude, lng: plantLongitude }}
        options={options}
      >
        <Marker
          position={{ lat: plantLatitude, lng: plantLongitude }}
          icon={powerPlantMapIconsByType[plantType]}
        />
      </GoogleMap>
    );
  }
}

const WithScriptjsWithGoogleMapMapComponent = withScriptjs(
  withGoogleMap(MapComponent)
);
const PowerPlantGoogleMap = (props) => (
  <WithScriptjsWithGoogleMapMapComponent
    plantLatitude={props.plantLatitude}
    plantLongitude={props.plantLongitude}
    plantType={props.plantType}
    googleMapURL={
      "https://maps.googleapis.com/maps/api/js?key=" +
      GOOGLE_MAPS_API_KEY +
      "&v=3.exp&libraries=geometry,drawing"
    }
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `300px` }} />}
    mapElement={
      <div style={{ height: `100%` }} className="PowerPlantGoogleMap" />
    }
  />
);

PowerPlantGoogleMap.propTypes = {
  plantLatitude: PropTypes.number,
  plantLongitude: PropTypes.number,
  plantType: PropTypes.string
};

export { PowerPlantGoogleMap };
