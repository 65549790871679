import * as Sentry from "@sentry/browser";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import './index.scss';
import { AUTHENTICATED, UNAUTHENTICATED } from "./actions";
import api from "./api";
import App from './App';
import rootReducer from "./reducers";
import reportWebVitals from './reportWebVitals';

import "./index.scss";
import {clearAuthTokens, isLoggedIn} from "axios-jwt";

// Send uncaught exceptions to Sentry (https://docs.sentry.io/platforms/javascript/react/)
// The Sentry DSN string is available via the build constant "process.env" (see env.js)
Sentry.init({ dsn: process.env.SENTRY_DSN });

const crashReporter = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    console.error("Caught an exception!", err);
    throw err;
  }
};

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware, // lets us dispatch() functions
      //loggerMiddleware, // neat middleware that logs actions
      crashReporter
    )
  )
);

if (isLoggedIn()) {
  store.dispatch({ type: AUTHENTICATED, payload: { user: {} } });
  api
    .get("rest-auth/user/")
    .then((response) => {
      store.dispatch({ type: AUTHENTICATED, payload: { user: response.data } });
    })
    .catch((error) => {
      clearAuthTokens()
      store.dispatch({ type: UNAUTHENTICATED });
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
