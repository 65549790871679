import { THEMES } from "./constants";

export const GOOGLE_MAPS_API_KEY = "AIzaSyA-fHTLfSMwubyQwgbcZ0uENXuL-PaKjDA";

export const MAP_OPTIONS = {
  [THEMES.CELLS_ENERGY]: {
    DEFAULT_ZOOM: 5,
    DEFAULT_LATITUDE: 51.165691, // default: Germany
    DEFAULT_LONGITUDE: 10.451526
  },
  [THEMES.SWL]: {
    DEFAULT_ZOOM: 9,
    DEFAULT_LATITUDE: 48.050580660048794,
    DEFAULT_LONGITUDE: 10.869725864135303
  },
  [THEMES.OBERLAND17]: {
    DEFAULT_ZOOM: 9,
    DEFAULT_LATITUDE: 48.133333,
    DEFAULT_LONGITUDE: 11.566667
  }
};

export const YEARLY_ENERGY_LIMITS = {
  [THEMES.CELLS_ENERGY]: {
    MIN: 1,
    MAX: 100000
  },
  [THEMES.SWL]: {
    MIN: 1,
    MAX: 40000
  },
  [THEMES.OBERLAND17]: {
    MIN: 1,
    MAX: 40000
  }
};
