import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

import { cancelSignInRequestAction } from "../actions";
import { STRING_KEYS } from "../constants";
import { checkFeature, FEATURES } from "../features";
import { getString } from "../utils";

function Login({
  isOpen,
  authenticated,
  forgotPasswordRequested,
  errorMessage,
  className,
  onLogin,
  onForgotPassword,
  cancelSignInRequest,
  onClose
}) {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  useEffect(() => {
    return () => {
      cancelSignInRequest();
    };
  }, []);

  useEffect(() => {
    if (isOpen && authenticated) {
      onClose();
    }
  }, [isOpen, authenticated]);

  function handleSubmit(values) {
    if (showForgotPassword) {
      onForgotPassword(values);
    } else {
      onLogin(values);
    }
  }

  function handleClickForgotPassword() {
    setShowForgotPassword(true);
    cancelSignInRequest();
  }

  function handleCancelForgotPassword() {
    setShowForgotPassword(false);
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} className={className}>
      {forgotPasswordRequested ? (
        <ForgotPasswordRequestedWidget onClose={onClose} />
      ) : (
        <LoginWidget
          errorMessage={errorMessage}
          forgotPasswordMode={showForgotPassword}
          onClose={onClose}
          onCancelForgotPassword={handleCancelForgotPassword}
          onSubmit={handleSubmit}
          onClickForgotPassword={handleClickForgotPassword}
        />
      )}
    </Modal>
  );
}

function LoginWidget({
  errorMessage,
  forgotPasswordMode,
  onClose,
  onCancelForgotPassword,
  onSubmit,
  onClickForgotPassword
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit() {
    if (forgotPasswordMode) {
      onSubmit({
        email: email
      });
    } else {
      onSubmit({
        email: email,
        password: password
      });
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault();
  }

  const title = getString(
    forgotPasswordMode
      ? STRING_KEYS.LOGIN_FORGOT_PASSWORD_TITLE
      : STRING_KEYS.LOGIN_TITLE
  );
  const submitButtonText = getString(
    forgotPasswordMode
      ? STRING_KEYS.LOGIN_FORGOT_PASSWORD_TITLE
      : STRING_KEYS.LOGIN_SUBMIT_BUTTON
  );
  const cancelButtonText = forgotPasswordMode ? "Zurück" : "Abbrechen";
  const forgotPasswordButtonText = getString(
    STRING_KEYS.LOGIN_FORGOT_PASSWORD_TITLE
  );

  const forgotPasswordFeatureFlag = checkFeature(FEATURES.FORGOT_PASSWORD);

  return (
    <React.Fragment>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <Form onSubmit={handleFormSubmit}>
        <ModalBody>
          <div className="container">
            <Input
              type="text"
              name="email"
              id="email"
              component="input"
              placeholder="E-Mail-Adresse"
              autoComplete="email"
              invalid={!!errorMessage}
              onChange={(e) => setEmail(e.target.value)}
            />
            {!forgotPasswordMode && (
              <React.Fragment>
                <br />
                <Input
                  type="password"
                  name="password"
                  id="password"
                  component="input"
                  placeholder="Passwort"
                  autoComplete="current password"
                  invalid={!!errorMessage}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </React.Fragment>
            )}
            <p className="error" style={{ marginTop: "20px" }}>
              {errorMessage}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" onClick={handleSubmit}>
            {submitButtonText}
          </Button>
          <Button
            color="secondary"
            onClick={forgotPasswordMode ? onCancelForgotPassword : onClose}
          >
            {cancelButtonText}
          </Button>
          {!forgotPasswordMode && forgotPasswordFeatureFlag && (
            <Button color="secondary" onClick={onClickForgotPassword}>
              {forgotPasswordButtonText}
            </Button>
          )}
        </ModalFooter>
      </Form>
    </React.Fragment>
  );
}

function ForgotPasswordRequestedWidget({ onClose }) {
  const title = getString(STRING_KEYS.LOGIN_FORGOT_PASSWORD_TITLE);

  return (
    <React.Fragment>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>
        <p>{getString(STRING_KEYS.LOGIN_FORGOT_PASSWORD_REQUESTED)}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onClose}>
          Schließen
        </Button>
      </ModalFooter>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    forgotPasswordRequested: state.auth.forgotPasswordRequested
  };
}

const mapDispatchToProps = (dispatch) => ({
  cancelSignInRequest: () => dispatch(cancelSignInRequestAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
