/* global THEME_VARS */
/* global THEMED_STRINGS */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { PulseLoader } from "react-spinners";
import * as Sentry from "@sentry/browser";

import { CURRENT_THEME, STRING_KEYS, THEMES } from "./constants";

import pvDachanlageImage from "./pages/producer/plant-images/pv-dachanlage.jpg";
import pvParkImage from "./pages/producer/plant-images/pv-park.jpg";
import biogasanlageImage from "./pages/producer/plant-images/biogasanlage.jpg";
import biomethananlageImage from "./pages/producer/plant-images/biomethananlage.jpg";
import biomassekraftwerkImage from "./pages/producer/plant-images/biomassekraftwerk.jpg";
import windkraftanlageImage from "./pages/producer/plant-images/windkraftanlage.jpg";
import windParkImage from "./pages/producer/plant-images/windpark.jpg";
import wasserkraftanlageImage from "./pages/producer/plant-images/wasserkraftanlage.jpg";

import biogasMapIcon from "./images/icons/icon-map-biogas.png";
import solarMapIcon from "./images/icons/icon-map-solar.png";
import waterMapIcon from "./images/icons/icon-map-wasserkraft.png";
import windMapIcon from "./images/icons/icon-map-windkraft.png";
import swlBiogasMapIcon from "./images/swl/icons/icon-map-biogas.png";
import swlSolarMapIcon from "./images/swl/icons/icon-map-solar.png";
import swlWaterMapIcon from "./images/swl/icons/icon-map-wasserkraft.png";
import swlWindMapIcon from "./images/swl/icons/icon-map-windkraft.png";
import oberland17BiogasMapIcon from "./images/oberland17/icons/icon-map-biogas.jpg";
import oberland17SolarMapIcon from "./images/oberland17/icons/icon-map-solar.jpg";
import oberland17WaterMapIcon from "./images/oberland17/icons/icon-map-wasserkraft.jpg";
import oberland17WindMapIcon from "./images/oberland17/icons/icon-map-windkraft.jpg";

import selectedBiogasMapIcon from "./images/icons/icon-map-biogas-selected.png";
import selectedSolarMapIcon from "./images/icons/icon-map-solar-selected.png";
import selectedWaterMapIcon from "./images/icons/icon-map-wasserkraft-selected.png";
import selectedWindMapIcon from "./images/icons/icon-map-windkraft-selected.png";
import swlSelectedBiogasMapIcon from "./images/swl/icons/icon-map-biogas-selected.png";
import swlSelectedSolarMapIcon from "./images/swl/icons/icon-map-solar-selected.png";
import swlSelectedWaterMapIcon from "./images/swl/icons/icon-map-wasserkraft-selected.png";
import swlSelectedWindMapIcon from "./images/swl/icons/icon-map-windkraft-selected.png";
import oberland17SelectedBiogasMapIcon from "./images/oberland17/icons/icon-map-biogas-selected.jpg";
import oberland17SelectedSolarMapIcon from "./images/oberland17/icons/icon-map-solar-selected.jpg";
import oberland17SelectedWaterMapIcon from "./images/oberland17/icons/icon-map-wasserkraft-selected.jpg";
import oberland17SelectedWindMapIcon from "./images/oberland17/icons/icon-map-windkraft-selected.jpg";

import biogasIcon from "./images/icons/icon-biogas-circle.png";
import solarIcon from "./images/icons/icon-solar-circle.png";
import waterIcon from "./images/icons/icon-wasserkraft-circle.png";
import windIcon from "./images/icons/icon-windkraft-circle.png";
import selectedBiogasIcon from "./images/icons/icon-biogas-selected.png";
import selectedSolarIcon from "./images/icons/icon-solar-selected.png";
import selectedWaterIcon from "./images/icons/icon-wasserkraft-selected.png";
import selectedWindIcon from "./images/icons/icon-windkraft-selected.png";

import swlBiogasIcon from "./images/swl/icons/icon-map-biogas.png";
import swlSolarIcon from "./images/swl/icons/icon-map-solar.png";
import swlWaterIcon from "./images/swl/icons/icon-map-wasserkraft.png";
import swlWindIcon from "./images/swl/icons/icon-map-windkraft.png";
import swlSelectedBiogasIcon from "./images/swl/icons/icon-map-biogas-selected.png";
import swlSelectedSolarIcon from "./images/swl/icons/icon-map-solar-selected.png";
import swlSelectedWaterIcon from "./images/swl/icons/icon-map-wasserkraft-selected.png";
import swlSelectedWindIcon from "./images/swl/icons/icon-map-windkraft-selected.png";

import oberland17BiogasIcon from "./images/oberland17/icons/icon-map-biogas.jpg";
import oberland17SolarIcon from "./images/oberland17/icons/icon-map-solar.jpg";
import oberland17WaterIcon from "./images/oberland17/icons/icon-map-wasserkraft.jpg";
import oberland17WindIcon from "./images/oberland17/icons/icon-map-windkraft.jpg";
import oberland17SelectedBiogasIcon from "./images/oberland17/icons/icon-map-biogas-selected.jpg";
import oberland17SelectedSolarIcon from "./images/oberland17/icons/icon-map-solar-selected.jpg";
import oberland17SelectedWaterIcon from "./images/oberland17/icons/icon-map-wasserkraft-selected.jpg";
import oberland17SelectedWindIcon from "./images/oberland17/icons/icon-map-windkraft-selected.jpg";

function getDefaultImageByType(type) {
  switch (type) {
    case "PV Dachanlage":
      return pvDachanlageImage;
    case "PV Park":
      return pvParkImage;
    case "Biogasanlage":
      return biogasanlageImage;
    case "Biomethananlage":
      return biomethananlageImage;
    case "Biomassekraftwerk":
      return biomassekraftwerkImage;
    case "Windkraftanlage":
      return windkraftanlageImage;
    case "Windpark":
      return windParkImage;
    case "Wasserkraftanlage":
      return wasserkraftanlageImage;
    default:
      return "http://via.placeholder.com/200x200";
  }
}

export function getPowerPlantMainImageInWideFormat(plant) {
  if (plant.plantWideFormatHeaderImage) {
    return plant.plantWideFormatHeaderImage;
  }

  return getPowerPlantMainImage(plant);
}

export function getPowerPlantMainImage(plant) {
  if (plant.plantHeaderImage && Array.isArray(plant.images)) {
    const imageData = plant.images.find(
      (image) => image.id === plant.plantHeaderImage
    );
    if (imageData) {
      return imageData.file;
    }
  }

  return getDefaultImageByType(plant.plantType);
}

function getPowerPlantMapIconsTypeDict() {
  switch (CURRENT_THEME) {
    case THEMES.CELLS_ENERGY:
      return {
        "PV Dachanlage": solarMapIcon,
        "PV Park": solarMapIcon,
        Biogasanlage: biogasMapIcon,
        Biomethananlage: biogasMapIcon,
        Biomassekraftwerk: biogasMapIcon,
        Windkraftanlage: windMapIcon,
        Windpark: windMapIcon,
        Wasserkraftanlage: waterMapIcon
      };
    case THEMES.SWL:
      return {
        "PV Dachanlage": swlSolarMapIcon,
        "PV Park": swlSolarMapIcon,
        Biogasanlage: swlBiogasMapIcon,
        Biomethananlage: swlBiogasMapIcon,
        Biomassekraftwerk: swlBiogasMapIcon,
        Windkraftanlage: swlWindMapIcon,
        Windpark: swlWindMapIcon,
        Wasserkraftanlage: swlWaterMapIcon
      };
    case THEMES.OBERLAND17:
      return {
        "PV Dachanlage": oberland17SolarMapIcon,
        "PV Park": oberland17SolarMapIcon,
        Biogasanlage: oberland17BiogasMapIcon,
        Biomethananlage: oberland17BiogasMapIcon,
        Biomassekraftwerk: oberland17BiogasMapIcon,
        Windkraftanlage: oberland17WindMapIcon,
        Windpark: oberland17WindMapIcon,
        Wasserkraftanlage: oberland17WaterMapIcon
      };
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      return {
        "PV Dachanlage": solarMapIcon,
        "PV Park": solarMapIcon,
        Biogasanlage: biogasMapIcon,
        Biomethananlage: biogasMapIcon,
        Biomassekraftwerk: biogasMapIcon,
        Windkraftanlage: windMapIcon,
        Windpark: windMapIcon,
        Wasserkraftanlage: waterMapIcon
      };
  }
}
export const powerPlantMapIconsByType = getPowerPlantMapIconsTypeDict();

function getPowerPlantMapSelectedIconsTypeDict() {
  switch (CURRENT_THEME) {
    case THEMES.CELLS_ENERGY:
      return {
        "PV Dachanlage": selectedSolarMapIcon,
        "PV Park": selectedSolarMapIcon,
        Biogasanlage: selectedBiogasMapIcon,
        Biomethananlage: selectedBiogasMapIcon,
        Biomassekraftwerk: selectedBiogasMapIcon,
        Windkraftanlage: selectedWindMapIcon,
        Windpark: selectedWindMapIcon,
        Wasserkraftanlage: selectedWaterMapIcon
      };
    case THEMES.SWL:
      return {
        "PV Dachanlage": swlSelectedSolarMapIcon,
        "PV Park": swlSelectedSolarMapIcon,
        Biogasanlage: swlSelectedBiogasMapIcon,
        Biomethananlage: swlSelectedBiogasMapIcon,
        Biomassekraftwerk: swlSelectedBiogasMapIcon,
        Windkraftanlage: swlSelectedWindMapIcon,
        Windpark: swlSelectedWindMapIcon,
        Wasserkraftanlage: swlSelectedWaterMapIcon
      };
    case THEMES.OBERLAND17:
      return {
        "PV Dachanlage": oberland17SelectedSolarMapIcon,
        "PV Park": oberland17SelectedSolarMapIcon,
        Biogasanlage: oberland17SelectedBiogasMapIcon,
        Biomethananlage: oberland17SelectedBiogasMapIcon,
        Biomassekraftwerk: oberland17SelectedBiogasMapIcon,
        Windkraftanlage: oberland17SelectedWindMapIcon,
        Windpark: oberland17SelectedWindMapIcon,
        Wasserkraftanlage: oberland17SelectedWaterMapIcon
      };
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      return {
        "PV Dachanlage": selectedSolarMapIcon,
        "PV Park": selectedSolarMapIcon,
        Biogasanlage: selectedBiogasMapIcon,
        Biomethananlage: selectedBiogasMapIcon,
        Biomassekraftwerk: selectedBiogasMapIcon,
        Windkraftanlage: selectedWindMapIcon,
        Windpark: selectedWindMapIcon,
        Wasserkraftanlage: selectedWaterMapIcon
      };
  }
}
export const powerPlantMapSelectedIconsByType =
  getPowerPlantMapSelectedIconsTypeDict();

function getPowerPlantCircleIconsTypeDict() {
  switch (CURRENT_THEME) {
    case THEMES.CELLS_ENERGY:
      return {
        "PV Dachanlage": solarIcon,
        "PV Park": solarIcon,
        Biogasanlage: biogasIcon,
        Biomethananlage: biogasIcon,
        Biomassekraftwerk: biogasIcon,
        Windkraftanlage: windIcon,
        Windpark: windIcon,
        Wasserkraftanlage: waterIcon
      };
    case THEMES.SWL:
      return {
        "PV Dachanlage": swlSolarIcon,
        "PV Park": swlSolarIcon,
        Biogasanlage: swlBiogasIcon,
        Biomethananlage: swlBiogasIcon,
        Biomassekraftwerk: swlBiogasIcon,
        Windkraftanlage: swlWindIcon,
        Windpark: swlWindIcon,
        Wasserkraftanlage: swlWaterIcon
      };
    case THEMES.OBERLAND17:
      return {
        "PV Dachanlage": oberland17SolarIcon,
        "PV Park": oberland17SolarIcon,
        Biogasanlage: oberland17BiogasIcon,
        Biomethananlage: oberland17BiogasIcon,
        Biomassekraftwerk: oberland17BiogasIcon,
        Windkraftanlage: oberland17WindIcon,
        Windpark: oberland17WindIcon,
        Wasserkraftanlage: oberland17WaterIcon
      };
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      return {
        "PV Dachanlage": solarIcon,
        "PV Park": solarIcon,
        Biogasanlage: biogasIcon,
        Biomethananlage: biogasIcon,
        Biomassekraftwerk: biogasIcon,
        Windkraftanlage: windIcon,
        Windpark: windIcon,
        Wasserkraftanlage: waterIcon
      };
  }
}
export const powerPlantCircleIconsByType = getPowerPlantCircleIconsTypeDict();

function getPowerPlantSelectedIconsTypeDict() {
  switch (CURRENT_THEME) {
    case THEMES.CELLS_ENERGY:
      return {
        "PV Dachanlage": selectedSolarIcon,
        "PV Park": selectedSolarIcon,
        Biogasanlage: selectedBiogasIcon,
        Biomethananlage: selectedBiogasIcon,
        Biomassekraftwerk: selectedBiogasIcon,
        Windkraftanlage: selectedWindIcon,
        Windpark: selectedWindIcon,
        Wasserkraftanlage: selectedWaterIcon
      };
    case THEMES.SWL:
      return {
        "PV Dachanlage": swlSelectedSolarIcon,
        "PV Park": swlSelectedSolarIcon,
        Biogasanlage: swlSelectedBiogasIcon,
        Biomethananlage: swlSelectedBiogasIcon,
        Biomassekraftwerk: swlSelectedBiogasIcon,
        Windkraftanlage: swlSelectedWindIcon,
        Windpark: swlSelectedWindIcon,
        Wasserkraftanlage: swlSelectedWaterIcon
      };
    case THEMES.OBERLAND17:
      return {
        "PV Dachanlage": oberland17SelectedSolarIcon,
        "PV Park": oberland17SelectedSolarIcon,
        Biogasanlage: oberland17SelectedBiogasIcon,
        Biomethananlage: oberland17SelectedBiogasIcon,
        Biomassekraftwerk: oberland17SelectedBiogasIcon,
        Windkraftanlage: oberland17SelectedWindIcon,
        Windpark: oberland17SelectedWindIcon,
        Wasserkraftanlage: oberland17SelectedWaterIcon
      };
    default:
      console.warn(`Missing THEME implementation for ${CURRENT_THEME}.`);
      return {
        "PV Dachanlage": selectedSolarIcon,
        "PV Park": selectedSolarIcon,
        Biogasanlage: selectedBiogasIcon,
        Biomethananlage: selectedBiogasIcon,
        Biomassekraftwerk: selectedBiogasIcon,
        Windkraftanlage: selectedWindIcon,
        Windpark: selectedWindIcon,
        Wasserkraftanlage: selectedWaterIcon
      };
  }
}
export const powerPlantSelectedIconsByType =
  getPowerPlantSelectedIconsTypeDict();

// this function used to be more complex and I would rather keep it here,
// so there is one central place, in case we change the naming mechanics again
export function getProducerDisplayName(plant) {
  return plant.displayName;
}

export function getMixDisplayName(name) {
  switch (name) {
    case "closest":
      return "Nachbarn-Mix";
    case "cheapest":
      return "Günstigster Mix";
    default:
      return "Unknown Mix";
  }
}

// choose the producer power plants that match the mix list
export function getMixPowerPlantsFromMixIdsAndProducerPowerPlants(
  mixPowerPlantIds,
  producerPowerPlants
) {
  if (!Array.isArray(mixPowerPlantIds) || !producerPowerPlants) {
    return null;
  }

  const mixPowerPlants = mixPowerPlantIds.map((mixPlantId) => {
    const matchingPlant = producerPowerPlants.find(
      (producerPlant) => producerPlant.id === mixPlantId
    );

    if (!matchingPlant) {
      Sentry.captureMessage(
        `Plant with id ${mixPlantId} not found in /api/power-plants/, during getMixPowerPlantsFromMixIdsAndProducerPowerPlants`
      );
    }

    return matchingPlant;
  });

  return mixPowerPlants.filter((plant) => !!plant);
}

export function calculateAdditionalMargins(customMargins, powerPlants) {
  const additionalMargins = { ...customMargins };

  for (let plantId in customMargins) {
    additionalMargins[plantId] =
      customMargins[plantId] -
      powerPlants.find((plant) => plant.id === plantId).plantMargin;
  }

  return additionalMargins;
}

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const withRouterScrollToTop = withRouter(ScrollToTop);
export { withRouterScrollToTop as ScrollToTop };

export const LinkToProfilePageWithBack = ({ children, id, slug, ...other }) => (
  <Link
    {...other}
    to={{
      pathname: `/strom-kaufen/anlagen/${id}/${slug}`,
      state: { prevPath: window.location.pathname }
    }}
  >
    {children}
  </Link>
);

export const VatMarker = () => <span>*</span>;
export const Price = ({ value, unit, hideVatMarker = false }) => (
  <span>
    {value.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}{" "}
    {unit}
    {!hideVatMarker && <VatMarker />}
  </span>
);

export const Coverage = ({ value }) => {
  let roundedValue = value * 100;

  if (roundedValue < 0.0001) {
    // probably a floating point error
    roundedValue = 0;
  } else if (roundedValue < 1) {
    // better to show 1 than 0
    roundedValue = 1;
  } else if (roundedValue > 100) {
    // catch rounding errors (you can't have more than 100%)
    roundedValue = 100;
  } else if (roundedValue > 99.999) {
    // probably a floating point error
    roundedValue = 100;
  } else if (roundedValue > 99) {
    // better to show 99 than a false 100%
    roundedValue = 99;
  } else {
    // round to the nearest integer
    roundedValue = Math.round(roundedValue);
  }

  return <span>{roundedValue}%</span>;
};

export const OptionalMarker = () => (
  <span className="optional">
    {" "}
    - <em>Optional</em>
  </span>
);
export const Loader = () => <PulseLoader color={THEME_VARS.default.primary} />;

export function getString(key, replacements) {
  if (
    !Object.values(STRING_KEYS).includes(key) ||
    !THEMED_STRINGS.STRINGS[key]
  ) {
    Sentry.captureMessage(`Missing theme string: ${key}`);

    return "error: missing string";
  }

  let themedString = THEMED_STRINGS.STRINGS[key];

  if (replacements) {
    themedString = replacements.reduce((string, replacement) => {
      return string.replace(`{${replacement[0]}}`, replacement[1]);
    }, themedString);
  }

  return themedString;
}

export function MarkdownString({
  stringKey,
  openLinksInNewWindow,
  replacements
}) {
  const source = getString(stringKey, replacements);

  return (
    <ReactMarkdown
      components={{ Link: RouterLink }}
      linkTarget={openLinksInNewWindow ? "_blank" : null}
    >
      {source}
    </ReactMarkdown>
  );
}

function RouterLink(props) {
  return props.href.match(/^(https?:)?\/\//) ? (
    <a href={props.href}>{props.children}</a>
  ) : (
    <Link to={props.href}>{props.children}</Link>
  );
}
